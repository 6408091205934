import React, { useState } from "react";
import { ActionIcon, SyncIcon, TSize, Tooltip, errorManager } from "@budgeinc/budge-ui-core";
import { AxiosError } from "axios";
import { ErrorResponse } from "@budgeinc/budge-api";
import { delayed } from "utils/helpers";
import {
  getMethodSyncModalTitleByType,
  TMethodEntitySyncType,
  openMethodSyncModal,
  methodSyncByTypeAndId,
} from "./utils";
import { methodPrimaryColor } from "./constants";

interface OwnProps<T> {
  recordId: string;
  type: TMethodEntitySyncType;
  size?: TSize;
  onSynced?(payload: T): void;
}

const MethodSyncButton = <T,>({ recordId, type, size = "xs", onSynced }: OwnProps<T>) => {
  const [loading, setLoading] = useState(false);

  const handleSync = () => {
    setLoading(true);
    methodSyncByTypeAndId(type, recordId, true)
      .then(data => {
        // small hack so that the tooltip close when the
        // button is enabled after loading
        // TODO: Ajust tooltip component to prevent this
        delayed(() => openMethodSyncModal<T>({ type, rawData: data.rawData, recordId, onSynced }), 50);
      })
      .catch((e: AxiosError<ErrorResponse>) =>
        errorManager.showError({
          error: e.response?.data,
          customMessage: (e.response?.data as any)?.data?.exceptionData?.exceptionMessage,
        })
      )
      .finally(() => setLoading(false));
  };

  return (
    <Tooltip content={getMethodSyncModalTitleByType(type)}>
      <ActionIcon
        icon={SyncIcon}
        size={size}
        r="xl"
        color={methodPrimaryColor}
        iconColor="dark"
        variant="gradient"
        loading={loading}
        onPress={() => handleSync()}
      />
    </Tooltip>
  );
};

export default MethodSyncButton;
