import { Text } from "react-native";
import React, { useEffect, useState } from "react";
import { employeesApi, referralsApi } from "api/BudgeApi";
import { ReferralOutput, ReferralOutputReferralEligibilityStatusEnum } from "@budgeinc/budge-api";
import {
  DeleteIcon,
  Description,
  Pressable,
  SuccessIcon,
  Table,
  Tag,
  Tooltip,
  formatMoney,
  intlFormatDate,
} from "@budgeinc/budge-ui-core";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";
import { employeeEntityRoute } from "common/routes";
import { Link } from "components/Link";

const ReferralsListTab = () => {
  const { state } = useEmployeeEntityContext();
  const [loading, setLoading] = useState(true);
  const [referrals, setReferrals] = useState<ReferralOutput[]>([]);

  useEffect(() => {
    if (state.employee) {
      referralsApi
        .getEmployeeReferralPaymentsForecasted(state.employee.id)
        .then(({ data }) => setReferrals(data))
        .finally(() => setLoading(false));
    }
  }, [state.employee?.id]);

  return (
    <Table<ReferralOutput>
      data={referrals}
      columns={[
        {
          id: "name",
          title: "Name",
          render: record => (
            <Text numberOfLines={1}>
              <Link
                to={employeeEntityRoute(state.employee?.employerId!, record.referralEmployeeId)}
                onClick={e => e.stopPropagation()}
              >
                {record.referralFirstName} {record.referralLastName}
              </Link>
            </Text>
          ),
        },
        {
          id: "amount",
          title: "Amount",
          render: record => <Text>{formatMoney({ amountCents: record.referralAmountCents })}</Text>,
        },
        {
          id: "eligible",
          title: "Eligible",
          render: record =>
            record.referralEligibilityStatus !== ReferralOutputReferralEligibilityStatusEnum.IsNotEligible ? (
              <Tag
                value={
                  record.referralEligibilityStatus === ReferralOutputReferralEligibilityStatusEnum.AlreadyPaid
                    ? "Paid"
                    : "Eligible"
                }
                icon={SuccessIcon}
                color="green"
              />
            ) : (
              <Tooltip content={record.nonEligibleReason || "Unknown reason"}>
                <Pressable>
                  <Tag value="Not Eligible" icon={DeleteIcon} color="red" />
                </Pressable>
              </Tooltip>
            ),
        },
        {
          id: "joinon",
          title: "Join On",
          render: record => <Text numberOfLines={1}>{intlFormatDate({ value: record.referralJoinedDate })}</Text>,
        },
      ]}
      loading={loading}
      local={{
        empty: {
          title: "No referrals",
        },
      }}
      expandable={record => (
        <Description>
          <Description.Item label="Referral Employee ID">{record.referralEmployeeId}</Description.Item>
          <>
            {record.referralEligibilityStatus === ReferralOutputReferralEligibilityStatusEnum.IsNotEligible && (
              <Description.Item label="Non Eligible Reason">{record.nonEligibleReason}</Description.Item>
            )}
          </>
        </Description>
      )}
    />
  );
};

export default ReferralsListTab;
