import React, { useEffect, useState } from "react";
import { backofficeApi } from "api/BudgeApi";
import { ActionTakingOutput, ActionTakingOutputTypeEnum } from "@budgeinc/budge-api";
import { Box, Button, Card, FormProvider, Grid, Stack, Text } from "@budgeinc/budge-ui-core";
import { useFormik } from "formik";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";
import CenteredSpinner from "components/CenteredSpinner";
import { getApiPayload, getFormInitialValue } from "./utils";
import ActionTakingGrid from "./components/ActionTakingGrid";

const ActionTakingTab = () => {
  const { state } = useEmployeeEntityContext();
  const [initLoading, setInitLoading] = useState(true);
  const [actionTaking, setActionTaking] = useState<ActionTakingOutput[]>([]);

  useEffect(() => {
    if (state.employee) {
      backofficeApi
        .getActionTakingForEmployee(state.employee.id)
        .then(({ data }) => setActionTaking(data))
        .finally(() => setInitLoading(false));
    }
  }, []);

  const savingActionTaking = actionTaking
    .sort((a, b) => a.sortOrder - b.sortOrder)
    .filter(({ type }) => type === ActionTakingOutputTypeEnum.Savings);

  const debtActionTaking = actionTaking
    .sort((a, b) => a.sortOrder - b.sortOrder)
    .filter(({ type }) => type === ActionTakingOutputTypeEnum.Debt);

  const form = useFormik({
    initialValues: getFormInitialValue(actionTaking),
    enableReinitialize: true,
    onSubmit: values => {
      if (state.employee) {
        return backofficeApi
          .saveActionTakingForEmployee(state.employee.id, getApiPayload(values, actionTaking))
          .then(({ data }) => setActionTaking(data));
      }
    },
  });

  if (initLoading) {
    return <CenteredSpinner />;
  }

  return (
    <Box pb={40}>
      <Card>
        <FormProvider value={form}>
          <Stack spacing="lg">
            <Grid column={4} gutter={0} bordered>
              <Box f={1} p="md">
                <Text fw="600" maw="75%">
                  Recommendations
                </Text>
              </Box>
              <Box f={1} p="md" alignItems="center">
                <Text fw="600" maw="65%" ta="center">
                  User Was Recommended By Budge Coach
                </Text>
              </Box>
              <Box f={1} p="md" alignItems="center">
                <Text fw="600" maw="65%" ta="center">
                  User Executed On Budge Coach Recommendation
                </Text>
              </Box>
              <Box f={1} p="md" alignItems="center">
                <Text fw="600" maw="65%" ta="center">
                  Comment
                </Text>
              </Box>
            </Grid>
            <ActionTakingGrid title="SAVINGS" actionTakingItems={savingActionTaking} />
            <ActionTakingGrid title="DEBT" actionTakingItems={debtActionTaking} />
            <Button
              title="Save"
              color="primary"
              size="sm"
              variant="gradient"
              alignSelf="flex-end"
              loading={form.isSubmitting}
              onPress={form.submitForm}
              disabled={!form.dirty}
            />
          </Stack>
        </FormProvider>
      </Card>
    </Box>
  );
};

export default ActionTakingTab;
