/* eslint-disable react/no-unstable-nested-components */
import { OnePaymentPlanOutput } from "@budgeinc/budge-api";
import { InfiniteScrollTable, Description } from "@budgeinc/budge-ui-core";
import { hasFilters } from "components/ListFilterBar/utils";
import { getOnePayPlanColumns } from "./columns";
import { TInfiniteScrollDataTableProps } from "../types";
import { useOnePayPlanFilterContext } from "./filters/context";

type OwnProps = TInfiniteScrollDataTableProps<OnePaymentPlanOutput> & {
  employerId: string;
};

const OnePayPlansTable = ({ emptyLocal, employerId, ...props }: OwnProps) => {
  const filters = useOnePayPlanFilterContext();

  return (
    <InfiniteScrollTable
      {...props}
      columns={getOnePayPlanColumns(employerId)}
      local={{
        empty: {
          m: 24,
          title: "No onepay plans found",
          description: hasFilters(filters)
            ? `No onepay plan match these filters. Edit or clear all filters.`
            : undefined,
          ...emptyLocal,
        },
        noMoreItems: "No more onepay plans to load",
      }}
      contentContainerStyle={{ marginBottom: 16 }}
      expandable={({ id }) => (
        <Description>
          <Description.Item label="ID">{id}</Description.Item>
        </Description>
      )}
    />
  );
};

export default OnePayPlansTable;
