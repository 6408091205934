import { useContext } from "react";
import { ProgramEnrollmentOutput } from "@budgeinc/budge-api";
import { createInfiniteScrollDataTableContext } from "components/Table/tableContext";

export const ProgramEnrollmentDataTableContext = createInfiniteScrollDataTableContext<ProgramEnrollmentOutput>();

export const useProgramEnrollmentDataTableContext = () => {
  const context = useContext(ProgramEnrollmentDataTableContext);

  if (!context) {
    throw new Error(
      "ProgramEnrollmentDataTableContext must be used inside an ProgramEnrollmentDataTableContext.Provider"
    );
  }

  return context;
};
