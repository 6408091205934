import { useState } from "react";
import { Button, Stack, modalManager, toast } from "@budgeinc/budge-ui-core";
import { usersApi } from "api/BudgeApi";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";

interface OwnProps {
  flag: "isReadOnly" | "isKpiExcluded";
  isActiveButtonText: string;
  isInactiveButtonText: string;
  confirmText: string;
}

const ToggleUserFlagButton = ({ flag, isActiveButtonText, isInactiveButtonText, confirmText }: OwnProps) => {
  const { state, dispatch } = useEmployeeEntityContext();
  const [isLoading, setLoading] = useState(false);

  const toggleFlag = async () => {
    if (!state.user || !state.employee || !state.employee.userId) {
      return false;
    }

    return usersApi
      .updateUser(state.employee.userId, {
        [flag]: !state.user[flag],
      })
      .then(resp => {
        dispatch({
          type: "setUser",
          data: resp.data,
        });
        toast.success({
          message: "User saved successfully",
        });
      });
  };

  const toggleConfirm = () =>
    modalManager.openConfirm({
      children: confirmText,
      onConfirm: toggleFlag,
      confirmProps: {
        title: "Yes",
      },
    });

  const handleToggleFlag = async () => {
    setLoading(true);
    await toggleFlag();
    setLoading(false);
  };

  const isActive = state.user?.[flag];

  return (
    <>
      {state.user && (
        <Stack.Horizontal justifyContent="flex-end">
          <Button
            title={isActive ? isActiveButtonText : isInactiveButtonText}
            size="xs"
            color="red"
            variant="gradient"
            onPress={isActive ? handleToggleFlag : toggleConfirm}
            loading={isLoading}
          />
        </Stack.Horizontal>
      )}
    </>
  );
};

export default ToggleUserFlagButton;
