import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormItem,
  FormProvider,
  Grid,
  Input,
  Stack,
  Text,
  getApiErrorMessage,
} from "@budgeinc/budge-ui-core";
import { intakeApi } from "api/BudgeApi";
import { ErrorResponse, IntakePainOutput } from "@budgeinc/budge-api";
import { useFormik } from "formik";
import { useAppMessages } from "store/global";
import { AxiosError } from "axios";
import TabFormStateChecker from "features/financial-profiles/components/TabFormStateChecker";
import CenteredSpinner from "components/CenteredSpinner";
import { IntakePainFormItemType } from "./types";
import { getApiPayload, getFormInitialValue, getIntakeWhyFormItemName } from "./utils";
import { useFinancialProfileEntityContext } from "../../contexts/FinancialProfileEntityContext";

const PainsTab = () => {
  const messages = useAppMessages();
  const { state } = useFinancialProfileEntityContext();
  const [initLoading, setInitLoading] = useState(true);
  const [painOutput, setPainOutput] = useState<IntakePainOutput[]>([]);
  const [formError, setFormError] = useState<string>();

  useEffect(() => {
    if (state.financialProfile?.id) {
      intakeApi
        .getEmployeeIntakePainData(state.financialProfile.id)
        .then(({ data }) => setPainOutput(data))
        .finally(() => setInitLoading(false));
    }
  }, [state.financialProfile?.id]);

  const form = useFormik({
    initialValues: getFormInitialValue(painOutput),
    enableReinitialize: true,
    onSubmit: values => {
      if (state.financialProfile) {
        return intakeApi
          .saveEmployeeIntakePainData(state.financialProfile.id, getApiPayload(values, painOutput))
          .then(({ data }) => setPainOutput(data))
          .catch((e: AxiosError<ErrorResponse>) => setFormError(getApiErrorMessage(messages, e.response?.data)));
      }
    },
  });

  if (initLoading) {
    return <CenteredSpinner />;
  }

  return (
    <>
      <Card>
        <FormProvider value={form} formErrorMsg={formError}>
          <Stack spacing="md">
            <Grid column={3} gutter={0} bordered grow>
              <Box p="md">
                <Text fw="600">What is your financial pain point?</Text>
                <Text>What area of your personal finances is causing you to stress out?</Text>
              </Box>
              <Box p="md">
                <Text fw="600" ta="center">
                  (Check all that apply)
                </Text>
              </Box>
              <Box p="md">
                <Text fw="600" ta="center">
                  Comment
                </Text>
              </Box>
              {painOutput
                .sort((a, b) => a.sortOrder - b.sortOrder)
                .map(pain => [
                  <Box p="md">
                    <Text>{pain.description}</Text>
                  </Box>,
                  <Box p="md" alignItems="center">
                    <FormItem
                      name={getIntakeWhyFormItemName(pain.intakePainElementId, IntakePainFormItemType.VALUE)}
                      w="100%"
                    >
                      <Checkbox alignSelf="center" />
                    </FormItem>
                  </Box>,
                  <Box p="md" alignItems="center">
                    <FormItem
                      name={getIntakeWhyFormItemName(pain.intakePainElementId, IntakePainFormItemType.COMMENT)}
                      w="100%"
                    >
                      <Input multiline numberOfLines={4} />
                    </FormItem>
                  </Box>,
                ])}
            </Grid>
            <Button
              title="Save"
              size="sm"
              color="primary"
              variant="gradient"
              alignSelf="flex-end"
              onPress={form.submitForm}
              loading={form.isSubmitting}
              disabled={!form.dirty}
            />
          </Stack>
        </FormProvider>
      </Card>
      <TabFormStateChecker canNavigate={!form.dirty} onSaveChanges={form.submitForm} />
    </>
  );
};

export default PainsTab;
