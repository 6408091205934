/* eslint-disable react/no-array-index-key */
import React from "react";
import { Col, Row, TBoxProps, Text } from "@budgeinc/budge-ui-core";
import { StyleSheet } from "react-native";

export type TGridTableHeader = TBoxProps & {
  title?: string;
};

interface OwnProps {
  headers: TGridTableHeader[];
}
const GridTableHeader = ({ headers }: OwnProps) => (
  <Row style={styles.sticky}>
    {headers.map(({ title, ...props }, index) => (
      <Col key={index} px="md" {...props}>
        <Text fw="600">{title}</Text>
      </Col>
    ))}
  </Row>
);

const styles = StyleSheet.create({
  sticky: {
    // @ts-ignore web only
    position: "sticky",
    zIndex: 100,
    backgroundColor: "white",
    top: -41,
  },
});

export default GridTableHeader;
