import { LoginHistoryOutput, LoginHistoryOutputTargetEnum } from "@budgeinc/budge-api";
import {
  TColumnProps,
  Text,
  Tag,
  formatEmployeeName,
  i18n,
  EMPTY_VALUE_PLACEHOLDER,
  SuccessIcon,
  DeleteIcon,
  Tooltip,
  Pressable,
} from "@budgeinc/budge-ui-core";
import { TColorToken } from "@budgeinc/budge-ui-styling";
import { employeeEntityRoute } from "common/routes";
import { Link } from "components/Link";
import DateDisplay from "components/DateDisplay";
import GeoLocationDetails from "components/GeoLocationDetails";

const AppTargetColorMap: Record<LoginHistoryOutputTargetEnum, TColorToken> = {
  EMPLOYEE_APP: "gray",
  EMPLOYER_APP: "blue",
  ADMIN_APP: "primary",
};

export const getLoginsColumns = (includeEmployeeColumns: boolean = false): TColumnProps<LoginHistoryOutput>[] => {
  const columns: TColumnProps<LoginHistoryOutput>[] = [];

  if (includeEmployeeColumns) {
    columns.push({
      id: "name",
      title: "Name",
      maxWidth: 200,
      render: ({ employee, firstName, lastName }) => (
        <Text numberOfLines={1}>
          {employee ? (
            <Link to={employeeEntityRoute(employee.employerId, employee.id)} onClick={e => e.stopPropagation()}>
              {formatEmployeeName({ employee })}
            </Link>
          ) : (
            formatEmployeeName({
              employee: { firstName, lastName } as any,
              fallback: EMPTY_VALUE_PLACEHOLDER,
            })
          )}
        </Text>
      ),
    });
  }

  columns.push(
    {
      id: "target",
      title: "App",
      maxWidth: 200,
      render: record => (
        <Tag color={AppTargetColorMap[record.target]} value={i18n.get(`enums.loginHistoryTargets.${record.target}`)} />
      ),
    },
    {
      id: "ipAddress",
      title: "Location",
      maxWidth: 250,
      render: record => <GeoLocationDetails ipAddress={record.ipAddress} geoLocation={record.geoLocation} />,
    },
    {
      id: "success",
      title: "Success",
      maxWidth: 250,
      render: record =>
        record.success ? (
          <Tag value="Succeed" icon={SuccessIcon} color="green" />
        ) : (
          <Tooltip content={(record.failedReason as any) || "Unknown reason"}>
            <Pressable>
              <Tag value="Failed" icon={DeleteIcon} color="red" />
            </Pressable>
          </Tooltip>
        ),
    },
    {
      id: "loginat",
      title: "Login at",
      render: record => <DateDisplay value={record.lastLoginAt} showTime />,
    }
  );

  return columns;
};
