import React, { useEffect, useState } from "react";
import {
  BuildValidationSchema,
  Col,
  DeleteIcon,
  FormItem,
  FormProvider,
  Input,
  MoneyInput,
  NumberInput,
  Pressable,
  Row,
  Select,
  Tooltip,
  i18n,
  yup,
  isNil,
} from "@budgeinc/budge-ui-core";
import { useFormik } from "formik";
import {
  IntakeDebtDataPointInput,
  IntakeDebtDataPointInputIntakeTargetEnum,
  IntakeDebtDataPointOutput,
} from "@budgeinc/budge-api";
import { intakeApi } from "api/BudgeApi";
import { useFinancialProfileEntityContext } from "features/financial-profiles/entity/contexts/FinancialProfileEntityContext";
import { getElementTypeDisplay } from "features/financial-profiles/entity/utils";
import MultilineInputModal from "features/financial-profiles/components/MultilineInputModal";
import { useDebtsContext } from "../context";
import { DEBT_FIELDS, MONEY_COL_MAW } from "../types";

interface OwnProps {
  initialData?: IntakeDebtDataPointOutput;
  localId: string;
}

const validationSchema = BuildValidationSchema({
  [DEBT_FIELDS.TYPE]: yup.string().required(),
  [DEBT_FIELDS.COMPANY_NAME]: yup.string().nullable().optional(),
  [DEBT_FIELDS.BALANCE_CURRENT]: yup.number().required(),
  [DEBT_FIELDS.BALANCE_ORIGINAL]: yup.number().nullable().optional(),
  [DEBT_FIELDS.INTEREST_RATE]: yup.number().nullable().optional(),
  [DEBT_FIELDS.MONTHLY_PAYMENT]: yup.number().nullable().optional(),
  [DEBT_FIELDS.PROGRAM_TYPE]: yup.string().nullable().optional(),
  [DEBT_FIELDS.COMMENT]: yup.string().nullable().optional(),
  [DEBT_FIELDS.TARGET]: yup.string().oneOf(Object.values(IntakeDebtDataPointInputIntakeTargetEnum)).required(),
});

const DebtRow = ({ initialData, localId }: OwnProps) => {
  const { state, dispatch } = useDebtsContext();
  const { state: fpState } = useFinancialProfileEntityContext();
  const [intakeDebt, setIntakeDebt] = useState(initialData);
  const selectedElement = state.elements.find(({ id }) => id === intakeDebt?.intakeDebtElementId);

  const form = useFormik({
    initialValues: {
      [DEBT_FIELDS.TYPE]: intakeDebt?.intakeDebtElementId
        ? getElementTypeDisplay(intakeDebt.intakeDebtElementId, state.elements).sub
        : "",
      [DEBT_FIELDS.COMPANY_NAME]: intakeDebt?.companyName || "",
      [DEBT_FIELDS.BALANCE_CURRENT]: isNil(intakeDebt?.balanceCurrentCents)
        ? undefined
        : intakeDebt?.balanceCurrentCents,
      [DEBT_FIELDS.BALANCE_ORIGINAL]: isNil(intakeDebt?.balanceOriginalCents)
        ? undefined
        : intakeDebt?.balanceOriginalCents,
      [DEBT_FIELDS.MONTHLY_PAYMENT]: isNil(intakeDebt?.monthlyPaymentCents)
        ? undefined
        : intakeDebt?.monthlyPaymentCents,
      [DEBT_FIELDS.INTEREST_RATE]: isNil(intakeDebt?.interestRate) ? undefined : intakeDebt?.interestRate,
      [DEBT_FIELDS.PROGRAM_TYPE]: intakeDebt?.programType || "",
      [DEBT_FIELDS.TERM]: isNil(intakeDebt?.termLengthMonths) ? undefined : intakeDebt?.termLengthMonths,
      [DEBT_FIELDS.TARGET]: intakeDebt?.intakeTarget || IntakeDebtDataPointInputIntakeTargetEnum.Self,
      [DEBT_FIELDS.COMMENT]: intakeDebt?.comment || "",
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: values => {
      if (fpState.financialProfile && intakeDebt?.intakeDebtElementId) {
        const payload: IntakeDebtDataPointInput = {
          intakeDebtElementId: intakeDebt.intakeDebtElementId,
          companyName: values[DEBT_FIELDS.COMPANY_NAME] || null,
          balanceCurrentCents: isNil(values[DEBT_FIELDS.BALANCE_CURRENT])
            ? undefined
            : values[DEBT_FIELDS.BALANCE_CURRENT],
          balanceOriginalCents: isNil(values[DEBT_FIELDS.BALANCE_ORIGINAL])
            ? null
            : values[DEBT_FIELDS.BALANCE_ORIGINAL],
          monthlyPaymentCents: isNil(values[DEBT_FIELDS.MONTHLY_PAYMENT]) ? null : values[DEBT_FIELDS.MONTHLY_PAYMENT],
          interestRate: isNil(values[DEBT_FIELDS.INTEREST_RATE]) ? null : values[DEBT_FIELDS.INTEREST_RATE],
          programType: selectedElement?.allowProgramType ? values[DEBT_FIELDS.PROGRAM_TYPE] : undefined,
          termLengthMonths: selectedElement?.allowTermLengthMonths
            ? isNil(values[DEBT_FIELDS.TERM])
              ? null
              : values[DEBT_FIELDS.TERM]
            : undefined,
          comment: values[DEBT_FIELDS.COMMENT] || null,
          intakeTarget: values[DEBT_FIELDS.TARGET],
        };

        if (intakeDebt?.id) {
          return intakeApi
            .updateEmployeeIntakeDebtDataPoint(fpState.financialProfile.id, intakeDebt.id, payload)
            .then(({ data }) => setIntakeDebt(data));
        }
        return intakeApi
          .createEmployeeIntakeDebtDataPoint(fpState.financialProfile.id, payload)
          .then(({ data }) => setIntakeDebt(data));
      }
    },
  });

  useEffect(() => {
    dispatch({
      type: "registerForm",
      data: {
        id: localId,
        dirty: false,
        target: intakeDebt?.intakeTarget || IntakeDebtDataPointInputIntakeTargetEnum.Self,
        balanceCurrentCents: intakeDebt?.balanceCurrentCents || 0,
        balanceOriginalCents: intakeDebt?.balanceOriginalCents || 0,
        monthlyPaymentCents: intakeDebt?.monthlyPaymentCents || 0,
        formSubmitRef: form.submitForm,
        validateFormRef: form.validateForm,
      },
    });
  }, [state.refreshIndex]);

  useEffect(() => {
    dispatch({
      type: "updateForm",
      data: {
        id: localId,
        dirty: form.dirty,
      },
    });
  }, [form.dirty]);

  const handleDelete = () => {
    if (intakeDebt?.id) {
      if (fpState.financialProfile) {
        intakeApi.deleteEmployeeIntakeDebtDataPoint(fpState.financialProfile.id, intakeDebt.id).then(() =>
          dispatch({
            type: "removeLocalDataPoint",
            data: { id: intakeDebt.id },
          })
        );
      }
    } else {
      dispatch({
        type: "removeLocalDataPoint",
        data: { id: localId },
      });
    }
  };

  const typeValue = form.getFieldMeta(DEBT_FIELDS.TYPE).value;

  return (
    <FormProvider value={form}>
      <Row>
        <Col>
          <Tooltip content={typeValue}>
            <Pressable noCursor>
              <FormItem name={DEBT_FIELDS.TYPE}>
                <Input editable={false} />
              </FormItem>
            </Pressable>
          </Tooltip>
        </Col>
        <Col maw={200}>
          <Tooltip content={form.getFieldMeta(DEBT_FIELDS.COMPANY_NAME).value || "-"}>
            <Pressable noCursor>
              <FormItem name={DEBT_FIELDS.COMPANY_NAME}>
                <Input />
              </FormItem>
            </Pressable>
          </Tooltip>
        </Col>
        <Col maw={MONEY_COL_MAW}>
          <FormItem name={DEBT_FIELDS.BALANCE_CURRENT}>
            <MoneyInput
              decimalScale={0}
              allowDecimal={false}
              onBlur={({ floatValue }) => {
                dispatch({
                  type: "updateForm",
                  data: {
                    id: localId,
                    balanceCurrentCents: floatValue || 0,
                  },
                });
              }}
            />
          </FormItem>
        </Col>
        <Col maw={MONEY_COL_MAW}>
          <FormItem name={DEBT_FIELDS.BALANCE_ORIGINAL}>
            <MoneyInput
              decimalScale={0}
              allowDecimal={false}
              onBlur={({ floatValue }) => {
                dispatch({
                  type: "updateForm",
                  data: {
                    id: localId,
                    balanceOriginalCents: floatValue || 0,
                  },
                });
              }}
            />
          </FormItem>
        </Col>
        <Col maw={100}>
          <FormItem name={DEBT_FIELDS.INTEREST_RATE}>
            <NumberInput />
          </FormItem>
        </Col>
        <Col maw={MONEY_COL_MAW}>
          <FormItem name={DEBT_FIELDS.MONTHLY_PAYMENT}>
            <MoneyInput
              decimalScale={0}
              allowDecimal={false}
              onBlur={({ floatValue }) => {
                dispatch({
                  type: "updateForm",
                  data: {
                    id: localId,
                    monthlyPaymentCents: floatValue || 0,
                  },
                });
              }}
            />
          </FormItem>
        </Col>
        <Col maw={200}>
          <FormItem name={DEBT_FIELDS.PROGRAM_TYPE}>
            <Input multiline numberOfLines={1.95} disabled={!selectedElement?.allowProgramType} />
          </FormItem>
        </Col>
        <Col maw={115}>
          <FormItem name={DEBT_FIELDS.TERM}>
            <NumberInput disabled={!selectedElement?.allowTermLengthMonths} />
          </FormItem>
        </Col>
        <Col maw={100}>
          <FormItem name={DEBT_FIELDS.TARGET}>
            <Select
              options={Object.values(IntakeDebtDataPointInputIntakeTargetEnum).map(target => ({
                label: i18n.get(`enums.intakeTarget.${target}`),
                value: target,
              }))}
              onChange={target =>
                dispatch({
                  type: "updateForm",
                  data: {
                    id: localId,
                    target: target as IntakeDebtDataPointInputIntakeTargetEnum,
                  },
                })
              }
            />
          </FormItem>
        </Col>
        <Col>
          <FormItem name={DEBT_FIELDS.COMMENT}>
            <MultilineInputModal title="Comment" />
          </FormItem>
        </Col>
        <Col maw={45} alignItems="center">
          <Tooltip content="Delete">
            <Pressable mt={17.5} onPress={handleDelete}>
              {({ isHovered }) => <DeleteIcon color={isHovered ? "red" : "gray"} />}
            </Pressable>
          </Tooltip>
        </Col>
      </Row>
    </FormProvider>
  );
};

export default DebtRow;
