import { FinancialAccountVerifyStatusOutput } from "@budgeinc/budge-api";
import { capitalize, formatDate } from "@budgeinc/budge-ui-utils";
import useHandleApiError from "hooks/useHandleApiError";
import { useEmployerContext } from "features/employers/contexts/EmployerContext";
import { useState } from "react";
import { accountsCrossTenantApi, employersApi } from "api/BudgeApi";
import { Modal, Button, Grid, Input, Box, TBoxProps } from "@budgeinc/budge-ui-core";

const AccountVerifyStatusButton = (props: TBoxProps) => {
  const {
    state: { employer },
  } = useEmployerContext();
  const [isLoading, setLoading] = useState(false);
  const [verifyInfo, setVerifyInfo] = useState<FinancialAccountVerifyStatusOutput>();
  const { handleError } = useHandleApiError();

  const fetchVerificationStatus = () => {
    setLoading(true);
    handleError(
      accountsCrossTenantApi
        .getEmployerFundingAccountVerifyStatus(employer?.id!)
        .then(({ data }) => setVerifyInfo(data))
    ).finally(() => setLoading(false));
  };

  return (
    <Box {...props}>
      <Button
        title="Verify Status"
        size="sm"
        color="primary"
        variant="gradient"
        alignSelf="flex-start"
        onPress={fetchVerificationStatus}
        loading={isLoading}
      />
      <Modal
        isOpen={!!verifyInfo}
        onClose={() => setVerifyInfo(undefined)}
        header={{
          title: "Verification Status Info",
        }}
      >
        <Grid column={1} px="xl" pb="xl">
          <Input editable={false} label="Account ID" value={verifyInfo?.accountId} />
          <Input editable={false} label="Verification ID" value={verifyInfo?.verificationId} />
          <Input editable={false} label="Status" value={capitalize(verifyInfo?.status || "")} />
          <Input editable={false} label="Created At" value={formatDate(verifyInfo?.createdAt)} />
          <Input editable={false} label="Updated At" value={formatDate(verifyInfo?.updatedAt)} />
        </Grid>
      </Modal>
    </Box>
  );
};

export default AccountVerifyStatusButton;
