import { EmployerOutput, EmployerUpdateInput } from "@budgeinc/budge-api";

import CollapseEditForm, { TEntityCollapseEditForm } from "components/CollapseEditForm";
import { employersApi } from "api/BudgeApi";
import { useEmployerContext } from "features/employers/contexts/EmployerContext";
import { toast } from "@budgeinc/budge-ui-core";

const EmployerCollapseEditForm = ({
  setEditMode,
  transformForSubmit,
  ...props
}: TEntityCollapseEditForm<EmployerOutput, EmployerUpdateInput>) => {
  const {
    state: { employer },
    dispatch,
  } = useEmployerContext();

  return (
    <CollapseEditForm
      {...props}
      entity={employer!}
      onSubmit={values =>
        employersApi
          .updateEmployer(employer?.id!, transformForSubmit(values))
          .then(({ data }) => {
            dispatch({ type: "set", data });
            toast.success({
              message: "Employer saved successfully",
            });
          })
          .finally(() => {
            setEditMode(false);
          })
      }
    />
  );
};

export default EmployerCollapseEditForm;
