import { createListFilterReducer, TListFilterState } from "components/ListFilterBar/reducer";
import { createListFilterContext } from "components/ListFilterBar/context";
import { FinancialAccountTransferSearchCriteriaInput } from "@budgeinc/budge-api";

export const initialState: TListFilterState<FinancialAccountTransferSearchCriteriaInput> = {
  status: undefined,
  processedDateFrom: undefined,
  processedDateTo: undefined,
};

export const TransferFilterReducer = createListFilterReducer<FinancialAccountTransferSearchCriteriaInput>(initialState);
export const TransferFilterContext = createListFilterContext<FinancialAccountTransferSearchCriteriaInput>(initialState);
