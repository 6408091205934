import { ActionTakingInput, ActionTakingItemInput, ActionTakingOutput } from "@budgeinc/budge-api";
import { formatDateISO } from "@budgeinc/budge-ui-core";
import { ActionTakingFormItemKey, ActionTakingFormItemType, ActionTakingFormValue } from "./types";

export const getActionTakingFormItemName = (id: string, type: ActionTakingFormItemType): ActionTakingFormItemKey =>
  `${id}-${type}`;

export const getFormInitialValue = (actionTaking: ActionTakingOutput[]): ActionTakingFormValue => {
  const initialValues: ActionTakingFormValue = {};

  actionTaking.forEach(at => {
    initialValues[getActionTakingFormItemName(at.actionTakingElementId, ActionTakingFormItemType.COACH_RECOMMENCED)] =
      !!at.coachRecommended;
    initialValues[getActionTakingFormItemName(at.actionTakingElementId, ActionTakingFormItemType.USER_EXECUTED)] =
      !!at.userExecuted;
    initialValues[getActionTakingFormItemName(at.actionTakingElementId, ActionTakingFormItemType.COMMENT)] =
      at.comment || "";
  });

  return initialValues;
};

export const getApiPayload = (
  value: ActionTakingFormValue,
  initialActionTaking: ActionTakingOutput[]
): ActionTakingInput => {
  const items: ActionTakingItemInput[] = [];

  initialActionTaking.forEach(action => {
    const userExecuted = value[`${action.actionTakingElementId}-${ActionTakingFormItemType.USER_EXECUTED}`];
    const coachRecommended = value[`${action.actionTakingElementId}-${ActionTakingFormItemType.COACH_RECOMMENCED}`];
    const comment = value[`${action.actionTakingElementId}-${ActionTakingFormItemType.COMMENT}`] as string;

    items.push({
      actionTakingElementId: action.actionTakingElementId,
      userExecuted: userExecuted ? formatDateISO(new Date()) : null,
      coachRecommended: coachRecommended ? formatDateISO(new Date()) : null,
      comment,
    });
  });

  return { items };
};
