import React, { useImperativeHandle, useRef } from "react";
import { Modal, Text, useDisclosure, useTabsPaneContext } from "@budgeinc/budge-ui-core";

interface OwnProps {
  onSaveChanges(): Promise<any>;
  canNavigate: boolean;
}

const TabFormStateChecker = ({ onSaveChanges, canNavigate }: OwnProps) => {
  const tabContext = useTabsPaneContext();
  const disclosure = useDisclosure();
  const navigateRef = useRef<() => void>();

  useImperativeHandle(tabContext.tabRef, () => ({
    canNavigate: async navigateToNextTab => {
      if (canNavigate) {
        return true;
      }

      navigateRef.current = navigateToNextTab;

      disclosure.toggle();

      return false;
    },
  }));

  const handleOnOk = async () => {
    onSaveChanges()
      .then(navigateRef.current)
      .catch(() => {
        // swallow error
      })
      .finally(disclosure.toggle);
  };

  const handleOnContinue = () => {
    disclosure.toggle();
    navigateRef.current?.();
  };

  return (
    <Modal
      isOpen={disclosure.isOpen}
      onClose={disclosure.toggle}
      size={400}
      header={{
        title: "Warning, you have unsaved changes",
      }}
      footer={{
        okButtonProps: {
          title: "Save",
          onPress: handleOnOk,
        },
        cancelButtonProps: {
          title: "Continue without saving",
          onPress: handleOnContinue,
        },
      }}
    >
      <Text px="xl">Do you want save or continue without saving?</Text>
    </Modal>
  );
};

export default TabFormStateChecker;
