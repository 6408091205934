import { IntakeProfileOutput } from "@budgeinc/budge-api";

export type TFinancialProfileEntityState = {
  financialProfile?: IntakeProfileOutput;
};

export type TFinancialProfileEntityAction = { type: "setFinancialProfile"; data: IntakeProfileOutput };

export const initialState: TFinancialProfileEntityState = {};

export const financialProfileEntityReducer = (
  state: TFinancialProfileEntityState,
  action: TFinancialProfileEntityAction
): TFinancialProfileEntityState => {
  switch (action.type) {
    case "setFinancialProfile":
      return {
        ...state,
        financialProfile: action.data,
      };
  }
};
