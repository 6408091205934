import useHandleApiError from "hooks/useHandleApiError";
import { useEffect, useMemo, useReducer, useState } from "react";
import { accountsCrossTenantApi, employersApi } from "api/BudgeApi";
import { employerEntityReducer, initialState } from "features/employers/entity/contexts/reducer";
import { useEmployerContext } from "features/employers/contexts/EmployerContext";

const useEmployerEntity = () => {
  const {
    state: { employer },
  } = useEmployerContext();
  const [isLoading, setLoading] = useState(true);
  const [state, dispatch] = useReducer(employerEntityReducer, initialState);
  const { handleError } = useHandleApiError();

  const memoedContextValues = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  const fetchOnboardingStatus = () =>
    handleError(
      employersApi.getEmployerOnboardingStatus(employer?.id!).then(({ data }) => {
        dispatch({ type: "setOnboardingStatus", data });
      })
    );

  const fetchFundingAccount = () =>
    handleError(
      accountsCrossTenantApi.getEmployerFundingAccount(employer?.id!).then(({ data }) => {
        if (data) {
          dispatch({ type: "setFundingAccount", data });
        }
      }),
      {
        ignore404: true,
      }
    );

  useEffect(() => {
    const promises: Promise<any>[] = [fetchOnboardingStatus(), fetchFundingAccount()];

    Promise.all(promises).finally(() => setLoading(false));
  }, [employer?.id]);

  return {
    isLoading,
    employer,
    state,
    dispatch,
    memoedContextValues,
  };
};

export default useEmployerEntity;
