import { FinancialAccountTransferOutput } from "@budgeinc/budge-api";
import { formatMoney } from "@budgeinc/budge-ui-utils";
import { TColumnProps, Text, Tag, EMPTY_VALUE_PLACEHOLDER } from "@budgeinc/budge-ui-core";
import DateDisplay from "components/DateDisplay";

export const getTransfersColumns = (): TColumnProps<FinancialAccountTransferOutput>[] => [
  {
    id: "status",
    title: "Status",
    render: record => <Tag value={record.status} />,
  },
  {
    id: "amount",
    title: "Amount",
    render: record => (
      <Text>
        {formatMoney({
          amountCents: record.transferAmountCents,
        })}
      </Text>
    ),
  },
  {
    id: "processedat",
    title: "Processed at",
    render: ({ processedDate }) =>
      processedDate ? (
        <DateDisplay numberOfLines={1} value={processedDate} showTime />
      ) : (
        <Text>{EMPTY_VALUE_PLACEHOLDER}</Text>
      ),
  },
  {
    id: "updatedat",
    title: "Updated at",
    render: ({ updatedAt }) => <DateDisplay numberOfLines={1} value={updatedAt} showTime />,
  },
  {
    id: "createdat",
    title: "Created at",
    render: ({ createdAt }) => <DateDisplay numberOfLines={1} value={createdAt} />,
  },
];
