import { RouterProvider } from "react-router-dom";
import { ErrorBoundary, PageLoader } from "@budgeinc/budge-ui-core";
import { errorLogger } from "api/BudgeApi";
import { setupI18n } from "i18n";
import { SafeAreaProvider } from "react-native-safe-area-context";
import routerConfig from "common/routerConfig";
import { Suspense } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

setupI18n();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
    },
    mutations: {
      retry: 0,
    },
  },
});

const App = () => (
  <QueryClientProvider client={queryClient}>
    <SafeAreaProvider style={{ flex: 1, height: "100%" }}>
      <ErrorBoundary
        onReset={() => window.location.reload()}
        onError={error => {
          if (error) {
            errorLogger.logClientError(error);
          }
        }}
      >
        <Suspense fallback={<PageLoader />}>
          <RouterProvider router={routerConfig} fallbackElement={<PageLoader />} />
        </Suspense>
      </ErrorBoundary>
    </SafeAreaProvider>
  </QueryClientProvider>
);

export default App;
