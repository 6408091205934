import {
  Description,
  EMPTY_VALUE_PLACEHOLDER,
  Stack,
  Text,
  Collapse,
  Input,
  Grid,
  TGridProps,
} from "@budgeinc/budge-ui-core";
import { useAccountEntityContext } from "features/accounts/contexts/AccountEntityContext";
import PlaidExternalButton from "components/Plaid/PlaidExternalButton";
import PlaidRawDetailsButton from "components/Plaid/PlaidRawDetailsButton";
import PlaidSyncButton from "components/Plaid/PlaidSyncButton";

const gridProps: TGridProps = {
  column: 2,
  gutter: "md",
  responsive: {
    xs: 1,
    sm: 1,
    md: 1,
    xl: 3,
    xxl: 3,
  },
};

const PlaidInfoCard = () => {
  const {
    state: { account },
  } = useAccountEntityContext();

  if (!account || !account.plaidItemId) return null;

  return (
    <Collapse
      trigger={
        <Stack.Horizontal f={1} justifyContent="space-between" alignItems="center">
          <Text fw="500" variant="bodyMedium">
            Plaid
          </Text>
          <PlaidExternalButton buttonType="normal" plaidItemId={account.plaidItemId} />
        </Stack.Horizontal>
      }
    >
      <Stack spacing="xl">
        <Grid {...gridProps}>
          <Input
            label="Plaid Item ID"
            value={account.plaidItemId}
            disabled
            rightSection={
              account.methodAccountId && (
                <Stack.Horizontal>
                  <PlaidExternalButton buttonType="actionIcon" plaidItemId={account.plaidItemId} />
                  <PlaidRawDetailsButton accountId={account.id} />
                  <PlaidSyncButton accountId={account.id} />
                </Stack.Horizontal>
              )
            }
          />
        </Grid>
        <Description>
          <Description.Item label="Item ID">
            <Text>{account.plaidItemId}</Text>
          </Description.Item>
          <Description.Item label="Institution ID">
            <Text>{account.plaidInstitutionId || EMPTY_VALUE_PLACEHOLDER}</Text>
          </Description.Item>
          <Description.Item label="Pending Verification">
            <Text>{account.plaidPendingVerification ? "False" : "True"}</Text>
          </Description.Item>
          <Description.Item label="Error Name">
            <Text>{account.plaidErrorName || EMPTY_VALUE_PLACEHOLDER}</Text>
          </Description.Item>
          <Description.Item label="Error Message">
            <Text>{account.plaidErrorMessage || EMPTY_VALUE_PLACEHOLDER}</Text>
          </Description.Item>
        </Description>
      </Stack>
    </Collapse>
  );
};

export default PlaidInfoCard;
