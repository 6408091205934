import { Pressable } from "react-native";
import React from "react";
import { Box, Input, Modal, Tooltip, useDisclosure, useFormikField } from "@budgeinc/budge-ui-core";

interface OwnProps {
  title: string;
}

const MultilineInputModal = ({ title }: OwnProps) => {
  const disclosure = useDisclosure();
  const { field, helpers } = useFormikField();

  const handleDone = () => disclosure.toggle();

  return (
    <>
      <Tooltip content={field?.value || "-"}>
        <Pressable onPress={disclosure.toggle}>
          <Input editable={false} />
        </Pressable>
      </Tooltip>
      <Modal
        isOpen={disclosure.isOpen}
        onClose={disclosure.toggle}
        header={{
          title,
        }}
        footer={{
          okButtonProps: {
            title: "Done",
            onPress: handleDone,
          },
        }}
      >
        <Box px="xl">
          <Input
            ignoreFormContext
            autoFocus
            multiline
            numberOfLines={12}
            value={field?.value}
            onBlur={(_, value) => helpers?.setValue(value)}
          />
        </Box>
      </Modal>
    </>
  );
};

export default MultilineInputModal;
