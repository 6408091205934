import { useLocation } from "react-router-dom";
import { formatEmployeeName, Text, Tabs, TAccessOperation, useAccessScopeValidator } from "@budgeinc/budge-ui-core";

import ContentHeader from "components/Layout/Main/Header";
import EmployeeDetailTab from "features/employees/tabs/EmployeeDetails";
import EmployeeNoteTakingTab from "features/employees/tabs/Notes";
import ReferralsTab from "features/employees/tabs/Referrals";
import ActionTakingTab from "features/employees/tabs/ActionTaking";
import { EmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";
import useEmployeeEntity from "features/employees/hooks/useEmployeeEntity";
import { useIsEmployerD2C } from "features/employers/contexts/EmployerContext";
import SentimentListTab from "features/employees/tabs/Sentiments";

import { UserWhoamiOutputScopesEnum } from "@budgeinc/budge-api";
import SecurityListTab from "features/employees/tabs/Security";
import CenteredSpinner from "components/CenteredSpinner";
import SubscriptionTab from "features/employees/tabs/Subscription";
import FinancialsTab from "features/employees/tabs/Financials";
import withAccessScopeValidator from "components/AccessScopeValidator/withAccessScopeValidator";
import CommsTab from "features/employees/tabs/Comms";

export enum EMPLOYEE_TAB_KEYS {
  DETAILS = "#details",
  SUBSCRIPTION = "#subscription",
  NOTES = "#notes",
  ACTION_TAKING = "#action-taking",
  PAYCHECKS = "#paychecks",
  REFERRALS = "#referrals",
  FINANCIALS = "#financials",
  SECURITY = "#security",
  SENTIMENTS = "#sentiments",
  COMMS = "#comms",
}

export const Component = withAccessScopeValidator(() => {
  const { hash } = useLocation();
  const isD2C = useIsEmployerD2C();
  const { validateAccessScopes } = useAccessScopeValidator();
  const { isLoading, state, memoedContextValues } = useEmployeeEntity();

  if (isLoading) {
    return <CenteredSpinner />;
  }

  return (
    <EmployeeEntityContext.Provider value={memoedContextValues}>
      <ContentHeader
        title={<Text variant="titleH2">{state.employee && formatEmployeeName({ employee: state.employee })}</Text>}
      />
      <Tabs
        onChange={activeKey => {
          window.location.hash = activeKey;
        }}
        defaultActiveKey={hash || EMPLOYEE_TAB_KEYS.DETAILS}
        activeKey={hash}
      >
        {validateAccessScopes(TAccessOperation.READ, [UserWhoamiOutputScopesEnum.Clients]) && (
          <Tabs.Pane title="Details" tabKey={EMPLOYEE_TAB_KEYS.DETAILS} flex={false}>
            <EmployeeDetailTab />
          </Tabs.Pane>
        )}
        {validateAccessScopes(TAccessOperation.READ, [UserWhoamiOutputScopesEnum.Subscriptions]) && (
          <Tabs.Pane title="Subscription" tabKey={EMPLOYEE_TAB_KEYS.SUBSCRIPTION} flex={false}>
            <SubscriptionTab />
          </Tabs.Pane>
        )}
        {validateAccessScopes(TAccessOperation.READ, [UserWhoamiOutputScopesEnum.ClientsNotes]) && (
          <Tabs.Pane title="Notes" tabKey={EMPLOYEE_TAB_KEYS.NOTES} flex={false}>
            <EmployeeNoteTakingTab />
          </Tabs.Pane>
        )}
        {validateAccessScopes(TAccessOperation.READ, [UserWhoamiOutputScopesEnum.ClientsEmails]) && (
          <Tabs.Pane title="Comms" tabKey={EMPLOYEE_TAB_KEYS.COMMS}>
            <CommsTab />
          </Tabs.Pane>
        )}
        {validateAccessScopes(TAccessOperation.READ, [UserWhoamiOutputScopesEnum.ClientsFinancials]) && (
          <Tabs.Pane title="Financials" tabKey={EMPLOYEE_TAB_KEYS.FINANCIALS}>
            <FinancialsTab />
          </Tabs.Pane>
        )}
        {validateAccessScopes(TAccessOperation.READ, [UserWhoamiOutputScopesEnum.ClientsActionTaking]) && (
          <Tabs.Pane title="Action Taking" tabKey={EMPLOYEE_TAB_KEYS.ACTION_TAKING}>
            <ActionTakingTab />
          </Tabs.Pane>
        )}
        {validateAccessScopes(TAccessOperation.READ, [UserWhoamiOutputScopesEnum.ClientsSentiments]) && (
          <Tabs.Pane title="Sentiments" tabKey={EMPLOYEE_TAB_KEYS.SENTIMENTS} flex={false}>
            <SentimentListTab />
          </Tabs.Pane>
        )}
        {validateAccessScopes(TAccessOperation.READ, [UserWhoamiOutputScopesEnum.Security]) && (
          <Tabs.Pane title="Security" tabKey={EMPLOYEE_TAB_KEYS.SECURITY}>
            <SecurityListTab />
          </Tabs.Pane>
        )}
        {validateAccessScopes(TAccessOperation.READ, [
          UserWhoamiOutputScopesEnum.Referrals,
          UserWhoamiOutputScopesEnum.UserAccess,
        ]) &&
          isD2C && (
            <Tabs.Pane title="Referrals" tabKey={EMPLOYEE_TAB_KEYS.REFERRALS}>
              <ReferralsTab />
            </Tabs.Pane>
          )}
      </Tabs>
    </EmployeeEntityContext.Provider>
  );
}, [
  {
    OR: [UserWhoamiOutputScopesEnum.Admin, UserWhoamiOutputScopesEnum.AdminRead, UserWhoamiOutputScopesEnum.Clients],
  },
]);
