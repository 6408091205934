import { ErrorResponse, FinancialAccountOutput } from "@budgeinc/budge-api";
import { BuildValidationSchema, yup } from "@budgeinc/budge-ui-utils";
import {
  FormFieldsWrapper,
  FormItem,
  FormProvider,
  getApiErrorMessage,
  Input,
  Modal,
  Stack,
  Text,
} from "@budgeinc/budge-ui-core";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import { useState } from "react";
import { accountsCrossTenantApi, employersApi } from "api/BudgeApi";
import { useAppMessages } from "store/global";
import { useEmployerContext } from "features/employers/contexts/EmployerContext";

interface OwnProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (account: FinancialAccountOutput) => void;
  account?: FinancialAccountOutput | undefined;
}

enum FIELDS {
  ACH_ROUTING_NUMBER = "achRoutingNumber",
  ACCOUNT_NUMBER = "accountNumber",
}

const CreateFundingAccountModal = ({ isOpen, onClose, account, onSuccess }: OwnProps) => {
  const messages = useAppMessages();
  const { state } = useEmployerContext();
  const [formError, setFormError] = useState<string>();

  const form = useFormik({
    initialValues: getInitialValues(account),
    enableReinitialize: true,
    validationSchema,
    onSubmit: async value => {
      if (!state.employer) return;

      return accountsCrossTenantApi
        .createEmployerFundingAccount(state.employer.id, {
          accountNumber: value.accountNumber,
          routingNumber: value.achRoutingNumber,
        })
        .then(({ data }) => {
          onSuccess?.(data);
          onClose();
        })
        .catch((e: AxiosError<ErrorResponse>) => setFormError(getApiErrorMessage(messages, e.response?.data)));
    },
  });

  const handleOnClosed = () => {
    form.resetForm();
    setFormError(undefined);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onClosed={handleOnClosed}
      header={{
        title: (
          <Text variant="bodyLarge" fw="600">
            Enter Account Information
          </Text>
        ),
      }}
      footer={{
        okButtonProps: { title: "Confirm", loading: form.isSubmitting, onPress: form.submitForm },
      }}
    >
      <FormProvider value={form} formErrorMsg={formError} px="xl">
        <FormFieldsWrapper>
          <Stack spacing="md">
            <FormItem name={FIELDS.ACH_ROUTING_NUMBER}>
              <Input label="ACH Routing Number" />
            </FormItem>
            <FormItem name={FIELDS.ACCOUNT_NUMBER}>
              <Input label="Account Number" />
            </FormItem>
          </Stack>
        </FormFieldsWrapper>
      </FormProvider>
    </Modal>
  );
};

const validationSchema = BuildValidationSchema({
  [FIELDS.ACH_ROUTING_NUMBER]: yup.string().required(),
  [FIELDS.ACCOUNT_NUMBER]: yup.string().required(),
});

const getInitialValues = (account: FinancialAccountOutput | undefined): Record<FIELDS, string> => ({
  [FIELDS.ACH_ROUTING_NUMBER]: account?.routingNumber || "",
  [FIELDS.ACCOUNT_NUMBER]: account?.accountNumber || "",
});

export default CreateFundingAccountModal;
