import { DebtPaymentPlanOutput } from "@budgeinc/budge-api";
import { debtPlanApi } from "api/BudgeApi";
import { useEffect, useState } from "react";
import useHandleApiError from "hooks/useHandleApiError";
import EntityNotFound from "components/EntityNotFound";
import { useParams } from "react-router-dom";
import { useEmployerContext } from "features/employers/contexts/EmployerContext";
import CenteredSpinner from "components/CenteredSpinner";
import DebtPlanDetails from "features/debt-plans/components/DebtPlanDetails";
import { useEmployeeRouteLoaderData } from "features/employees/routeConfig";

const DebtPlanTab = () => {
  const { handleError } = useHandleApiError();
  const [isLoading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const params = useParams<{ employeeId: string }>();
  const employee = useEmployeeRouteLoaderData();
  const {
    state: { employer },
  } = useEmployerContext();
  const [debtPlan, setDebtPlan] = useState<DebtPaymentPlanOutput>();

  useEffect(() => {
    handleError(
      debtPlanApi.getDebtPaymentPlan(employee.id).then(resp => setDebtPlan(resp.data)),
      {
        ignore404: true,
        onCatch: e => {
          if (e.response?.status === 404) {
            setNotFound(true);
          }
        },
      }
    ).finally(() => setLoading(false));
  }, []);

  return isLoading ? (
    <CenteredSpinner />
  ) : notFound || !debtPlan ? (
    <EntityNotFound
      title="Debt Plan not found"
      description={`Customer ID ${params?.employeeId} has not created any debt plan yet`}
    />
  ) : (
    <DebtPlanDetails
      debtPlan={debtPlan}
      employee={employee}
      employerId={employer?.id!}
      onDeleted={() => setDebtPlan(undefined)}
    />
  );
};

export default DebtPlanTab;
