import { getPersistedFilters } from "components/ListFilterBar/utils";
import { FinancialAccountBalanceSearchCriteriaInput } from "@budgeinc/budge-api";
import { FilterFields, TFormValues } from "./FiltersBar";

export const getInitialFilters = (persistId: string): FinancialAccountBalanceSearchCriteriaInput => {
  const filters = getPersistedFilters<TFormValues>(persistId);

  return {
    balanceSource: filters?.[FilterFields.BALANCE_SOURCE] || undefined,
    asOfDateFrom: (filters?.[FilterFields.AS_OF_DATE_FROM] as any) || undefined,
    asOfDateTo: (filters?.[FilterFields.AS_OF_DATE_TO] as any) || undefined,
  };
};
