import TransactionsTable from "components/Table/TransactionsTable";
import { TransactionInfiniteScrollTableContext } from "components/Table/TransactionsTable/context";
import { useCallback, useEffect, useMemo, useReducer } from "react";
import { Stack, useInfiniteScrollList, AccessScopeValidator, TAccessOperation } from "@budgeinc/budge-ui-core";
import { employersApi, programsCrossTenantApi } from "api/BudgeApi";
import { useEmployerContext } from "features/employers/contexts/EmployerContext";
import { ProgramTransactionAdminOutputWithId } from "components/Table/TransactionsTable/types";
import {
  ProgramTransactionFilterContext,
  ProgramTransactionFilterReducer,
} from "components/Table/TransactionsTable/filters/context";
import { getInitialFilters } from "components/Table/TransactionsTable/filters/utils";
import FiltersBar from "components/Table/TransactionsTable/filters/FiltersBar";
import { InfiniteScrollDataTableContextType } from "components/Table/tableContext";
import CreateManualPaymentButton from "features/transactions/components/ManualPayment/CreateManualPayment/CreateManualPaymentButton";
import { UserWhoamiOutputScopesEnum } from "@budgeinc/budge-api";
import ExportAccessScopeValidator from "components/AccessScopeValidator/validators/ExportAccessScopeValidator";

import ExportTransactionsButton from "./components/ExportTransactionsButton";

const EmployerTransactionsListPersistFilterId = "employer-transactions-list-filters";

const TransactionsListTab = () => {
  const {
    state: { employer },
  } = useEmployerContext();
  const [filters, dispatch] = useReducer(
    ProgramTransactionFilterReducer,
    getInitialFilters(EmployerTransactionsListPersistFilterId)
  );

  const handleOnFetch = useCallback(
    ({ page, pageSize }: { page: number; pageSize: number }) =>
      programsCrossTenantApi
        .searchEmployerProgramTransactions(employer?.id!, filters, `${pageSize * (page - 1)}`, pageSize.toString())
        .then(({ data }) => ({
          results: data.map(record => ({
            id: record.programTransaction.id,
            ...record,
          })),
        })),
    [JSON.stringify(filters)]
  );

  const { requestState, updateData, onEndReached, forceRefresh } = useInfiniteScrollList({
    onFetch: handleOnFetch,
    skipFirstForceRefresh: true,
  });

  const updateRecord = useCallback(
    (newRecord: ProgramTransactionAdminOutputWithId) => {
      updateData(requestState.data.map(record => (record.id === newRecord.id ? newRecord : record)));
    },
    [requestState]
  );

  const addRecord = useCallback(
    (newRecord: ProgramTransactionAdminOutputWithId) => {
      updateData([newRecord, ...requestState.data]);
    },
    [requestState]
  );

  useEffect(() => {
    forceRefresh();
  }, [JSON.stringify(filters)]);

  const memoedFilterContextValues = useMemo(
    () => ({
      state: filters,
      dispatch,
    }),
    [filters, dispatch]
  );

  const memoedContextValues = useMemo<InfiniteScrollDataTableContextType<ProgramTransactionAdminOutputWithId>>(
    () => ({
      updateRecord,
      addRecord,
    }),
    [updateRecord, addRecord, updateData]
  );

  return (
    <ProgramTransactionFilterContext.Provider value={memoedFilterContextValues}>
      <TransactionInfiniteScrollTableContext.Provider value={memoedContextValues}>
        <FiltersBar
          persistId={EmployerTransactionsListPersistFilterId}
          extra={
            <Stack.Horizontal>
              <AccessScopeValidator
                op={TAccessOperation.WRITE}
                rule={[UserWhoamiOutputScopesEnum.ManualPaymentsCreation]}
              >
                <CreateManualPaymentButton
                  onSuccess={t =>
                    addRecord({
                      ...t,
                      id: t.programTransaction.id,
                    })
                  }
                />
              </AccessScopeValidator>
              <ExportAccessScopeValidator rule={[UserWhoamiOutputScopesEnum.ClientsFinancials]}>
                <ExportTransactionsButton />
              </ExportAccessScopeValidator>
            </Stack.Horizontal>
          }
        />
        <TransactionsTable
          keyExtractor={item => item.programTransaction.id}
          requestState={requestState}
          onEndReached={onEndReached}
          onTransactionUpdated={t => {
            updateRecord({
              ...t,
              id: t.programTransaction.id,
            });
          }}
          showEmployee
        />
      </TransactionInfiniteScrollTableContext.Provider>
    </ProgramTransactionFilterContext.Provider>
  );
};

export default TransactionsListTab;
