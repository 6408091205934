import React, { useState } from "react";
import { usersApi } from "api/BudgeApi";
import { Button, Stack, TButtonProps, Text, toast } from "@budgeinc/budge-ui-core";
import DateDisplay from "components/DateDisplay";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";

const UnlockUserButton = (props: TButtonProps) => {
  const [loading, setLoading] = useState(false);
  const { state, dispatch } = useEmployeeEntityContext();

  const handleUnlockUser = () => {
    if (state.user) {
      setLoading(true);
      usersApi
        .removeLock(state.user?.id)
        .then(({ data }) => {
          dispatch({ type: "setUser", data });
          toast.success({
            message: "User unlocked successfully",
          });
        })
        .finally(() => setLoading(false));
    }
  };

  if (!state.user?.lockedUntil) return null;

  return (
    <Stack.Horizontal alignItems="center" spacing="md">
      <Stack spacing={0}>
        <Text color="secondary" variant="bodySmall">
          Locked until
        </Text>
        <DateDisplay variant="bodySmall" value={state.user.lockedUntil} showTime />
      </Stack>
      <Button title="Unlock User" loading={loading} color="red" {...props} onPress={handleUnlockUser} />
    </Stack.Horizontal>
  );
};

export default UnlockUserButton;
