import {
  IntakeAssetDataPointOutput,
  IntakeAssetDataPointInputIntakeTargetEnum,
  IntakeAssetElementOutput,
} from "@budgeinc/budge-api";
import {
  TGridTableState,
  createGridTableReducer,
  TLocalDataPointBase,
  TGridTableAction,
} from "features/financial-profiles/components/GridTable/reducer";

export type TAssetFormRefComputable = {
  balance: number;
  annualContribution: number;
  annualMatch: number;
  target: IntakeAssetDataPointInputIntakeTargetEnum;
};

export type TLocalAssetDataPoint = TLocalDataPointBase<IntakeAssetDataPointOutput>;

export type TIntakeAssetsState = TGridTableState<
  IntakeAssetDataPointOutput,
  IntakeAssetElementOutput,
  TAssetFormRefComputable
>;

export type TIntakeAssetsAction = TGridTableAction<
  IntakeAssetDataPointOutput,
  IntakeAssetElementOutput,
  TAssetFormRefComputable
>;

export const intakeAssetsReducer = createGridTableReducer<
  IntakeAssetDataPointOutput,
  IntakeAssetElementOutput,
  TAssetFormRefComputable
>();

export const initialState: TIntakeAssetsState = {
  localDataPoint: [],
  elements: [],
  formRefs: [],
  refreshIndex: 0,
};
