import { createContext, useContext } from "react";
import { TIntakeDebtsAction, TIntakeDebtsState, initialState } from "./reducer";

export type DebtsContextType = {
  dispatch: React.Dispatch<TIntakeDebtsAction>;
  state: TIntakeDebtsState;
};

export const DebtsContext = createContext<DebtsContextType>({
  dispatch: () => {},
  state: initialState,
});

export const useDebtsContext = () => {
  const context = useContext(DebtsContext);

  if (!context) {
    throw new Error("DebtsContext must be used inside an DebtsContext.Provider");
  }

  return context;
};
