import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatus } from "@budgeinc/budge-ui-utils";
import { TInitialState } from "store/user/types";

import { AdminAppStorage } from "utils/storage";
import { loginUser, logoutUser, refreshAccessToken, whoAmi } from "./thunks";

export const UserInitialState: TInitialState = {
  requestStatus: RequestStatus.IDLE,
  whoami: undefined,
  sessionExpired: false,
  userLoggedOut: false,
  isAuthenticated: false,
  userAccessScopes: [],
  loginState: {
    requestStatus: RequestStatus.IDLE,
  },
};

const userSlice = createSlice({
  name: "user",
  initialState: UserInitialState,
  reducers: {
    sessionExpired: state => {
      state.sessionExpired = true;
    },
    localLogout: (
      state,
      action: PayloadAction<
        | {
            userLoggedOut?: boolean;
            sessionExpired?: boolean;
          }
        | undefined
      >
    ) => {
      state.isAuthenticated = false;
      state.whoami = undefined;
      state.userLoggedOut = action.payload?.userLoggedOut === undefined ? false : action.payload.userLoggedOut;
      state.sessionExpired = action.payload?.sessionExpired === undefined ? false : action.payload.sessionExpired;
      AdminAppStorage.clear();
    },
    clearErrors: state => {
      state.loginState.error = undefined;
    },
  },
  extraReducers: builder => {
    // WHO AM I
    builder.addCase(whoAmi.pending, state => {
      state.requestStatus = RequestStatus.PENDING;
    });
    builder.addCase(whoAmi.fulfilled, (state, action) => {
      state.requestStatus = RequestStatus.COMPLETED;
      state.sessionExpired = false;
      state.isAuthenticated = true;
      state.whoami = action.payload;
      state.userAccessScopes = action.payload.scopes;
    });
    builder.addCase(whoAmi.rejected, state => {
      state.requestStatus = RequestStatus.ERROR;
    });

    // LOGIN
    builder.addCase(loginUser.pending, state => {
      AdminAppStorage.clear();
      state.loginState.requestStatus = RequestStatus.PENDING;
      state.isAuthenticated = false;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.loginState.requestStatus = RequestStatus.COMPLETED;
      state.isAuthenticated = true;
      state.sessionExpired = false;
      state.loginState.error = undefined;
      state.whoami = action.payload.user;
      state.userAccessScopes = action.payload.user.scopes;

      AdminAppStorage.saveTokens(action.payload);
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.loginState.requestStatus = RequestStatus.ERROR;
      state.loginState.error = action.payload;
      state.isAuthenticated = false;
    });

    // LOGOUT
    builder.addCase(logoutUser.fulfilled, (state, action) => {
      state.isAuthenticated = false;
      state.whoami = undefined;
      state.userLoggedOut = true;
      state.sessionExpired = false;
      action.meta.arg?.onDone?.();
      AdminAppStorage.clear();
    });

    builder.addCase(refreshAccessToken.fulfilled, (state, action) => {
      if (action.payload.sessionExpired) {
        state.sessionExpired = action.payload.sessionExpired;
      }

      AdminAppStorage.saveTokens(action.payload);
    });
  },
});

export const userActions = userSlice.actions;
export default userSlice.reducer;
