import React from "react";
import ListFilterBar, { TFilterBarProps } from "components/ListFilterBar";
import { BuildValidationSchema, Checkbox, DatePickerInput, FormItem, Stack, yup } from "@budgeinc/budge-ui-core";
import FilterCollapse from "components/ListFilterBar/FilterCollapse";
import { ProgramTransactionSearchCriteriaInputStatusEnum } from "@budgeinc/budge-api";
import { DateSchema } from "@budgeinc/budge-ui-utils";
import { FormikConsumer } from "formik";
import { getFilterDateToMinDate, getFilterDateFromMaxDate } from "components/ListFilterBar/utils";
import { useProgramTransactionFilterContext } from "components/Table/TransactionsTable/filters/context";
import { formatEnumValue } from "utils/format";

export enum FilterFields {
  STATUS = "status",
  DATE_FROM = "from",
  DATE_TO = "to",
}

export type TFormValues = {
  [FilterFields.STATUS]: ProgramTransactionSearchCriteriaInputStatusEnum[];
  [FilterFields.DATE_FROM]: Date | undefined;
  [FilterFields.DATE_TO]: Date | undefined;
};

const validationSchema = BuildValidationSchema({
  [FilterFields.STATUS]: yup
    .array()
    .of(yup.string().oneOf(Object.values(ProgramTransactionSearchCriteriaInputStatusEnum))),
  [FilterFields.DATE_FROM]: DateSchema().nullable(),
  [FilterFields.DATE_TO]: DateSchema().nullable(),
});

const FiltersBar = ({ showSearch = true, ...props }: TFilterBarProps & { showSearch?: boolean }) => {
  const { dispatch, initialFilters } = useProgramTransactionFilterContext();

  const handleOnSearch = (value: string | undefined) =>
    dispatch({
      type: "updateFilters",
      data: {
        genericSearchLike: value,
      },
    });

  return (
    <ListFilterBar<TFormValues>
      {...props}
      showSearchBar={showSearch}
      onSearchChange={handleOnSearch}
      searchPlaceHolder="Search by first name, last name or email"
      formProps={{
        onSubmit: async values => {
          const status = values[FilterFields.STATUS];

          dispatch({
            type: "updateFilters",
            data: {
              status: status.length ? status : undefined,
              transactionDateFrom: values[FilterFields.DATE_FROM]?.toISOString() || undefined,
              transactionDateTo: values[FilterFields.DATE_TO]?.toISOString() || undefined,
            },
          });
        },
        validationSchema,
        getInitialValues: reset => {
          if (initialFilters && !reset) {
            return {
              [FilterFields.STATUS]: initialFilters.status || [],
              [FilterFields.DATE_FROM]: initialFilters.transactionDateFrom
                ? new Date(initialFilters.transactionDateFrom)
                : undefined,
              [FilterFields.DATE_TO]: initialFilters.transactionDateTo
                ? new Date(initialFilters.transactionDateTo)
                : undefined,
            };
          }

          return {
            [FilterFields.STATUS]: [],
            [FilterFields.DATE_FROM]: undefined,
            [FilterFields.DATE_TO]: undefined,
          };
        },
      }}
    >
      <FormikConsumer>
        {form => (
          <Stack>
            <FilterCollapse defaultOpen trigger="Transaction Date">
              <Stack>
                <FormItem name={FilterFields.DATE_FROM}>
                  <DatePickerInput
                    label="From"
                    maxDate={getFilterDateFromMaxDate(form.getFieldMeta<Date>(FilterFields.DATE_TO).value)}
                  />
                </FormItem>
                <FormItem name={FilterFields.DATE_TO}>
                  <DatePickerInput
                    label="To"
                    minDate={getFilterDateToMinDate(form.getFieldMeta<Date>(FilterFields.DATE_FROM).value)}
                  />
                </FormItem>
              </Stack>
            </FilterCollapse>
            <FilterCollapse defaultOpen trigger="Status">
              <FormItem name={FilterFields.STATUS}>
                <Checkbox.Group
                  options={Object.values(ProgramTransactionSearchCriteriaInputStatusEnum).map(status => ({
                    label: formatEnumValue(status),
                    value: status,
                  }))}
                />
              </FormItem>
            </FilterCollapse>
          </Stack>
        )}
      </FormikConsumer>
    </ListFilterBar>
  );
};

export default FiltersBar;
