import React, { useContext } from "react";

import { initialState, TEmployerEntityAction, TEmployerEntityState } from "./reducer";

interface EmployerEntityContextType {
  dispatch: React.Dispatch<TEmployerEntityAction>;
  state: TEmployerEntityState;
}

export const EmployerEntityContext = React.createContext<EmployerEntityContextType | null>({
  dispatch: () => {},
  state: initialState,
});

export const useEmployerEntityContext = () => {
  const context = useContext(EmployerEntityContext);

  if (!context) {
    throw new Error("EmployerEntityContext must be used inside an EmployerEntityContext.Provider");
  }

  return context;
};
