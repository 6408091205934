import React, { useCallback, useMemo, useReducer } from "react";
import { getInitialFilters } from "components/Table/AccountsTable/filter/utils";
import { accountsCrossTenantApi } from "api/BudgeApi";
import { useEmployerContext } from "features/employers/contexts/EmployerContext";
import { TListFilterContextType } from "components/ListFilterBar/context";
import { FinancialAccountDataRatingOutput } from "@budgeinc/budge-api";
import { Description, InfiniteScrollTableV2 } from "@budgeinc/budge-ui-core";
import { useBudgeInfiniteQuery } from "api/useBudgeInfiniteQuery";
import { getAccountDataRatingColumns } from "./columns";
import { DataRatingFilterContext, DataRatingFilterReducer } from "./filters/context";
import FiltersBar from "./filters/FiltersBar";
import ExportRatingButton from "./components/ExportRatingButton";

const EmployerAccountRatingsListPersistFilterId = "employer-accounts-ratings-list-filters";

const DataRatingListTab = () => {
  const {
    state: { employer },
  } = useEmployerContext();
  const initialFilters = getInitialFilters(EmployerAccountRatingsListPersistFilterId);
  const [filters, dispatch] = useReducer(DataRatingFilterReducer, initialFilters);

  const handleOnFetch = useCallback(
    ({ page, pageSize }: { page: number; pageSize: number }) =>
      accountsCrossTenantApi
        .searchAccountDataRatingsForEmployeesOfEmployer(
          employer?.id!,
          filters,
          `${pageSize * (page - 1)}`,
          pageSize.toString()
        )
        .then(({ data }) => data),
    [JSON.stringify(filters)]
  );

  const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } = useBudgeInfiniteQuery({
    queryKey: ["account-data-ratings", filters],
    queryFn: ({ pageParam }) => handleOnFetch({ page: pageParam, pageSize: 20 }),
    pageSize: 20,
    staleTime: 10 * 60 * 1000,
  });

  const accounts = useMemo(
    () => data?.pages.reduce((acc, page) => [...acc, ...page], [] as FinancialAccountDataRatingOutput[]) || [],
    [data]
  );

  const memoedContextValues = useMemo<TListFilterContextType>(
    () => ({
      state: filters,
      dispatch,
      initialFilters,
    }),
    [filters, dispatch]
  );

  return (
    <DataRatingFilterContext.Provider value={memoedContextValues}>
      <FiltersBar persistId={EmployerAccountRatingsListPersistFilterId} extra={<ExportRatingButton />} />
      <InfiniteScrollTableV2
        data={accounts}
        columns={getAccountDataRatingColumns(employer?.id!)}
        loading={isFetching && !isFetchingNextPage}
        loadingNextPage={isFetchingNextPage}
        reachedEnd={!hasNextPage}
        onEndReached={() => fetchNextPage()}
        onEndReachedThreshold={0.5}
        keyExtractor={e => e.id}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ marginBottom: 16 }}
        local={{
          empty: {
            title: "No ratings found",
          },
          noMoreItems: "No more ratings",
        }}
        expandable={record => (
          <Description>
            <Description.Item label="ID">{record.id}</Description.Item>
          </Description>
        )}
      />
    </DataRatingFilterContext.Provider>
  );
};

export default DataRatingListTab;
