import { directPaymentsCrossTenantApi, employersApi } from "api/BudgeApi";
import { useCallback, useEffect, useMemo, useReducer } from "react";
import { useInfiniteScrollList } from "@budgeinc/budge-ui-core";
import {
  DirectPaymentFilterContext,
  DirectPaymentFilterReducer,
} from "components/Table/DirectPaymentsTable/filters/context";
import FiltersBar from "components/Table/DirectPaymentsTable/filters/FiltersBar";
import DirectPaymentsTable from "components/Table/DirectPaymentsTable";
import { getInitialFilters } from "components/Table/DirectPaymentsTable/filters/utils";
import { useEmployerContext } from "features/employers/contexts/EmployerContext";
import { useSearchParams } from "react-router-dom";
import { TListFilterContextType } from "components/ListFilterBar/context";

const EmployerEmployeeDirectPaymentsFilterId = "employer-employees-direct-payments-filters";

const EmployerEmployeesDirectPaymentsListTab = () => {
  const {
    state: { employer },
  } = useEmployerContext();
  const [params] = useSearchParams();
  const initialFilters = getInitialFilters(EmployerEmployeeDirectPaymentsFilterId, params);
  const [filters, dispatch] = useReducer(DirectPaymentFilterReducer, initialFilters);

  const handleOnFetch = useCallback(
    ({ page, pageSize }: { page: number; pageSize: number }) =>
      directPaymentsCrossTenantApi
        .searchEmployerDirectPayments(employer?.id!, filters, `${pageSize * (page - 1)}`, pageSize.toString())
        .then(({ data }) => ({ results: data })),
    [JSON.stringify(filters)]
  );

  const { requestState, onEndReached, forceRefresh } = useInfiniteScrollList({
    onFetch: handleOnFetch,
    skipFirstForceRefresh: true,
  });

  useEffect(() => {
    forceRefresh();
  }, [JSON.stringify(filters)]);

  const memoedFilterContextValues = useMemo<TListFilterContextType>(
    () => ({
      state: filters,
      dispatch,
      initialFilters,
    }),
    [filters, dispatch]
  );

  return (
    <DirectPaymentFilterContext.Provider value={memoedFilterContextValues}>
      <FiltersBar showSearchBar persistId={EmployerEmployeeDirectPaymentsFilterId} />
      <DirectPaymentsTable
        keyExtractor={item => item.id}
        showEmployee
        onEndReached={onEndReached}
        requestState={requestState}
      />
    </DirectPaymentFilterContext.Provider>
  );
};

export default EmployerEmployeesDirectPaymentsListTab;
