import { getPersistedFilters } from "components/ListFilterBar/utils";
import {
  FinancialAccountSearchCriteriaInput,
  FinancialAccountSearchCriteriaInputAccountSourceEnum,
  FinancialAccountSearchCriteriaInputAccountTypeEnum,
  FinancialAccountSearchCriteriaInputMethodCanReceivePaymentsEnum,
} from "@budgeinc/budge-api";
import { paramAsList } from "utils/queryParams";
import { FilterFields, getBooleanDataFilterValue, TFormValues } from "./FiltersBar";

export const getInitialFilters = (persistId: string, params?: URLSearchParams): FinancialAccountSearchCriteriaInput => {
  const filters = getPersistedFilters<TFormValues>(persistId);

  const accountSourceParams = paramAsList(
    params?.get(FilterFields.SOURCE)
  ) as FinancialAccountSearchCriteriaInputAccountSourceEnum[];
  const accountTypeParams = paramAsList(
    params?.get(FilterFields.TYPE)
  ) as FinancialAccountSearchCriteriaInputAccountTypeEnum[];
  const methodCanReceivePaymentParam = paramAsList(
    params?.get(FilterFields.METHOD_CAN_RECEIVE_PAYEMENT)
  ) as FinancialAccountSearchCriteriaInputMethodCanReceivePaymentsEnum[];
  const balanceFrom = params?.get(FilterFields.BALANCE_DATE_FROM);
  const balanceTo = params?.get(FilterFields.BALANCE_DATE_TO);
  const methodAccountIdParam = params?.get(FilterFields.METHOD_ACCOUNT_ID);
  const methodEntityIdParam = params?.get(FilterFields.METHOD_ENTITY_ID);

  const hasMethodError = filters?.[FilterFields.METHOD_ERROR];
  const hasPlaidError = filters?.[FilterFields.PLAID_ERROR];

  return {
    accountSource: accountSourceParams.length ? accountSourceParams : filters?.[FilterFields.SOURCE] || undefined,
    accountType: accountTypeParams.length ? accountTypeParams : filters?.[FilterFields.TYPE] || undefined,
    methodCanReceivePayments: methodCanReceivePaymentParam.length
      ? methodCanReceivePaymentParam
      : filters?.[FilterFields.METHOD_CAN_RECEIVE_PAYEMENT] || undefined,
    balanceAsOfDateFrom: balanceFrom || (filters?.[FilterFields.BALANCE_DATE_FROM] as any) || undefined,
    balanceAsOfDateTo: balanceTo || (filters?.[FilterFields.BALANCE_DATE_TO] as any) || undefined,
    genericSearchLike: undefined,
    methodAccountId: methodAccountIdParam || filters?.[FilterFields.METHOD_ACCOUNT_ID] || undefined,
    methodEntityId: methodEntityIdParam || filters?.[FilterFields.METHOD_ENTITY_ID] || undefined,
    hasMethodError: hasMethodError ? getBooleanDataFilterValue(hasMethodError) : undefined,
    hasPlaidError: hasPlaidError ? getBooleanDataFilterValue(hasPlaidError) : undefined,
  };
};
