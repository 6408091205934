import { Stack } from "@budgeinc/budge-ui-core";

import AddressInfoCard from "components/AddressInfoCard";
import { useIsEmployerD2C } from "features/employers/contexts/EmployerContext";
import FundingAccountCard from "./cards/FundingAccountCard";

import GeneralInfoCard from "./cards/GeneralInfoCard";
import EmployerCollapseEditForm from "./components/EmployerCollapseEditForm";
import { useEmployerEntityContext } from "../../contexts/EmployerEntityContext";

const EmployerDetailTab = () => {
  const isD2C = useIsEmployerD2C();
  const { state } = useEmployerEntityContext();

  return (
    <Stack spacing="md" mb={40}>
      <GeneralInfoCard />
      <Stack spacing="md">
        {!isD2C && <AddressInfoCard CollapseEditFormComponent={EmployerCollapseEditForm} initialOpen />}
        {(state.fundingAccount || isD2C) && <FundingAccountCard />}
      </Stack>
    </Stack>
  );
};

export default EmployerDetailTab;
