import React from "react";
import { Button, ExternalLinkIcon, TButtonProps } from "@budgeinc/budge-ui-core";

const HotjarRecordingsButton = ({ userId, ...props }: { userId: string } & TButtonProps) => (
  <a
    href={`${process.env.REACT_APP_HOTJAR_SEARCH_RECORDING_URL}?filters=${encodeURIComponent(
      `{"CONTAINS":{"user_attributes.str.budge_user_id":"${userId}"}}`
    )}`}
    target="_blank"
    onClick={e => e.stopPropagation()}
    rel="noreferrer"
  >
    <Button title="Hotjar Recordings" color="#FF3C00" rightIcon={ExternalLinkIcon} variant="gradient" {...props} />
  </a>
);

export default HotjarRecordingsButton;
