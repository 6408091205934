import {
  FinancialAccountDataRatingOutput,
  FinancialAccountDataRatingInputDataPointRatingEnum,
} from "@budgeinc/budge-api";
import { TColumnProps, Text, Tag, EMPTY_VALUE_PLACEHOLDER } from "@budgeinc/budge-ui-core";
import { accountEntityRoute, employeeEntityRoute } from "common/routes";
import DateDisplay from "components/DateDisplay";
import { Link } from "components/Link";
import { formatEnumValue } from "utils/format";

export const getAccountDataRatingColumns = (employerId: string): TColumnProps<FinancialAccountDataRatingOutput>[] => [
  {
    id: "customer",
    title: "Customer",
    render: ({ employee }) =>
      employee ? (
        <Text>
          <Link to={employeeEntityRoute(employee.employerId, employee.id)}>
            {employee.firstName} {employee.lastName}
          </Link>
        </Text>
      ) : (
        <Text>{EMPTY_VALUE_PLACEHOLDER}</Text>
      ),
  },
  {
    id: "account",
    title: "Account",
    render: ({ financialAccount }) =>
      financialAccount ? (
        <Text numberOfLines={2}>
          <Link to={accountEntityRoute(employerId, financialAccount.employeeId, financialAccount.id)}>
            {financialAccount.name}
          </Link>
        </Text>
      ) : (
        <Text>{EMPTY_VALUE_PLACEHOLDER}</Text>
      ),
  },
  {
    id: "dataPointType",
    title: "Type",
    render: ({ dataPointType }) => <Text>{formatEnumValue(dataPointType)}</Text>,
  },
  {
    id: "dataPointSource",
    title: "Source",
    render: ({ dataPointSource }) => <Text>{dataPointSource}</Text>,
  },
  {
    id: "dataPointValueAtTimeOfRating",
    title: "Value",
    render: ({ dataPointValueAtTimeOfRating }) => <Text>{dataPointValueAtTimeOfRating}</Text>,
  },
  {
    id: "dataPointRating",
    title: "Rating",
    render: ({ dataPointRating }) => {
      if (dataPointRating === FinancialAccountDataRatingInputDataPointRatingEnum.Bad) {
        return <Tag color="red" value="Bad" />;
      }
      if (dataPointRating === FinancialAccountDataRatingInputDataPointRatingEnum.Good) {
        return <Tag color="green" value="Good" />;
      }
      return <Tag color="dark" value={dataPointRating} />;
    },
  },
  {
    id: "createdat",
    title: "Created at",
    render: ({ createdAt }) => <DateDisplay numberOfLines={1} value={createdAt} />,
  },
];
