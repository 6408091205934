import { LoginInput } from "@budgeinc/budge-api";
import { isIdleOrLoading } from "@budgeinc/budge-ui-utils";
import {
  IdleMonitoring,
  Box,
  PageLoader,
  SessionExpiredConfirm,
  TAccessScopeContextType,
  AccessScopeContext,
} from "@budgeinc/budge-ui-core";
import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch } from "store";
import { fetchAppMessage, fetchD2CEmployer } from "store/global/thunks";
import { useUser } from "store/user";
import { EXPIRED_TIME_KEY, loginUser, logoutUser, whoAmi as fetchWhoami } from "store/user/thunks";
import { TAuthInitialState } from "store/user/types";
import { isProd } from "utils/env";
import { userActions } from "store/user/slice";
import { AdminAppStorage } from "utils/storage";

type AuthContextType = Omit<TAuthInitialState, "startPathState" | "onboardingStatus" | "sessionExired"> & {
  login: (params: Omit<LoginInput, "target">) => void;
  logout: () => void;
};

const SessionContext = createContext<AuthContextType>({} as AuthContextType);

export const SessionContextProvider = ({ children }: PropsWithChildren<{}>): JSX.Element => {
  const dispatch = useAppDispatch();
  const rootViewRef = useRef<any>(null);
  const [isIdling, setIdling] = useState(false);
  const [isAppReady, setAppReady] = useState(false);
  const [rootRef, setRootRef] = useState<any>();
  const { whoami, isAuthenticated, loginState, requestStatus, userAccessScopes, sessionExpired, userLoggedOut } =
    useUser();

  useEffect(() => {
    setRootRef(rootViewRef);
  }, [rootViewRef.current]);

  useEffect(() => {
    const onLoad = async () => {
      await dispatch(fetchAppMessage());
      await dispatch(fetchWhoami());
    };

    onLoad();
  }, []);

  useEffect(() => {
    const prepare = async () => {
      try {
        await dispatch(fetchD2CEmployer());
      } finally {
        setAppReady(true);
      }
    };

    if (isAuthenticated) {
      setIdling(true);
      prepare();
    }
  }, [isAuthenticated]);

  const login = (params: Omit<LoginInput, "target">) => dispatch(loginUser(params));

  const logout = () => {
    setIdling(false);
    dispatch(logoutUser({}));
  };

  const handleConfirmSessionExpired = () => {
    setIdling(false);
    dispatch(userActions.localLogout({ sessionExpired: true }));
  };

  const handleConfirmLogout = () => {
    setIdling(false);
    dispatch(userActions.localLogout({ userLoggedOut: true }));
  };

  const handleIdleExpired = () => {
    setIdling(false);
    AdminAppStorage.clear();
  };

  const memoedValue = useMemo(
    () => ({
      whoami,
      login,
      logout,
      isAuthenticated,
      requestStatus,
      loginState,
      userAccessScopes,
      sessionExpired,
      userLoggedOut,
    }),
    [whoami, requestStatus, isAuthenticated, loginState, userAccessScopes, sessionExpired, userLoggedOut]
  );

  const memoedAccessScopes = useMemo<TAccessScopeContextType>(
    () => ({
      scopes: userAccessScopes,
    }),
    [userAccessScopes]
  );

  return (
    <SessionContext.Provider value={memoedValue}>
      <AccessScopeContext.Provider value={memoedAccessScopes}>
        <Box ref={rootViewRef} f={1} h="100%">
          {isIdleOrLoading(requestStatus) || (isAuthenticated && !isAppReady) ? <PageLoader /> : children}
        </Box>
        {rootRef && isAuthenticated && (
          <IdleMonitoring
            active={isIdling}
            timeoutInSecondes={isProd ? 60 * 5 : 60 * 60}
            sessionExpiringTimerDurationInSeconds={60}
            rootViewRef={rootRef}
            onConfirmLogout={handleConfirmLogout}
            onConfirmSessionExpired={handleConfirmSessionExpired}
            onExpired={handleIdleExpired}
            storageKey={EXPIRED_TIME_KEY}
          />
        )}
        <SessionExpiredConfirm
          isOpen={isAuthenticated && sessionExpired}
          onConfirm={() => dispatch(userActions.localLogout({ sessionExpired: true }))}
        />
      </AccessScopeContext.Provider>
    </SessionContext.Provider>
  );
};

export default function useSession() {
  const context = useContext(SessionContext);

  if (!context) {
    throw new Error("SessionContext must be used inside an SessionContext.Provider");
  }

  return context;
}
