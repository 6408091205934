import { useState } from "react";
import { Outlet, Route, Routes } from "react-router-dom";

import { RESET_PASSWORD_SUB_ROUTES } from "common/routes";

import ChangePasswordPage from "./ChangePassword";
import EmailSentPage from "./EmailSent";
import LinkExpiredPage from "./LinkExpired";
import EmailPage from "./SendEmail";
import Success from "./Success";

const ResetPasswordPage = () => {
  const [email, setEmail] = useState("");

  return (
    <>
      <Routes>
        <Route path="*" element={<EmailPage setEmailCb={setEmail} />} />
        <Route path={RESET_PASSWORD_SUB_ROUTES.emailSent} element={<EmailSentPage email={email} />} />
        <Route path={RESET_PASSWORD_SUB_ROUTES.token} element={<ChangePasswordPage />} />
        <Route path={RESET_PASSWORD_SUB_ROUTES.success} element={<Success />} />
        <Route path={RESET_PASSWORD_SUB_ROUTES.expiredLink} element={<LinkExpiredPage />} />
      </Routes>
      <Outlet />
    </>
  );
};

export default ResetPasswordPage;
