import React from "react";
import { ArrowDownIcon, Box, Card, Description, Stack, Text, formatMoney } from "@budgeinc/budge-ui-core";
import { FinancialAccountOutput } from "@budgeinc/budge-api";
import MethodExternalButton from "components/Method/MethodExternalButton";

interface OwnProps {
  fromAccount: FinancialAccountOutput;
  toAccount: FinancialAccountOutput;
  amountCents: number;
  creatorName?: string;
}

const ReviewManualPayment = ({ fromAccount, toAccount, amountCents, creatorName }: OwnProps) => (
  <Stack spacing="xl">
    <Stack>
      {creatorName && (
        <Text mb="sm">
          <Text fw="600">Created by</Text>: {creatorName}
        </Text>
      )}
      <Card p="lg" variant="dark">
        <Stack.Horizontal alignItems="center" justifyContent="space-between" w="100%">
          <Text f={1} fw="600">
            From:{" "}
            <Text>
              {fromAccount.name}
              {fromAccount.mask ? ` ****${fromAccount.mask}` : ""}
            </Text>
          </Text>
          <MethodExternalButton buttonType="actionIcon" type="accounts" recordId={fromAccount.methodAccountId} />
        </Stack.Horizontal>
        <Description>
          <Description.Item label="ID">{fromAccount?.id}</Description.Item>
          <Description.Item label="Method Account Id">{fromAccount?.methodAccountId}</Description.Item>
        </Description>
      </Card>
      <Box alignItems="center">
        <Text fw="500" variant="bodyMedium">
          {formatMoney({
            amountCents,
          })}
        </Text>
        <ArrowDownIcon />
      </Box>
      <Card p="lg" variant="dark">
        <Stack.Horizontal alignItems="center" justifyContent="space-between" w="100%">
          <Text f={1} fw="600">
            To:{" "}
            <Text>
              {toAccount.name}
              {toAccount.mask ? ` ****${toAccount.mask}` : ""}
            </Text>
          </Text>
          <MethodExternalButton buttonType="actionIcon" type="accounts" recordId={toAccount.methodAccountId} />
        </Stack.Horizontal>
        <Description>
          <Description.Item label="ID">{toAccount?.id}</Description.Item>
          <Description.Item label="Method Account Id">{toAccount?.methodAccountId}</Description.Item>
        </Description>
      </Card>
    </Stack>
  </Stack>
);

export default ReviewManualPayment;
