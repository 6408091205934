import { InviteOutput } from "@budgeinc/budge-api";
import { EMPTY_VALUE_PLACEHOLDER, TColumnProps, Text, Tag } from "@budgeinc/budge-ui-core";
import DateDisplay from "components/DateDisplay";

export const getInviteColumns = (): TColumnProps<any>[] => [
  {
    id: "email",
    title: "Email",
    render: ({ email }) => <Text numberOfLines={2}>{email || EMPTY_VALUE_PLACEHOLDER}</Text>,
  },
  {
    id: "status",
    title: "Status",
    maxWidth: 250,
    render: ({ status }: InviteOutput) => <Tag value={status} />,
  },
  {
    id: "challengefailedcount",
    title: "Challenge failed count",
    maxWidth: 250,
    render: ({ challengeFailedAttemptCount }: InviteOutput) => <Text>{challengeFailedAttemptCount}</Text>,
  },
  {
    id: "createdat",
    title: "Created at",
    render: ({ createdAt }) => <DateDisplay numberOfLines={1} value={createdAt} showTime />,
  },
];
