import { InviteOutput } from "@budgeinc/budge-api";
import { Button, toast } from "@budgeinc/budge-ui-core";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";
import useHandleApiError from "hooks/useHandleApiError";
import { useState } from "react";

import { employersApi, invitesCrossTenantApi } from "api/BudgeApi";

interface OwnProps {
  onInviteSent: (invite: InviteOutput) => void;
}

const ResendEmailVerificationsButton = ({ onInviteSent }: OwnProps) => {
  const [isLoading, setLoading] = useState(false);
  const { state, employerId } = useEmployeeEntityContext();
  const { handleError } = useHandleApiError();

  const handleResendInvite = () => {
    setLoading(true);
    handleError(
      invitesCrossTenantApi.resendEmployeeInvite(employerId!, state.employee?.id!).then(({ data }) => {
        onInviteSent(data);
        toast.success({
          message: "Email verification sent successfully",
        });
      })
    ).finally(() => setLoading(false));
  };

  return (
    <Button
      title="Send Email Verification"
      color="primary"
      size="sm"
      variant="gradient"
      loading={isLoading}
      onPress={handleResendInvite}
    />
  );
};

export default ResendEmailVerificationsButton;
