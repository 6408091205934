import React from "react";
import { Description, Divider, Collapse, Stack, Text } from "@budgeinc/budge-ui-core";
import { OnePaymentPlanOutput } from "@budgeinc/budge-api";
import DateDisplay from "components/DateDisplay";
import ShowRawDataButton from "components/ShowRawDataButton";
import DeleteOnePayPlanButton from "./DeleteOnePayPlanButton";

interface OwnProps {
  onePayPlan: OnePaymentPlanOutput;
  onDeleted: () => void;
}

const GeneralInfoCard = ({ onePayPlan, onDeleted }: OwnProps) => (
  <Collapse
    trigger={
      <Stack.Horizontal f={1} alignItems="center" justifyContent="space-between">
        <Text fw="500" variant="bodyMedium">
          General Information
        </Text>
        <DeleteOnePayPlanButton employeeId={onePayPlan.employeeId} onDeleted={onDeleted} />
      </Stack.Horizontal>
    }
    defaultOpen
  >
    <Description>
      <Description.Item label="ID">{onePayPlan.id}</Description.Item>
      <Description.Item label="Customer Email">{onePayPlan.employeeEmail}</Description.Item>
      <Description.Item label="Customer First Name">{onePayPlan.employeeFirstName}</Description.Item>
      <Description.Item label="Customer Last Name">{onePayPlan.employeeLastName}</Description.Item>
      <Divider />
      <Description.Item label="Created At">
        <DateDisplay value={onePayPlan.createdAt} showTime />
      </Description.Item>
      <Description.Item label="Updated At">
        <DateDisplay value={onePayPlan.updatedAt} showTime />
      </Description.Item>
    </Description>
    <ShowRawDataButton data={onePayPlan} alignSelf="flex-start" mt="xl" size="xs" />
  </Collapse>
);

export default GeneralInfoCard;
