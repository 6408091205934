import { useMemo, useReducer } from "react";
import { useParams } from "react-router-dom";
import { TAccountEntityState, accountDetailPageReducer, initialState } from "features/accounts/contexts/reducer";
import { useEmployeeRouteLoaderData } from "features/employees/routeConfig";
import { EmployeeAccountRouteParams, useAccountRouteLoaderData } from "../routeConfig";

const useAccountEntity = () => {
  const employeeOutput = useEmployeeRouteLoaderData();
  const accountOutput = useAccountRouteLoaderData();
  const params = useParams<EmployeeAccountRouteParams>();
  const [state, dispatch] = useReducer(accountDetailPageReducer, {
    ...initialState,
    account: accountOutput,
    employee: employeeOutput,
  } as TAccountEntityState);

  const memoedContextValues = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return {
    state,
    dispatch,
    params,
    memoedContextValues,
  };
};

export default useAccountEntity;
