import React, { useCallback } from "react";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";
import { backofficeApi } from "api/BudgeApi";
import { Description, Divider, InfiniteScrollTable, useInfiniteScrollList } from "@budgeinc/budge-ui-core";
import DateDisplay from "components/DateDisplay";
import ShowRawDataButton from "components/ShowRawDataButton";
import { getNotificationsColumns } from "./columns";

const NotificationsTab = () => {
  const {
    state: { employee },
  } = useEmployeeEntityContext();

  const handleOnFetch = useCallback(async ({ page, pageSize }: { page: number; pageSize: number }) => {
    if (!employee?.userId) {
      return {
        results: [],
      };
    }

    return backofficeApi
      .getUserNotifications(
        employee.userId,
        {
          target: "EMPLOYEE_APP",
        },
        `${pageSize * (page - 1)}`,
        pageSize.toString()
      )
      .then(({ data }) => ({ results: data }));
  }, []);

  const { requestState, onEndReached } = useInfiniteScrollList({
    onFetch: handleOnFetch,
  });

  return (
    <InfiniteScrollTable
      requestState={requestState}
      onEndReached={onEndReached}
      keyExtractor={item => item.id}
      columns={getNotificationsColumns()}
      local={{
        empty: {
          m: 24,
          title: "No notifications found",
        },
        noMoreItems: "No more notifications to load",
      }}
      contentContainerStyle={{ marginBottom: 16 }}
      expandable={record => (
        <>
          <Description>
            <Description.Item label="ID">{record.id}</Description.Item>
            <Description.Item label="Title">{record.title}</Description.Item>
            <Description.Item label="Body">{record.body}</Description.Item>
            <ShowRawDataButton
              title="View Data"
              modalTitle="Notification Data"
              data={record.data}
              mt="xl"
              alignSelf="flex-start"
              size="xs"
            />
          </Description>
          <Divider />
          <Description>
            <Description.Item label="Updated At">
              <DateDisplay numberOfLines={1} value={record.updatedAt} showTime />
            </Description.Item>
            <Description.Item label="Created At">
              <DateDisplay numberOfLines={1} value={record.createdAt} showTime />
            </Description.Item>
          </Description>
        </>
      )}
    />
  );
};

export default NotificationsTab;
