import React, { useState } from "react";
import { Tabs } from "@budgeinc/budge-ui-core";
import AuthorizationsListTab from "./Authorizations/AuthorizationsList";
import TokensListTab from "./AuthTokens/AuthTokensList";
import AuditListTab from "./Audits";
import EmployeeLoginsListTab from "./Logins";
import EmailVerificationsListTab from "./EmailVerifications";

export enum SECURITY_TAB_KEYS {
  AUTHORIZATIONS = "#authorizations",
  TOKENS = "#tokens",
  EMAIL_VERIFICATIONS = "#email-verifications",
  LOGINS = "#logins",
  AUDIT = "#audit",
}

const SecurityTab = () => {
  const [activeKey, setActiveKey] = useState<SECURITY_TAB_KEYS>(SECURITY_TAB_KEYS.EMAIL_VERIFICATIONS);

  return (
    <Tabs
      defaultActiveKey={SECURITY_TAB_KEYS.EMAIL_VERIFICATIONS}
      activeKey={activeKey}
      onChange={e => setActiveKey(e as SECURITY_TAB_KEYS)}
      mt={-16}
    >
      <Tabs.Pane title="Email Verifications" tabKey={SECURITY_TAB_KEYS.EMAIL_VERIFICATIONS}>
        <EmailVerificationsListTab />
      </Tabs.Pane>
      <Tabs.Pane title="Authorizations" tabKey={SECURITY_TAB_KEYS.AUTHORIZATIONS}>
        <AuthorizationsListTab />
      </Tabs.Pane>
      <Tabs.Pane title="Logins" tabKey={SECURITY_TAB_KEYS.LOGINS}>
        <EmployeeLoginsListTab />
      </Tabs.Pane>
      <Tabs.Pane title="Tokens" tabKey={SECURITY_TAB_KEYS.TOKENS}>
        <TokensListTab />
      </Tabs.Pane>
      <Tabs.Pane title="Audit" tabKey={SECURITY_TAB_KEYS.AUDIT}>
        <AuditListTab />
      </Tabs.Pane>
    </Tabs>
  );
};

export default SecurityTab;
