import React, { useState } from "react";
import { Tabs } from "@budgeinc/budge-ui-core";
import FinancialProfileTab from "./Profiles";
import CreditScoreTab from "./CreditScore";
import AccountListTab from "./Accounts";
import DebtPlanTab from "./DebtPlan";
import ProgramEnrollmentListTab from "./ProgramEnrollments";
import TransfersListTab from "./Transfers";
import TransactionListTab from "./Transactions";
import DirectPaymentsListTab from "./DirectPayments";
import OnePayPlanTab from "./OnePayPlan";

export enum FINANCIALS_TAB_KEYS {
  PROFILE = "#profile",
  CREDIT_SCORE = "#credit-score",
  ACCOUNTS = "#accounts",
  ONEPAY_PLAN = "#onepay-plan",
  DEBT_PLAN = "#debt-plan",
  PROGRAM_ENROLLMENTS = "#program-enrollments",
  DIRECT_PAYMENTS = "#direct-payments",
  TRANSACTIONS = "#transactions",
  TRANSFERS = "#transfers",
}

const FinancialsTab = () => {
  const [activeKey, setActiveKey] = useState<FINANCIALS_TAB_KEYS>(FINANCIALS_TAB_KEYS.PROFILE);

  return (
    <Tabs
      defaultActiveKey={FINANCIALS_TAB_KEYS.PROFILE}
      activeKey={activeKey}
      onChange={e => setActiveKey(e as FINANCIALS_TAB_KEYS)}
      mt={-16}
    >
      <Tabs.Pane title="Profile" tabKey={FINANCIALS_TAB_KEYS.PROFILE} flex={false}>
        <FinancialProfileTab />
      </Tabs.Pane>
      <Tabs.Pane title="Credit Score" tabKey={FINANCIALS_TAB_KEYS.CREDIT_SCORE}>
        <CreditScoreTab />
      </Tabs.Pane>
      <Tabs.Pane title="Accounts" tabKey={FINANCIALS_TAB_KEYS.ACCOUNTS}>
        <AccountListTab />
      </Tabs.Pane>
      <Tabs.Pane title="OnePay Plan" tabKey={FINANCIALS_TAB_KEYS.ONEPAY_PLAN} flex={false}>
        <OnePayPlanTab />
      </Tabs.Pane>
      <Tabs.Pane title="Debt Plan" tabKey={FINANCIALS_TAB_KEYS.DEBT_PLAN} flex={false}>
        <DebtPlanTab />
      </Tabs.Pane>
      <Tabs.Pane title="Program Enrollments" tabKey={FINANCIALS_TAB_KEYS.PROGRAM_ENROLLMENTS}>
        <ProgramEnrollmentListTab />
      </Tabs.Pane>
      <Tabs.Pane title="Direct Payments" tabKey={FINANCIALS_TAB_KEYS.DIRECT_PAYMENTS}>
        <DirectPaymentsListTab />
      </Tabs.Pane>
      <Tabs.Pane title="Transactions" tabKey={FINANCIALS_TAB_KEYS.TRANSACTIONS}>
        <TransactionListTab />
      </Tabs.Pane>
      <Tabs.Pane title="Transfers" tabKey={FINANCIALS_TAB_KEYS.TRANSFERS}>
        <TransfersListTab />
      </Tabs.Pane>
    </Tabs>
  );
};

export default FinancialsTab;
