import { Tabs } from "@budgeinc/budge-ui-core";
import { useLocation } from "react-router-dom";
import ContentHeader from "components/Layout/Main/Header";
import { ProgramEntityContext } from "features/programs/contexts/ProgramEntityContext";
import ProgramEnrollmentsListTab from "features/programs/tabs/ProgramEnrollments";
import TransactionsListTab from "features/programs/tabs/Transactions";
import useProgramsEntity from "features/programs/hooks/useProgramEntity";

import { UserWhoamiOutputScopesEnum } from "@budgeinc/budge-api";
import withAccessScopeValidator from "components/AccessScopeValidator/withAccessScopeValidator";

export enum PROGRAM_TAB_KEYS {
  TRANSACTIONS = "#transactions",
  ENROLLMENTS = "#enrollments",
}

export const Component = withAccessScopeValidator(() => {
  const { hash } = useLocation();
  const { state, memoedContextValues } = useProgramsEntity();

  const getPageTitle = () => `Program - ${state.program?.program.name}`;

  return (
    <ProgramEntityContext.Provider value={memoedContextValues}>
      <ContentHeader title={state.employer?.name} subTitle={getPageTitle()} />
      <Tabs
        onChange={activeKey => {
          window.location.hash = activeKey;
        }}
        defaultActiveKey={hash || PROGRAM_TAB_KEYS.TRANSACTIONS}
        activeKey={hash}
      >
        <Tabs.Pane title="Transactions" tabKey={PROGRAM_TAB_KEYS.TRANSACTIONS}>
          <TransactionsListTab />
        </Tabs.Pane>
        <Tabs.Pane title="Program Enrollments" tabKey={PROGRAM_TAB_KEYS.ENROLLMENTS}>
          <ProgramEnrollmentsListTab />
        </Tabs.Pane>
      </Tabs>
    </ProgramEntityContext.Provider>
  );
}, [
  {
    OR: [
      UserWhoamiOutputScopesEnum.Admin,
      UserWhoamiOutputScopesEnum.AdminRead,
      UserWhoamiOutputScopesEnum.ClientsFinancials,
    ],
  },
]);
