import React, { useState } from "react";
import { Button } from "@budgeinc/budge-ui-core";
import { ProgramTransactionAdminOutput } from "@budgeinc/budge-api";
import ApproveManualPaymentModal from "./ApproveManualPaymentModal";

interface OwnProps {
  transaction: ProgramTransactionAdminOutput;
  onSuccess?: (transaction: ProgramTransactionAdminOutput) => void;
}

const ApproveManualPaymentButton = ({ transaction, onSuccess }: OwnProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button size="xs" title="Approve" color="primary" variant="gradient" onPress={() => setOpen(true)} />
      <ApproveManualPaymentModal
        open={open}
        onClose={() => setOpen(false)}
        onSuccess={onSuccess}
        transaction={transaction}
      />
    </>
  );
};

export default ApproveManualPaymentButton;
