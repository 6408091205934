import React, { useCallback, useEffect } from "react";
import { useInfiniteScrollList } from "@budgeinc/budge-ui-core";
import { creditScoresApi, employeesApi } from "api/BudgeApi";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";
import useTableSorting from "hooks/useTableSorting";
import CreditScoresTable from "components/Table/CreditScoresTable";

const EmployeeCreditScoreListPersistSortId = "employee-credit-score-list-sort";

const CreditScoreTab = () => {
  const {
    state: { employee },
  } = useEmployeeEntityContext();
  const { sort, sortRefreshIndex, updateSort } = useTableSorting(EmployeeCreditScoreListPersistSortId);

  const handleOnFetch = useCallback(
    ({ page, pageSize }: { page: number; pageSize: number }) =>
      creditScoresApi
        .getCreditScoreAll(
          employee?.id!,
          `${pageSize * (page - 1)}`,
          pageSize.toString(),
          sort?.columnId,
          sort?.direction
        )
        .then(({ data }) => ({ results: data })),
    [sortRefreshIndex]
  );

  const { requestState, onEndReached, forceRefresh } = useInfiniteScrollList({
    onFetch: handleOnFetch,
    skipFirstForceRefresh: true,
  });

  useEffect(() => {
    forceRefresh();
  }, [sortRefreshIndex]);

  return (
    <CreditScoresTable
      requestState={requestState}
      sort={sort}
      onSort={updateSort}
      onEndReached={onEndReached}
      keyExtractor={item => item.id}
    />
  );
};

export default CreditScoreTab;
