import { Outlet } from "react-router-dom";
import { BudgeUIProvider, ModalManager, Toaster } from "@budgeinc/budge-ui-core";
import getStoreConfig from "store/index";
import { Provider as ReduxProvider } from "react-redux";
import { SessionContextProvider } from "SessionContext";
import ErrorManager from "components/ErrorManager";

const { store } = getStoreConfig();

const RouterRoot = () => (
  <ReduxProvider store={store}>
    <BudgeUIProvider>
      <SessionContextProvider>
        <Outlet />
        <ErrorManager />
        <ModalManager />
        <Toaster />
      </SessionContextProvider>
    </BudgeUIProvider>
  </ReduxProvider>
);

export default RouterRoot;
