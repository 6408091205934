import { IntakeProfileOutput } from "@budgeinc/budge-api";
import { RouteHandle } from "common/routerConfig.types";
import EntityNotFound from "components/EntityNotFound";
import { LoaderFunctionArgs, useParams, useRouteLoaderData } from "react-router-dom";
import { intakeApi } from "api/BudgeApi";

export type FinancialProfileRouteParams = {
  financialProfileId: string;
};

export const FinancialProfileEntityRouteId = "FinancialProfileEntityRouteId";

export const loadFinancialProfile = async ({ params }: LoaderFunctionArgs): Promise<IntakeProfileOutput> => {
  const routeParams = params as FinancialProfileRouteParams;

  return intakeApi.getIntakeProfile(routeParams.financialProfileId!).then(({ data }) => data);
};

export const FinancialProfileErrorElement = () => {
  const params = useParams<FinancialProfileRouteParams>();

  return (
    <EntityNotFound
      title="Financial profile not found"
      description={`Financial Profile ID ${params.financialProfileId} was not found in the database`}
    />
  );
};

export const financialProfileHandles: RouteHandle = {
  crumb: (data: IntakeProfileOutput, pathname: string) => ({
    pathname,
    hash: "",
    search: "",
    meta: {
      title: data ? data.description || data.id : "Not Found",
    },
  }),
};

export const useFinancialProfileRouteLoaderData = () =>
  useRouteLoaderData(FinancialProfileEntityRouteId) as IntakeProfileOutput;
