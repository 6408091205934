import { useMemo, useReducer } from "react";
import { useParams } from "react-router-dom";
import { programDetailPageReducer, initialState, TProgramEntityState } from "features/programs/contexts/reducer";
import { useEmployerRouteLoaderData } from "features/employers/routeConfig";
import { ProgramRouteParams, useProgramRouteLoaderData } from "../routeConfig";

const useProgramsEntity = () => {
  const employerOutput = useEmployerRouteLoaderData();
  const programWithGoalOutput = useProgramRouteLoaderData();
  const params = useParams<ProgramRouteParams>();
  const [state, dispatch] = useReducer(programDetailPageReducer, {
    ...initialState,
    program: programWithGoalOutput,
    employer: employerOutput,
  } as TProgramEntityState);

  const memoedContextValues = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return {
    state,
    dispatch,
    params,
    memoedContextValues,
  };
};

export default useProgramsEntity;
