import React, { useCallback, useMemo } from "react";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";
import { Description, InfiniteScrollTable, useInfiniteScrollList } from "@budgeinc/budge-ui-core";
import { authzApi } from "api/BudgeApi";
import { AuthTokenOutput } from "@budgeinc/budge-api";
import { InfiniteScrollDataTableContextType } from "components/Table/tableContext";
import { AuthTokenDataTableContext } from "components/Table/AuthTokenTable/AuthTokenDataTableContext";
import { getAuthTokenColumns } from "components/Table/AuthTokenTable/columns";

const AuthTokensListTab = () => {
  const employee = useEmployeeEntityContext();
  const { requestState, onEndReached, updateData } = useInfiniteScrollList({
    onFetch: ({ page, pageSize }) =>
      authzApi
        .getUserAuthTokens(employee.state.user?.id!, `${pageSize * (page - 1)}`, pageSize.toString())
        .then(({ data }) => ({ results: data })),
  });

  const updateRecord = useCallback(
    (newRecord: AuthTokenOutput) => {
      updateData(requestState.data.map(record => (record.id === newRecord.id ? newRecord : record)));
    },
    [requestState]
  );

  const memoedContext = useMemo<InfiniteScrollDataTableContextType<AuthTokenOutput>>(
    () => ({
      updateRecord,
    }),
    [updateData, updateRecord]
  );

  return (
    <AuthTokenDataTableContext.Provider value={memoedContext}>
      <InfiniteScrollTable<AuthTokenOutput>
        columns={getAuthTokenColumns()}
        keyExtractor={item => item.id}
        requestState={requestState}
        onEndReached={onEndReached}
        local={{
          empty: {
            m: 24,
            title: "No tokens found",
          },
        }}
        contentContainerStyle={{ marginBottom: 16 }}
        expandable={record => (
          <Description>
            <Description.Item label="ID">{record.id}</Description.Item>
          </Description>
        )}
      />
    </AuthTokenDataTableContext.Provider>
  );
};

export default AuthTokensListTab;
