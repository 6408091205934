import React, { useEffect, useState } from "react";
import {
  BuildValidationSchema,
  Col,
  DeleteIcon,
  FormItem,
  FormProvider,
  Input,
  MoneyInput,
  Pressable,
  Row,
  Select,
  Tooltip,
  i18n,
  yup,
  isNil,
} from "@budgeinc/budge-ui-core";
import { useFormik } from "formik";
import {
  IntakeCashFlowDataPointInput,
  IntakeCashFlowDataPointInputFrequencyEnum,
  IntakeCashFlowDataPointInputIncomeTypeEnum,
  IntakeCashFlowDataPointOutput,
  IntakeCashFlowDataPointInputIntakeTargetEnum,
} from "@budgeinc/budge-api";
import { intakeApi } from "api/BudgeApi";
import { useFinancialProfileEntityContext } from "features/financial-profiles/entity/contexts/FinancialProfileEntityContext";
import { getElementTypeDisplay } from "features/financial-profiles/entity/utils";
import MultilineInputModal from "features/financial-profiles/components/MultilineInputModal";
import { formatEnumValue } from "utils/format";
import { CASH_FLOW_FIELDS, MONEY_COL_MAW } from "../types";
import { useCashFlowsContext } from "../context";

interface OwnProps {
  initialData?: IntakeCashFlowDataPointOutput;
  localId: string;
}

const validationSchema = BuildValidationSchema({
  [CASH_FLOW_FIELDS.AMOUNT]: yup.string().required(),
  [CASH_FLOW_FIELDS.FREQUENCY]: yup.string().oneOf(Object.values(IntakeCashFlowDataPointInputFrequencyEnum)).required(),
  [CASH_FLOW_FIELDS.INCOME_TYPE]: yup
    .string()
    .oneOf(Object.values(IntakeCashFlowDataPointInputIncomeTypeEnum))
    .nullable()
    .optional(),
  [CASH_FLOW_FIELDS.TARGET]: yup.string().oneOf(Object.values(IntakeCashFlowDataPointInputIntakeTargetEnum)).required(),
  [CASH_FLOW_FIELDS.COMMENT]: yup.string().nullable().optional(),
});

const CashRow = ({ initialData, localId }: OwnProps) => {
  const { state, dispatch } = useCashFlowsContext();
  const { state: fpState } = useFinancialProfileEntityContext();
  const [intakeCashFlow, setIntakeCashFlow] = useState(initialData);
  const selectedElement = state.elements.find(({ id }) => id === intakeCashFlow?.intakeCashFlowElementId);

  const form = useFormik({
    initialValues: {
      [CASH_FLOW_FIELDS.TYPE]: intakeCashFlow?.intakeCashFlowElementId
        ? getElementTypeDisplay(intakeCashFlow.intakeCashFlowElementId, state.elements).sub
        : "",
      [CASH_FLOW_FIELDS.AMOUNT]: isNil(intakeCashFlow?.amountCents) ? undefined : intakeCashFlow?.amountCents,
      [CASH_FLOW_FIELDS.FREQUENCY]: intakeCashFlow?.frequency || IntakeCashFlowDataPointInputFrequencyEnum.Monthly,
      [CASH_FLOW_FIELDS.INCOME_TYPE]: selectedElement?.allowIncomeType ? intakeCashFlow?.incomeType : undefined,
      [CASH_FLOW_FIELDS.COMMENT]: intakeCashFlow?.comment || "",
      [CASH_FLOW_FIELDS.TARGET]: intakeCashFlow?.intakeTarget || IntakeCashFlowDataPointInputIntakeTargetEnum.Self,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: values => {
      if (fpState.financialProfile && intakeCashFlow?.intakeCashFlowElementId) {
        const payload: IntakeCashFlowDataPointInput = {
          intakeCashFlowElementId: intakeCashFlow.intakeCashFlowElementId,
          amountCents: values[CASH_FLOW_FIELDS.AMOUNT],
          frequency: values[CASH_FLOW_FIELDS.FREQUENCY],
          incomeType: selectedElement?.allowIncomeType ? values[CASH_FLOW_FIELDS.INCOME_TYPE] : undefined,
          intakeTarget: values[CASH_FLOW_FIELDS.TARGET],
          comment: values[CASH_FLOW_FIELDS.COMMENT] || null,
        };

        if (intakeCashFlow?.id) {
          return intakeApi
            .updateEmployeeIntakeCashFlowDataPoint(fpState.financialProfile.id, intakeCashFlow.id, payload)
            .then(({ data }) => setIntakeCashFlow(data));
        }
        return intakeApi
          .createEmployeeIntakeCashFlowDataPoint(fpState.financialProfile.id, payload)
          .then(({ data }) => setIntakeCashFlow(data));
      }
    },
  });

  useEffect(() => {
    dispatch({
      type: "updateForm",
      data: {
        id: localId,
        dirty: form.dirty,
      },
    });
  }, [form.dirty]);

  useEffect(() => {
    dispatch({
      type: "registerForm",
      data: {
        id: localId,
        dirty: false,
        target: intakeCashFlow?.intakeTarget || IntakeCashFlowDataPointInputIntakeTargetEnum.Self,
        incomeAmountCents: selectedElement?.decreasesCashflow ? 0 : intakeCashFlow?.amountCents || 0,
        expenseAmountCents: selectedElement?.increasesCashflow ? 0 : intakeCashFlow?.amountCents || 0,
        frequency: intakeCashFlow?.frequency || IntakeCashFlowDataPointInputFrequencyEnum.Monthly,
        formSubmitRef: form.submitForm,
        validateFormRef: form.validateForm,
      },
    });
  }, []);

  const handleDelete = () => {
    if (intakeCashFlow?.id) {
      if (fpState.financialProfile) {
        intakeApi.deleteEmployeeIntakeCashFlowDataPoint(fpState.financialProfile.id, intakeCashFlow.id).then(() =>
          dispatch({
            type: "removeLocalDataPoint",
            data: { id: intakeCashFlow.id },
          })
        );
      }
    } else {
      dispatch({
        type: "removeLocalDataPoint",
        data: { id: localId },
      });
    }
  };

  const typeValue = form.getFieldMeta(CASH_FLOW_FIELDS.TYPE).value;

  return (
    <FormProvider value={form}>
      <Row>
        <Col maw={300}>
          <Tooltip content={typeValue}>
            <Pressable noCursor>
              <FormItem name={CASH_FLOW_FIELDS.TYPE}>
                <Input editable={false} />
              </FormItem>
            </Pressable>
          </Tooltip>
        </Col>
        <Col maw={MONEY_COL_MAW}>
          <FormItem name={CASH_FLOW_FIELDS.AMOUNT}>
            <MoneyInput
              decimalScale={0}
              allowDecimal={false}
              onBlur={({ floatValue }) => {
                dispatch({
                  type: "updateForm",
                  data: {
                    id: localId,
                    incomeAmountCents: selectedElement?.increasesCashflow ? floatValue || 0 : 0,
                    expenseAmountCents: selectedElement?.decreasesCashflow ? floatValue || 0 : 0,
                  },
                });
              }}
            />
          </FormItem>
        </Col>
        <Col maw={200}>
          <FormItem name={CASH_FLOW_FIELDS.FREQUENCY}>
            <Select
              options={Object.values(IntakeCashFlowDataPointInputFrequencyEnum).map(frequency => ({
                label: formatEnumValue(frequency),
                value: frequency,
              }))}
              onChange={value => {
                dispatch({
                  type: "updateForm",
                  data: {
                    id: localId,
                    frequency: value as IntakeCashFlowDataPointInputFrequencyEnum,
                  },
                });
              }}
            />
          </FormItem>
        </Col>
        <Col maw={200}>
          <FormItem name={CASH_FLOW_FIELDS.INCOME_TYPE}>
            <Select
              disabled={!selectedElement?.allowIncomeType}
              options={Object.values(IntakeCashFlowDataPointInputIncomeTypeEnum).map(incomeType => ({
                label: formatEnumValue(incomeType),
                value: incomeType,
              }))}
            />
          </FormItem>
        </Col>
        <Col maw={100}>
          <FormItem name={CASH_FLOW_FIELDS.TARGET}>
            <Select
              options={Object.values(IntakeCashFlowDataPointInputIntakeTargetEnum).map(target => ({
                label: i18n.get(`enums.intakeTarget.${target}`),
                value: target,
              }))}
              onChange={target =>
                dispatch({
                  type: "updateForm",
                  data: {
                    id: localId,
                    target: target as IntakeCashFlowDataPointInputIntakeTargetEnum,
                  },
                })
              }
            />
          </FormItem>
        </Col>
        <Col>
          <FormItem name={CASH_FLOW_FIELDS.COMMENT}>
            <MultilineInputModal title="Comment" />
          </FormItem>
        </Col>
        <Col maw={45} alignItems="center">
          <Tooltip content="Delete">
            <Pressable mt={17.5} onPress={handleDelete}>
              {({ isHovered }) => <DeleteIcon color={isHovered ? "red" : "gray"} />}
            </Pressable>
          </Tooltip>
        </Col>
      </Row>
    </FormProvider>
  );
};

export default CashRow;
