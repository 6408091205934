import { useNavigate } from "react-router-dom";
import { Button, Stack, Text } from "@budgeinc/budge-ui-core";
import { ROUTES } from "common/routes";
import styles from "features/authentication/password/styles";
import AuthLayout from "features/authentication/Layout";

const Success = () => {
  const navigate = useNavigate();

  return (
    <AuthLayout>
      <Stack spacing="xl">
        <Stack.Horizontal alignItems="center">
          <Text variant="bodyLarge" style={styles.notice}>
            Your password was saved successfully!
          </Text>
        </Stack.Horizontal>
        <Button
          onPress={() => navigate(ROUTES.signin)}
          title="Return to login"
          color="primary"
          variant="gradient"
          size="lg"
          fullWidth
        />
      </Stack>
    </AuthLayout>
  );
};

export default Success;
