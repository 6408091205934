import React, { useState } from "react";
import { Tabs } from "@budgeinc/budge-ui-core";
import ReferralsListTab from "./ReferralsList";
import EarningsListTab from "./EarningsList";

export enum REFERRALS_TAB_KEYS {
  REFERRALS = "#referrals",
  EARNINGS = "#earnings",
}

const ReferralsTab = () => {
  const [activeKey, setActiveKey] = useState<REFERRALS_TAB_KEYS>(REFERRALS_TAB_KEYS.REFERRALS);

  return (
    <Tabs
      defaultActiveKey={REFERRALS_TAB_KEYS.REFERRALS}
      activeKey={activeKey}
      onChange={e => setActiveKey(e as REFERRALS_TAB_KEYS)}
      mt={-16}
    >
      <Tabs.Pane tabKey={REFERRALS_TAB_KEYS.REFERRALS} title="Referrals">
        <ReferralsListTab />
      </Tabs.Pane>
      <Tabs.Pane tabKey={REFERRALS_TAB_KEYS.EARNINGS} title="Earnings">
        <EarningsListTab />
      </Tabs.Pane>
    </Tabs>
  );
};

export default ReferralsTab;
