import { OnePaymentPlanOutput } from "@budgeinc/budge-api";
import { TColumnProps, Text } from "@budgeinc/budge-ui-core";
import DateDisplay from "components/DateDisplay";
import { Link } from "components/Link";
import { employeeEntityRoute } from "common/routes";
import ActionCell from "./components/ActionCell";

export const getOnePayPlanColumns = (employerId: string): TColumnProps<OnePaymentPlanOutput>[] => [
  {
    id: "name",
    title: "Name",
    render: ({ employeeFirstName, employeeLastName, employeeId }) => (
      <Text numberOfLines={1}>
        <Link to={employeeEntityRoute(employerId, employeeId)} onClick={e => e.stopPropagation()}>
          {employeeFirstName} {employeeLastName}
        </Link>
      </Text>
    ),
  },
  {
    id: "updatedat",
    title: "Updated at",
    render: ({ updatedAt }) => <DateDisplay numberOfLines={1} value={updatedAt} showTime />,
  },
  {
    id: "createdat",
    title: "Created at",
    render: ({ createdAt }) => <DateDisplay numberOfLines={1} value={createdAt} />,
  },
  {
    id: "action",
    title: "",
    align: "flex-end",
    render: record => <ActionCell onePayPlan={record} />,
  },
];
