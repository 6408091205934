import {
  IntakeDebtDataPointInputIntakeTargetEnum,
  IntakeDebtDataPointOutput,
  IntakeDebtElementOutput,
} from "@budgeinc/budge-api";
import {
  TGridTableState,
  createGridTableReducer,
  TLocalDataPointBase,
  TGridTableAction,
} from "features/financial-profiles/components/GridTable/reducer";

export type TDebtFormRefComputable = {
  balanceCurrentCents: number;
  balanceOriginalCents: number;
  monthlyPaymentCents: number;
  target: IntakeDebtDataPointInputIntakeTargetEnum;
};

export type TLocalDebtDataPoint = TLocalDataPointBase<IntakeDebtDataPointOutput>;

export type TIntakeDebtsState = TGridTableState<
  IntakeDebtDataPointOutput,
  IntakeDebtElementOutput,
  TDebtFormRefComputable
>;

export type TIntakeDebtsAction = TGridTableAction<
  IntakeDebtDataPointOutput,
  IntakeDebtElementOutput,
  TDebtFormRefComputable
>;

export const intakeDebtsReducer = createGridTableReducer<
  IntakeDebtDataPointOutput,
  IntakeDebtElementOutput,
  TDebtFormRefComputable
>();

export const initialState: TIntakeDebtsState = {
  localDataPoint: [],
  elements: [],
  formRefs: [],
  refreshIndex: 0,
};
