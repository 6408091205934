import { createContext, useContext } from "react";
import { TIntakeCashFlowAction, TIntakeCashFlowState, initialState } from "./reducer";

export type CashFlowsContextType = {
  dispatch: React.Dispatch<TIntakeCashFlowAction>;
  state: TIntakeCashFlowState;
};

export const CashFlowsContext = createContext<CashFlowsContextType | null>({
  dispatch: () => {},
  state: initialState,
});

export const useCashFlowsContext = () => {
  const context = useContext(CashFlowsContext);

  if (!context) {
    throw new Error("CashFlowsContext must be used inside an CashFlowsContext.Provider");
  }

  return context;
};
