import React from "react";
import ListFilterBar, { TFilterBarProps } from "components/ListFilterBar";
import {
  BuildValidationSchema,
  Checkbox,
  FormItem,
  Stack,
  yup,
  DateSchema,
  DatePickerInput,
  Input,
} from "@budgeinc/budge-ui-core";
import FilterCollapse from "components/ListFilterBar/FilterCollapse";
import { LoginHistoryOutputTargetEnum } from "@budgeinc/budge-api";
import { i18n } from "@budgeinc/budge-ui-utils";
import { getFilterDateFromMaxDate, getFilterDateToMinDate } from "components/ListFilterBar/utils";
import { FormikConsumer } from "formik";
import { splitAndRemoveWhiteSpace } from "utils/array";
import { useLoginFilterContext } from "./context";

export enum FilterFields {
  TARGET = "target",
  DATE_FROM = "from",
  DATE_TO = "to",
  IP = "ip",
  SUCCESS = "success",
}

export type TFormValues = {
  [FilterFields.TARGET]: LoginHistoryOutputTargetEnum[];
  [FilterFields.DATE_FROM]: Date | undefined;
  [FilterFields.DATE_TO]: Date | undefined;
  [FilterFields.SUCCESS]: boolean[] | undefined;
  [FilterFields.IP]: string | undefined;
};

const validationSchema = BuildValidationSchema({
  [FilterFields.TARGET]: yup.array().of(yup.string().oneOf(Object.values(LoginHistoryOutputTargetEnum))),
  [FilterFields.DATE_FROM]: DateSchema().nullable(),
  [FilterFields.DATE_TO]: DateSchema().nullable(),
  [FilterFields.SUCCESS]: yup.array().of(yup.boolean()).nullable(),
  [FilterFields.IP]: yup.string().nullable(),
});

const FiltersBar = ({ showSearch = true, ...props }: TFilterBarProps & { showSearch?: boolean }) => {
  const { dispatch, initialFilters } = useLoginFilterContext();

  const handleOnSearch = (value: string | undefined) =>
    dispatch({
      type: "updateFilters",
      data: {
        genericSearchLike: value,
      },
    });

  return (
    <ListFilterBar<TFormValues>
      {...props}
      showSearchBar={showSearch}
      onSearchChange={handleOnSearch}
      searchPlaceHolder="Search by first name, last name or email"
      formProps={{
        onSubmit: async values => {
          const target = values[FilterFields.TARGET];
          const ips = values[FilterFields.IP] ? splitAndRemoveWhiteSpace(values[FilterFields.IP]) : [];
          const success = values[FilterFields.SUCCESS];

          dispatch({
            type: "updateFilters",
            data: {
              target: target.length ? target : undefined,
              loginDateFrom: values[FilterFields.DATE_FROM]?.toISOString() || undefined,
              loginDateTo: values[FilterFields.DATE_TO]?.toISOString() || undefined,
              success: success?.length === 1 ? success[0] : undefined,
              ipAddress: ips?.length > 0 ? ips : undefined,
            },
          });
        },
        validationSchema,
        getInitialValues: reset => {
          if (initialFilters && !reset) {
            return {
              [FilterFields.TARGET]: initialFilters.target || [],
              [FilterFields.DATE_FROM]: initialFilters.loginDateFrom
                ? new Date(initialFilters.loginDateFrom)
                : undefined,
              [FilterFields.DATE_TO]: initialFilters.loginDateTo ? new Date(initialFilters.loginDateTo) : undefined,
              [FilterFields.SUCCESS]: initialFilters.success ? [initialFilters.success] : [],
              [FilterFields.IP]: initialFilters.ipAddress?.toString(),
            };
          }

          return {
            [FilterFields.TARGET]: [],
            [FilterFields.DATE_FROM]: undefined,
            [FilterFields.DATE_TO]: undefined,
            [FilterFields.SUCCESS]: [],
            [FilterFields.IP]: "",
          };
        },
      }}
    >
      <FormikConsumer>
        {form => (
          <Stack>
            <FilterCollapse defaultOpen trigger="Login Date">
              <Stack>
                <FormItem name={FilterFields.DATE_FROM}>
                  <DatePickerInput
                    label="From"
                    maxDate={getFilterDateFromMaxDate(form.getFieldMeta<Date>(FilterFields.DATE_TO).value)}
                  />
                </FormItem>
                <FormItem name={FilterFields.DATE_TO}>
                  <DatePickerInput
                    label="To"
                    minDate={getFilterDateToMinDate(form.getFieldMeta<Date>(FilterFields.DATE_FROM).value)}
                  />
                </FormItem>
              </Stack>
            </FilterCollapse>
            <FilterCollapse defaultOpen trigger="Target">
              <FormItem name={FilterFields.TARGET}>
                <Checkbox.Group
                  options={Object.values(LoginHistoryOutputTargetEnum).map(target => ({
                    label: i18n.get(`enums.loginHistoryTargets.${target}`),
                    value: target,
                  }))}
                />
              </FormItem>
            </FilterCollapse>
            <FilterCollapse defaultOpen trigger="Success">
              <FormItem name={FilterFields.SUCCESS}>
                <Checkbox.Group
                  options={[
                    {
                      label: "Yes",
                      value: true,
                    },
                    {
                      label: "No",
                      value: false,
                    },
                  ]}
                />
              </FormItem>
            </FilterCollapse>
            <FilterCollapse defaultOpen trigger="IP Addresses">
              <FormItem name={FilterFields.IP}>
                <Input multiline numberOfLines={5} label="IP Addresses (separated by comma)" />
              </FormItem>
            </FilterCollapse>
          </Stack>
        )}
      </FormikConsumer>
    </ListFilterBar>
  );
};

export default FiltersBar;
