import { createContext, useContext } from "react";
import { TIntakeAssetsAction, TIntakeAssetsState, initialState } from "./reducer";

export type AssetsContextType = {
  dispatch: React.Dispatch<TIntakeAssetsAction>;
  state: TIntakeAssetsState;
};

export const AssetsContext = createContext<AssetsContextType | null>({
  dispatch: () => {},
  state: initialState,
});

export const useAssetsContext = () => {
  const context = useContext(AssetsContext);

  if (!context) {
    throw new Error("AssetsContext must be used inside an AssetsContext.Provider");
  }

  return context;
};
