import React, { useState } from "react";
import { ActionIcon, DocumentIcon, TSize, Tooltip, errorManager } from "@budgeinc/budge-ui-core";
import { AxiosError } from "axios";
import { ErrorResponse } from "@budgeinc/budge-api";
import {
  fetchMethodDetailsByTypeAndId,
  TMethodEntityDetailWithIds,
  openMethodDetailsModal,
  getMethodDetailsModalTitleByType,
} from "./utils";
import { methodPrimaryColor } from "./constants";

interface OwnProps {
  recordId: string;
  type: TMethodEntityDetailWithIds;
  size?: TSize;
}

const MethodRawDetailsButton = ({ recordId, type, size = "xs" }: OwnProps) => {
  const [loading, setLoading] = useState(false);

  const handleFetchDetails = () => {
    setLoading(true);
    fetchMethodDetailsByTypeAndId(type, recordId)
      .then(({ data }) => {
        // small hack so that the tooltip close when the
        // button is enabled after loading
        // TODO: Ajust tooltip component to prevent this
        setTimeout(() => openMethodDetailsModal(type, data), 50);
      })
      .catch((e: AxiosError<ErrorResponse>) =>
        setTimeout(
          () =>
            errorManager.showError({
              error: e.response?.data,
              customMessage: (e.response?.data as any)?.data?.exceptionData?.exceptionMessage,
            }),
          50
        )
      )
      .finally(() => setLoading(false));
  };

  return (
    <Tooltip content={getMethodDetailsModalTitleByType(type)}>
      <ActionIcon
        icon={DocumentIcon}
        size={size}
        r="xl"
        color={methodPrimaryColor}
        iconColor="dark"
        variant="gradient"
        loading={loading}
        onPress={handleFetchDetails}
      />
    </Tooltip>
  );
};

export default MethodRawDetailsButton;
