import { DirectPaymentOutput } from "@budgeinc/budge-api";
import { Button, Pressable } from "@budgeinc/budge-ui-core";
import { Link } from "components/Link";
import { employeeDirectPaymentEntityRoute } from "common/routes";
import { useEmployerContext } from "features/employers/contexts/EmployerContext";
import { DIRECT_PAYMENT_TAB_KEYS } from "pages/DirectPaymentEntity";

interface OwnProps {
  directPayment: DirectPaymentOutput;
}

const ActionCell = ({ directPayment }: OwnProps) => {
  const {
    state: { employer },
  } = useEmployerContext();

  return (
    <Pressable withPressEffect>
      <Link
        to={employeeDirectPaymentEntityRoute(
          employer?.id!,
          directPayment.employee.id,
          directPayment.id,
          DIRECT_PAYMENT_TAB_KEYS.DETAILS
        )}
        asChild
      >
        <Button title="View" size="xs" color="primary" variant="gradient" />
      </Link>
    </Pressable>
  );
};

export default ActionCell;
