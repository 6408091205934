import { useCallback, useMemo } from "react";
import { directPaymentsCrossTenantApi, employersApi } from "api/BudgeApi";
import { useInfiniteScrollList } from "@budgeinc/budge-ui-core";
import { TransactionInfiniteScrollTableContext } from "components/Table/TransactionsTable/context";
import TransactionsTable from "components/Table/TransactionsTable";
import { ProgramTransactionAdminOutputWithId } from "components/Table/TransactionsTable/types";
import { useEmployerContext } from "features/employers/contexts/EmployerContext";
import { InfiniteScrollDataTableContextType } from "components/Table/tableContext";
import { useEmployeeRouteLoaderData } from "features/employees/routeConfig";
import { useDirectPaymentRouteLoaderData } from "../routeConfig";

const DirectPaymentTransactionsTab = () => {
  const {
    state: { employer },
  } = useEmployerContext();
  const employee = useEmployeeRouteLoaderData();
  const directPayment = useDirectPaymentRouteLoaderData();

  const { requestState, onEndReached, updateData } = useInfiniteScrollList({
    onFetch: ({ page, pageSize }) =>
      directPaymentsCrossTenantApi
        .searchEmployeeDirectPaymentTransactions(
          employer?.id!,
          employee.id,
          directPayment.id,
          {},
          `${pageSize * (page - 1)}`,
          pageSize.toString()
        )
        .then(({ data }) => ({
          results: data.map(record => ({
            id: record.programTransaction.id,
            ...record,
          })),
        })),
  });

  const updateRecord = useCallback(
    (newRecord: ProgramTransactionAdminOutputWithId) => {
      updateData(requestState.data.map(record => (record.id === newRecord.id ? newRecord : record)));
    },
    [requestState]
  );

  const memoedContext = useMemo<InfiniteScrollDataTableContextType<ProgramTransactionAdminOutputWithId>>(
    () => ({
      updateRecord,
    }),
    [updateRecord, updateData]
  );

  return (
    <TransactionInfiniteScrollTableContext.Provider value={memoedContext}>
      <TransactionsTable
        requestState={requestState}
        onEndReached={onEndReached}
        keyExtractor={item => item.programTransaction.id}
        onTransactionUpdated={t => {
          updateRecord({
            ...t,
            id: t.programTransaction.id,
          });
        }}
        showEmployee
      />
    </TransactionInfiniteScrollTableContext.Provider>
  );
};

export default DirectPaymentTransactionsTab;
