import { EmployeeOutput, EmployerOutput } from "@budgeinc/budge-api";
import { Button, Stack } from "@budgeinc/budge-ui-core";
import { Link } from "components/Link";

import { employeeEntityRoute } from "common/routes";

import { EMPLOYEE_TAB_KEYS } from "pages/Employees/EmployeeEntity";
import { isEmployeeMissingData } from "../utils";

import MissingDataTag from "./MissingDataTag";

interface OwnProps {
  employee: EmployeeOutput;
  employer: EmployerOutput;
}

const ActionCell = ({ employee, employer }: OwnProps) => (
  <Stack.Horizontal alignItems="center" spacing={32}>
    {isEmployeeMissingData(employee) && <MissingDataTag />}
    <Link to={employeeEntityRoute(employer.id, employee.id, EMPLOYEE_TAB_KEYS.DETAILS)} asChild>
      <Button title="View" size="xs" color="primary" variant="gradient" />
    </Link>
  </Stack.Horizontal>
);

export default ActionCell;
