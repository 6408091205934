import { createListFilterReducer, TListFilterState } from "components/ListFilterBar/reducer";
import { createListFilterContext } from "components/ListFilterBar/context";
import { FinancialAccountSearchCriteriaInput } from "@budgeinc/budge-api";
import { useContext } from "react";

export const initialState: TListFilterState<FinancialAccountSearchCriteriaInput> = {
  genericSearchLike: undefined,
  accountSource: undefined,
  accountType: undefined,
  methodCanReceivePayments: undefined,
  balanceAsOfDateFrom: undefined,
  balanceAsOfDateTo: undefined,
  methodAccountId: undefined,
  methodEntityId: undefined,
};

export const AccountFilterReducer = createListFilterReducer<FinancialAccountSearchCriteriaInput>(initialState);
export const AccountFilterContext = createListFilterContext<FinancialAccountSearchCriteriaInput>(initialState);

export const useAccountFilterContext = () => {
  const context = useContext(AccountFilterContext);

  if (!context) {
    throw new Error("useAccountFilterContext must be used inside an AccountFilterContext.Provider");
  }

  return context;
};
