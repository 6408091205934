export const MONEY_COL_MAW = 145;

export enum CASH_FLOW_FIELDS {
  TYPE = "type",
  AMOUNT = "amount",
  FREQUENCY = "frequency",
  INCOME_TYPE = "incomeType",
  TARGET = "target",
  COMMENT = "comment",
}
