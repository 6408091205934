import { useEffect, useState } from "react";
import { UserResetPasswordStatusOutputStatusEnum } from "@budgeinc/budge-api";
import { isNotUUID } from "@budgeinc/budge-ui-utils";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "common/routes";
import { usersApi } from "api/BudgeApi";

const useResetPasswordStatusCheck = (token: string) => {
  const navigate = useNavigate();
  const [isCheckLoading, setIsLoading] = useState(true);

  const redirectLinkExpired = () => navigate(ROUTES.resetPasswordLinkExpired);

  useEffect(() => {
    if (isNotUUID(token!)) {
      redirectLinkExpired();
    }

    usersApi
      .getResetPasswordStatus({
        token: token!,
      })
      .then(response => {
        const { status } = response.data;
        if (
          status === UserResetPasswordStatusOutputStatusEnum.Consumed ||
          status === UserResetPasswordStatusOutputStatusEnum.Expired
        ) {
          redirectLinkExpired();
        }
      })
      .catch(redirectLinkExpired)
      .finally(() => setIsLoading(false));
  }, [token]);

  return {
    isCheckLoading,
  };
};

export default useResetPasswordStatusCheck;
