import { createListFilterReducer, TListFilterState } from "components/ListFilterBar/reducer";
import { createListFilterContext } from "components/ListFilterBar/context";
import { DebtPlanSearchCriteriaInput } from "@budgeinc/budge-api";
import { useContext } from "react";

export const initialState: TListFilterState<DebtPlanSearchCriteriaInput> = {
  genericSearchLike: undefined,
  employeeId: undefined,
  debtPaymentPlanType: undefined,
  debtPaymentPlanStatus: undefined,
};

export const DebtPlanFilterReducer = createListFilterReducer<DebtPlanSearchCriteriaInput>(initialState);
export const DebtPlanFilterContext = createListFilterContext<DebtPlanSearchCriteriaInput>(initialState);

export const useDebtPlanFilterContext = () => {
  const context = useContext(DebtPlanFilterContext);

  if (!context) {
    throw new Error("useDebtPlanFilterContext must be used inside an DebtPlanFilterContext.Provider");
  }

  return context;
};
