import { DirectPaymentOutput } from "@budgeinc/budge-api";
import { formatMoney } from "@budgeinc/budge-ui-utils";
import { TColumnProps, Text, Tag, EMPTY_VALUE_PLACEHOLDER, formatEmployeeName } from "@budgeinc/budge-ui-core";
import { Link } from "components/Link";
import { employeeEntityRoute } from "common/routes";
import ActionCell from "./components/ActionCell";

export const getDirectPaymentsColumns = ({
  isD2c = false,
  showEmployee = false,
}: {
  isD2c: boolean;
  showEmployee: boolean;
}): TColumnProps<DirectPaymentOutput>[] => {
  const columns: TColumnProps<DirectPaymentOutput>[] = [
    {
      id: "status",
      title: "Status",
      render: record => <Tag value={record.status} />,
    },
    {
      id: "totalpayment",
      title: "Total Payment",
      render: record => (
        <Text>
          {formatMoney({
            amountCents: record.totalPaymentCents,
          })}
        </Text>
      ),
    },
    {
      id: "processedat",
      title: "Processed at",
      render: ({ processedDate }) =>
        processedDate ? <Text>{processedDate}</Text> : <Text>{EMPTY_VALUE_PLACEHOLDER}</Text>,
    },
    {
      title: "",
      id: "action",
      align: "flex-end",
      render: record => <ActionCell directPayment={record} />,
    },
  ];

  if (showEmployee) {
    columns.splice(0, 0, {
      id: "clientemployee",
      title: "Customer",
      render: ({ employee }) => (
        <Text numberOfLines={1}>
          <Link to={employeeEntityRoute(employee.employerId, employee.id)} onClick={e => e.stopPropagation()}>
            {formatEmployeeName({ employee })}
          </Link>
        </Text>
      ),
    });
  }

  return columns;
};
