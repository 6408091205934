/* eslint-disable react/no-unstable-nested-components */
import { ProgramTransactionAdminOutput, ProgramTransactionOutputStatusEnum } from "@budgeinc/budge-api";
import {
  Description,
  Divider,
  Text,
  Stack,
  InfiniteScrollTable,
  EMPTY_VALUE_PLACEHOLDER,
  formatMoney,
  PaymentStatusProgress,
  Box,
} from "@budgeinc/budge-ui-core";
import { Link } from "components/Link";
import { accountEntityRoute } from "common/routes";
import { useEmployerContext } from "features/employers/contexts/EmployerContext";
import MethodExternalButton from "components/Method/MethodExternalButton";
import { hasFilters } from "components/ListFilterBar/utils";
import { useProgramTransactionFilterContext } from "components/Table/TransactionsTable/filters/context";
import DateDisplay from "components/DateDisplay";
import MethodRawDetailsButton from "components/Method/MethodRawDetailsButton";
import MethodSyncButton from "components/Method/MethodSyncButton";
import ShowSyncAuditButton from "components/Method/ShowSyncAuditButton";
import { methodApi } from "api/BudgeApi";
import {
  MethodAccessScopeValidator,
  MethodReadAccessScopeValidator,
} from "components/AccessScopeValidator/validators/MethodAccessScopeValidator";
import { TInfiniteScrollDataTableProps } from "../types";
import { getTransactionColumns } from "./columns";
import UpdateTransactionStatusButton from "./components/UpdateTransactionStatusButton";
import { ProgramTransactionAdminOutputWithId } from "./types";
import TransactionCreatorApproverDetails from "./components/TransactionCreatorApproverDetails";

type OwnProps = TInfiniteScrollDataTableProps<ProgramTransactionAdminOutputWithId> & {
  showEmployee?: boolean;
  onTransactionUpdated: (transaction: ProgramTransactionAdminOutput) => void;
};

const TransactionsTable = ({ emptyLocal, showEmployee = false, onTransactionUpdated, ...props }: OwnProps) => {
  const {
    state: { employer },
  } = useEmployerContext();
  const filters = useProgramTransactionFilterContext();

  return (
    <InfiniteScrollTable<ProgramTransactionAdminOutputWithId>
      {...props}
      columns={getTransactionColumns({
        employerId: employer?.id!,
        onTransactionUpdated,
        showEmployee,
      })}
      local={{
        empty: {
          m: 24,
          title: "No transactions found",
          description: hasFilters(filters)
            ? `No transaction match these filters. Edit or clear all filters.`
            : undefined,
          ...emptyLocal,
        },
        noMoreItems: "No more transactions to load",
      }}
      contentContainerStyle={{ marginBottom: 16 }}
      expandable={record => (
        <>
          <Description>
            <Description.Item label="ID">{record.programTransaction.id}</Description.Item>
          </Description>
          <Divider />
          {showEmployee && record.programTransaction.employee && (
            <>
              <Description>
                <Description.Item label="Customer">
                  <Text>
                    {record.paymentRecipientName} ({record.programTransaction.employee.id})
                  </Text>
                </Description.Item>
              </Description>
              <Divider />
            </>
          )}
          <Description>
            {record.programTransaction.directPaymentId ? (
              <Description.Item label="Direct Payment ID">{record.programTransaction.directPaymentId}</Description.Item>
            ) : (
              <Description.Item label="Paycheck ID">
                {record.programTransaction.paycheckId || EMPTY_VALUE_PLACEHOLDER}
              </Description.Item>
            )}
            {record.programTransaction.programEnrollment && record.programTransaction.employee ? (
              <>
                <Description.Item label="Program Enrollment ID">
                  {record.programTransaction.programEnrollment.id}
                </Description.Item>
                <Description.Item label="Financial Account ID">
                  <Text>
                    <Link
                      to={accountEntityRoute(
                        record.programTransaction.employee.employerId,
                        record.programTransaction.employee.id,
                        record.programTransaction.programEnrollment?.financialAccountId
                      )}
                    >
                      {record.programTransaction.programEnrollment.financialAccountId}
                    </Link>
                  </Text>
                </Description.Item>
              </>
            ) : (
              <></>
            )}
          </Description>
          <Divider />
          <Description>
            <Description.Item label="Transaction Amount">
              <Text>
                {formatMoney({
                  amountCents: record.programTransaction.transactionAmountCents,
                })}
              </Text>
            </Description.Item>
            <Description.Item label="Transaction Fee">
              <Text>
                {formatMoney({
                  amountCents: record.programTransaction.transactionFeeAmountCents,
                })}
              </Text>
            </Description.Item>
            <Description.Item label="Transaction Contribution">
              <Text>
                {formatMoney({
                  amountCents: record.programTransaction.transactionContributionAmountCents,
                })}
              </Text>
            </Description.Item>
          </Description>
          <Divider />
          <Description>
            <Description.Item label="Failure Email Notified">
              <Text>{String(record.programTransaction.failureEmailNotified)}</Text>
            </Description.Item>
          </Description>
          <Divider />
          <Description>
            <Stack spacing="md">
              <Description.Item alignItems="center" label="Method Funding Account ID">
                <Stack.Horizontal alignItems="center">
                  <Text>{record.methodfiFundingAccountId || EMPTY_VALUE_PLACEHOLDER}</Text>
                  {record.methodfiFundingAccountId && (
                    <>
                      <MethodExternalButton
                        buttonType="actionIcon"
                        recordId={record.methodfiFundingAccountId}
                        type="accounts"
                      />
                      <MethodReadAccessScopeValidator>
                        <MethodRawDetailsButton recordId={record.methodfiFundingAccountId} type="accountRaw" />
                      </MethodReadAccessScopeValidator>
                    </>
                  )}
                </Stack.Horizontal>
              </Description.Item>
              <Description.Item alignItems="center" label="Method Destination Account ID">
                <Stack.Horizontal alignItems="center">
                  <Text>{record.methodfiDestinationAccountId || EMPTY_VALUE_PLACEHOLDER}</Text>
                  {record.methodfiDestinationAccountId && (
                    <>
                      <MethodExternalButton
                        buttonType="actionIcon"
                        recordId={record.methodfiDestinationAccountId}
                        type="accounts"
                      />
                      <MethodReadAccessScopeValidator>
                        <MethodRawDetailsButton recordId={record.methodfiDestinationAccountId} type="accountRaw" />
                      </MethodReadAccessScopeValidator>
                    </>
                  )}
                </Stack.Horizontal>
              </Description.Item>
              <Description.Item alignItems="center" label="Method Payment ID">
                <Stack.Horizontal alignItems="center">
                  <Text>{record.methodfiPaymentId || EMPTY_VALUE_PLACEHOLDER}</Text>
                  {record.methodfiPaymentId && (
                    <>
                      <MethodExternalButton
                        buttonType="actionIcon"
                        recordId={record.methodfiPaymentId}
                        type="payments"
                      />
                      <MethodReadAccessScopeValidator>
                        <MethodRawDetailsButton recordId={record.methodfiPaymentId} type="paymentRaw" />
                      </MethodReadAccessScopeValidator>
                      <MethodAccessScopeValidator>
                        <MethodSyncButton recordId={record.methodfiPaymentId} type="paymentSync" />
                      </MethodAccessScopeValidator>
                    </>
                  )}
                </Stack.Horizontal>
              </Description.Item>
              <Stack>
                <Description.Item alignItems="center" label="Method Last Sync">
                  {record.methodLastSync ? <DateDisplay value={record.methodLastSync} /> : EMPTY_VALUE_PLACEHOLDER}
                </Description.Item>
                <Description.Item alignItems="center" label="Method Error Code">
                  {record.methodErrorCode?.toString() || EMPTY_VALUE_PLACEHOLDER}
                </Description.Item>
                <Description.Item alignItems="center" label="Method Error Message">
                  {record.methodErrorMessage || EMPTY_VALUE_PLACEHOLDER}
                </Description.Item>
              </Stack>
            </Stack>
          </Description>
          <Divider />
          <Description>
            <Description.Item label="Completed At">
              {record.programTransaction.completedAt ? (
                <DateDisplay numberOfLines={1} value={record.programTransaction.completedAt} showTime />
              ) : (
                <Text>{EMPTY_VALUE_PLACEHOLDER}</Text>
              )}
            </Description.Item>
            <Description.Item label="Update At">
              <DateDisplay numberOfLines={1} value={record.programTransaction.createdAt} showTime />
            </Description.Item>
            <Description.Item label="Created At">
              <DateDisplay numberOfLines={1} value={record.programTransaction.createdAt} showTime />
            </Description.Item>
            {record.programTransaction.status === ProgramTransactionOutputStatusEnum.Processed ? (
              <Description.Item label="Processed Date">
                {record.programTransaction.processedDate ? (
                  <DateDisplay value={record.programTransaction.processedDate} showTime />
                ) : (
                  EMPTY_VALUE_PLACEHOLDER
                )}
              </Description.Item>
            ) : (
              <></>
            )}
          </Description>
          {(record.programTransaction.approverUserId || record.programTransaction.creatorUserId) && (
            <>
              <Divider />
              <TransactionCreatorApproverDetails
                creatorUserId={record.programTransaction.creatorUserId}
                approverUserId={record.programTransaction.approverUserId}
              />
            </>
          )}
          <Box maw={500} mt="xl">
            <PaymentStatusProgress transactionDetails={record.programTransaction.transactionDetails} />
          </Box>
          {(record.programTransaction.status === ProgramTransactionOutputStatusEnum.Submitted ||
            record.programTransaction.status === ProgramTransactionOutputStatusEnum.Hold ||
            record?.methodfiPaymentId) && (
            <>
              <Divider />
              <Stack.Horizontal>
                {(record.programTransaction.status === ProgramTransactionOutputStatusEnum.Submitted ||
                  record.programTransaction.status === ProgramTransactionOutputStatusEnum.Hold) && (
                  <UpdateTransactionStatusButton size="xs" record={record as any} />
                )}
                {record?.methodfiPaymentId && (
                  <ShowSyncAuditButton
                    modalTitle="Method Payment Sync History"
                    api={() => methodApi.getPaymentSyncHistory(record.methodfiPaymentId!)}
                    size="xs"
                    alignSelf="flex-start"
                  />
                )}
              </Stack.Horizontal>
            </>
          )}
        </>
      )}
    />
  );
};

export default TransactionsTable;
