import React, { useEffect, useState } from "react";
import {
  BuildValidationSchema,
  Col,
  DeleteIcon,
  FormItem,
  FormProvider,
  Input,
  MoneyInput,
  NumberInput,
  Pressable,
  Row,
  Select,
  Tooltip,
  i18n,
  yup,
  isNil,
} from "@budgeinc/budge-ui-core";
import { useFormik } from "formik";
import {
  IntakeAssetDataPointInput,
  IntakeAssetDataPointInputIntakeTargetEnum,
  IntakeAssetDataPointOutput,
} from "@budgeinc/budge-api";
import { intakeApi } from "api/BudgeApi";
import { useFinancialProfileEntityContext } from "features/financial-profiles/entity/contexts/FinancialProfileEntityContext";
import { getElementTypeDisplay } from "features/financial-profiles/entity/utils";
import MultilineInputModal from "features/financial-profiles/components/MultilineInputModal";
import { ASSET_FIELDS, MONEY_COL_MAW } from "../types";
import { useAssetsContext } from "../context";

interface OwnProps {
  initialData?: IntakeAssetDataPointOutput;
  localId: string;
}

const validationSchema = BuildValidationSchema({
  [ASSET_FIELDS.TYPE]: yup.string().required(),
  [ASSET_FIELDS.COMPANY_NAME]: yup.string().nullable().optional(),
  [ASSET_FIELDS.BALANCE]: yup.number().required(),
  [ASSET_FIELDS.ANNUAL_CONTRIBUTION]: yup.number().nullable().optional(),
  [ASSET_FIELDS.ANNUAL_MATCH]: yup.number().nullable().optional(),
  [ASSET_FIELDS.COMMENT]: yup.string().nullable().optional(),
  [ASSET_FIELDS.APR]: yup.number().nullable().optional(),
  [ASSET_FIELDS.TARGET]: yup.string().oneOf(Object.values(IntakeAssetDataPointInputIntakeTargetEnum)).required(),
});

const AssetRow = ({ initialData, localId }: OwnProps) => {
  const { state, dispatch } = useAssetsContext();
  const { state: fpState } = useFinancialProfileEntityContext();
  const [intakeAsset, setIntakeAsset] = useState(initialData);
  const selectedElement = state.elements.find(({ id }) => id === intakeAsset?.intakeAssetElementId);

  const form = useFormik({
    initialValues: {
      [ASSET_FIELDS.TYPE]: intakeAsset?.intakeAssetElementId
        ? getElementTypeDisplay(intakeAsset.intakeAssetElementId, state.elements).sub
        : "",
      [ASSET_FIELDS.COMPANY_NAME]: intakeAsset?.companyName || "",
      [ASSET_FIELDS.ANNUAL_CONTRIBUTION]: isNil(intakeAsset?.annualContributionCents)
        ? undefined
        : intakeAsset?.annualContributionCents,
      [ASSET_FIELDS.ANNUAL_MATCH]: isNil(intakeAsset?.annualEmployerMatchCents)
        ? undefined
        : intakeAsset?.annualEmployerMatchCents,
      [ASSET_FIELDS.BALANCE]: isNil(intakeAsset?.balanceCents) ? undefined : intakeAsset?.balanceCents,
      [ASSET_FIELDS.COMMENT]: intakeAsset?.comment || "",
      [ASSET_FIELDS.APR]: isNil(intakeAsset?.apr) ? null : intakeAsset?.apr,
      [ASSET_FIELDS.TARGET]: intakeAsset?.intakeTarget || IntakeAssetDataPointInputIntakeTargetEnum.Self,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: values => {
      if (fpState.financialProfile && intakeAsset?.intakeAssetElementId) {
        const payload: IntakeAssetDataPointInput = {
          intakeAssetElementId: intakeAsset.intakeAssetElementId,
          companyName: values[ASSET_FIELDS.COMPANY_NAME] || null,
          annualContributionCents: selectedElement?.allowAnnualContribution
            ? values[ASSET_FIELDS.ANNUAL_CONTRIBUTION]
            : undefined,
          annualEmployerMatchCents: selectedElement?.allowAnnualEmployerMatch
            ? values[ASSET_FIELDS.ANNUAL_MATCH]
            : undefined,
          balanceCents: values[ASSET_FIELDS.BALANCE],
          comment: values[ASSET_FIELDS.COMMENT] || null,
          apr: selectedElement?.allowApr
            ? isNil(values[ASSET_FIELDS.APR])
              ? null
              : values[ASSET_FIELDS.APR]
            : undefined,
          intakeTarget: values[ASSET_FIELDS.TARGET],
        };

        if (intakeAsset?.id) {
          return intakeApi
            .updateEmployeeIntakeAssetDataPoint(fpState.financialProfile.id, intakeAsset.id, payload)
            .then(({ data }) => setIntakeAsset(data));
        }
        return intakeApi
          .createEmployeeIntakeAssetDataPoint(fpState.financialProfile.id, payload)
          .then(({ data }) => setIntakeAsset(data));
      }
    },
  });

  useEffect(() => {
    dispatch({
      type: "updateForm",
      data: {
        id: localId,
        dirty: form.dirty,
      },
    });
  }, [form.dirty]);

  useEffect(() => {
    dispatch({
      type: "registerForm",
      data: {
        id: localId,
        dirty: false,
        target: intakeAsset?.intakeTarget || IntakeAssetDataPointInputIntakeTargetEnum.Self,
        balance: intakeAsset?.balanceCents || 0,
        annualContribution: intakeAsset?.annualContributionCents || 0,
        annualMatch: intakeAsset?.annualEmployerMatchCents || 0,
        formSubmitRef: form.submitForm,
        validateFormRef: form.validateForm,
      },
    });
  }, [state.refreshIndex]);

  const handleDelete = () => {
    if (intakeAsset?.id) {
      if (fpState.financialProfile) {
        intakeApi.deleteEmployeeIntakeAssetDataPoint(fpState.financialProfile.id, intakeAsset.id).then(() =>
          dispatch({
            type: "removeLocalDataPoint",
            data: { id: intakeAsset.id },
          })
        );
      }
    } else {
      dispatch({
        type: "removeLocalDataPoint",
        data: { id: localId },
      });
    }
  };

  const typeValue = form.getFieldMeta(ASSET_FIELDS.TYPE).value;

  return (
    <FormProvider value={form}>
      <Row>
        <Col>
          <Tooltip content={typeValue}>
            <Pressable noCursor>
              <FormItem name={ASSET_FIELDS.TYPE}>
                <Input editable={false} />
              </FormItem>
            </Pressable>
          </Tooltip>
        </Col>
        <Col maw={200}>
          <Tooltip content={form.getFieldMeta(ASSET_FIELDS.COMPANY_NAME).value || "-"}>
            <Pressable noCursor>
              <FormItem name={ASSET_FIELDS.COMPANY_NAME}>
                <Input />
              </FormItem>
            </Pressable>
          </Tooltip>
        </Col>
        <Col maw={MONEY_COL_MAW}>
          <FormItem name={ASSET_FIELDS.BALANCE}>
            <MoneyInput
              decimalScale={0}
              allowDecimal={false}
              onBlur={({ floatValue }) => {
                dispatch({
                  type: "updateForm",
                  data: {
                    id: localId,
                    balance: floatValue || 0,
                  },
                });
              }}
            />
          </FormItem>
        </Col>
        <Col maw={MONEY_COL_MAW}>
          <FormItem name={ASSET_FIELDS.ANNUAL_CONTRIBUTION}>
            <MoneyInput
              decimalScale={0}
              allowDecimal={false}
              disabled={!selectedElement?.allowAnnualContribution}
              onBlur={({ floatValue }) => {
                dispatch({
                  type: "updateForm",
                  data: {
                    id: localId,
                    annualContribution: floatValue || 0,
                  },
                });
              }}
            />
          </FormItem>
        </Col>
        <Col maw={MONEY_COL_MAW}>
          <FormItem name={ASSET_FIELDS.ANNUAL_MATCH}>
            <MoneyInput
              decimalScale={0}
              allowDecimal={false}
              disabled={!selectedElement?.allowAnnualEmployerMatch}
              onBlur={({ floatValue }) => {
                dispatch({
                  type: "updateForm",
                  data: {
                    id: localId,
                    annualMatch: floatValue || 0,
                  },
                });
              }}
            />
          </FormItem>
        </Col>
        <Col maw={100}>
          <FormItem name={ASSET_FIELDS.APR}>
            <NumberInput disabled={!selectedElement?.allowApr} />
          </FormItem>
        </Col>
        <Col maw={100}>
          <FormItem name={ASSET_FIELDS.TARGET}>
            <Select
              options={Object.values(IntakeAssetDataPointInputIntakeTargetEnum).map(target => ({
                label: i18n.get(`enums.intakeTarget.${target}`),
                value: target,
              }))}
              onChange={target =>
                dispatch({
                  type: "updateForm",
                  data: {
                    id: localId,
                    target: target as IntakeAssetDataPointInputIntakeTargetEnum,
                  },
                })
              }
            />
          </FormItem>
        </Col>
        <Col>
          <FormItem name={ASSET_FIELDS.COMMENT}>
            <MultilineInputModal title="Comment" />
          </FormItem>
        </Col>
        <Col maw={45} alignItems="center">
          <Tooltip content="Delete">
            <Pressable mt={17.5} onPress={handleDelete}>
              {({ isHovered }) => <DeleteIcon color={isHovered ? "red" : "gray"} />}
            </Pressable>
          </Tooltip>
        </Col>
      </Row>
    </FormProvider>
  );
};

export default AssetRow;
