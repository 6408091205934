import React, { useState } from "react";
import { Button } from "@budgeinc/budge-ui-core";
import { intakeApi } from "api/BudgeApi";
import useHandleApiError from "hooks/useHandleApiError";
import { useFinancialProfileEntityContext } from "features/financial-profiles/entity/contexts/FinancialProfileEntityContext";
import { useDebtsContext } from "../context";

const AutoFeedButton = () => {
  const { dispatch } = useDebtsContext();
  const { state } = useFinancialProfileEntityContext();
  const [loading, setLoading] = useState(false);
  const { handleError } = useHandleApiError();

  const handleOnPress = () => {
    if (state.financialProfile) {
      setLoading(true);
      handleError(
        intakeApi.createEmployeeIntakeDebtDataPointsFromLinkedAccounts(state.financialProfile.id).then(({ data }) => {
          dispatch({
            type: "setLocalDataPoints",
            data,
          });
        })
      ).finally(() => setLoading(false));
    }
  };

  return <Button size="sm" title="Feed From Linked Accounts" onPress={handleOnPress} loading={loading} />;
};

export default AutoFeedButton;
