import { getPersistedFilters } from "components/ListFilterBar/utils";
import {
  ProgramEnrollmentSearchCriteriaInput,
  ProgramEnrollmentSearchCriteriaInputStatusEnum,
} from "@budgeinc/budge-api";
import { paramAsList } from "utils/queryParams";
import { FilterFields, TFormValues } from "./FiltersBar";

export const getInitialFilters = (
  persistId: string,
  params?: URLSearchParams
): ProgramEnrollmentSearchCriteriaInput => {
  const filters = getPersistedFilters<TFormValues>(persistId);

  const statusParams = paramAsList(
    params?.get(FilterFields.STATUS)
  ) as ProgramEnrollmentSearchCriteriaInputStatusEnum[];

  return {
    status: statusParams.length ? statusParams : filters?.[FilterFields.STATUS] || undefined,
    genericSearchLike: undefined,
  };
};
