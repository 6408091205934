import { Box, Stack, Text } from "@budgeinc/budge-ui-core";
import { ReactNode } from "react";

interface OwnProps {
  title: ReactNode;
  description: ReactNode;
  actionButton: ReactNode;
}

const DangerZoneItem = ({ title, description, actionButton }: OwnProps) => (
  <Stack>
    <Stack.Horizontal alignItems="center" justifyContent="space-between">
      <Box f={1}>
        <Text fw="600" numberOfLines={2}>
          {title}
        </Text>
        <Text numberOfLines={2}>{description}</Text>
      </Box>
      {actionButton}
    </Stack.Horizontal>
  </Stack>
);

export default DangerZoneItem;
