import { ErrorResponse } from "@budgeinc/budge-api";
import { Box, Button, errorManager, Modal, modalManager, RawJsonBox, Stack, toast } from "@budgeinc/budge-ui-core";
import { plaidApi } from "api/BudgeApi";
import { AxiosError } from "axios";
import { useState } from "react";

export const openPlaidDetailsModal = (payload: any) =>
  modalManager.openModal({
    size: 750,
    onClose: modalManager.closeActiveModal,
    children: (
      <>
        <Modal.Header title="Plaid Account Details" onClose={() => modalManager.closeActiveModal()} />
        <Box px="xl" pb="xl">
          <RawJsonBox data={payload} />
        </Box>
      </>
    ),
  });

export const openPlaidSyncModal = <T,>({
  accountId,
  rawData,
  onSynced,
}: {
  rawData: any;
  accountId: string;
  onSynced?(payload: T): void;
}) => {
  const parsedRawData = JSON.parse(rawData);

  modalManager.openModal({
    size: 750,
    onClose: modalManager.closeActiveModal,
    children: (
      <>
        <Modal.Header title="Plaid Account Sync" onClose={() => modalManager.closeActiveModal()} />
        <Stack spacing="md" px="xl" pb="xl">
          <RawJsonBox data={parsedRawData} />
          {parsedRawData.updated ? (
            <PlaidConfirmSyncButton<T> accountId={accountId} onSynced={onSynced} />
          ) : (
            <Button
              title="No Change Found. Close"
              variant="gradient"
              color="primary"
              onPress={modalManager.closeActiveModal}
            />
          )}
        </Stack>
      </>
    ),
  });
};

export const PlaidConfirmSyncButton = <T,>({
  accountId,
  onSynced,
}: {
  accountId: string;
  onSynced?(payload: T): void;
}) => {
  const [loading, setLoading] = useState(false);

  const handleConfirmSync = async () => {
    setLoading(true);
    return plaidApi
      .syncAccountPlaid(accountId, false)
      .then(payload => {
        onSynced?.(payload as T);
        modalManager.closeActiveModal();
        toast.success({
          message: `Plaid account synced successfully`,
        });
      })
      .catch((e: AxiosError<ErrorResponse>) =>
        errorManager.showError({
          error: e.response?.data,
          customMessage:
            (e.response?.data as any)?.data?.exceptionData?.exceptionMessage ||
            (e.response?.data as any)?.data?.exceptionMessage,
        })
      )
      .finally(() => setLoading(false));
  };

  return (
    <Button title="Confirm Sync" color="primary" variant="gradient" loading={loading} onPress={handleConfirmSync} />
  );
};
