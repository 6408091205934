import React from "react";
import GridTableFooter from "features/financial-profiles/components/GridTable/GridTableFooter";
import { Stack, Text, formatMoney, i18n } from "@budgeinc/budge-ui-core";
import { IntakeCashFlowDataPointInputIntakeTargetEnum } from "@budgeinc/budge-api";
import { MONEY_COL_MAW } from "../types";
import { useCashFlowsContext } from "../context";
import { TCashFlowFormRefComputable } from "../reducer";
import { calculateMonthlyAmount } from "../utils";

const CashFlowFooter = () => {
  const { state } = useCashFlowsContext();

  const computeTotalFor = (
    key: keyof Pick<TCashFlowFormRefComputable, "expenseAmountCents" | "incomeAmountCents">,
    target?: IntakeCashFlowDataPointInputIntakeTargetEnum
  ) =>
    state.formRefs
      .filter(formRef => (target ? formRef.target === target : true))
      .reduce((prev, cur) => calculateMonthlyAmount(cur[key], cur.frequency) + prev, 0);

  const leftOverIncome = computeTotalFor("incomeAmountCents") - computeTotalFor("expenseAmountCents");

  return (
    <GridTableFooter
      footers={[
        {
          title: (
            <Stack spacing="lg">
              {Object.values(IntakeCashFlowDataPointInputIntakeTargetEnum).map(target => (
                <Stack key={`cashflow-totals-for-${target}`}>
                  <Text numberOfLines={1} fw="500" px={14}>
                    Total Monthly Income {i18n.get(`enums.intakeTarget.${target}`)}
                  </Text>
                  <Text numberOfLines={1} fw="500" px={14}>
                    Total Monthly Expense {i18n.get(`enums.intakeTarget.${target}`)}
                  </Text>
                </Stack>
              ))}
              <Stack>
                <Text numberOfLines={1} fw="700" px={14}>
                  Total Monthly Income
                </Text>
                <Text numberOfLines={1} fw="700" px={14}>
                  Total Monthly Expense
                </Text>
                <Text numberOfLines={1} fw="700" px={14}>
                  Monthly Left Over Income
                </Text>
              </Stack>
            </Stack>
          ),
          maw: 300,
        },
        {
          title: (
            <Stack px={14} spacing="lg">
              {Object.values(IntakeCashFlowDataPointInputIntakeTargetEnum).map(target => (
                <Stack key={`cashflow-income-for-${target}`}>
                  <Text fw="500">
                    {formatMoney({
                      amountCents: computeTotalFor("incomeAmountCents", target),
                      minFractionDigits: 0,
                      maxFractionDigits: 0,
                    })}
                  </Text>
                  <Text fw="500">
                    (
                    {formatMoney({
                      amountCents: computeTotalFor("expenseAmountCents", target),
                      minFractionDigits: 0,
                      maxFractionDigits: 0,
                    })}
                    )
                  </Text>
                </Stack>
              ))}
              <Stack>
                <Text fw="700">
                  {formatMoney({
                    amountCents: computeTotalFor("incomeAmountCents"),
                    minFractionDigits: 0,
                    maxFractionDigits: 0,
                  })}
                </Text>
                <Text fw="700">
                  (
                  {formatMoney({
                    amountCents: computeTotalFor("expenseAmountCents"),
                    minFractionDigits: 0,
                    maxFractionDigits: 0,
                  })}
                  )
                </Text>
                <Text fw="700" color={leftOverIncome === 0 ? undefined : leftOverIncome < 0 ? "red.9" : "green.9"}>
                  {formatMoney({
                    amountCents: leftOverIncome,
                    minFractionDigits: 0,
                    maxFractionDigits: 0,
                  })}
                </Text>
              </Stack>
            </Stack>
          ),
          maw: MONEY_COL_MAW,
        },
        {
          title: "",
          maw: 200,
        },
        {
          title: "",
          maw: 200,
        },
        {
          title: "",
          maw: 100,
        },
        {
          title: "",
        },
        {
          title: "",
          maw: 45,
        },
      ]}
    />
  );
};

export default CashFlowFooter;
