import React from "react";
import { DebtPaymentPlanOutputDebtPaymentPlanStatusEnum } from "@budgeinc/budge-api";
import { MoreDotsCircledIcon, SuccessIcon, Tag } from "@budgeinc/budge-ui-core";

interface OwnProps {
  status: DebtPaymentPlanOutputDebtPaymentPlanStatusEnum;
}

const DebtPlanStatusTag = ({ status }: OwnProps) => {
  const isActive = status === DebtPaymentPlanOutputDebtPaymentPlanStatusEnum.Active;

  return <Tag value={status} color={isActive ? "blue" : "green"} icon={isActive ? MoreDotsCircledIcon : SuccessIcon} />;
};

export default DebtPlanStatusTag;
