import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorResponse } from "@budgeinc/budge-api";
import { BuildValidationSchema, PasswordSchema } from "@budgeinc/budge-ui-utils";
import {
  Button,
  Form,
  FormFieldsWrapper,
  FormItem,
  getApiErrorMessage,
  PasswordInput,
  Stack,
} from "@budgeinc/budge-ui-core";
import { ROUTES } from "common/routes";
import useActionOnUnMount from "hooks/useActionOnUnMount";
import { usersApi } from "api/BudgeApi";
import { userActions } from "store/user/slice";
import { AxiosError } from "axios";
import { useAppMessages } from "store/global";
import AuthLayout from "features/authentication/Layout";
import useResetPasswordStatusCheck from "features/authentication/password/hooks/useResetPasswordStatusCheck";
import CenteredSpinner from "components/CenteredSpinner";

enum FIELDS {
  PASSWORD = "password",
}

const ChangePasswordSchema = BuildValidationSchema({
  password: PasswordSchema().required(),
});

const ChangePasswordPage = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const messages = useAppMessages();
  const [formError, setFormError] = useState<string>();
  const { isCheckLoading } = useResetPasswordStatusCheck(token!);

  useActionOnUnMount(userActions.clearErrors);

  if (isCheckLoading) {
    return <CenteredSpinner />;
  }

  const resetPassword = (password: string) =>
    usersApi
      .resetPassword({
        token: token!,
        password,
      })
      .then(() => navigate(ROUTES.resetPasswordSuccess))
      .catch((e: AxiosError<ErrorResponse>) => setFormError(getApiErrorMessage(messages, e.response?.data)));

  return (
    <AuthLayout title="Create new password">
      <Form
        formikProps={{
          initialValues: {
            [FIELDS.PASSWORD]: "",
          },
          validateOnChange: false,
          validationSchema: ChangePasswordSchema,
          onSubmit: values => resetPassword(values[FIELDS.PASSWORD]),
        }}
        formErrorMsg={formError}
      >
        {({ isSubmitting, submitForm }) => (
          <FormFieldsWrapper>
            <Stack spacing="xl">
              <Stack spacing="md">
                <FormItem name={FIELDS.PASSWORD}>
                  <PasswordInput
                    label="Password"
                    showStrengthMeter
                    showSuggestions
                    id="new-password"
                    autoComplete="new-password"
                    // @ts-ignore @web only
                    name="new-password"
                  />
                </FormItem>
              </Stack>
              <Button
                onPress={submitForm}
                title="Change Password"
                color="primary"
                variant="gradient"
                size="lg"
                fullWidth
                loading={isSubmitting}
              />
            </Stack>
          </FormFieldsWrapper>
        )}
      </Form>
    </AuthLayout>
  );
};

export default ChangePasswordPage;
