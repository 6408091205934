import React from "react";
import { Stack, Text, formatMoney, i18n } from "@budgeinc/budge-ui-core";
import { IntakeDebtDataPointInputIntakeTargetEnum } from "@budgeinc/budge-api";
import GridTableFooter from "features/financial-profiles/components/GridTable/GridTableFooter";
import { MONEY_COL_MAW } from "../types";
import { useDebtsContext } from "../context";
import { TDebtFormRefComputable } from "../reducer";

const DebtFooter = () => {
  const { state } = useDebtsContext();

  const computeTotalFor = (
    key: keyof Pick<TDebtFormRefComputable, "balanceCurrentCents" | "balanceOriginalCents" | "monthlyPaymentCents">,
    target?: IntakeDebtDataPointInputIntakeTargetEnum
  ) =>
    state.formRefs
      .filter(formRef => (target ? formRef.target === target : true))
      .reduce((prev, cur) => cur[key] + prev, 0);

  return (
    <GridTableFooter
      footers={[
        {
          title: (
            <Stack>
              {Object.values(IntakeDebtDataPointInputIntakeTargetEnum).map(target => (
                <Text key={`debt-totals-for-${target}`} fw="500" px={14}>
                  Total {i18n.get(`enums.intakeTarget.${target}`)}
                </Text>
              ))}
              <Text fw="700" px={14}>
                Total
              </Text>
            </Stack>
          ),
        },
        {
          title: "",
          maw: 200,
        },
        {
          title: (
            <Stack px={14}>
              {Object.values(IntakeDebtDataPointInputIntakeTargetEnum).map(target => (
                <Text key={`debt-bcurrent-for-${target}`} fw="500">
                  {formatMoney({
                    amountCents: computeTotalFor("balanceCurrentCents", target),
                    minFractionDigits: 0,
                    maxFractionDigits: 0,
                  })}
                </Text>
              ))}
              <Text fw="700">
                {formatMoney({
                  amountCents: computeTotalFor("balanceCurrentCents"),
                  minFractionDigits: 0,
                  maxFractionDigits: 0,
                })}
              </Text>
            </Stack>
          ),
          maw: MONEY_COL_MAW,
        },
        {
          title: (
            <Stack px={14}>
              {Object.values(IntakeDebtDataPointInputIntakeTargetEnum).map(target => (
                <Text key={`debt-boriginal-for-${target}`} fw="500">
                  {formatMoney({
                    amountCents: computeTotalFor("balanceOriginalCents", target),
                    minFractionDigits: 0,
                    maxFractionDigits: 0,
                  })}
                </Text>
              ))}
              <Text fw="700">
                {formatMoney({
                  amountCents: computeTotalFor("balanceOriginalCents"),
                  minFractionDigits: 0,
                  maxFractionDigits: 0,
                })}
              </Text>
            </Stack>
          ),
          maw: MONEY_COL_MAW,
        },
        {
          title: "",
          maw: 100,
        },
        {
          title: (
            <Stack px={14}>
              {Object.values(IntakeDebtDataPointInputIntakeTargetEnum).map(target => (
                <Text key={`debt-mpayment-for-${target}`} fw="500">
                  {formatMoney({
                    amountCents: computeTotalFor("monthlyPaymentCents", target),
                    minFractionDigits: 0,
                    maxFractionDigits: 0,
                  })}
                </Text>
              ))}
              <Text fw="700">
                {formatMoney({
                  amountCents: computeTotalFor("monthlyPaymentCents"),
                  minFractionDigits: 0,
                  maxFractionDigits: 0,
                })}
              </Text>
            </Stack>
          ),
          maw: MONEY_COL_MAW,
        },
        {
          title: "",
        },
        {
          title: "",
          maw: 115,
        },
        {
          title: "",
          maw: 100,
        },
        {
          title: "",
        },
        {
          title: "",
          maw: 45,
        },
      ]}
    />
  );
};

export default DebtFooter;
