import React, { useState } from "react";
import {
  Button,
  DeleteIcon,
  Description,
  Divider,
  EMPTY_VALUE_PLACEHOLDER,
  Modal,
  SuccessIcon,
  TColumnProps,
  Table,
  Tag,
  Text,
  formatMoney,
  useDisclosure,
} from "@budgeinc/budge-ui-core";
import { ReferralPaymentDetailOutput, ReferralPaymentOutput } from "@budgeinc/budge-api";
import { backofficeApi } from "api/BudgeApi";
import DateDisplay from "components/DateDisplay";
import { Link } from "components/Link";
import { employeeEntityRoute } from "common/routes";
import { useEmployerContext } from "features/employers/contexts/EmployerContext";

interface OwnProps {
  payment: ReferralPaymentOutput;
  includeReferrer?: boolean;
}

const PaymentDetailsButton = ({ payment, includeReferrer = false }: OwnProps) => {
  const disclosure = useDisclosure(false);
  const [loading, setLoading] = useState(false);
  const employerContext = useEmployerContext();
  const [paymentDetails, setPaymentDetails] = useState<ReferralPaymentDetailOutput[]>([]);

  const handleView = () => {
    setLoading(true);
    backofficeApi
      .getReferralPaymentDetails(payment.id)
      .then(({ data }) => setPaymentDetails(data))
      .finally(() => {
        disclosure.open();
        setLoading(false);
      });
  };

  return (
    <>
      <Button
        size="xs"
        loading={loading}
        color="primary"
        variant="gradient"
        title="View Details"
        onPress={handleView}
      />
      <Modal
        size={1000}
        isOpen={disclosure.isOpen}
        onClose={disclosure.close}
        header={{
          title: "Referral Payment Details",
        }}
      >
        <Table
          px="xl"
          pb="xl"
          variant="dark"
          data={paymentDetails}
          columns={getColumns(employerContext.state.employer?.id!, includeReferrer)}
          local={{
            empty: {
              title: "No payment details",
            },
          }}
          expandable={record => (
            <>
              <Description>
                <Description.Item label="ID">{record.id}</Description.Item>
                <Description.Item label="Payment ID">
                  {record.referralPaymentId || EMPTY_VALUE_PLACEHOLDER}
                </Description.Item>
              </Description>
              <Divider />
              <Description>
                <Description.Item label="Eligible">
                  <Tag
                    size="xs"
                    value={record.isReferralEligible ? "Eligible" : "Not Elibigle"}
                    color={record.isReferralEligible ? "green" : "red"}
                    icon={record.isReferralEligible ? SuccessIcon : DeleteIcon}
                  />
                </Description.Item>
                <Description.Item label="Referral Employee ID">{record.referralEmployeeId}</Description.Item>
                <Description.Item label="Referral Email">{record.referralEmployeeEmail}</Description.Item>
                <>
                  {!record.isReferralEligible && (
                    <Description.Item label="Non Eligible Reason">{record.nonEligibleReason}</Description.Item>
                  )}
                </>
              </Description>
              <Divider />
              <Description>
                <Description.Item label="Referrer Employee ID">{record.referrerEmployeeId}</Description.Item>
                <Description.Item label="Referrer Name">
                  <Text>
                    {record.referrerEmployeeFirstName} {record.referrerEmployeeLastName}
                  </Text>
                </Description.Item>
                <Description.Item label="Referrer Email">{record.referrerEmployeeEmail}</Description.Item>
              </Description>
              <Divider />
              <Description>
                <Description.Item label="Referral Period">
                  <DateDisplay numberOfLines={1} value={record.referralPeriod} showTime />
                </Description.Item>
                <Description.Item label="Updated At">
                  <DateDisplay numberOfLines={1} value={record.updatedAt} showTime />
                </Description.Item>
                <Description.Item label="Created At">
                  <DateDisplay numberOfLines={1} value={record.createdAt} showTime />
                </Description.Item>
              </Description>
            </>
          )}
        />
      </Modal>
    </>
  );
};

const getColumns = (employerId: string, includeReferrer: boolean): TColumnProps<ReferralPaymentDetailOutput>[] => {
  const columns: TColumnProps<ReferralPaymentDetailOutput>[] = [];

  if (includeReferrer) {
    columns.push({
      id: "referrer",
      title: "Referrer",
      render: record => (
        <Text numberOfLines={1}>
          <Link to={employeeEntityRoute(employerId, record.referrerEmployeeId)} onClick={e => e.stopPropagation()}>
            {record.referrerEmployeeFirstName} {record.referrerEmployeeLastName}
          </Link>
        </Text>
      ),
    });
  }

  columns.push(
    {
      id: "referral",
      title: "Referral",
      render: record => (
        <Text numberOfLines={1}>
          <Link to={employeeEntityRoute(employerId, record.referralEmployeeId)} onClick={e => e.stopPropagation()}>
            {record.referralEmployeeFirstName} {record.referralEmployeeLastName}
          </Link>
        </Text>
      ),
    },
    {
      id: "amount",
      title: "Amount",
      render: record => <Text>{formatMoney({ amountCents: record.referralAmountCents })}</Text>,
    },
    {
      id: "status",
      title: "Status",
      render: record => <Tag value={record.status} />,
    }
  );

  return columns;
};

export default PaymentDetailsButton;
