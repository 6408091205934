import { getPersistedFilters } from "components/ListFilterBar/utils";
import {
  DebtPlanSearchCriteriaInput,
  DebtPlanSearchCriteriaInputDebtPaymentPlanStatusEnum,
  DebtPlanSearchCriteriaInputDebtPaymentPlanTypeEnum,
} from "@budgeinc/budge-api";
import { paramAsList } from "utils/queryParams";
import { FilterFields, TFormValues } from "./FiltersBar";

export const getInitialFilters = (persistId: string, params?: URLSearchParams): DebtPlanSearchCriteriaInput => {
  const filters = getPersistedFilters<TFormValues>(persistId);

  const statusParams = paramAsList(
    params?.get(FilterFields.STATUS)
  ) as DebtPlanSearchCriteriaInputDebtPaymentPlanStatusEnum[];
  const typesParams = paramAsList(
    params?.get(FilterFields.TYPE)
  ) as DebtPlanSearchCriteriaInputDebtPaymentPlanTypeEnum[];

  return {
    debtPaymentPlanStatus: statusParams.length ? statusParams : filters?.[FilterFields.STATUS] || undefined,
    debtPaymentPlanType: typesParams.length ? typesParams : filters?.[FilterFields.TYPE] || undefined,
    genericSearchLike: undefined,
  };
};
