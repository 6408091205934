import { useEffect, useMemo, useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import { usersApi } from "api/BudgeApi";
import { employeeDetailPageReducer, initialState } from "features/employees/contexts/reducer";
import useHandleApiError from "hooks/useHandleApiError";
import { useEmployeeRouteLoaderData } from "features/employees/routeConfig";
import { EmployeeRouteParams } from "../routeConfig";

const useEmployeeEntity = () => {
  const employeeOutput = useEmployeeRouteLoaderData();
  const params = useParams<EmployeeRouteParams>();
  const [isLoading, setLoading] = useState(true);
  const [state, dispatch] = useReducer(employeeDetailPageReducer, initialState);
  const { handleError } = useHandleApiError();

  const memoedContextValues = useMemo(
    () => ({ state, dispatch, employerId: params.employerId }),
    [state, dispatch, params.employerId]
  );

  const fetchUser = (userId: string) =>
    handleError(
      usersApi.getUser(userId).then(({ data }) => {
        dispatch({
          type: "setUser",
          data,
        });
      })
    );

  useEffect(() => {
    if (employeeOutput) {
      dispatch({
        type: "set",
        data: employeeOutput,
      });

      if (employeeOutput.userId) {
        fetchUser(employeeOutput.userId).finally(() => setLoading(false));
      } else {
        setLoading(false);
      }
    }
  }, [employeeOutput?.id]);

  return {
    isLoading,
    state,
    dispatch,
    params,
    memoedContextValues,
  };
};

export default useEmployeeEntity;
