import { EmployeeOutput, UserOutput } from "@budgeinc/budge-api";

export type TEmployeeEntityState = {
  employee: EmployeeOutput | null;
  user: UserOutput | null;
};

export type TEmployeeEntityAction =
  | {
      type: "set";
      data: EmployeeOutput;
    }
  | {
      type: "setUser";
      data: UserOutput;
    };

export const initialState: TEmployeeEntityState = {
  employee: null,
  user: null,
};

export const employeeDetailPageReducer = (
  state: TEmployeeEntityState,
  action: TEmployeeEntityAction
): TEmployeeEntityState => {
  switch (action.type) {
    case "set":
      return {
        ...state,
        employee: action.data,
      };
    case "setUser":
      return {
        ...state,
        user: action.data,
      };
  }
};
