import { Navigate, Outlet, createSearchParams, useLocation } from "react-router-dom";

import { REDIRECT_PARAM_KEY, ROUTES } from "common/routes";
import useSession from "SessionContext";

const PrivateRoute = () => {
  const location = useLocation();
  const { whoami, isAuthenticated, sessionExpired, userLoggedOut } = useSession();
  const userNeedsToLogin = !whoami || !isAuthenticated;

  if (userNeedsToLogin) {
    return (
      <Navigate
        to={{
          pathname: ROUTES.signin,
          search:
            sessionExpired || (!sessionExpired && !userLoggedOut)
              ? createSearchParams({ [REDIRECT_PARAM_KEY]: location.pathname + location.hash }).toString()
              : "",
        }}
        replace
      />
    );
  }

  return <Outlet />;
};

export default PrivateRoute;
