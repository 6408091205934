import { Stack, Tag, AccessScopeValidator, TAccessOperation } from "@budgeinc/budge-ui-core";
import AddressInfoCard from "components/AddressInfoCard";
import { UserWhoamiOutputScopesEnum } from "@budgeinc/budge-api";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";
import { useIsEmployerD2C } from "features/employers/contexts/EmployerContext";

import EmployeeCollapseEditForm from "./components/EmployeeCollapseEditForm";
import GeneralInfoCard from "./GeneralInfoCard";
import PayrollInfoCard from "./PayrollInfoCard";
import DangerZoneCard from "./DangerZoneCard";
import ReferralProgramCard from "./ReferralProgramCard";
import PaydaySettingCard from "./PaydaySettingCard";
import MarketingCard from "./MarketingCard";
import MethodInfoCard from "./MethodInfoCard";
import StatusInfoCard from "./StatusInfoCard";

const EmployeeDetailTab = () => {
  const isD2C = useIsEmployerD2C();
  const { state } = useEmployeeEntityContext();

  return (
    <Stack spacing="xxl" mb={40}>
      <Stack spacing="md">
        <GeneralInfoCard />
        <StatusInfoCard />
        <MethodInfoCard />
        <MarketingCard />
        <PaydaySettingCard />
        <AddressInfoCard
          CollapseEditFormComponent={EmployeeCollapseEditForm}
          headerExtra={state.employee?.addressPayrollOverride && !isD2C && <Tag value="Overridden" color="yellow" />}
        />
        <ReferralProgramCard />
        {!isD2C && <PayrollInfoCard />}
      </Stack>
      <AccessScopeValidator op={TAccessOperation.WRITE} rule={[UserWhoamiOutputScopesEnum.Clients]}>
        <DangerZoneCard />
      </AccessScopeValidator>
    </Stack>
  );
};

export default EmployeeDetailTab;
