import { useDispatch } from "react-redux";
import { AnyAction, configureStore, Reducer } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import logger from "redux-logger";

// Reducers
import GlobalReducer, { GlobalInitialState } from "store/global";
import { RootState } from "store/types";
import UserReducer, { UserInitialState } from "store/user";

const devMode = false;

const appReducer = combineReducers<RootState>({
  user: UserReducer,
  global: GlobalReducer,
});

const appInitialState: RootState = {
  user: UserInitialState,
  global: GlobalInitialState,
};

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === "user/logout") {
    state = {
      ...appInitialState,
      user: {
        ...appInitialState.user,
        requestStatus: state.user.requestStatus,
      },
      global: {
        ...appInitialState.global,
        messages: state.global.messages,
        requestStatus: state.global.requestStatus,
      },
    };
    window.history.replaceState({}, document.title);
  }
  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  devTools: devMode,
  middleware: getDefaultMiddleware => {
    const defaultMid = getDefaultMiddleware({
      serializableCheck: false,
    });
    return devMode ? defaultMid.concat(logger as any) : defaultMid;
  },
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export default function getStoreConfig() {
  return { store };
}

export const appStore = store;
