import React from "react";
import { formatEnumValue } from "utils/format";
import {
  Box,
  Description,
  Divider,
  EMPTY_VALUE_PLACEHOLDER,
  Stack,
  SurveyScale,
  Tag,
  Text,
  i18n,
} from "@budgeinc/budge-ui-core";
import { SentimentPollOutput, SentimentPollOutputTypeEnum } from "@budgeinc/budge-api";
import DateDisplay from "components/DateDisplay";
import GeoLocationDetails from "components/GeoLocationDetails";
import ProductMarketFitSurveyScale from "./ProductMarketFitSurveyScale";

interface OwnProps {
  sentiment: SentimentPollOutput;
}

const SentimentDetails = ({ sentiment }: OwnProps) =>
  sentiment.type === SentimentPollOutputTypeEnum.GeneralFeedback ? (
    <SentimentFeedbackDetails sentiment={sentiment} />
  ) : (
    <Stack spacing="lg">
      <Description>
        <Description.Item label="ID">
          <Text>{sentiment.id}</Text>
        </Description.Item>
        <Description.Item label="Capture Source">
          <Text>{sentiment.captureSource ? formatEnumValue(sentiment.captureSource) : EMPTY_VALUE_PLACEHOLDER}</Text>
        </Description.Item>
        <Divider />
        <Description.Item label="Trigger" alignItems="center">
          <Stack.Horizontal alignItems="center">
            <Text>
              {i18n.t(`enums.sentimentPollTrigger.${sentiment.trigger}`, {
                defaultValue: formatEnumValue(sentiment.trigger),
              })}
            </Text>
            {sentiment.captureSource && <Tag color="primary" value={sentiment.captureSource} />}
          </Stack.Horizontal>
        </Description.Item>
        <Description.Item label="Question">
          <Text>{sentiment.question}</Text>
        </Description.Item>
      </Description>
      <Box maw={500}>
        {sentiment.type === SentimentPollOutputTypeEnum.ProductMarketFit ? (
          <ProductMarketFitSurveyScale value={sentiment.answer} />
        ) : (
          <SurveyScale
            readOnly
            minValueLabel="Not Confident"
            maxValueLabel="Totaly Confident"
            maxValue={10}
            value={sentiment.answer}
          />
        )}
      </Box>
      <Description>
        <Description.Item label="Asked Date">
          <DateDisplay value={sentiment.createdAt} showTime />
        </Description.Item>
        <Description.Item label="Answered Date">
          {sentiment.answeredAt ? <DateDisplay value={sentiment.answeredAt} showTime /> : EMPTY_VALUE_PLACEHOLDER}
        </Description.Item>
        <Description.Item label="Email Send Date">
          {sentiment.emailSentAt ? <DateDisplay value={sentiment.emailSentAt} showTime /> : EMPTY_VALUE_PLACEHOLDER}
        </Description.Item>
        <Description.Item label="Location">
          <GeoLocationDetails
            ipAddress={sentiment.ipAddress}
            geoLocation={sentiment.geoLocation}
            direction="horizontal"
          />
        </Description.Item>
      </Description>
    </Stack>
  );

const SentimentFeedbackDetails = ({ sentiment }: OwnProps) => (
  <Stack spacing="lg">
    <Description>
      <Description.Item label="ID">
        <Text>{sentiment.id}</Text>
      </Description.Item>
      <Description.Item label="Capture Source">
        <Text>{sentiment.captureSource ? formatEnumValue(sentiment.captureSource) : EMPTY_VALUE_PLACEHOLDER}</Text>
      </Description.Item>
      <Divider />
      <Description.Item label="Message">
        <Text>{sentiment.answerText}</Text>
      </Description.Item>
    </Description>
    <Description>
      <Description.Item label="Answered Date">
        {sentiment.answeredAt ? <DateDisplay value={sentiment.answeredAt} showTime /> : EMPTY_VALUE_PLACEHOLDER}
      </Description.Item>
      <Description.Item label="Location">
        <GeoLocationDetails
          ipAddress={sentiment.ipAddress}
          geoLocation={sentiment.geoLocation}
          direction="horizontal"
        />
      </Description.Item>
    </Description>
  </Stack>
);

export default SentimentDetails;
