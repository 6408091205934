import React, { useState } from "react";
import { Button } from "@budgeinc/budge-ui-core";
import { ProgramTransactionAdminOutput } from "@budgeinc/budge-api";
import CreateManualPaymentModal from "./CreateManualPaymentModal";

interface OwnProps {
  onSuccess?: (transaction: ProgramTransactionAdminOutput) => void;
}

const CreateManualPaymentButton = ({ onSuccess }: OwnProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button size="sm" title="New Payment" color="primary" variant="gradient" onPress={() => setOpen(true)} />
      <CreateManualPaymentModal open={open} onClose={() => setOpen(false)} onSuccess={onSuccess} />
    </>
  );
};

export default CreateManualPaymentButton;
