import React, { useState } from "react";
import CreateFundingAccountModal from "components/FundingAccount/CreateFundingAccountModal";
import { Button } from "@budgeinc/budge-ui-core";
import { FinancialAccountOutput } from "@budgeinc/budge-api";
import { useEmployerEntityContext } from "features/employers/entity/contexts/EmployerEntityContext";

const SetupFundingButton = () => {
  const [visible, setVisible] = useState(false);
  const { state, dispatch } = useEmployerEntityContext();

  const handleClose = () => setVisible(false);
  const handleOpen = () => setVisible(true);
  const handleSuccess = (account: FinancialAccountOutput) => dispatch({ type: "setFundingAccount", data: account });

  return (
    <>
      <Button
        title={`${state.fundingAccount ? "Update" : "Setup"} Funding Account`}
        size="sm"
        color="primary"
        alignSelf="flex-start"
        variant="gradient"
        onPress={handleOpen}
      />
      <CreateFundingAccountModal onSuccess={handleSuccess} isOpen={visible} onClose={handleClose} />
    </>
  );
};

export default SetupFundingButton;
