import React from "react";
import GridTableHeader, { TGridTableHeader } from "features/financial-profiles/components/GridTable/GridTableHeader";
import { MONEY_COL_MAW } from "../types";

const headers: TGridTableHeader[] = [
  {
    title: "",
  },
  {
    title: "Company Name",
    maw: 200,
  },
  {
    title: "Balance Current",
    maw: MONEY_COL_MAW,
  },
  {
    title: "Balance Original",
    maw: MONEY_COL_MAW,
  },
  {
    title: "Interest %",
    maw: 100,
  },
  {
    title: "Monthly Payment",
    maw: MONEY_COL_MAW,
  },
  {
    title: "Program Type",
    maw: 200,
  },
  {
    title: "Term (Months)",
    maw: 115,
  },
  {
    title: "Target",
    maw: 100,
  },
  {
    title: "Comment",
  },
  {
    title: "",
    maw: 45,
  },
];

const DebtHeader = () => <GridTableHeader headers={headers} />;

export default DebtHeader;
