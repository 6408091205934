import React from "react";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";
import CenteredSpinner from "components/CenteredSpinner";
import { Grid, Input, MoneyInput, Stack, Text, Collapse } from "@budgeinc/budge-ui-core";
import { getPerTransactionAmountDisplay } from "utils/transactions";
import { getSubscriptionStatusTag, getSubscriptionTag } from "features/employers/entity/tabs/Subscriptions/columns";
import { useFetchSubscription } from "api/hooks";
import ViewStripeSubscriptionButton from "../EmployeeDetails/components/ViewStripeSubscriptionButton";
import SubscriptionDetails from "./SubscriptionDetails";

const SubscriptionTab = () => {
  const {
    state: { employee },
  } = useEmployeeEntityContext();
  const { data, isFetching } = useFetchSubscription(employee);

  if (isFetching) {
    return <CenteredSpinner />;
  }

  return (
    <Stack spacing="md" mb={40}>
      <Collapse
        trigger={
          <Stack.Horizontal f={1} justifyContent="space-between">
            <Stack.Horizontal alignItems="center">
              <Text fw="500" variant="bodyMedium">
                Details
              </Text>
              {data?.subscriptionType && getSubscriptionTag(data)}
              {data?.status && getSubscriptionStatusTag(data.status)}
            </Stack.Horizontal>
            <Stack.Horizontal alignItems="center">
              {data?.stripeSubscriptionId && (
                <ViewStripeSubscriptionButton stripeSubscriptionId={data.stripeSubscriptionId} size="xs" />
              )}
            </Stack.Horizontal>
          </Stack.Horizontal>
        }
        defaultOpen
      >
        {data ? <SubscriptionDetails subscription={data} /> : <Text fs="italic">No Subscription</Text>}
      </Collapse>
      <Collapse
        trigger={
          <Text fw="500" variant="bodyMedium">
            Transaction Fees
          </Text>
        }
        defaultOpen
      >
        <Grid column={3} gutter="md">
          <Input label="Model" value={employee?.perTransactionFeeModel} disabled />
          <Input
            label="Fee"
            value={getPerTransactionAmountDisplay(employee?.perTransactionFeeModel, employee?.perTransactionFeeAmount)}
            disabled
          />
          <MoneyInput label="Cap Amount" value={employee?.perTransactionCapAmountCents || 0} disabled />
        </Grid>
      </Collapse>
    </Stack>
  );
};

export default SubscriptionTab;
