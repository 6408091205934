import { AuthTokenOutput, ErrorResponse } from "@budgeinc/budge-api";
import { Button, errorManager, toast } from "@budgeinc/budge-ui-core";
import React from "react";
import { useMutation } from "@tanstack/react-query";
import { authzApi } from "api/BudgeApi";
import { AxiosError } from "axios";
import { useAuthTokenDataTableContext } from "./AuthTokenDataTableContext";

interface OwnProps {
  token: AuthTokenOutput;
}

const ActionCell = ({ token }: OwnProps) => {
  const { updateRecord } = useAuthTokenDataTableContext();

  const { mutate, isPending } = useMutation({
    mutationFn: () => authzApi.revokeAuthToken(token.id),
    onSuccess: result => {
      updateRecord?.(result.data);
      toast.success({
        message: "Auth token revoked successfully",
      });
    },
    onError: (error: AxiosError<ErrorResponse>) =>
      errorManager.showError({
        error: error.response?.data,
      }),
  });

  return (
    <Button title="Revoke" size="xs" color="red" variant="gradient" onPress={() => mutate()} loading={isPending} />
  );
};

export default ActionCell;
