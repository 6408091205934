import React from "react";
import { Button, errorManager, modalManager, Text } from "@budgeinc/budge-ui-core";
import { debtPlanApi } from "api/BudgeApi";
import { AxiosError } from "axios";
import { ErrorResponse } from "@budgeinc/budge-api";

interface OwnProps {
  employeeId: string;
  onDeleted: () => void;
}

const DeleteDebtPlanButton = ({ employeeId, onDeleted }: OwnProps) => {
  const handleDeletePlan = async () =>
    debtPlanApi
      .deleteDebtPaymentPlan(employeeId)
      .then(() => onDeleted())
      .catch((error: AxiosError<ErrorResponse>) =>
        errorManager.showError({
          error: error.response?.data,
        })
      );

  const handleConfirm = () =>
    modalManager.openConfirm({
      confirmProps: {
        title: "Delete",
        color: "red",
      },
      onConfirm: handleDeletePlan,
      children: (
        <Text variant="bodyLarge">
          Are you sure you want to delete this debt plan? This action will also disable any enrollments that resulted
          from this debt plan.
        </Text>
      ),
    });

  return <Button variant="gradient" color="red" size="xs" title="Delete" onPress={handleConfirm} />;
};

export default DeleteDebtPlanButton;
