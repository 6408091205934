import React from "react";
import ListFilterBar, { TFilterBarProps } from "components/ListFilterBar";
import { BuildValidationSchema, Checkbox, FormItem, i18n, NumberInput, Stack, yup } from "@budgeinc/budge-ui-core";
import { FormikConsumer, FormikContextType } from "formik";
import {
  CreditScoreSearchCriteriaInputModelEnum,
  CreditScoreSearchCriteriaInputRatingEnum,
  CreditScoreSearchCriteriaInputSourceEnum,
  CreditScoreSearchCriteriaInputStatusEnum,
} from "@budgeinc/budge-api";
import FilterCollapse from "components/ListFilterBar/FilterCollapse";
import { formatEnumValue } from "utils/format";
import { useCreditScoreFilterContext } from "./context";

export enum FilterFields {
  IS_LATEST = "isLatest",
  IS_PREVIOUS = "isPrevious",
  SCORE_LOW = "creditScoreLowValue",
  SCORE_HIGH = "creditScoreHighValue",
  STATUS = "status",
  RATING = "rating",
  SOURCE = "source",
  MODEL = "model",
}

enum BooleanFilterGroupValues {
  YES = "yes",
  NO = "no",
}

export type TFormValues = {
  [FilterFields.IS_LATEST]: BooleanFilterGroupValues[];
  [FilterFields.IS_PREVIOUS]: BooleanFilterGroupValues[];
  [FilterFields.SCORE_LOW]: number | undefined;
  [FilterFields.SCORE_HIGH]: number | undefined;
  [FilterFields.STATUS]: CreditScoreSearchCriteriaInputStatusEnum[];
  [FilterFields.RATING]: CreditScoreSearchCriteriaInputRatingEnum[];
  [FilterFields.SOURCE]: CreditScoreSearchCriteriaInputSourceEnum[];
  [FilterFields.MODEL]: CreditScoreSearchCriteriaInputModelEnum[];
};

const yupBoolArraySchema = yup.array().of(yup.string().oneOf(Object.values(BooleanFilterGroupValues)));

const validationSchema = BuildValidationSchema({
  [FilterFields.IS_LATEST]: yupBoolArraySchema,
  [FilterFields.IS_PREVIOUS]: yupBoolArraySchema,
  [FilterFields.SCORE_LOW]: yup.number().nullable(),
  [FilterFields.SCORE_HIGH]: yup.number().nullable(),
  [FilterFields.STATUS]: yup.array().of(yup.string().oneOf(Object.values(CreditScoreSearchCriteriaInputStatusEnum))),
  [FilterFields.RATING]: yup.array().of(yup.string().oneOf(Object.values(CreditScoreSearchCriteriaInputRatingEnum))),
  [FilterFields.SOURCE]: yup.array().of(yup.string().oneOf(Object.values(CreditScoreSearchCriteriaInputSourceEnum))),
  [FilterFields.MODEL]: yup.array().of(yup.string().oneOf(Object.values(CreditScoreSearchCriteriaInputModelEnum))),
});

const FiltersBar = ({ ...props }: TFilterBarProps) => {
  const { dispatch } = useCreditScoreFilterContext();

  const handleOnSearch = (value: string | undefined) =>
    dispatch({
      type: "updateFilters",
      data: {
        genericSearchLike: value,
      },
    });

  return (
    <ListFilterBar<TFormValues>
      {...props}
      showSearchBar
      onSearchChange={handleOnSearch}
      searchPlaceHolder="Search by referral email, first name or last name"
      formProps={{
        onSubmit: async values => {
          const status = values[FilterFields.STATUS];
          const rating = values[FilterFields.RATING];
          const source = values[FilterFields.SOURCE];
          const model = values[FilterFields.MODEL];

          dispatch({
            type: "updateFilters",
            data: {
              isLatest: getBooleanDataFilterValue(values[FilterFields.IS_LATEST]),
              isPrevious: getBooleanDataFilterValue(values[FilterFields.IS_PREVIOUS]),
              creditScoreLowValue: values[FilterFields.SCORE_LOW] || undefined,
              creditScoreHighValue: values[FilterFields.SCORE_HIGH] || undefined,
              status: status.length ? status : undefined,
              rating: rating.length ? rating : undefined,
              source: source.length ? source : undefined,
              model: model.length ? model : undefined,
            },
          });
        },
        validationSchema,
        getFormValues: values => ({
          [FilterFields.IS_LATEST]: values?.[FilterFields.IS_LATEST] || [],
          [FilterFields.IS_PREVIOUS]: values?.[FilterFields.IS_PREVIOUS] || [],
          [FilterFields.SCORE_LOW]: values?.[FilterFields.SCORE_LOW] || undefined,
          [FilterFields.SCORE_HIGH]: values?.[FilterFields.SCORE_HIGH] || undefined,
          [FilterFields.STATUS]: values?.[FilterFields.STATUS] || [],
          [FilterFields.RATING]: values?.[FilterFields.RATING] || [],
          [FilterFields.SOURCE]: values?.[FilterFields.SOURCE] || [],
          [FilterFields.MODEL]: values?.[FilterFields.MODEL] || [],
        }),
      }}
    >
      <FormikConsumer>
        {form => (
          <Stack>
            <FilterCollapse trigger="Is Latest" defaultOpen={isdefaultOpen(form, FilterFields.IS_LATEST)}>
              <FormItem name={FilterFields.IS_LATEST}>
                <Checkbox.Group
                  options={[
                    { label: "Yes", value: BooleanFilterGroupValues.YES },
                    { label: "No", value: BooleanFilterGroupValues.NO },
                  ]}
                />
              </FormItem>
            </FilterCollapse>
            <FilterCollapse trigger="Is Previous" defaultOpen={isdefaultOpen(form, FilterFields.IS_PREVIOUS)}>
              <FormItem name={FilterFields.IS_PREVIOUS}>
                <Checkbox.Group
                  options={[
                    { label: "Yes", value: BooleanFilterGroupValues.YES },
                    { label: "No", value: BooleanFilterGroupValues.NO },
                  ]}
                />
              </FormItem>
            </FilterCollapse>
            <FilterCollapse
              trigger="Score Value"
              defaultOpen={
                !!(form.getFieldMeta(FilterFields.SCORE_LOW).value || form.getFieldMeta(FilterFields.SCORE_HIGH).value)
              }
            >
              <Stack>
                <FormItem name={FilterFields.SCORE_LOW}>
                  <NumberInput label="Score Low Value" />
                </FormItem>
                <FormItem name={FilterFields.SCORE_HIGH}>
                  <NumberInput label="Score High Value" />
                </FormItem>
              </Stack>
            </FilterCollapse>
            <FilterCollapse trigger="Status">
              <FormItem name={FilterFields.STATUS}>
                <Checkbox.Group
                  options={Object.values(CreditScoreSearchCriteriaInputStatusEnum).map(status => ({
                    label: formatEnumValue(status),
                    value: status,
                  }))}
                />
              </FormItem>
            </FilterCollapse>
            <FilterCollapse trigger="Rating">
              <FormItem name={FilterFields.RATING}>
                <Checkbox.Group
                  options={Object.values(CreditScoreSearchCriteriaInputRatingEnum).map(rating => ({
                    label: formatEnumValue(rating),
                    value: rating,
                  }))}
                />
              </FormItem>
            </FilterCollapse>
            <FilterCollapse trigger="Source">
              <FormItem name={FilterFields.SOURCE}>
                <Checkbox.Group
                  options={Object.values(CreditScoreSearchCriteriaInputSourceEnum).map(source => ({
                    label: formatEnumValue(source),
                    value: source,
                  }))}
                />
              </FormItem>
            </FilterCollapse>
            <FilterCollapse trigger="Model">
              <FormItem name={FilterFields.MODEL}>
                <Checkbox.Group
                  options={Object.values(CreditScoreSearchCriteriaInputModelEnum).map(model => ({
                    label: i18n.t(`enums.creditScore.model.${model}`),
                    value: model,
                  }))}
                />
              </FormItem>
            </FilterCollapse>
          </Stack>
        )}
      </FormikConsumer>
    </ListFilterBar>
  );
};

export const isdefaultOpen = (form: FormikContextType<any>, filterId: string) => {
  const { value } = form.getFieldMeta(filterId);

  return value ? (value as any).length : false;
};

export const getBooleanDataFilterValue = (values: BooleanFilterGroupValues[]): boolean | undefined => {
  if (!values.length || values.length === 2) return undefined;

  return values.includes(BooleanFilterGroupValues.YES);
};

export default FiltersBar;
