import { createListFilterReducer, TListFilterState } from "components/ListFilterBar/reducer";
import { createListFilterContext } from "components/ListFilterBar/context";
import { LoginSearchCriteriaInput } from "@budgeinc/budge-api";
import { useContext } from "react";

export const initialState: TListFilterState<LoginSearchCriteriaInput> = {
  target: undefined,
  loginDateFrom: undefined,
  loginDateTo: undefined,
  genericSearchLike: undefined,
  success: undefined,
  ipAddress: undefined,
};

export const LoginFilterReducer = createListFilterReducer<LoginSearchCriteriaInput>(initialState);
export const LoginFilterContext = createListFilterContext<LoginSearchCriteriaInput>(initialState);

export const useLoginFilterContext = () => {
  const context = useContext(LoginFilterContext);

  if (!context) {
    throw new Error("LoginFilterContext must be used inside an LoginFilterContext.Provider");
  }

  return context;
};
