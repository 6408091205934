import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  FormItem,
  FormProvider,
  Grid,
  Input,
  Stack,
  Text,
  getApiErrorMessage,
} from "@budgeinc/budge-ui-core";
import { intakeApi } from "api/BudgeApi";
import { ErrorResponse, IntakeWhyOutput } from "@budgeinc/budge-api";
import { useFormik } from "formik";
import { useAppMessages } from "store/global";
import { AxiosError } from "axios";
import TabFormStateChecker from "features/financial-profiles/components/TabFormStateChecker";
import CenteredSpinner from "components/CenteredSpinner";
import { IntakeWhyFormItemType } from "./types";
import { getApiPayload, getFormInitialValue, getIntakeWhyFormItemName } from "./utils";
import { useFinancialProfileEntityContext } from "../../contexts/FinancialProfileEntityContext";

const WhysTab = () => {
  const messages = useAppMessages();
  const { state } = useFinancialProfileEntityContext();
  const [initLoading, setInitLoading] = useState(true);
  const [whyOutput, setWhyOutput] = useState<IntakeWhyOutput[]>([]);
  const [formError, setFormError] = useState<string>();

  useEffect(() => {
    if (state.financialProfile?.id) {
      intakeApi
        .getIntakeWhyData(state.financialProfile.id)
        .then(({ data }) => setWhyOutput(data))
        .finally(() => setInitLoading(false));
    }
  }, [state.financialProfile?.id]);

  const form = useFormik({
    initialValues: getFormInitialValue(whyOutput),
    enableReinitialize: true,
    onSubmit: values => {
      if (state.financialProfile) {
        return intakeApi
          .saveEmployeeIntakeWhyData(state.financialProfile.id, getApiPayload(values, whyOutput))
          .then(({ data }) => setWhyOutput(data))
          .catch((e: AxiosError<ErrorResponse>) => setFormError(getApiErrorMessage(messages, e.response?.data)));
      }
    },
  });

  if (initLoading) {
    return <CenteredSpinner />;
  }

  return (
    <>
      <Card>
        <FormProvider value={form} formErrorMsg={formError}>
          <Stack spacing="md">
            <Grid column={3} gutter={0} bordered grow>
              <Box p="md">
                <Text fw="600">Understanding your Why:</Text>
              </Box>
              <Box p="md" alignItems="center">
                <Text fw="600">Response</Text>
              </Box>
              <Box p="md" alignItems="center">
                <Text fw="600">Comment</Text>
              </Box>
              {whyOutput
                .sort((a, b) => a.sortOrder - b.sortOrder)
                .map(why => [
                  <Box p="md">
                    <Text>{why.description}</Text>
                  </Box>,
                  <Box p="md" alignItems="center">
                    <FormItem
                      name={getIntakeWhyFormItemName(why.intakeWhyElementId, IntakeWhyFormItemType.VALUE)}
                      w="100%"
                    >
                      <Input multiline numberOfLines={8} />
                    </FormItem>
                  </Box>,
                  <Box p="md" alignItems="center">
                    <FormItem
                      name={getIntakeWhyFormItemName(why.intakeWhyElementId, IntakeWhyFormItemType.COMMENT)}
                      w="100%"
                    >
                      <Input multiline numberOfLines={8} />
                    </FormItem>
                  </Box>,
                ])}
            </Grid>
            <Button
              title="Save"
              size="sm"
              color="primary"
              variant="gradient"
              alignSelf="flex-end"
              onPress={form.submitForm}
              loading={form.isSubmitting}
              disabled={!form.dirty}
            />
          </Stack>
        </FormProvider>
      </Card>
      <TabFormStateChecker canNavigate={!form.dirty} onSaveChanges={form.submitForm} />
    </>
  );
};

export default WhysTab;
