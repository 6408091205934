import { SectionListData } from "react-native";

export type TAllIntakeElementOutput = { id: string; description: string; sortOrder: number }[];

export const getElementTypeDisplay = (
  elementId: string,
  elements: TAllIntakeElementOutput
): {
  main: string;
  sub: string;
} => {
  const element = elements.find(({ id }) => id === elementId);

  if (!element)
    return {
      main: "",
      sub: "",
    };

  if (element.description.indexOf(">") === -1) {
    return {
      main: element.description,
      sub: "-",
    };
  }

  const splittedElement = element.description.split(">");

  return {
    main: splittedElement[0].trim(),
    sub: splittedElement[1].trim(),
  };
};

export const splitDataPointByMainElementType = <T,>(
  dataPoints: T[],
  elements: TAllIntakeElementOutput,
  intakeElementIdKey: "intakeAssetElementId" | "intakeDebtElementId" | "intakeCashFlowElementId"
): ReadonlyArray<SectionListData<T>> => {
  const sections: Record<string, T[]> = {};

  dataPoints.forEach((dataPoint: any) => {
    const mainType = getElementTypeDisplay(dataPoint[intakeElementIdKey]!, elements).main;

    if (!(mainType in sections)) {
      sections[mainType] = [];
    }

    sections[mainType].push(dataPoint);
  });

  return Object.entries(sections).map(([elementMainType, data]) => ({
    title: elementMainType,
    data,
  }));
};
