import { useState } from "react";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";
import { useFormik } from "formik";
import {
  Stack,
  Description,
  FormProvider,
  Grid,
  FormItem,
  Input,
  getApiErrorMessage,
  Button,
  Text,
  Divider,
  Box,
  EMPTY_VALUE_PLACEHOLDER,
  toast,
  AccessScopeValidator,
  TAccessOperation,
  Collapse,
} from "@budgeinc/budge-ui-core";
import DateDisplay from "components/DateDisplay";
import {
  EmployeeInput,
  EmployeeOutput,
  ErrorResponse,
  UserWhoamiOutput,
  UserWhoamiOutputScopesEnum,
} from "@budgeinc/budge-api";
import { employeesCrossTenantApi, employersApi } from "api/BudgeApi";
import { AxiosError } from "axios";
import { useAppMessages } from "store/global";
import { useUser } from "store/user";

enum FIELDS {
  INTERNAL_NOTES = "internalNotes",
}

type TFormValues = Record<FIELDS, any>;

type NoteEntry = {
  timeStamp: string;
  author?: string;
  note: string;
};

const EmployeeNoteTakingTab = () => {
  const messages = useAppMessages();
  const { state, dispatch, employerId } = useEmployeeEntityContext();
  const [formError, setFormError] = useState<string | undefined>();
  const { whoami } = useUser();
  const activeEmployee = state.employee!;
  const notes = getNotes(activeEmployee);

  const form = useFormik({
    initialValues: getFormInitialValues(activeEmployee!),
    enableReinitialize: true,
    onSubmit: async values =>
      employeesCrossTenantApi
        .updateEmployeeForEmployer(employerId!, activeEmployee.id, getEmployeePayload(activeEmployee, whoami, values))
        .then(resp => {
          dispatch({
            type: "set",
            data: resp.data,
          });
          toast.success({
            message: "New note added successfully",
          });
          form.resetForm();
        })
        .catch((err: AxiosError<ErrorResponse>) => {
          setFormError(getApiErrorMessage(messages, err.response?.data));
        }),
  });

  return (
    <Stack spacing="xl" mb={40}>
      <AccessScopeValidator op={TAccessOperation.WRITE} rule={[UserWhoamiOutputScopesEnum.ClientsNotes]}>
        <Collapse
          defaultOpen
          expandable={false}
          trigger={
            <Stack.Horizontal spacing="md" alignItems="center">
              <Text fw="500" variant="bodyMedium">
                New Note
              </Text>
            </Stack.Horizontal>
          }
        >
          <Stack spacing="lg">
            <FormProvider value={form} formErrorMsg={formError}>
              <Stack spacing="md">
                <Grid column={1} gutter="md">
                  <FormItem name={FIELDS.INTERNAL_NOTES}>
                    <Input label="Note" />
                  </FormItem>
                </Grid>
              </Stack>
            </FormProvider>
            <Stack alignItems="flex-end">
              <Button
                title="Add Note"
                color="primary"
                variant="gradient"
                onPress={() => {
                  form.handleSubmit();
                }}
                size="sm"
                loading={form.isSubmitting}
                disabled={!form.dirty}
              />
            </Stack>
          </Stack>
        </Collapse>
      </AccessScopeValidator>
      {notes.length > 0 && (
        <Collapse
          defaultOpen
          expandable={false}
          trigger={
            <Stack.Horizontal spacing="md" alignItems="center">
              <Text fw="500" variant="bodyMedium">
                Previous Notes
              </Text>
            </Stack.Horizontal>
          }
        >
          {notes.map((note, index) => (
            <Box key={Math.random()}>
              <NoteItem noteEntry={note} />
              {index < notes.length - 1 && <Divider spacing="xl" />}
            </Box>
          ))}
        </Collapse>
      )}
    </Stack>
  );
};

const NoteItem = ({ noteEntry }: { noteEntry: NoteEntry }) => (
  <Stack spacing="lg">
    <DateDisplay value={noteEntry.timeStamp} showTime />
    <Description>
      <Description.Item label="Author">
        {noteEntry.author ? noteEntry.author : EMPTY_VALUE_PLACEHOLDER}
      </Description.Item>
    </Description>
    <Description>
      <Description.Item label="Note">{noteEntry.note}</Description.Item>
    </Description>
  </Stack>
);

const getFormInitialValues = (_employee: EmployeeOutput): TFormValues => ({
  [FIELDS.INTERNAL_NOTES]: "",
});

const getNotes = (employee: EmployeeOutput | null): NoteEntry[] => {
  if (!employee || employee.internalNotes === "") {
    return [];
  }
  const json = JSON.parse(employee.internalNotes);
  return json.notes as Array<NoteEntry>;
};

const getEmployeePayload = (
  employee: EmployeeOutput,
  user: UserWhoamiOutput | undefined,
  values: TFormValues
): EmployeeInput => {
  const notes = getNotes(employee);
  const author = user ? `${user.user.firstName} ${user.user.lastName}` : undefined;
  notes.unshift({
    note: values.internalNotes,
    author,
    timeStamp: new Date().toISOString(),
  });
  return {
    internalNotes: JSON.stringify({ notes }),
  };
};

export default EmployeeNoteTakingTab;
