import { ErrorResponse, FinancialAccountOutput } from "@budgeinc/budge-api";
import {
  ActionIcon,
  Button,
  errorManager,
  modalManager,
  Pressable,
  Stack,
  Text,
  toast,
  Tooltip,
  TrashIcon,
} from "@budgeinc/budge-ui-core";

import { accountEntityRoute } from "common/routes";
import { useEmployerContext } from "features/employers/contexts/EmployerContext";
import { ACCOUNT_TAB_KEYS } from "pages/AccountEntity";
import { useMutation } from "@tanstack/react-query";
import { accountsApi, employeesApi } from "api/BudgeApi";
import { AxiosError } from "axios";
import { Link } from "components/Link";
import { useAccountDataTableContext } from "../AccountDataTableContext";

interface OwnProps {
  account: FinancialAccountOutput;
}

const ActionCell = ({ account }: OwnProps) => {
  const {
    state: { employer },
  } = useEmployerContext();
  const { removeRecord } = useAccountDataTableContext();

  const { isPending, mutateAsync } = useMutation({
    mutationFn: async () => accountsApi.deleteEmployeeFinancialAccount(account.employeeId, account.id),
    onSuccess: response => {
      removeRecord?.(account);
      toast.success({
        message: "Account un-linked successfully",
      });
    },
    onError: (error: AxiosError<ErrorResponse>) =>
      errorManager.showError({
        error: error.response?.data,
      }),
  });

  const confirmUnlink = () =>
    modalManager.openConfirm({
      confirmProps: {
        title: "Yes, un-link",
        color: "red",
      },
      onConfirm: mutateAsync,
      children: <Text variant="bodyLarge">Are you sure you want to un-link this account?</Text>,
    });

  return (
    <Stack.Horizontal>
      <Pressable withPressEffect>
        <Link asChild to={accountEntityRoute(employer?.id!, account.employeeId, account.id, ACCOUNT_TAB_KEYS.DETAILS)}>
          <Button title="View" size="xs" color="primary" variant="gradient" />
        </Link>
      </Pressable>
      <Tooltip content="Un-link">
        <ActionIcon
          icon={TrashIcon}
          onPress={confirmUnlink}
          color="red"
          variant="light"
          size="xs"
          alignSelf="flex-end"
          loading={isPending}
        />
      </Tooltip>
    </Stack.Horizontal>
  );
};

export default ActionCell;
