import { EmployeeOutput } from "@budgeinc/budge-api";
import { RouteHandle } from "common/routerConfig.types";
import EntityNotFound from "components/EntityNotFound";
import { useIsEmployerD2C } from "features/employers/contexts/EmployerContext";
import { LoaderFunctionArgs, useParams, useRouteLoaderData } from "react-router-dom";
import { employeesCrossTenantApi, employersApi } from "api/BudgeApi";

export type EmployeeRouteParams = {
  employerId: string;
  employeeId: string;
};

export const EmployeeIndexRouteId = "EmployeeIndexRouteId";

export const loadEmployee = async ({ params }: LoaderFunctionArgs): Promise<EmployeeOutput> => {
  const routeParams = params as EmployeeRouteParams;
  return employeesCrossTenantApi.getEmployee(routeParams.employerId!, routeParams.employeeId!).then(({ data }) => data);
};

export const EmployeeErrorElement = () => {
  const params = useParams<EmployeeRouteParams>();
  const isD2C = useIsEmployerD2C();

  return (
    <EntityNotFound
      title="Customer not found"
      description={`Customer ID ${params.employeeId} was not found in the database`}
    />
  );
};

export const employeeHandles: RouteHandle = {
  crumb: (data: EmployeeOutput, pathname: string) => ({
    pathname,
    hash: "",
    search: "",
    meta: {
      title: data ? `${data.firstName} ${data.lastName}` : "Not Found",
    },
  }),
};

export const useEmployeeRouteLoaderData = () => useRouteLoaderData(EmployeeIndexRouteId) as EmployeeOutput;
