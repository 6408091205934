import React from "react";
import { Stack } from "@budgeinc/budge-ui-core";
import { EmployeeOutput, OnePaymentPlanOutput } from "@budgeinc/budge-api";
import GeneralInfoCard from "./GeneralInfoCard";
import AccountsCard from "./AccountsCard";
import ProgramEnrollmentsCard from "./ProgramEnrollmentsCard";

interface OwnProps {
  onePayPlan: OnePaymentPlanOutput;
  employerId: string;
  employee: EmployeeOutput;
  onDeleted: () => void;
}

const OnePayPlanDetails = ({ onePayPlan, employee, employerId, onDeleted }: OwnProps) => (
  <Stack spacing="md" mb={40}>
    <GeneralInfoCard onePayPlan={onePayPlan} onDeleted={onDeleted} />
    <AccountsCard onePayPlan={onePayPlan} employerId={employerId} />
    <ProgramEnrollmentsCard employee={employee} />
  </Stack>
);

export default OnePayPlanDetails;
