import {
  BuildValidationSchema,
  FormItem,
  FormProvider,
  getApiErrorMessage,
  Modal,
  Stack,
  Switch,
  yup,
  Text,
  Box,
  MoneyInput,
  Alert,
  UrgentIcon,
} from "@budgeinc/budge-ui-core";
import { useCallback, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import {
  ErrorResponse,
  FinancialAccountOutput,
  ProgramTransactionAdminOutput,
  ProgramTransactionCreateInput,
} from "@budgeinc/budge-api";
import { accountsCrossTenantApi, backofficeApi, employersApi } from "api/BudgeApi";
import { AxiosError } from "axios";
import { useAppMessages } from "store/global";
import EmployeeSearchSelect from "components/Select/EmployeeSearchSelect";
import { useEmployerContext } from "features/employers/contexts/EmployerContext";
import EmployeeAccountSearchSelect from "components/Select/EmployeeAccountSearchSelect";
import { delayed } from "utils/helpers";
import ReviewManualPayment from "../ReviewManualPayment";

interface OwnProps {
  open: boolean;
  onClose: () => void;
  onClosed?: () => void;
  onSuccess?: (transaction: ProgramTransactionAdminOutput) => void;
}

enum FIELDS {
  UseBudgeSourceAccountId = "useBudgeSourceAccountId",
  UseBudgeTargetAccountId = "useBudgeTargetAccountId",
  SourceClientId = "SourceClientId",
  TargetClientId = "TargetClientId",
  SourceAccountId = "SourceAccountId",
  TargetAccountId = "TargetAccountId",
  AmountCents = "AmountCents",
}

const validationSchema = BuildValidationSchema({
  [FIELDS.UseBudgeSourceAccountId]: yup.boolean().optional(),
  [FIELDS.UseBudgeTargetAccountId]: yup.boolean().optional(),
  [FIELDS.SourceAccountId]: yup.string().when([FIELDS.UseBudgeTargetAccountId, FIELDS.UseBudgeSourceAccountId], {
    is: (useBudgeTarget: boolean, useBudgeSource: boolean) => !!useBudgeTarget || !useBudgeSource,
    then: schema => schema.required(),
  }),
  [FIELDS.SourceClientId]: yup.string().when([FIELDS.UseBudgeTargetAccountId, FIELDS.UseBudgeSourceAccountId], {
    is: (useBudgeTarget: boolean, useBudgeSource: boolean) => !!useBudgeTarget || !useBudgeSource,
    then: schema => schema.required(),
  }),
  [FIELDS.TargetAccountId]: yup.string().when([FIELDS.UseBudgeTargetAccountId, FIELDS.UseBudgeSourceAccountId], {
    is: (useBudgeTarget: boolean, useBudgeSource: boolean) => !!useBudgeSource || !useBudgeTarget,
    then: schema => schema.required(),
  }),
  [FIELDS.TargetClientId]: yup.string().when([FIELDS.UseBudgeTargetAccountId, FIELDS.UseBudgeSourceAccountId], {
    is: (useBudgeTarget: boolean, useBudgeSource: boolean) => !!useBudgeSource || !useBudgeTarget,
    then: schema => schema.required(),
  }),
  [FIELDS.AmountCents]: yup.number().min(1, "Amount must be minimum 1$").required(),
});

const CreateManualPaymentModal = ({ open, onClose, onClosed, onSuccess }: OwnProps) => {
  const messages = useAppMessages();
  const { state: employerState } = useEmployerContext();
  const [formError, setFormError] = useState<string>();
  const { employer } = employerState;
  const [reviewMode, setReviewMode] = useState(false);
  const [employerAccount, setEmployerAccount] = useState<FinancialAccountOutput>();
  const fromAccountRef = useRef<FinancialAccountOutput | null | undefined>();
  const toAccountRef = useRef<FinancialAccountOutput | null | undefined>();

  const form = useFormik({
    initialValues: getInitialValues(),
    validationSchema,
    enableReinitialize: true,
    onSubmit: async values => {
      let paymentInput: ProgramTransactionCreateInput | null = null;

      if (!employerAccount?.id) {
        setFormError("Employer Funding Account Not Found");
        return;
      }

      if (values[FIELDS.UseBudgeSourceAccountId]) {
        paymentInput = {
          sourceFinancialAccountId: employerAccount.id,
          destinationFinancialAccountId: values[FIELDS.TargetAccountId],
          transactionAmountCents: parseInt(values[FIELDS.AmountCents]),
        };
      } else if (values[FIELDS.UseBudgeTargetAccountId]) {
        paymentInput = {
          sourceFinancialAccountId: values[FIELDS.SourceAccountId],
          destinationFinancialAccountId: employerAccount.id,
          transactionAmountCents: parseInt(values[FIELDS.AmountCents]),
        };
      } else {
        paymentInput = {
          sourceFinancialAccountId: values[FIELDS.SourceAccountId],
          destinationFinancialAccountId: values[FIELDS.TargetAccountId],
          transactionAmountCents: parseInt(values[FIELDS.AmountCents]),
        };
      }

      return backofficeApi
        .createPayment(paymentInput)
        .then(({ data }) => {
          onClose();
          delayed(() => onSuccess?.(data), 500);
        })
        .catch((e: AxiosError<ErrorResponse>) => setFormError(getApiErrorMessage(messages, e.response?.data)));
    },
  });

  const handleOnClosed = () => {
    form.resetForm();
    setReviewMode(false);
    setFormError(undefined);
    onClosed?.();
  };

  useEffect(() => {
    if (open && employer?.id) {
      accountsCrossTenantApi.getEmployerFundingAccount(employer.id!).then(({ data }) => setEmployerAccount(data));
    }
  }, [open, employer?.id]);

  useEffect(() => {
    if (form.values[FIELDS.UseBudgeTargetAccountId]) {
      form.setFieldValue(FIELDS.TargetClientId, "");
      form.setFieldValue(FIELDS.TargetAccountId, "");
      toAccountRef.current = employerAccount;
    }
  }, [form.values[FIELDS.UseBudgeTargetAccountId]]);

  useEffect(() => {
    if (form.values[FIELDS.UseBudgeSourceAccountId]) {
      form.setFieldValue(FIELDS.SourceClientId, "");
      form.setFieldValue(FIELDS.SourceAccountId, "");
      fromAccountRef.current = employerAccount;
    }
  }, [form.values[FIELDS.UseBudgeSourceAccountId]]);

  const handleNext = useCallback(async () => {
    form.setTouched({
      [FIELDS.UseBudgeSourceAccountId]: true,
      [FIELDS.UseBudgeTargetAccountId]: true,
      [FIELDS.SourceClientId]: true,
      [FIELDS.TargetClientId]: true,
      [FIELDS.SourceAccountId]: true,
      [FIELDS.TargetAccountId]: true,
      [FIELDS.AmountCents]: true,
    });

    if (Object.keys(await form.validateForm()).length === 0) {
      setReviewMode(true);
    }
  }, [form]);

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      onClosed={handleOnClosed}
      header={{
        title: "New Payment",
      }}
      footer={{
        okButtonProps: {
          title: reviewMode ? "Submit" : "Next",
          loading: form.isSubmitting,
          disabled: !form.dirty,
        },
        cancelButtonProps: {
          title: "Back",
          display: reviewMode ? "flex" : "none",
          onPress: reviewMode ? () => setReviewMode(false) : undefined,
        },
        onOk: reviewMode ? form.submitForm : handleNext,
      }}
    >
      <Box px="xl">
        {reviewMode && fromAccountRef.current && toAccountRef.current ? (
          <Stack>
            <ReviewManualPayment
              fromAccount={fromAccountRef.current}
              toAccount={toAccountRef.current}
              amountCents={parseInt(form.values[FIELDS.AmountCents])}
            />
            <Alert color="yellow" icon={UrgentIcon}>
              <Text>Payment will require approval before it actually gets submitted.</Text>
            </Alert>
          </Stack>
        ) : (
          <FormProvider value={form} formErrorMsg={formError}>
            <Stack spacing="xxl">
              <Stack spacing="md">
                <Text variant="labelMedium" fw="500">
                  Source Account
                </Text>
                {form.values[FIELDS.UseBudgeTargetAccountId] ? null : (
                  <FormItem name={FIELDS.UseBudgeSourceAccountId}>
                    <Switch label="Budge Account" />
                  </FormItem>
                )}
                {form.values[FIELDS.UseBudgeSourceAccountId] ? null : (
                  <>
                    <FormItem name={FIELDS.SourceClientId}>
                      <EmployeeSearchSelect
                        employerId={employer?.id!}
                        label="Source Client"
                        onChangeText={() => {
                          form.setFieldValue(FIELDS.SourceAccountId, "");
                          form.setFieldTouched(FIELDS.SourceAccountId, false);
                        }}
                      />
                    </FormItem>
                    <FormItem name={FIELDS.SourceAccountId}>
                      <EmployeeAccountSearchSelect
                        label="Client Source Account"
                        disabled={!form.values[FIELDS.SourceClientId]}
                        employerId={employer?.id!}
                        employeeId={form.values[FIELDS.SourceClientId]}
                        idsToExclude={[form.values[FIELDS.TargetAccountId]]}
                        onAccountChange={account => (fromAccountRef.current = account)}
                      />
                    </FormItem>
                  </>
                )}
              </Stack>
              <Stack spacing="md">
                <Text variant="labelMedium" fw="500">
                  Target Account
                </Text>
                {form.values[FIELDS.UseBudgeSourceAccountId] ? null : (
                  <FormItem name={FIELDS.UseBudgeTargetAccountId}>
                    <Switch label="Budge Account" />
                  </FormItem>
                )}
                {form.values[FIELDS.UseBudgeTargetAccountId] ? null : (
                  <>
                    <FormItem name={FIELDS.TargetClientId}>
                      <EmployeeSearchSelect
                        employerId={employer?.id!}
                        label="Target Client"
                        onChangeText={() => {
                          form.setFieldValue(FIELDS.TargetAccountId, "");
                          form.setFieldTouched(FIELDS.TargetAccountId, false);
                        }}
                      />
                    </FormItem>
                    <FormItem name={FIELDS.TargetAccountId}>
                      <EmployeeAccountSearchSelect
                        label="Client Target Account"
                        disabled={!form.values[FIELDS.TargetClientId]}
                        employerId={employer?.id!}
                        employeeId={form.values[FIELDS.TargetClientId]}
                        idsToExclude={[form.values[FIELDS.SourceAccountId]]}
                        onAccountChange={account => (toAccountRef.current = account)}
                      />
                    </FormItem>
                  </>
                )}
              </Stack>
              <FormItem name={FIELDS.AmountCents}>
                <MoneyInput label="Transaction Amount" />
              </FormItem>
            </Stack>
          </FormProvider>
        )}
      </Box>
    </Modal>
  );
};

const getInitialValues = () => ({
  [FIELDS.UseBudgeSourceAccountId]: false,
  [FIELDS.UseBudgeTargetAccountId]: false,
  [FIELDS.SourceClientId]: "",
  [FIELDS.TargetClientId]: "",
  [FIELDS.SourceAccountId]: "",
  [FIELDS.TargetAccountId]: "",
  [FIELDS.AmountCents]: "",
});

export default CreateManualPaymentModal;
