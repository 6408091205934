import React, { useState } from "react";
import {
  BuildValidationSchema,
  Button,
  Card,
  Divider,
  FormItem,
  FormProvider,
  Stack,
  TCardProps,
  Text,
  getApiErrorMessage,
  yup,
  TAccessOperation,
  useAccessScopeValidator,
} from "@budgeinc/budge-ui-core";
import { useFormik } from "formik";
import { intakeApi } from "api/BudgeApi";
import { useAppMessages } from "store/global";
import { AxiosError } from "axios";
import { ErrorResponse, IntakeProfileOutput } from "@budgeinc/budge-api";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";

import FinancialProfileSelect from "./FinancialProfileSelect";
import CreateFinancialProfileButton from "./CreateFinancialProfileButton";

enum FIELDS {
  PROFILE_ID = "profileId",
}

const validationSchema = BuildValidationSchema({
  [FIELDS.PROFILE_ID]: yup.string().required(),
});

interface OwnProps extends TCardProps {
  onSuccess(data: IntakeProfileOutput): void;
}

const AssignedFinancialProfileCard = ({ onSuccess, ...props }: OwnProps) => {
  const { validateAccessScopes } = useAccessScopeValidator();
  const messages = useAppMessages();
  const [formError, setFormError] = useState<string>();
  const { state } = useEmployeeEntityContext();
  const isAdminWrite = validateAccessScopes(TAccessOperation.WRITE);

  const form = useFormik({
    initialValues: {
      [FIELDS.PROFILE_ID]: "",
    },
    validationSchema,
    onSubmit: async value =>
      intakeApi
        .updateIntakeProfile(value[FIELDS.PROFILE_ID], {
          employeeId: state.employee?.id!,
          description: state.employee?.email!,
        })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch((e: AxiosError<ErrorResponse>) => setFormError(getApiErrorMessage(messages, e.response?.data))),
  });

  return (
    <Card {...props}>
      <CreateFinancialProfileButton onSuccess={onSuccess} disabled={!isAdminWrite} />
      <Divider spacing={32}>
        <Text color="secondary">Or select an existing one</Text>
      </Divider>
      <FormProvider value={form} formErrorMsg={formError}>
        <Stack spacing="md">
          <FormItem name={FIELDS.PROFILE_ID}>
            <FinancialProfileSelect disabled={!isAdminWrite} />
          </FormItem>
          <Button
            title="Save"
            size="sm"
            color="primary"
            variant="gradient"
            alignSelf="flex-end"
            loading={form.isSubmitting}
            onPress={form.submitForm}
            disabled={isAdminWrite}
          />
        </Stack>
      </FormProvider>
    </Card>
  );
};

export default AssignedFinancialProfileCard;
