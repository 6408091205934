import React from "react";

export type DefaultRecordType = {
  id: string;
};

export interface InfiniteScrollDataTableContextType<T extends DefaultRecordType> {
  updateRecord?: (record: T) => void;
  addRecord?: (record: T) => void;
  addManyRecords?: (records: T[]) => void;
  removeRecord?: (record: T) => void;
}

export const createInfiniteScrollDataTableContext = <T extends DefaultRecordType>() =>
  React.createContext<InfiniteScrollDataTableContextType<T> | null>(null);
