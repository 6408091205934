import React from "react";
import {
  DeleteIcon,
  Description,
  LockIcon,
  Stack,
  SuccessIcon,
  Tag,
  Text,
  UrgentIcon,
  capitalize,
} from "@budgeinc/budge-ui-core";
import { EmployeeOutput, UserOutput, UserOutputIdpSourceEnum, SubscriptionOutput } from "@budgeinc/budge-api";
import DateDisplay from "components/DateDisplay";
import { getSubscriptionTag } from "features/employers/entity/tabs/Subscriptions/columns";

interface OwnProps {
  record: EmployeeOutput & {
    user?: UserOutput;
    subscription?: SubscriptionOutput;
  };
}

const EmployeeStatusInfoContent = ({ record }: OwnProps) => (
  <Stack spacing="md">
    <Description.Item label="ID">{record.id}</Description.Item>
    <>
      {record.user?.lockedUntil && (
        <Description.Item label="Locked Until">
          <DateDisplay variant="bodySmall" value={record.user?.lockedUntil} showTime />
        </Description.Item>
      )}
    </>
    {record.user && record.user.idpSource !== UserOutputIdpSourceEnum.Budge && (
      <Description.Item label="Password managed on">
        <Text>{capitalize(record.user.idpSource.toLowerCase())}</Text>
      </Description.Item>
    )}
    <Description.Item alignItems="center" label="Budge Account Status">
      <Stack.Horizontal fwrap="wrap">
        {record.isDirectToConsumer && (
          <>
            {record.hasLeapfroggedSignup && <Tag value="Signup Leapfrogged" />}
            {getTagByCompletion(record.hasCompletedOnboarding, "Signup Completed")}
            {getTagByCompletion(record.hasCompletedKyc, "KYC Completed")}
            {record.user?.forcePasswordReset && <Tag value="Password Reset Forced" color="red" icon={LockIcon} />}
          </>
        )}
      </Stack.Horizontal>
    </Description.Item>
    <Description.Item alignItems="center" label="Accounts Status">
      <Stack.Horizontal fwrap="wrap">
        {record.isDirectToConsumer && (
          <>
            {getTagByCompletion(record.hasLinkedFundingAccount, "Funding Account Linked")}
            {getTagByCompletion(record.hasVerifiedFundingAccount, "Funding Account Verified")}
            {getTagByCompletion(record.hasLinkedTargetAccount, "Target Account Linked")}
          </>
        )}
      </Stack.Horizontal>
    </Description.Item>
    <Description.Item alignItems="center" label="Payments Status">
      <Stack.Horizontal fwrap="wrap">
        {record.isDirectToConsumer && (
          <>
            {getTagByCompletion(record.hasEnrolledIntoProgram, "Program Enrolled")}
            {getTagByCompletion(record.hasEnrollmentPaymentReady, "Payment Ready")}
            {getTagByCompletion(record.hasProcessedTransaction, "Transaction Processed")}
          </>
        )}
      </Stack.Horizontal>
    </Description.Item>
    {record.subscription && (
      <Description.Item alignItems="center" label="Subscription">
        <Stack.Horizontal fwrap="wrap">
          {record.canceledAt ? (
            <Tag value="Cancelled" color="red" icon={DeleteIcon} />
          ) : (
            getSubscriptionTag(record.subscription)
          )}
        </Stack.Horizontal>
      </Description.Item>
    )}
    <Description.Item alignItems="center" label="Other">
      <Stack.Horizontal fwrap="wrap">
        {record.isDirectToConsumer && (
          <>{getTagByCompletion(record.budgeOnHomeScreenSelfReported, "Added Budge to Home Screen")}</>
        )}
      </Stack.Horizontal>
    </Description.Item>
    <Description>
      <Description.Item label="Updated At">
        <DateDisplay numberOfLines={1} value={record.updatedAt} showTime />
      </Description.Item>
      <Description.Item label="Created At">
        <DateDisplay numberOfLines={1} value={record.createdAt} showTime />
      </Description.Item>
      {record.canceledAt ? (
        <Description.Item label="Canceled At">
          <DateDisplay numberOfLines={1} value={record.canceledAt} showTime />
        </Description.Item>
      ) : (
        <></>
      )}
    </Description>
  </Stack>
);

const getTagByCompletion = (completed: boolean, title: string) =>
  completed ? (
    <Tag value={title} color="green" icon={SuccessIcon} />
  ) : (
    <Tag value={title} color="yellow" icon={UrgentIcon} />
  );

export default EmployeeStatusInfoContent;
