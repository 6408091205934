import { DebtPaymentPlanOutput } from "@budgeinc/budge-api";
import { RouteHandle } from "common/routerConfig.types";
import EntityNotFound from "components/EntityNotFound";
import { LoaderFunctionArgs, useParams, useRouteLoaderData } from "react-router-dom";
import { debtPlanApi } from "api/BudgeApi";

export type DebtPlanRouteParams = {
  employerId: string;
  employeeId: string;
  debtPlanId: string;
};

export const EmployeeDebtPlanRouteId = "EmployeeDebtPlanRouteId";

export const loadEmployeeDebtPlan = async ({ params }: LoaderFunctionArgs): Promise<DebtPaymentPlanOutput> => {
  const routeParams = params as DebtPlanRouteParams;
  return debtPlanApi.getDebtPaymentPlan(routeParams.employeeId).then(({ data }) => data);
};

export const EmployeeDebtPlanErrorElement = () => {
  const params = useParams<DebtPlanRouteParams>();

  return (
    <EntityNotFound
      title="Debt Plan not found"
      description={`Debt Plan ID ${params.debtPlanId} was not found in the database`}
    />
  );
};

export const employeeDebtPlanHandles: RouteHandle = {
  crumb: (data: DebtPaymentPlanOutput, pathname: string) => ({
    pathname,
    hash: "",
    search: "",
    meta: {
      title: data ? "Debt Plan" : "Not Found",
    },
  }),
};

export const useDebtPlanRouteLoaderData = () => useRouteLoaderData(EmployeeDebtPlanRouteId) as DebtPaymentPlanOutput;
