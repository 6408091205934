import { Card, Divider, List, Text } from "@budgeinc/budge-ui-core";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";
import DangerZoneItem from "./components/DangerZoneItem";
import ToggleEmployeeFlagButton from "./components/ToggleEmployeeFlag";
import ToggleUserFlagButton from "./components/ToggleUserFlag";

const DangerZoneCard = () => {
  const { state } = useEmployeeEntityContext();

  const getDangerItems = () => {
    const items = [];

    if (!state.employee?.isExcluded) {
      items.push({
        title: `Exclude client from Budge`,
        description: (
          <Text numberOfLines={2}>
            This client is currently{" "}
            <Text tdl="underline" fw="600">
              {state.employee?.isExcluded ? "excluded" : "included"}
            </Text>
          </Text>
        ),
        actionButton: (
          <ToggleEmployeeFlagButton
            flag="isExcluded"
            isActiveButtonText="Include client"
            isInactiveButtonText="Exclude client"
            confirmText="Excluded clients lose functionality in the Budge app and are removed from our e-mailing list. If the client was an active user, their access and existing programs will be terminated. Are you sure you want to proceed?"
          />
        ),
      });
    }

    items.push({
      title: `Include or exclude client from Active Campaign`,
      description: (
        <Text numberOfLines={2}>
          This client is currently{" "}
          <Text tdl="underline" fw="600">
            {state.employee?.isActiveCampaignExcluded ? "excluded" : "included"}
          </Text>
        </Text>
      ),
      actionButton: (
        <ToggleEmployeeFlagButton
          flag="isActiveCampaignExcluded"
          isActiveButtonText="Include into Active Campaign"
          isInactiveButtonText="Exclude from Active Campaign"
          confirmText="Are you sure you want to proceed?"
        />
      ),
    });

    items.push({
      title: `Include or exclude client from Budge KPI calculations`,
      description: (
        <Text numberOfLines={2}>
          This client is currently{" "}
          <Text tdl="underline" fw="600">
            {state.user?.isKpiExcluded ? "excluded" : "included"}
          </Text>{" "}
          from KPI calculations
        </Text>
      ),
      actionButton: (
        <ToggleUserFlagButton
          flag="isKpiExcluded"
          isActiveButtonText="Include in KPI calulations"
          isInactiveButtonText="Exclude from KPI calulations"
          confirmText="Excluded client will not be taken into account when calculating Budge KPIs. Are you sure you want to proceed?"
        />
      ),
    });

    return items;
  };

  return (
    <Card
      sx={theme => ({
        borderColor: theme.fn.themeColor({ color: "red" }),
        borderWidth: 1.5,
      })}
    >
      <Text fw="500" variant="bodyMedium">
        Danger Zone
      </Text>
      <List
        data={getDangerItems()}
        renderItem={({ item }) => <DangerZoneItem {...item} />}
        ItemSeparatorComponent={() => <Divider />}
      />
    </Card>
  );
};

export default DangerZoneCard;
