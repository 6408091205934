import {
  DebtPaymentPlanSimulationItemOutput,
  DebtPaymentPlanAccountOutputDebtPaymentPlanAccountStatusEnum,
} from "@budgeinc/budge-api";
import { formatMoney, formatPercent } from "@budgeinc/budge-ui-utils";
import { TColumnProps, Text } from "@budgeinc/budge-ui-core";
import { Link } from "components/Link";
import { accountEntityRoute } from "common/routes";
import DateDisplay from "components/DateDisplay";
import DebtPlanStatusTag from "../DebtPlanStatusTag";

export const getAccountColumns = (
  employerId: string
): TColumnProps<
  DebtPaymentPlanSimulationItemOutput & {
    status: DebtPaymentPlanAccountOutputDebtPaymentPlanAccountStatusEnum;
  }
>[] => [
  {
    id: "financialAccount.name",
    title: "Name",
    render: record => (
      <Text numberOfLines={2}>
        <Link to={accountEntityRoute(employerId, record.financialAccount.employeeId, record.financialAccount.id)}>
          {record.financialAccount.name}
        </Link>
      </Text>
    ),
  },
  {
    id: "paymentSequence",
    title: "Sequence",
    maxWidth: 250,
    render: ({ paymentSequence }) => <Text>{paymentSequence}</Text>,
  },
  {
    id: "debtPaymentPlanAccountStatus",
    title: "Status",
    render: record => <DebtPlanStatusTag status={record.status} />,
  },
  {
    id: "debtAmountCents",
    title: "Debt Amount",
    render: record => <Text>{formatMoney({ amountCents: record.financialAccount.balanceCurrentCents })}</Text>,
  },
  {
    id: "interestRate",
    title: "Interest Rate",
    render: record => <Text>{formatPercent({ value: record.financialAccount.interestRate })}</Text>,
  },
  {
    id: "payoffDate",
    title: "Payoff Date",
    render: ({ payoffDate }) => <DateDisplay numberOfLines={1} value={payoffDate} showTime />,
  },
];
