import { createListFilterReducer, TListFilterState } from "components/ListFilterBar/reducer";
import { createListFilterContext } from "components/ListFilterBar/context";
import { ReferralPaymentSearchInput } from "@budgeinc/budge-api";
import { useContext } from "react";

export const initialState: TListFilterState<ReferralPaymentSearchInput> = {
  genericSearchLike: undefined,
  paymentDateFrom: undefined,
  paymentDateTo: undefined,
  status: undefined,
};

export const ReferralPaymentsFilterReducer = createListFilterReducer<ReferralPaymentSearchInput>(initialState);
export const ReferralPaymentsFilterContext = createListFilterContext<ReferralPaymentSearchInput>(initialState);

export const useReferralPaymentsFilterContext = () => {
  const context = useContext(ReferralPaymentsFilterContext);

  if (!context) {
    throw new Error("ReferralPaymentsFilterContext must be used inside an ReferralPaymentsFilterContext.Provider");
  }

  return context;
};
