import { AuthProfileOutput, AuthScopeOutput } from "@budgeinc/budge-api";
import { RouteHandle } from "common/routerConfig.types";
import EntityNotFound from "components/EntityNotFound";
import { LoaderFunctionArgs, useParams, useRouteLoaderData } from "react-router-dom";
import { authzApi } from "api/BudgeApi";

export type AuthProfileEntityRouteParams = {
  authProfileId: string;
};

export const AuthProfileEntityRouteId = "AuthProfileEntityRouteId";

type loaderOutput = {
  authScopes: AuthScopeOutput[];
  authProfile: AuthProfileOutput;
};

export const loadAuthProfile = async ({ params }: LoaderFunctionArgs): Promise<loaderOutput> => {
  const routeParams = params as AuthProfileEntityRouteParams;

  const authScopes = await authzApi.getAllAuthScopes().then(({ data }) => data);
  const authProfile = await authzApi.getAuthProfile(routeParams.authProfileId!).then(({ data }) => data);

  return {
    authScopes,
    authProfile,
  };
};

export const AuthProfileErrorElement = () => {
  const params = useParams<AuthProfileEntityRouteParams>();

  return (
    <EntityNotFound
      title="Profile not found"
      description={`Profile ID ${params.authProfileId} was not found in the database`}
    />
  );
};

export const authProfileHandles: RouteHandle = {
  crumb: (data: loaderOutput, pathname: string) => ({
    pathname,
    hash: "",
    search: "",
    meta: {
      title: data.authProfile ? `${data.authProfile.name || data.authProfile.id} Profile` : "Not Found",
    },
  }),
};

export const useAuthProfileRouteLoaderData = () => useRouteLoaderData(AuthProfileEntityRouteId) as loaderOutput;
