import { EmployerOutput, ProgramWithGoalsOutput } from "@budgeinc/budge-api";

export type TProgramEntityState = {
  program?: ProgramWithGoalsOutput;
  employer?: EmployerOutput;
  notFound: boolean;
};

export type TProgramEntityAction =
  | {
      type: "set";
      data: ProgramWithGoalsOutput;
    }
  | {
      type: "setEmployer";
      data: EmployerOutput;
    }
  | { type: "notFound" };

export const initialState: TProgramEntityState = {
  program: undefined,
  employer: undefined,
  notFound: false,
};

export const programDetailPageReducer = (
  state: TProgramEntityState,
  action: TProgramEntityAction
): TProgramEntityState => {
  switch (action.type) {
    case "set":
      return {
        ...state,
        notFound: false,
        program: action.data,
      };
    case "setEmployer":
      return {
        ...state,
        notFound: false,
        employer: action.data,
      };
    case "notFound":
      return {
        ...state,
        notFound: true,
      };
  }
};
