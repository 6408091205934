import React from "react";
import { Button, ExternalLinkIcon, TButtonProps } from "@budgeinc/budge-ui-core";
import { isProd } from "utils/env";

type OwnProps = TButtonProps & {
  stripeSubscriptionId: string;
};

const ViewStripeSubscriptionButton = ({ stripeSubscriptionId, ...buttonProps }: OwnProps) => {
  const handleLoadSubscription = () =>
    window.open(`https://dashboard.stripe.com${isProd ? "" : "/test"}/subscriptions/${stripeSubscriptionId}`);

  return (
    <Button
      title="View in Stripe"
      rightIcon={ExternalLinkIcon}
      onPress={handleLoadSubscription}
      color="#5047f9"
      variant="gradient"
      {...buttonProps}
    />
  );
};

export default ViewStripeSubscriptionButton;
