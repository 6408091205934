import { createListFilterReducer, TListFilterState } from "components/ListFilterBar/reducer";
import { createListFilterContext } from "components/ListFilterBar/context";
import { ProgramEnrollmentSearchCriteriaInput } from "@budgeinc/budge-api";
import { useContext } from "react";

export const initialState: TListFilterState<ProgramEnrollmentSearchCriteriaInput> = {
  genericSearchLike: undefined,
  status: undefined,
};

export const ProgramEnrollmentFilterReducer =
  createListFilterReducer<ProgramEnrollmentSearchCriteriaInput>(initialState);
export const ProgramEnrollmentFilterContext =
  createListFilterContext<ProgramEnrollmentSearchCriteriaInput>(initialState);

export const useProgramEnrollmentFilterContext = () => {
  const context = useContext(ProgramEnrollmentFilterContext);

  if (!context) {
    throw new Error("useProgramEnrollmentFilterContext must be used inside an ProgramEnrollmentFilterContext.Provider");
  }

  return context;
};
