import React from "react";
import { Box, Modal, TColumnProps, RawJsonBox, TModalProps, Table, Text } from "@budgeinc/budge-ui-core";
import { MethodSyncHistoryOutput } from "@budgeinc/budge-api";
import DateDisplay from "components/DateDisplay";

type OwnProps = TModalProps & {
  loading?: boolean;
  data: MethodSyncHistoryOutput[];
};

const MethodSyncHistoryModal = ({ data, loading, ...props }: OwnProps) => (
  <Modal size={1000} {...props}>
    <Box px="xl" pb="xl">
      <Table
        data={data}
        loading={loading}
        columns={columns}
        variant="dark"
        local={{
          empty: {
            title: "No sync history",
          },
        }}
        expandable={record => <RawJsonBox h={300} data={JSON.parse(record.data)} />}
      />
    </Box>
  </Modal>
);

const columns: TColumnProps<MethodSyncHistoryOutput>[] = [
  {
    id: "createdby",
    title: "Created By",
    render: record => <Text numberOfLines={1}>{record.createdBy}</Text>,
  },
  {
    id: "updatedat",
    title: "Updated at",
    render: ({ updatedAt }) => <DateDisplay numberOfLines={1} value={updatedAt} showTime />,
  },
  {
    id: "createdat",
    title: "Created at",
    render: ({ createdAt }) => <DateDisplay numberOfLines={1} value={createdAt} />,
  },
];

export default MethodSyncHistoryModal;
