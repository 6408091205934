import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import { employersApi, sentimentsCrossTenantApi } from "api/BudgeApi";
import { useEmployerContext } from "features/employers/contexts/EmployerContext";
import { InfiniteScrollTable, useInfiniteScrollList } from "@budgeinc/budge-ui-core";
import {
  SentimentPollSearchCriteriaInput,
  SentimentPollWithEmployeeOutput,
  UserWhoamiOutputScopesEnum,
} from "@budgeinc/budge-api";
import SentimentDetails from "features/employees/tabs/Sentiments/SentimentDetails";
import ExportAccessScopeValidator from "components/AccessScopeValidator/validators/ExportAccessScopeValidator";
import { TListFilterContextType } from "components/ListFilterBar/context";
import { getSentimentPollColumns } from "./columns";
import ExportSentimentPollButton from "./components/ExportSentimentPollButton";
import { SentimentPollFilterContext, SentimentPollFilterReducer } from "./context";
import FiltersBar from "./components/FiltersBar";

const EmployerSentimentPollListPersistFilterId = "employer-sentiment-polls-list-filters";

const getInitialFilters = (): SentimentPollSearchCriteriaInput => ({
  genericSearchLike: undefined,
});

const SentimentPollsListTab = () => {
  const {
    state: { employer },
  } = useEmployerContext();
  const initialFilters = getInitialFilters();
  const [filters, dispatch] = useReducer(SentimentPollFilterReducer, initialFilters);

  const handleOnFetch = useCallback(
    ({ page, pageSize }: { page: number; pageSize: number }) =>
      sentimentsCrossTenantApi
        .searchEmployeeSentimentPolls(employer?.id!, filters, `${pageSize * (page - 1)}`, pageSize.toString())
        .then(({ data }) => ({ results: data })),
    [JSON.stringify(filters)]
  );

  const { requestState, onEndReached, forceRefresh } = useInfiniteScrollList({
    onFetch: handleOnFetch,
    skipFirstForceRefresh: true,
  });

  useEffect(() => {
    forceRefresh();
  }, [JSON.stringify(filters)]);

  const memoedFilterContextValues = useMemo<TListFilterContextType<SentimentPollSearchCriteriaInput>>(
    () => ({
      state: filters,
      dispatch,
      initialFilters,
    }),
    [filters, dispatch]
  );

  return (
    <SentimentPollFilterContext.Provider value={memoedFilterContextValues}>
      <FiltersBar
        persistId={EmployerSentimentPollListPersistFilterId}
        extra={
          <ExportAccessScopeValidator rule={[UserWhoamiOutputScopesEnum.ClientsSentiments]}>
            <ExportSentimentPollButton />
          </ExportAccessScopeValidator>
        }
      />
      <InfiniteScrollTable<SentimentPollWithEmployeeOutput>
        keyExtractor={item => item.id}
        requestState={requestState}
        onEndReached={onEndReached}
        columns={getSentimentPollColumns()}
        local={{
          empty: {
            m: 24,
            title: "No sentiment polls found",
          },
          noMoreItems: "No more sentiment polls to load",
        }}
        contentContainerStyle={{ marginBottom: 16 }}
        expandable={record => <SentimentDetails sentiment={record} />}
      />
    </SentimentPollFilterContext.Provider>
  );
};

export default SentimentPollsListTab;
