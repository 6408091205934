import { getPersistedFilters } from "components/ListFilterBar/utils";
import { DirectPaymentSearchCriteriaInput, DirectPaymentSearchCriteriaInputStatusEnum } from "@budgeinc/budge-api";
import { paramAsList } from "utils/queryParams";
import { FilterFields, TFormValues } from "./FiltersBar";

export const getInitialFilters = (persistId: string, params?: URLSearchParams): DirectPaymentSearchCriteriaInput => {
  const filters = getPersistedFilters<TFormValues>(persistId);

  const statusParam = paramAsList(params?.get(FilterFields.STATUS)) as DirectPaymentSearchCriteriaInputStatusEnum[];
  const dateFromParam = params?.get(FilterFields.DATE_FROM);
  const dateToParam = params?.get(FilterFields.DATE_TO);

  return {
    status: statusParam.length ? statusParam : filters?.[FilterFields.STATUS] || undefined,
    processedDateFrom: dateFromParam || (filters?.[FilterFields.DATE_FROM] as any) || undefined,
    processedDateTo: dateToParam || (filters?.[FilterFields.DATE_TO] as any) || undefined,
    genericSearchLike: undefined,
  };
};
