export const MONEY_COL_MAW = 145;

export enum ASSET_FIELDS {
  TYPE = "type",
  COMPANY_NAME = "companyName",
  BALANCE = "balance",
  ANNUAL_CONTRIBUTION = "annualContribution",
  ANNUAL_MATCH = "annualMatch",
  APR = "apr",
  TARGET = "target",
  COMMENT = "comment",
}
