import { FormikErrors } from "formik";

export type TLocalDataPointBase<T> = Partial<T> & {
  id?: string;
  localId: string;
};

export type TFormRefs<T> = T & {
  id: string;
  dirty: boolean;
  formSubmitRef: () => Promise<any>;
  validateFormRef: () => Promise<FormikErrors<any>>;
};

export type TElementOutputBase = {
  sortOrder: number;
};

export type TGridTableState<TDataPointOutput = any, TElementOutput = any, TFormRefComputableFields = any> = {
  localDataPoint: TLocalDataPointBase<TDataPointOutput>[];
  elements: TElementOutput[];
  formRefs: TFormRefs<TFormRefComputableFields>[];
  refreshIndex: number;
};

export type TGridTableAction<TDataPointOutput = any, TElementOutput = any, TFormRefComputableFields = any> =
  | { type: "registerForm"; data: TFormRefs<TFormRefComputableFields> }
  | { type: "updateForm"; data: Partial<TFormRefs<TFormRefComputableFields>> & { id: string } }
  | { type: "setElements"; data: TElementOutput[] }
  | { type: "setLocalDataPoints"; data: TDataPointOutput[] }
  | {
      type: "addLocalDataPoint";
      data: TLocalDataPointBase<TDataPointOutput>;
    }
  | {
      type: "removeLocalDataPoint";
      data: { id: string };
    };

export const createGridTableReducer =
  <TDataPointOutput, TElementOutput, TFormRefComputableFields>() =>
  (
    state: TGridTableState<TDataPointOutput, TElementOutput, TFormRefComputableFields>,
    action: TGridTableAction<TDataPointOutput, TElementOutput, TFormRefComputableFields>
  ): TGridTableState<TDataPointOutput, TElementOutput, TFormRefComputableFields> => {
    switch (action.type) {
      case "registerForm":
        if (state.formRefs.find(({ id }) => id === action.data.id)) {
          return state;
        }

        return {
          ...state,
          formRefs: [...state.formRefs, action.data],
        };
      case "updateForm":
        return {
          ...state,
          formRefs: state.formRefs.map(formRef => {
            if (formRef.id === action.data.id) {
              return {
                ...formRef,
                ...action.data,
              };
            }

            return formRef;
          }),
        };
      case "setElements":
        return {
          ...state,
          elements: action.data.sort((a: any, b: any) => a.sortOrder - b.sortOrder),
        };
      case "setLocalDataPoints":
        return {
          ...state,
          formRefs: [],
          refreshIndex: state.refreshIndex + 1,
          localDataPoint: action.data.map((dataPoint: any) => ({
            ...dataPoint,
            localId: dataPoint.id,
          })),
        };
      case "addLocalDataPoint":
        return {
          ...state,
          localDataPoint: [...state.localDataPoint, action.data],
        };
      case "removeLocalDataPoint":
        return {
          ...state,
          localDataPoint: state.localDataPoint.filter(
            ({ id, localId }) => action.data.id !== id && action.data.id !== localId
          ),
          formRefs: state.formRefs.filter(({ id }) => id !== action.data.id),
        };
    }
  };
