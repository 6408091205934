import { EmployerOutputBusinessTypeEnum } from "@budgeinc/budge-api";
import React, { useContext } from "react";

import { TEmployerAction, TEmployerState } from "./reducer";

interface EmployerContextType {
  dispatch: React.Dispatch<TEmployerAction>;
  state: TEmployerState;
}

export const EmployerContext = React.createContext<EmployerContextType | null>(null);

export const useEmployerContext = () => {
  const context = useContext(EmployerContext);

  if (!context) {
    throw new Error("useEmployerContext must be used inside an EmployerContext.Provider");
  }

  return context;
};

export const useIsEmployerD2C = () =>
  useEmployerContext().state.employer?.businessType === EmployerOutputBusinessTypeEnum.ConsumerDirect;
