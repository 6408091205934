import React, { useContext } from "react";

import { TEmployeeEntityAction, TEmployeeEntityState } from "./reducer";

interface EmployeeEntityContextType {
  dispatch: React.Dispatch<TEmployeeEntityAction>;
  state: TEmployeeEntityState;
  employerId?: string;
}

export const EmployeeEntityContext = React.createContext<EmployeeEntityContextType | null>(null);

export const useEmployeeEntityContext = () => {
  const context = useContext(EmployeeEntityContext);

  if (!context) {
    throw new Error("useEmployeeEntityContext must be used inside an EmployeeEntityContext.Provider");
  }

  return context;
};
