import { ErrorResponse, ProgramEnrollmentOutput } from "@budgeinc/budge-api";
import { ActionIcon, errorManager, modalManager, Text, toast, Tooltip, TrashIcon } from "@budgeinc/budge-ui-core";
import { employeesApi, programsApi } from "api/BudgeApi";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useProgramEnrollmentDataTableContext } from "../ProgramEnrollmentDataTableContext";

interface OwnProps {
  record: ProgramEnrollmentOutput;
}

const ActionCell = ({ record }: OwnProps) => {
  const { updateRecord } = useProgramEnrollmentDataTableContext();

  const { isPending, mutateAsync } = useMutation({
    mutationFn: async () => programsApi.unenrollEmployeeFromProgram(record.employee.id, record.id),
    onSuccess: response => {
      updateRecord?.(response.data);
      toast.success({
        message: "Program Enrollment disabled successfully",
      });
    },
    onError: (error: AxiosError<ErrorResponse>) =>
      errorManager.showError({
        error: error.response?.data,
      }),
  });

  const confirmDelete = () =>
    modalManager.openConfirm({
      confirmProps: {
        title: "Yes, disable",
        color: "red",
      },
      onConfirm: mutateAsync,
      children: <Text variant="bodyLarge">Are you sure you want to disable this enrollment?</Text>,
    });

  return (
    <Tooltip content="Disable">
      <ActionIcon
        icon={TrashIcon}
        onPress={confirmDelete}
        color="red"
        variant="light"
        size="xs"
        alignSelf="flex-end"
        loading={isPending}
      />
    </Tooltip>
  );
};

export default ActionCell;
