import { ProgramWithGoalsOutput } from "@budgeinc/budge-api";
import { RouteHandle } from "common/routerConfig.types";
import EntityNotFound from "components/EntityNotFound";
import { LoaderFunctionArgs, useParams, useRouteLoaderData } from "react-router-dom";
import { programsCrossTenantApi } from "api/BudgeApi";

export type ProgramRouteParams = {
  programId: string;
  employerId: string;
};

export const ProgramEntityRouteId = "ProgramEntityRouteId";

export const loadProgram = async ({ params }: LoaderFunctionArgs): Promise<ProgramWithGoalsOutput> => {
  const routeParams = params as ProgramRouteParams;
  return programsCrossTenantApi
    .getProgramForEmployer(routeParams.employerId, routeParams.programId!)
    .then(({ data }) => data);
};

export const ProgramErrorElement = () => {
  const params = useParams<ProgramRouteParams>();

  return (
    <EntityNotFound
      title="Program not found"
      description={`Program ID ${params.programId} was not found in the database`}
    />
  );
};

export const programHandles: RouteHandle = {
  crumb: (data: ProgramWithGoalsOutput, pathname: string) => ({
    pathname,
    hash: "",
    search: "",
    meta: {
      title: data ? `Program - ${data.program.name}` : "Not Found",
    },
  }),
};

export const useProgramRouteLoaderData = () => useRouteLoaderData(ProgramEntityRouteId) as ProgramWithGoalsOutput;
