import { Text } from "react-native";
import React, { useCallback, useEffect, useState } from "react";
import { Description, EMPTY_VALUE_PLACEHOLDER } from "@budgeinc/budge-ui-core";
import { usersApi } from "api/BudgeApi";

interface OwnProps {
  creatorUserId?: string | null;
  approverUserId?: string | null;
}

const TransactionCreatorApproverDetails = ({ creatorUserId, approverUserId }: OwnProps) => {
  const [creatorName, setCreatorName] = useState("");
  const [approverName, setApproverName] = useState("");

  const fetchCreatorName = useCallback(async () => {
    if (creatorUserId) {
      usersApi.getUser(creatorUserId).then(({ data }) => setCreatorName(`${data.firstName} ${data.lastName}`));
    }
  }, [creatorUserId]);

  const fetchApproverName = useCallback(async () => {
    if (approverUserId) {
      usersApi.getUser(approverUserId).then(({ data }) => setApproverName(`${data.firstName} ${data.lastName}`));
    }
  }, [approverUserId]);

  useEffect(() => {
    Promise.all([fetchCreatorName(), fetchApproverName()]);
  }, []);

  return (
    <Description>
      <Description.Item label="Creator">
        <Text>{creatorName || EMPTY_VALUE_PLACEHOLDER}</Text>
      </Description.Item>
      <Description.Item label="Approver">
        <Text>{approverName || EMPTY_VALUE_PLACEHOLDER}</Text>
      </Description.Item>
    </Description>
  );
};

export default TransactionCreatorApproverDetails;
