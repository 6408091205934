import React, { useEffect, useMemo, useReducer, useState } from "react";
import { Box, Button, Card, Stack, Text } from "@budgeinc/budge-ui-core";
import { intakeApi } from "api/BudgeApi";
import { IntakeAssetDataPointOutput } from "@budgeinc/budge-api";
import { SectionList } from "react-native";
import TabFormStateChecker from "features/financial-profiles/components/TabFormStateChecker";
import CenteredSpinner from "components/CenteredSpinner";
import AssetRow from "./components/AssetRow";
import { AssetsContext } from "./context";
import { initialState, intakeAssetsReducer } from "./reducer";
import AddAssetButton from "./components/AddAssetButton";
import AssetHeader from "./components/AssetHeader";
import { splitDataPointByMainElementType } from "../../utils";
import AssetFooter from "./components/AssetFooter";
import AutoFeedButton from "./components/AutoFeedButton";
import { useFinancialProfileEntityContext } from "../../contexts/FinancialProfileEntityContext";

const AssetsTab = () => {
  const [loading, setLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(true);
  const { state: fpState } = useFinancialProfileEntityContext();
  const [state, dispatch] = useReducer(intakeAssetsReducer, initialState);

  useEffect(() => {
    if (fpState.financialProfile?.id) {
      const promises: Promise<any>[] = [];

      promises.push(
        intakeApi.getIntakeAssetElements().then(({ data }) =>
          dispatch({
            type: "setElements",
            data,
          })
        )
      );

      if (!state.localDataPoint.length) {
        promises.push(
          intakeApi.getEmployeeIntakeAssetDataPoints(fpState.financialProfile.id).then(({ data }) =>
            dispatch({
              type: "setLocalDataPoints",
              data,
            })
          )
        );
      }

      Promise.all(promises).finally(() => setInitLoading(false));
    }
  }, [fpState.financialProfile?.id, state.localDataPoint.length]);

  const handleOnSaveChanges = async () => {
    setLoading(true);

    await Promise.all(state.formRefs.filter(({ dirty }) => !!dirty).map(formRef => formRef.formSubmitRef()));

    const errorResults = await Promise.all(
      state.formRefs.filter(({ dirty }) => !!dirty).map(formRef => formRef.validateFormRef())
    );

    setLoading(false);

    if (errorResults.some(errors => Object.keys(errors).length > 0)) {
      throw Error("Some rows have errors");
    }
  };

  const handleOnSave = () => {
    handleOnSaveChanges().catch(() => {});
  };

  const memoedContextValue = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state, dispatch]
  );

  if (initLoading) {
    return <CenteredSpinner />;
  }

  const hasNoDirtyRows = state.formRefs.every(({ dirty }) => !dirty);

  return (
    <>
      <AssetsContext.Provider value={memoedContextValue}>
        <Card>
          <Stack spacing="xl">
            {state.localDataPoint.length ? (
              <Stack spacing="md">
                <Box>
                  <AssetHeader />
                  <SectionList
                    sections={splitDataPointByMainElementType(
                      state.localDataPoint,
                      state.elements,
                      "intakeAssetElementId"
                    )}
                    renderItem={({ item: { localId, ...rest } }) => (
                      <AssetRow initialData={rest as IntakeAssetDataPointOutput} localId={localId} />
                    )}
                    renderSectionHeader={({ section: { title } }) => (
                      <Text variant="bodyMedium" fw="600" py="md" px="lg">
                        {title}
                      </Text>
                    )}
                    initialNumToRender={99}
                  />
                </Box>
                <AssetFooter />
              </Stack>
            ) : null}
            <Stack.Horizontal justifyContent="space-between">
              <AddAssetButton />
              <Stack.Horizontal>
                {fpState.financialProfile?.employeeId && <AutoFeedButton />}
                {state.localDataPoint.length > 0 && (
                  <Button
                    title="Save"
                    size="sm"
                    color="primary"
                    variant="gradient"
                    alignSelf="flex-end"
                    loading={loading}
                    onPress={handleOnSave}
                    disabled={hasNoDirtyRows}
                  />
                )}
              </Stack.Horizontal>
            </Stack.Horizontal>
          </Stack>
        </Card>
      </AssetsContext.Provider>
      <TabFormStateChecker canNavigate={hasNoDirtyRows} onSaveChanges={handleOnSaveChanges} />
    </>
  );
};

export default AssetsTab;
