import React from "react";
import ListFilterBar, { TFilterBarProps } from "components/ListFilterBar";
import { BuildValidationSchema, Checkbox, FormItem, Stack, yup } from "@budgeinc/budge-ui-core";
import FilterCollapse from "components/ListFilterBar/FilterCollapse";
import {
  DebtPlanSearchCriteriaInputDebtPaymentPlanTypeEnum,
  DebtPlanSearchCriteriaInputDebtPaymentPlanStatusEnum,
} from "@budgeinc/budge-api";
import { formatEnumValue } from "utils/format";
import { useDebtPlanFilterContext } from "./context";

export enum FilterFields {
  STATUS = "status",
  TYPE = "type",
}

export type TFormValues = {
  [FilterFields.STATUS]: DebtPlanSearchCriteriaInputDebtPaymentPlanStatusEnum[];
  [FilterFields.TYPE]: DebtPlanSearchCriteriaInputDebtPaymentPlanTypeEnum[];
};

const validationSchema = BuildValidationSchema({
  [FilterFields.STATUS]: yup
    .array()
    .of(yup.string().oneOf(Object.values(DebtPlanSearchCriteriaInputDebtPaymentPlanStatusEnum))),
  [FilterFields.TYPE]: yup
    .array()
    .of(yup.string().oneOf(Object.values(DebtPlanSearchCriteriaInputDebtPaymentPlanTypeEnum))),
});

const FiltersBar = (props: TFilterBarProps) => {
  const { dispatch, initialFilters } = useDebtPlanFilterContext();

  const handleOnSearch = (value: string | undefined) =>
    dispatch({
      type: "updateFilters",
      data: {
        genericSearchLike: value,
      },
    });

  return (
    <ListFilterBar<TFormValues>
      {...props}
      showSearchBar
      searchPlaceHolder="Search by first name, last name or email"
      onSearchChange={handleOnSearch}
      formProps={{
        onSubmit: async values => {
          const status = values[FilterFields.STATUS];
          const types = values[FilterFields.TYPE];

          dispatch({
            type: "updateFilters",
            data: {
              debtPaymentPlanStatus: status.length ? status : undefined,
              debtPaymentPlanType: types.length ? types : undefined,
            },
          });
        },
        validationSchema,
        getInitialValues: reset => {
          if (initialFilters && !reset) {
            return {
              [FilterFields.STATUS]: initialFilters.debtPaymentPlanStatus || [],
              [FilterFields.TYPE]: initialFilters.debtPaymentPlanType || [],
            };
          }

          return {
            [FilterFields.STATUS]: [],
            [FilterFields.TYPE]: [],
          };
        },
      }}
    >
      <Stack spacing="sm">
        <FilterCollapse defaultOpen trigger="Status">
          <FormItem name={FilterFields.STATUS}>
            <Checkbox.Group
              options={Object.values(DebtPlanSearchCriteriaInputDebtPaymentPlanStatusEnum).map(status => ({
                label: formatEnumValue(status),
                value: status,
              }))}
            />
          </FormItem>
        </FilterCollapse>
        <FilterCollapse defaultOpen trigger="Type">
          <FormItem name={FilterFields.TYPE}>
            <Checkbox.Group
              options={Object.values(DebtPlanSearchCriteriaInputDebtPaymentPlanTypeEnum).map(status => ({
                label: formatEnumValue(status),
                value: status,
              }))}
            />
          </FormItem>
        </FilterCollapse>
      </Stack>
    </ListFilterBar>
  );
};

export default FiltersBar;
