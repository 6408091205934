import React from "react";
import { ActionIcon, ExternalLinkIcon, TSize, Tooltip } from "@budgeinc/budge-ui-core";
import { postmarkPrimaryColor } from "./constants";

interface OwnProps {
  url: string;
  size?: TSize;
}

const PostmarkExternalButton = ({ url, size = "xs" }: OwnProps) => (
  <a href={url} target="_blank" onClick={e => e.stopPropagation()} rel="noreferrer">
    <Tooltip content="View in Postmark">
      <ActionIcon
        icon={ExternalLinkIcon}
        r="xl"
        variant="gradient"
        iconColor="dark"
        color={postmarkPrimaryColor}
        size={size}
      />
    </Tooltip>
  </a>
);

export default PostmarkExternalButton;
