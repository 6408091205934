import {
  DatePickerInput,
  Description,
  EMPTY_VALUE_PLACEHOLDER,
  Grid,
  Input,
  Stack,
  isEmpty,
  Tag,
  Text,
  TGridProps,
  Collapse,
} from "@budgeinc/budge-ui-core";
import { methodApi } from "api/BudgeApi";
import MethodExternalButton from "components/Method/MethodExternalButton";
import MethodRawDetailsButton from "components/Method/MethodRawDetailsButton";
import { useAccountEntityContext } from "features/accounts/contexts/AccountEntityContext";
import MethodSyncButton from "components/Method/MethodSyncButton";
import ShowSyncAuditButton from "components/Method/ShowSyncAuditButton";
import { AccountSyncOutput } from "@budgeinc/budge-api";
import {
  MethodAccessScopeValidator,
  MethodReadAccessScopeValidator,
} from "components/AccessScopeValidator/validators/MethodAccessScopeValidator";

const gridProps: TGridProps = {
  column: 2,
  gutter: "md",
  responsive: {
    xs: 1,
    sm: 1,
    md: 1,
    xl: 3,
    xxl: 3,
  },
};

const MethodInfoCard = () => {
  const {
    state: { account },
    dispatch,
  } = useAccountEntityContext();

  if (!account) return null;

  return (
    <Collapse
      trigger={
        <Stack.Horizontal alignItems="center" f={1} justifyContent="space-between">
          <Text fw="500" variant="bodyMedium">
            Method
          </Text>
          <MethodExternalButton buttonType="normal" recordId={account.methodAccountId} type="accounts" />
        </Stack.Horizontal>
      }
    >
      <Stack spacing="xl">
        <Grid {...gridProps}>
          {account.methodAccountId && (
            <Input
              label="Method Account ID"
              value={account.methodAccountId}
              disabled
              rightSection={
                account.methodAccountId && (
                  <Stack.Horizontal>
                    <MethodExternalButton buttonType="actionIcon" type="accounts" recordId={account.methodAccountId} />
                    <MethodReadAccessScopeValidator>
                      <MethodRawDetailsButton type="accountRaw" recordId={account.methodAccountId} />
                    </MethodReadAccessScopeValidator>
                    <MethodAccessScopeValidator>
                      <MethodSyncButton<AccountSyncOutput>
                        type="accountSync"
                        recordId={account.methodAccountId}
                        onSynced={data =>
                          dispatch({
                            type: "set",
                            data: data.account,
                          })
                        }
                      />
                    </MethodAccessScopeValidator>
                  </Stack.Horizontal>
                )
              }
            />
          )}
          <DatePickerInput
            label="Method Last Sync"
            value={account.methodLastSync ? new Date(account.methodLastSync) : undefined}
            disabled
          />
          <Input
            label="Method Account Verification Status"
            value={account.methodAccountVerificationStatus || ""}
            disabled
          />
        </Grid>
        <Description>
          <Description.Item label="Method Subscriptions" alignItems="center">
            {isEmpty(account.methodSubscriptionsRaw)
              ? EMPTY_VALUE_PLACEHOLDER
              : JSON.parse(account.methodSubscriptionsRaw!).map((sub: any) => <Tag key={sub} value={sub} />)}
          </Description.Item>
          <Description.Item label="Method Products" alignItems="center">
            {isEmpty(account.methodProductsRaw)
              ? EMPTY_VALUE_PLACEHOLDER
              : JSON.parse(account.methodProductsRaw!).map((product: any) => <Tag key={product} value={product} />)}
          </Description.Item>
          <Description.Item label="Method Products Restricted" alignItems="center">
            {isEmpty(account.methodProductsRestrictedRaw)
              ? EMPTY_VALUE_PLACEHOLDER
              : JSON.parse(account.methodProductsRestrictedRaw!).map((product: any) => (
                  <Tag key={product} value={product} />
                ))}
          </Description.Item>
          <Description.Item label="Method Verification Session ID">
            {account.methodVerificationSessionId || EMPTY_VALUE_PLACEHOLDER}
          </Description.Item>
          <Description.Item label="Method Error Code" alignItems="center">
            {account.methodErrorCode?.toString() || EMPTY_VALUE_PLACEHOLDER}
          </Description.Item>
          <Description.Item label="Method Error Message" alignItems="center">
            {account.methodErrorMessage || EMPTY_VALUE_PLACEHOLDER}
          </Description.Item>
        </Description>
        {account.methodAccountId && (
          <ShowSyncAuditButton
            modalTitle="Method Account Sync History"
            api={() => methodApi.getAccountSyncHistory(account.methodAccountId)}
            size="sm"
            alignSelf="flex-start"
          />
        )}
      </Stack>
    </Collapse>
  );
};

export default MethodInfoCard;
