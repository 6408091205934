import React, { useState } from "react";
import { Button, TFileInputOnFileChange, toast, useDocumentPicker } from "@budgeinc/budge-ui-core";
import { backofficeApi } from "api/BudgeApi";
import { useIsEmployerD2C } from "features/employers/contexts/EmployerContext";

interface OwnProps {
  onComplete?(): void;
}

const BatchUpdateButton = ({ onComplete }: OwnProps) => {
  const isD2C = useIsEmployerD2C();
  const [loading, setLoading] = useState(false);

  const onFileChange = (result: TFileInputOnFileChange) => {
    if (result.files?.length) {
      setLoading(true);
      backofficeApi
        .batchUpdate("Employee", result.files[0])
        .then(({ data }) => {
          if (data.errors.length) {
            toast.error({
              title: "The following error(s) occured:",
              message: data.errors.map(e => `- ${e.message}`).join("\n"),
            });
          } else {
            toast.success({
              message: `${data.rowsUpdatedCount} customer saved successfully`,
            });
            onComplete?.();
          }
        })
        .finally(() => setLoading(false));
    }
  };

  const { openDocumentPickerAsync } = useDocumentPicker({
    mimeType: "text/csv",
    onFileChange,
  });

  return (
    <Button
      title="Batch Update"
      loading={loading}
      color="primary"
      size="sm"
      variant="gradient"
      onPress={openDocumentPickerAsync}
    />
  );
};

export default BatchUpdateButton;
