import { NotificationOutput } from "@budgeinc/budge-api";
import { TColumnProps, Text } from "@budgeinc/budge-ui-core";
import DateDisplay from "components/DateDisplay";
import { formatEnumValue } from "utils/format";

export const getNotificationsColumns = (): TColumnProps<NotificationOutput>[] => [
  {
    id: "type",
    title: "Type",
    render: ({ type }) => <Text>{formatEnumValue(type)}</Text>,
  },
  {
    id: "status",
    title: "status",
    render: ({ status }) => <Text>{formatEnumValue(status)}</Text>,
  },
  {
    id: "target",
    title: "Target",
    render: ({ target }) => <Text>{formatEnumValue(target)}</Text>,
  },
  {
    id: "createdat",
    title: "Created At",
    render: ({ createdAt }) => <DateDisplay numberOfLines={1} value={createdAt} showTime />,
  },
];
