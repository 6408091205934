/* eslint-disable react/no-unstable-nested-components */
import { DebtPaymentPlanOutput } from "@budgeinc/budge-api";
import { InfiniteScrollTable, Description } from "@budgeinc/budge-ui-core";
import { hasFilters } from "components/ListFilterBar/utils";
import { getDebtPlanColumns } from "./columns";
import { TInfiniteScrollDataTableProps } from "../types";
import { useDebtPlanFilterContext } from "./filters/context";

type OwnProps = TInfiniteScrollDataTableProps<DebtPaymentPlanOutput> & {
  employerId: string;
};

const DebtPlansTable = ({ emptyLocal, employerId, ...props }: OwnProps) => {
  const filters = useDebtPlanFilterContext();

  return (
    <InfiniteScrollTable
      {...props}
      columns={getDebtPlanColumns(employerId)}
      local={{
        empty: {
          m: 24,
          title: "No debt plans found",
          description: hasFilters(filters) ? `No debt plan match these filters. Edit or clear all filters.` : undefined,
          ...emptyLocal,
        },
        noMoreItems: "No more debt plans to load",
      }}
      contentContainerStyle={{ marginBottom: 16 }}
      expandable={({ id }) => (
        <Description>
          <Description.Item label="ID">{id}</Description.Item>
        </Description>
      )}
    />
  );
};

export default DebtPlansTable;
