import { useInfiniteScrollList } from "@budgeinc/budge-ui-core";
import ReferralPaymentsTable from "components/Table/Referral/PaymentsList";
import FiltersBar from "components/Table/Referral/PaymentsList/filters/FiltersBar";
import {
  ReferralPaymentsFilterContext,
  ReferralPaymentsFilterReducer,
} from "components/Table/Referral/PaymentsList/filters/context";
import { getInitialFilters } from "components/Table/Referral/PaymentsList/filters/utils";
import { useEmployerContext } from "features/employers/contexts/EmployerContext";
import { useCallback, useEffect, useMemo, useReducer } from "react";
import { backofficeApi } from "api/BudgeApi";
import ExportAccessScopeValidator from "components/AccessScopeValidator/validators/ExportAccessScopeValidator";
import { UserWhoamiOutputScopesEnum } from "@budgeinc/budge-api";
import ExportReferralPaymentsButton from "./components/ExportReferralPaymentsButton";

const EmployerReferralPaymentsPersistFilterId = "employer-referral-payments-list-filters";

const ReferralPaymentsListTab = () => {
  const {
    state: { employer },
  } = useEmployerContext();
  const [filters, dispatch] = useReducer(
    ReferralPaymentsFilterReducer,
    getInitialFilters(EmployerReferralPaymentsPersistFilterId)
  );

  const handleOnFetch = useCallback(
    ({ page, pageSize }: { page: number; pageSize: number }) =>
      backofficeApi
        .searchReferralPayments(filters, `${pageSize * (page - 1)}`, pageSize.toString())
        .then(({ data }) => ({ results: data })),
    [JSON.stringify(filters)]
  );

  const { requestState, onEndReached, forceRefresh } = useInfiniteScrollList({
    onFetch: handleOnFetch,
    skipFirstForceRefresh: true,
  });

  useEffect(() => {
    forceRefresh();
  }, [JSON.stringify(filters)]);

  const memoedContextValues = useMemo(
    () => ({
      state: filters,
      dispatch,
    }),
    [filters, dispatch]
  );

  return (
    <ReferralPaymentsFilterContext.Provider value={memoedContextValues}>
      <FiltersBar
        persistId={EmployerReferralPaymentsPersistFilterId}
        extra={
          <ExportAccessScopeValidator rule={[UserWhoamiOutputScopesEnum.Referrals]}>
            <ExportReferralPaymentsButton />
          </ExportAccessScopeValidator>
        }
      />
      <ReferralPaymentsTable
        includeReferrer
        keyExtractor={item => item.id}
        requestState={requestState}
        onEndReached={onEndReached}
      />
    </ReferralPaymentsFilterContext.Provider>
  );
};

export default ReferralPaymentsListTab;
