import { useContext } from "react";

import { AuthProfileUserOutput } from "@budgeinc/budge-api";
import { createInfiniteScrollDataTableContext } from "components/Table/tableContext";

export const AuthProfileUserDataTableContext = createInfiniteScrollDataTableContext<AuthProfileUserOutput>();

export const useAuthProfileUserDataTableContext = () => {
  const context = useContext(AuthProfileUserDataTableContext);

  if (!context) {
    throw new Error("AuthProfileUserDataTableContext must be used inside an AuthProfileUserDataTableContext.Provider");
  }

  return context;
};
