import { createSlice } from "@reduxjs/toolkit";
import { RequestStatus } from "@budgeinc/budge-ui-utils";
import { initialState } from "store/global/types";
import { fetchKpis, fetchAppMessage, fetchD2CEmployer } from "./thunks";

export const GlobalInitialState: initialState = {
  messages: {},
  d2cEmployer: null,
  kpis: {
    data: [],
    categories: [],
    categoriesTypes: [],
  },
  requestStatus: RequestStatus.IDLE,
};

const globalSlice = createSlice({
  name: "global",
  initialState: GlobalInitialState,
  reducers: {},
  extraReducers: builder => {
    // FETCH APP MESSAGES
    builder.addCase(fetchD2CEmployer.fulfilled, (state, action) => {
      state.requestStatus = RequestStatus.COMPLETED;
      state.d2cEmployer = action.payload;
    });
    builder.addCase(fetchD2CEmployer.rejected, state => {
      state.requestStatus = RequestStatus.ERROR;
    });

    // FETCH APP MESSAGES
    builder.addCase(fetchAppMessage.fulfilled, (state, action) => {
      state.requestStatus = RequestStatus.COMPLETED;
      state.messages = action.payload.messages;
    });
    builder.addCase(fetchAppMessage.rejected, state => {
      state.requestStatus = RequestStatus.ERROR;
    });

    // FETCH KPIS
    builder.addCase(fetchKpis.pending, (state, action) => {
      state.requestStatus = RequestStatus.PENDING;
    });
    builder.addCase(fetchKpis.fulfilled, (state, action) => {
      state.requestStatus = RequestStatus.COMPLETED;
      state.kpis.data = action.payload.kpis;
      state.kpis.categories = action.payload.categories;
      state.kpis.categoriesTypes = action.payload.categoriesTypes;
    });
    builder.addCase(fetchKpis.rejected, state => {
      state.requestStatus = RequestStatus.ERROR;
    });
  },
});

export const globalActions = globalSlice.actions;
export default globalSlice.reducer;
