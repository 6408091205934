import { useState } from "react";
import { Button, Stack, modalManager, toast } from "@budgeinc/budge-ui-core";
import { employeesCrossTenantApi, employersApi } from "api/BudgeApi";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";

interface OwnProps {
  flag: "isExcluded" | "isActiveCampaignExcluded";
  isActiveButtonText: string;
  isInactiveButtonText: string;
  confirmText: string;
}

const ToggleEmployeeFlagButton = ({ flag, isActiveButtonText, isInactiveButtonText, confirmText }: OwnProps) => {
  const { state, dispatch, employerId } = useEmployeeEntityContext();
  const [isLoading, setLoading] = useState(false);

  const toggleFlag = async () =>
    employeesCrossTenantApi
      .updateEmployeeForEmployer(employerId!, state.employee?.id!, {
        [flag]: !state.employee?.[flag],
      })
      .then(resp => {
        dispatch({
          type: "set",
          data: resp.data,
        });
        toast.success({
          message: "Customer saved successfully",
        });
      });

  const toggleConfirm = () =>
    modalManager.openConfirm({
      children: confirmText,
      onConfirm: toggleFlag,
      confirmProps: {
        title: "Yes",
      },
    });

  const handleToggleFlag = async () => toggleFlag();

  const isActive = state.employee?.[flag];

  return (
    <Stack.Horizontal justifyContent="flex-end">
      <Button
        title={isActive ? isActiveButtonText : isInactiveButtonText}
        size="xs"
        color="red"
        variant="gradient"
        onPress={isActive ? handleToggleFlag : toggleConfirm}
        loading={isLoading}
      />
    </Stack.Horizontal>
  );
};

export default ToggleEmployeeFlagButton;
