import React, { useContext } from "react";
import ListFilterBar, { TFilterBarProps } from "components/ListFilterBar";
import {
  BuildValidationSchema,
  Checkbox,
  DatePickerInput,
  FormItem,
  Input,
  Stack,
  yup,
  Maybe,
} from "@budgeinc/budge-ui-core";
import FilterCollapse from "components/ListFilterBar/FilterCollapse";
import {
  FinancialAccountOutputAccountSourceEnum,
  FinancialAccountOutputAccountTypeEnum,
  FinancialAccountSearchCriteriaInputMethodCanReceivePaymentsEnum,
  FinancialAccountOutputMethodCanReceivePaymentsEnum,
} from "@budgeinc/budge-api";
import { DateSchema, i18n } from "@budgeinc/budge-ui-utils";
import { getFilterDateFromMaxDate, getFilterDateToMinDate } from "components/ListFilterBar/utils";
import { FormikConsumer, FormikContextType } from "formik";
import { formatEnumValue } from "utils/format";
import { AccountFilterContext } from "./context";

export enum FilterFields {
  SOURCE = "accountSource",
  TYPE = "accountType",
  METHOD_CAN_RECEIVE_PAYEMENT = "canReceivePayment",
  BALANCE_DATE_FROM = "balanceFrom",
  BALANCE_DATE_TO = "balanceTo",
  METHOD_ACCOUNT_ID = "methodAccountId",
  METHOD_ENTITY_ID = "methodEntityId",
  METHOD_ERROR = "methodError",
  PLAID_ERROR = "plaidError",
}

enum BooleanFilterGroupValues {
  YES = "yes",
  NO = "no",
}

export type TFormValues = {
  [FilterFields.SOURCE]: FinancialAccountOutputAccountSourceEnum[];
  [FilterFields.TYPE]: FinancialAccountOutputAccountTypeEnum[];
  [FilterFields.METHOD_CAN_RECEIVE_PAYEMENT]: FinancialAccountSearchCriteriaInputMethodCanReceivePaymentsEnum[];
  [FilterFields.BALANCE_DATE_FROM]: Date | undefined;
  [FilterFields.BALANCE_DATE_TO]: Date | undefined;
  [FilterFields.METHOD_ACCOUNT_ID]: string | undefined;
  [FilterFields.METHOD_ENTITY_ID]: string | undefined;
  [FilterFields.METHOD_ERROR]: BooleanFilterGroupValues[];
  [FilterFields.PLAID_ERROR]: BooleanFilterGroupValues[];
};

const yupBoolArraySchema = yup.array().of(yup.string().oneOf(Object.values(BooleanFilterGroupValues)));

const validationSchema = BuildValidationSchema({
  [FilterFields.SOURCE]: yup.array().of(yup.string().oneOf(Object.values(FinancialAccountOutputAccountSourceEnum))),
  [FilterFields.TYPE]: yup.array().of(yup.string().oneOf(Object.values(FinancialAccountOutputAccountTypeEnum))),
  [FilterFields.METHOD_CAN_RECEIVE_PAYEMENT]: yup
    .array()
    .of(yup.string().oneOf(Object.values(FinancialAccountSearchCriteriaInputMethodCanReceivePaymentsEnum))),
  [FilterFields.BALANCE_DATE_FROM]: DateSchema().nullable(),
  [FilterFields.BALANCE_DATE_TO]: DateSchema().nullable(),
  [FilterFields.METHOD_ACCOUNT_ID]: yup.string().nullable(),
  [FilterFields.METHOD_ENTITY_ID]: yup.string().nullable(),
  [FilterFields.METHOD_ERROR]: yupBoolArraySchema,
  [FilterFields.PLAID_ERROR]: yupBoolArraySchema,
});

const FiltersBar = (props: TFilterBarProps) => {
  const { dispatch, initialFilters } = useContext(AccountFilterContext);

  const handleOnSearch = (value: string | undefined) =>
    dispatch({
      type: "updateFilters",
      data: {
        genericSearchLike: value,
      },
    });

  return (
    <ListFilterBar<TFormValues>
      {...props}
      showSearchBar
      onSearchChange={handleOnSearch}
      searchPlaceHolder="Search by account ID, account name, institution name, slug, etc."
      formProps={{
        onSubmit: async values => {
          const sources = values[FilterFields.SOURCE];
          const types = values[FilterFields.TYPE];
          const canReceivePayments = values[FilterFields.METHOD_CAN_RECEIVE_PAYEMENT];

          dispatch({
            type: "updateFilters",
            data: {
              accountSource: sources.length ? sources : undefined,
              accountType: types.length ? types : undefined,
              methodCanReceivePayments: canReceivePayments.length ? canReceivePayments : undefined,
              balanceAsOfDateFrom: values[FilterFields.BALANCE_DATE_FROM]?.toISOString() || undefined,
              balanceAsOfDateTo: values[FilterFields.BALANCE_DATE_TO]?.toISOString() || undefined,
              methodAccountId: values[FilterFields.METHOD_ACCOUNT_ID] || undefined,
              methodEntityId: values[FilterFields.METHOD_ENTITY_ID] || undefined,
              hasMethodError: getBooleanDataFilterValue(values[FilterFields.METHOD_ERROR]),
              hasPlaidError: getBooleanDataFilterValue(values[FilterFields.PLAID_ERROR]),
            },
          });
        },
        validationSchema,
        getInitialValues: reset => {
          if (initialFilters && !reset) {
            return {
              [FilterFields.SOURCE]: initialFilters.accountSource || [],
              [FilterFields.TYPE]: initialFilters.accountType || [],
              [FilterFields.METHOD_CAN_RECEIVE_PAYEMENT]: initialFilters.methodCanReceivePayments || [],
              [FilterFields.BALANCE_DATE_FROM]: initialFilters.balanceAsOfDateFrom
                ? new Date(initialFilters.balanceAsOfDateFrom)
                : undefined,
              [FilterFields.BALANCE_DATE_TO]: initialFilters.balanceAsOfDateTo
                ? new Date(initialFilters.balanceAsOfDateTo)
                : undefined,
              [FilterFields.METHOD_ACCOUNT_ID]: initialFilters.methodAccountId,
              [FilterFields.METHOD_ENTITY_ID]: initialFilters.methodEntityId,
              [FilterFields.METHOD_ERROR]: getInitialBooleanDataFilterValue(initialFilters.hasMethodError),
              [FilterFields.PLAID_ERROR]: getInitialBooleanDataFilterValue(initialFilters.hasPlaidError),
            };
          }

          return {
            [FilterFields.SOURCE]: [],
            [FilterFields.TYPE]: [],
            [FilterFields.METHOD_CAN_RECEIVE_PAYEMENT]: [],
            [FilterFields.BALANCE_DATE_FROM]: undefined,
            [FilterFields.BALANCE_DATE_TO]: undefined,
            [FilterFields.METHOD_ACCOUNT_ID]: "",
            [FilterFields.METHOD_ENTITY_ID]: "",
            [FilterFields.METHOD_ERROR]: [],
            [FilterFields.PLAID_ERROR]: [],
          };
        },
      }}
    >
      <FormikConsumer>
        {form => (
          <Stack>
            <FilterCollapse defaultOpen trigger="Balance Update As Of Date">
              <Stack>
                <FormItem name={FilterFields.BALANCE_DATE_FROM}>
                  <DatePickerInput
                    label="From"
                    maxDate={getFilterDateFromMaxDate(form.getFieldMeta<Date>(FilterFields.BALANCE_DATE_TO).value)}
                  />
                </FormItem>
                <FormItem name={FilterFields.BALANCE_DATE_TO}>
                  <DatePickerInput
                    label="To"
                    minDate={getFilterDateToMinDate(form.getFieldMeta<Date>(FilterFields.BALANCE_DATE_FROM).value)}
                  />
                </FormItem>
              </Stack>
            </FilterCollapse>
            <FilterCollapse
              trigger="Account Type"
              defaultOpen={(form.getFieldMeta(FilterFields.TYPE).value as any).length}
            >
              <FormItem name={FilterFields.TYPE}>
                <Checkbox.Group
                  options={Object.values(FinancialAccountOutputAccountTypeEnum).map(type => ({
                    label: formatEnumValue(type),
                    value: type,
                  }))}
                />
              </FormItem>
            </FilterCollapse>
            <FilterCollapse
              trigger="Account Source"
              defaultOpen={(form.getFieldMeta(FilterFields.SOURCE).value as any).length}
            >
              <FormItem name={FilterFields.SOURCE}>
                <Checkbox.Group
                  options={Object.values(FinancialAccountOutputAccountSourceEnum).map(source => ({
                    label: i18n.get(`enums.accountSources.${source}`),
                    value: source,
                  }))}
                />
              </FormItem>
            </FilterCollapse>
            <FilterCollapse
              trigger="Can Receive Payment"
              defaultOpen={(form.getFieldMeta(FilterFields.METHOD_CAN_RECEIVE_PAYEMENT).value as any).length}
            >
              <FormItem name={FilterFields.METHOD_CAN_RECEIVE_PAYEMENT}>
                <Checkbox.Group
                  options={Object.values(FinancialAccountOutputMethodCanReceivePaymentsEnum).map(val => ({
                    label: i18n.get(`enums.methodCanReceivePayment.${val}`),
                    value: val,
                  }))}
                />
              </FormItem>
            </FilterCollapse>
            <FilterCollapse
              trigger="Method Identifiers"
              defaultOpen={
                !!form.getFieldMeta(FilterFields.METHOD_ENTITY_ID).value ||
                !!form.getFieldMeta(FilterFields.METHOD_ACCOUNT_ID).value
              }
            >
              <Stack>
                <FormItem name={FilterFields.METHOD_ACCOUNT_ID}>
                  <Input label="Method Account ID" />
                </FormItem>
                <FormItem name={FilterFields.METHOD_ENTITY_ID}>
                  <Input label="Method Entity ID" />
                </FormItem>
              </Stack>
            </FilterCollapse>
            <FilterCollapse trigger="Method Error" defaultOpen={isdefaultOpen(form, FilterFields.METHOD_ERROR)}>
              <FormItem name={FilterFields.METHOD_ERROR}>
                <Checkbox.Group
                  options={[
                    { label: "Yes", value: BooleanFilterGroupValues.YES },
                    { label: "No", value: BooleanFilterGroupValues.NO },
                  ]}
                />
              </FormItem>
            </FilterCollapse>
            <FilterCollapse trigger="Plaid Error" defaultOpen={isdefaultOpen(form, FilterFields.PLAID_ERROR)}>
              <FormItem name={FilterFields.PLAID_ERROR}>
                <Checkbox.Group
                  options={[
                    { label: "Yes", value: BooleanFilterGroupValues.YES },
                    { label: "No", value: BooleanFilterGroupValues.NO },
                  ]}
                />
              </FormItem>
            </FilterCollapse>
          </Stack>
        )}
      </FormikConsumer>
    </ListFilterBar>
  );
};

export const isdefaultOpen = (form: FormikContextType<any>, filterId: string) => {
  const { value } = form.getFieldMeta(filterId);

  return value ? (value as any).length > 0 : false;
};

export const getBooleanDataFilterValue = (values: BooleanFilterGroupValues[]): boolean | undefined => {
  if (!values.length || values.length === 2) return undefined;

  return values.includes(BooleanFilterGroupValues.YES);
};

export const getInitialBooleanDataFilterValue = (value: Maybe<boolean>): BooleanFilterGroupValues[] => {
  if (value === null || value === undefined) return [];

  return value ? [BooleanFilterGroupValues.YES] : [BooleanFilterGroupValues.NO];
};

export default FiltersBar;
