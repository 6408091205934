import { FinancialAccountOutputAccountStatusEnum, UserWhoamiOutputScopesEnum } from "@budgeinc/budge-api";
import {
  Grid,
  Input,
  Text,
  EMPTY_VALUE_PLACEHOLDER,
  SuccessIcon,
  Tag,
  Stack,
  UrgentIcon,
  AccessScopeValidator,
  TAccessOperation,
  Collapse,
} from "@budgeinc/budge-ui-core";
import { useIsEmployerD2C } from "features/employers/contexts/EmployerContext";
import { useEmployerEntityContext } from "features/employers/entity/contexts/EmployerEntityContext";
import { formatEnumValue } from "utils/format";
import AccountVerifyStatusButton from "../components/AccountVerifyStatusButton";
import SetupFundingButton from "../components/SetupFundingButton";
import VerifyFundingButton from "../components/VerifyFundingButton";

const FundingAccountCard = () => {
  const isD2C = useIsEmployerD2C();
  const { state } = useEmployerEntityContext();

  const { fundingAccount } = state;
  const status = fundingAccount?.accountStatus;
  const isLive = fundingAccount?.accountStatus === FinancialAccountOutputAccountStatusEnum.Live;

  return (
    <Collapse
      trigger={
        <Stack.Horizontal w="100%" justifyContent="space-between">
          <Text fw="500" variant="bodyMedium">
            Funding Account
          </Text>
          {status && (
            <Tag color={isLive ? "green" : "yellow"} icon={isLive ? SuccessIcon : UrgentIcon} value={status} />
          )}
        </Stack.Horizontal>
      }
    >
      <Stack spacing="xl">
        <AccessScopeValidator op={TAccessOperation.WRITE} rule={[UserWhoamiOutputScopesEnum.ClientsFinancials]}>
          <Stack.Horizontal>
            {isD2C && (fundingAccount && !isLive ? <VerifyFundingButton /> : <SetupFundingButton />)}
            {fundingAccount &&
              fundingAccount?.accountStatus === FinancialAccountOutputAccountStatusEnum.Provisioning && (
                <AccountVerifyStatusButton />
              )}
          </Stack.Horizontal>
        </AccessScopeValidator>
        {fundingAccount && (
          <Grid
            column={2}
            gutter="md"
            responsive={{
              xs: 1,
              sm: 1,
              md: 1,
              xl: 3,
              xxl: 3,
            }}
          >
            <Input label="Account ID" value={fundingAccount.id} disabled />
            <Input
              label="Account Source"
              value={
                fundingAccount?.accountSource ? formatEnumValue(fundingAccount.accountSource) : EMPTY_VALUE_PLACEHOLDER
              }
              disabled
            />
            <Input label="Routing Number" value={fundingAccount.routingNumber || EMPTY_VALUE_PLACEHOLDER} disabled />
            <Input label="Account Number" value={fundingAccount.accountNumber || EMPTY_VALUE_PLACEHOLDER} disabled />
          </Grid>
        )}
      </Stack>
    </Collapse>
  );
};

export default FundingAccountCard;
