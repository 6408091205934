/* eslint-disable class-methods-use-this */
import { _AppLocalStorage } from "@budgeinc/budge-ui-core";
import { getKpiFilters, saveKpiFilters } from "features/reporting/kpis/utils";
import { TTokensPayload } from "store/user/types";

const ACCESS_TOKEN_KEY = "a-token";
const REFRESH_TOKEN_KEY = "r-token";
const ID_TOKEN_KEY = "i-token";

class _AdminAppStorage extends _AppLocalStorage {
  clear() {
    // clear everything except kpi filters
    // will need to save this in a user config eventually
    const kpifilters = getKpiFilters();
    window.localStorage.clear();
    saveKpiFilters(kpifilters);
  }

  saveTokens(tokens: TTokensPayload) {
    this.save(ID_TOKEN_KEY, tokens.idToken);
    this.save(ACCESS_TOKEN_KEY, tokens.accessToken);
    if (tokens.refreshToken) this.save(REFRESH_TOKEN_KEY, tokens.refreshToken);
  }

  getIdToken() {
    return this.get(ID_TOKEN_KEY);
  }

  getAccessToken() {
    return this.get(ACCESS_TOKEN_KEY);
  }

  getRefreshToken() {
    return this.get(REFRESH_TOKEN_KEY);
  }
}

export const AdminAppStorage = new _AdminAppStorage();
