import { ProgramOutput } from "@budgeinc/budge-api";
import { Button } from "@budgeinc/budge-ui-core";
import { Link } from "components/Link";

import { programEntityRoute } from "common/routes";
import { PROGRAM_TAB_KEYS } from "pages/ProgramEntity";

interface OwnProps {
  program: ProgramOutput;
}

const ActionCell = ({ program }: OwnProps) => (
  <Link to={programEntityRoute(program.employerId, program.id, PROGRAM_TAB_KEYS.TRANSACTIONS)} asChild>
    <Button title="View" size="xs" color="primary" variant="gradient" />
  </Link>
);

export default ActionCell;
