import { useEffect } from "react";
import { PayloadActionCreator } from "@reduxjs/toolkit";

import { useAppDispatch } from "store";

const useActionOnUnMount = (action: PayloadActionCreator) => {
  const dispatch = useAppDispatch();

  useEffect(
    () => () => {
      dispatch(action());
    },
    []
  );
};

export default useActionOnUnMount;
