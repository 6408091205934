import React from "react";
import {
  Description,
  Divider,
  EMPTY_VALUE_PLACEHOLDER,
  Text,
  capitalize,
  i18n,
  Collapse,
} from "@budgeinc/budge-ui-core";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";
import {
  EmergencySavingsCopyMap,
  GrossSalaryIncomeCopyMap,
  LeftOverIncomeCopyMap,
  LeftOverIncomeV2CopyMap,
  TotalDebtCopyMap,
} from "features/marketing/tabs/discovery-quizzes/utils";
import DateDisplay from "components/DateDisplay";

const MarketingCard = () => {
  const { state } = useEmployeeEntityContext();
  const activeEmployee = state.employee!;

  return (
    <Collapse
      trigger={
        <Text fw="500" variant="bodyMedium">
          Marketing
        </Text>
      }
    >
      <Description>
        <Description.Item label="UTM Campaign">
          <Text>{activeEmployee.utmCampaign || EMPTY_VALUE_PLACEHOLDER}</Text>
        </Description.Item>
        <Description.Item label="UTM Content">
          <Text>{activeEmployee.utmContent || EMPTY_VALUE_PLACEHOLDER}</Text>
        </Description.Item>
        <Description.Item label="UTM Medium">
          <Text>{activeEmployee.utmMedium || EMPTY_VALUE_PLACEHOLDER}</Text>
        </Description.Item>
        <Description.Item label="UTM Source">
          <Text>{activeEmployee.utmSource || EMPTY_VALUE_PLACEHOLDER}</Text>
        </Description.Item>
        <Description.Item label="SMS Opt-In">
          <Text>{activeEmployee.hasOptedInMarketingSms ? "True" : "False"}</Text>
        </Description.Item>
        <Divider />
        <Description.Item label="Most Urgent Debts">
          <Text>
            {activeEmployee.quizMostUrgentDebtMulti?.length
              ? i18n.toSentence(
                  activeEmployee.quizMostUrgentDebtMulti.map(mostUrgentDebt =>
                    i18n.t(`enums.debtSignup.mostUrgentDebt.${mostUrgentDebt}`)
                  )
                )
              : EMPTY_VALUE_PLACEHOLDER}
          </Text>
        </Description.Item>
        <Description.Item label="Gross Salary">
          <Text>
            {activeEmployee.quizGrossSalaryRange
              ? GrossSalaryIncomeCopyMap[activeEmployee.quizGrossSalaryRange]
              : EMPTY_VALUE_PLACEHOLDER}
          </Text>
        </Description.Item>
        <Description.Item label="Debt Payment Services">
          <Text>
            {activeEmployee.quizDebtPaymentServices
              ? i18n.toSentence(
                  activeEmployee.quizDebtPaymentServices.map(service =>
                    i18n.t(`enums.debtSignup.debtPaymentServices.${service}`, {
                      defaultValue: service,
                    })
                  )
                )
              : EMPTY_VALUE_PLACEHOLDER}
          </Text>
        </Description.Item>
        <Description.Item label="Debt Payment Issues">
          <Text>
            {activeEmployee.quizDebtPaymentIssues?.length
              ? i18n.toSentence(
                  activeEmployee.quizDebtPaymentIssues.map(issue =>
                    i18n.t(`enums.debtSignup.debtPaymentIssues.${issue}`, {
                      defaultValue: issue,
                    })
                  )
                )
              : EMPTY_VALUE_PLACEHOLDER}
          </Text>
        </Description.Item>
        <Description.Item label="Monthly Cash Flow">
          <Text>
            {activeEmployee.quizDebtMonthlyCashFlow
              ? i18n.t(`enums.debtSignup.monthlyCashFlow.${activeEmployee.quizDebtMonthlyCashFlow}`)
              : EMPTY_VALUE_PLACEHOLDER}
          </Text>
        </Description.Item>
        <Description.Item label="Debt Relief Methods">
          <Text>
            {activeEmployee.quizDebtReliefMethods?.length
              ? i18n.toSentence(
                  activeEmployee.quizDebtReliefMethods.map(issue =>
                    i18n.t(`enums.debtSignup.debtReliefMethods.${issue}`, {
                      defaultValue: issue,
                    })
                  )
                )
              : EMPTY_VALUE_PLACEHOLDER}
          </Text>
        </Description.Item>
        <Description.Item label="Balance Transfer Experience">
          <Text>
            {activeEmployee.quizBalanceTransferExperience
              ? i18n.t(`enums.debtSignup.balanceTransferExperience.${activeEmployee.quizBalanceTransferExperience}`)
              : EMPTY_VALUE_PLACEHOLDER}
          </Text>
        </Description.Item>
        <Description.Item label="Debt Payment Automation">
          <Text>
            {activeEmployee.quizDebtPaymentAutomation
              ? i18n.t(`enums.debtSignup.debtPaymentAutomation.${activeEmployee.quizDebtPaymentAutomation}`)
              : EMPTY_VALUE_PLACEHOLDER}
          </Text>
        </Description.Item>
        <Description.Item label="Debt Solutions">
          <Text>
            {activeEmployee.quizPrimaryDebtObjectiveMulti?.length
              ? i18n.toSentence(
                  activeEmployee.quizPrimaryDebtObjectiveMulti.map(solution =>
                    i18n.t(`enums.debtSignup.debtObjectiveV2.${solution}`, {
                      defaultValue: solution,
                    })
                  )
                )
              : EMPTY_VALUE_PLACEHOLDER}
          </Text>
        </Description.Item>
        <Description.Item label="Credit Card Payment Methods">
          <Text>
            {activeEmployee.quizCreditCardPaymentMethods?.length
              ? i18n.toSentence(
                  activeEmployee.quizCreditCardPaymentMethods.map(method =>
                    i18n.t(`enums.debtSignup.creditCardPaymentMethod.${method}`, {
                      defaultValue: method,
                    })
                  )
                )
              : EMPTY_VALUE_PLACEHOLDER}
          </Text>
        </Description.Item>
        <Description.Item label="Side Hustle">
          <Text>
            {activeEmployee.quizSideHustle
              ? i18n.get(`enums.debtSignup.sideHustle.${activeEmployee.quizSideHustle}`)
              : EMPTY_VALUE_PLACEHOLDER}
          </Text>
        </Description.Item>
        <Description.Item label="Extra Payment">
          <Text>
            {activeEmployee.quizAvailableMonthlyCashFlowRangeV2
              ? LeftOverIncomeV2CopyMap[activeEmployee.quizAvailableMonthlyCashFlowRangeV2]
              : EMPTY_VALUE_PLACEHOLDER}
          </Text>
        </Description.Item>
        <Description.Item label="Credit Score">
          <Text>
            {activeEmployee.quizCreditScore
              ? i18n.get(`enums.debtSignup.creditScore.${activeEmployee.quizCreditScore}`)
              : EMPTY_VALUE_PLACEHOLDER}
          </Text>
        </Description.Item>
        <Description.Item label="Financial Goals">
          <Text>
            {activeEmployee.quizFinancialGoals?.length
              ? i18n.toSentence(
                  activeEmployee.quizFinancialGoals.map(goal => i18n.get(`enums.debtSignup.financialGoals.${goal}`))
                )
              : EMPTY_VALUE_PLACEHOLDER}
          </Text>
        </Description.Item>
        <Description.Item label="Financial Journey">
          <Text>
            {activeEmployee.quizFinancialJourneyItems?.length
              ? i18n.toSentence(
                  activeEmployee.quizFinancialJourneyItems.map(journey =>
                    i18n.get(`enums.debtSignup.financialJourney.${journey}`)
                  )
                )
              : EMPTY_VALUE_PLACEHOLDER}
          </Text>
        </Description.Item>
        <Description.Item label="Primary Debt Objective">
          <Text>
            {activeEmployee.quizPrimaryDebtObjective
              ? i18n.get(`enums.debtSignup.primaryDebtObjective.${activeEmployee.quizPrimaryDebtObjective}`)
              : EMPTY_VALUE_PLACEHOLDER}
          </Text>
        </Description.Item>
        <Description.Item label="Financial Sentiment">
          <Text>
            {activeEmployee.quizFinancialSentiment
              ? i18n.get(`enums.debtSignup.financialSentiment.${activeEmployee.quizFinancialSentiment}`)
              : EMPTY_VALUE_PLACEHOLDER}
          </Text>
        </Description.Item>
        <Description.Item label="Available Monthly Cash Flow">
          <Text>
            {activeEmployee.quizAvailableMonthlyCashFlowRange
              ? LeftOverIncomeCopyMap[activeEmployee.quizAvailableMonthlyCashFlowRange]
              : EMPTY_VALUE_PLACEHOLDER}
          </Text>
        </Description.Item>
        <Description.Item label="Total Debt">
          <Text>
            {activeEmployee.quizTotalDebtAmountRange
              ? TotalDebtCopyMap[activeEmployee.quizTotalDebtAmountRange]
              : EMPTY_VALUE_PLACEHOLDER}
          </Text>
        </Description.Item>
        <Description.Item label="Experience Preference">
          <Text>
            {activeEmployee.quizExperiencePreference
              ? capitalize(activeEmployee.quizExperiencePreference.replaceAll("_", " ").toLowerCase())
              : EMPTY_VALUE_PLACEHOLDER}
          </Text>
        </Description.Item>
        <Description.Item label="Emergency Savings">
          <Text>
            {activeEmployee.quizEmergencySavingsRange
              ? EmergencySavingsCopyMap[activeEmployee.quizEmergencySavingsRange]
              : EMPTY_VALUE_PLACEHOLDER}
          </Text>
        </Description.Item>
        <Divider />
        <Description.Item label="Joined Minimum Payments Waiting List At">
          {activeEmployee.joinedMinimumPaymentsWaitingListAt ? (
            <DateDisplay value={activeEmployee.joinedMinimumPaymentsWaitingListAt} />
          ) : (
            EMPTY_VALUE_PLACEHOLDER
          )}
        </Description.Item>
        <Description.Item label="Joined Side Hustle Waiting List At">
          {activeEmployee.joinedSideHustleWaitingListAt ? (
            <DateDisplay value={activeEmployee.joinedSideHustleWaitingListAt} />
          ) : (
            EMPTY_VALUE_PLACEHOLDER
          )}
        </Description.Item>
        <Description.Item label="$500 Congrats Email Sent At">
          {activeEmployee.paymentMark500CongratulationEmailSentAt ? (
            <DateDisplay value={activeEmployee.paymentMark500CongratulationEmailSentAt} />
          ) : (
            EMPTY_VALUE_PLACEHOLDER
          )}
        </Description.Item>
      </Description>
    </Collapse>
  );
};

export default MarketingCard;
