import { debtPlanCrossTenantApi } from "api/BudgeApi";
import { useInfiniteScrollList } from "@budgeinc/budge-ui-core";
import { useEmployerContext } from "features/employers/contexts/EmployerContext";
import { useCallback, useEffect, useMemo, useReducer } from "react";
import { TListFilterContextType } from "components/ListFilterBar/context";
import { DebtPlanSearchCriteriaInput, UserWhoamiOutputScopesEnum } from "@budgeinc/budge-api";
import { useSearchParams } from "react-router-dom";
import { DebtPlanFilterContext, DebtPlanFilterReducer } from "components/Table/DebtPlansTable/filters/context";
import DebtPlansTable from "components/Table/DebtPlansTable";
import FiltersBar from "components/Table/DebtPlansTable/filters/FiltersBar";
import ExportAccessScopeValidator from "components/AccessScopeValidator/validators/ExportAccessScopeValidator";
import { getInitialFilters } from "components/Table/DebtPlansTable/filters/utils";
import ExportDebtPlansButton from "./components/ExportDebtPlansButton";

const EmployerDebtPlansListPersistFilterId = "employer-debt-plans-list-filters";

const DebtPlansListTab = () => {
  const [params] = useSearchParams();
  const {
    state: { employer },
  } = useEmployerContext();
  const initialFilters = getInitialFilters(EmployerDebtPlansListPersistFilterId, params);
  const [filters, dispatch] = useReducer(DebtPlanFilterReducer, initialFilters);

  const handleOnFetch = useCallback(
    ({ page, pageSize }: { page: number; pageSize: number }) =>
      debtPlanCrossTenantApi
        .searchEmployerDebtPlans(employer?.id!, filters, `${pageSize * (page - 1)}`, pageSize.toString())
        .then(({ data }) => ({ results: data })),
    [JSON.stringify(filters)]
  );

  const { requestState, onEndReached, forceRefresh } = useInfiniteScrollList({
    onFetch: handleOnFetch,
    skipFirstForceRefresh: true,
  });

  useEffect(() => {
    forceRefresh();
  }, [JSON.stringify(filters)]);

  const memoedFilterContextValues = useMemo<TListFilterContextType<DebtPlanSearchCriteriaInput>>(
    () => ({
      state: filters,
      dispatch,
      initialFilters,
    }),
    [filters, dispatch]
  );

  return (
    <DebtPlanFilterContext.Provider value={memoedFilterContextValues}>
      <FiltersBar
        persistId={EmployerDebtPlansListPersistFilterId}
        extra={
          <ExportAccessScopeValidator rule={[UserWhoamiOutputScopesEnum.ClientsFinancials]}>
            <ExportDebtPlansButton />
          </ExportAccessScopeValidator>
        }
      />
      <DebtPlansTable
        keyExtractor={item => item.id}
        requestState={requestState}
        onEndReached={onEndReached}
        employerId={employer?.id!}
      />
    </DebtPlanFilterContext.Provider>
  );
};

export default DebtPlansListTab;
