import { employersApi, loginsCrossTenantApi } from "api/BudgeApi";
import { useCallback, useEffect, useMemo, useReducer } from "react";
import { useInfiniteScrollList } from "@budgeinc/budge-ui-core";
import LoginsTable from "components/Table/LoginsTable";
import { LoginFilterContext, LoginFilterReducer } from "components/Table/LoginsTable/filters/context";
import { getInitialFilters } from "components/Table/LoginsTable/filters/utils";
import FiltersBar from "components/Table/LoginsTable/filters/FiltersBar";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";
import { useSearchParams } from "react-router-dom";
import { TListFilterContextType } from "components/ListFilterBar/context";

const EmployeeLoginsPersistFilterId = "employee-logins-filters";

const EmployeeLoginsListTab = () => {
  const {
    state: { employee },
    employerId,
  } = useEmployeeEntityContext();
  const [params] = useSearchParams();
  const initialFilters = getInitialFilters(EmployeeLoginsPersistFilterId, params);
  const [filters, dispatch] = useReducer(LoginFilterReducer, initialFilters);

  const handleOnFetch = useCallback(
    ({ page, pageSize }: { page: number; pageSize: number }) =>
      loginsCrossTenantApi
        .searchEmployeeLogins(employerId!, employee?.id!, filters, `${pageSize * (page - 1)}`, pageSize.toString())
        .then(({ data }) => ({ results: data })),
    [JSON.stringify(filters)]
  );

  const { requestState, onEndReached, forceRefresh } = useInfiniteScrollList({
    onFetch: handleOnFetch,
    skipFirstForceRefresh: true,
  });

  useEffect(() => {
    forceRefresh();
  }, [JSON.stringify(filters)]);

  const memoedContextValues = useMemo<TListFilterContextType>(
    () => ({
      state: filters,
      dispatch,
      initialFilters,
    }),
    [filters, dispatch]
  );

  return (
    <LoginFilterContext.Provider value={memoedContextValues}>
      <FiltersBar persistId={EmployeeLoginsPersistFilterId} showSearch={false} />
      <LoginsTable requestState={requestState} keyExtractor={item => item.id} onEndReached={onEndReached} />
    </LoginFilterContext.Provider>
  );
};

export default EmployeeLoginsListTab;
