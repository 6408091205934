import { EmployerOutput } from "@budgeinc/budge-api";
import { RouteHandle } from "common/routerConfig.types";
import EntityNotFound from "components/EntityNotFound";
import { LoaderFunctionArgs, useParams, useRouteLoaderData } from "react-router-dom";
import { employersApi } from "api/BudgeApi";

export type EmployerRouteParams = {
  employerId: string;
};

export const EmployerIndexRouteId = "EmployerIndexRouteId";

export const loadEmployer = async ({ params }: LoaderFunctionArgs): Promise<EmployerOutput> => {
  const routeParams = params as EmployerRouteParams;

  return employersApi.getEmployer(routeParams.employerId!).then(({ data }) => data);
};

export const EmployerErrorElement = () => {
  const params = useParams<EmployerRouteParams>();

  return (
    <EntityNotFound
      title="Employer not found"
      description={`Employer ID ${params.employerId!} was not found in the database`}
    />
  );
};

export const employerHandles: RouteHandle = {
  crumb: (data: EmployerOutput, pathname: string) => ({
    pathname,
    hash: "",
    search: "",
    meta: {
      title: data ? data.name : "Not Found",
    },
  }),
};

export const useEmployerRouteLoaderData = () => useRouteLoaderData(EmployerIndexRouteId) as EmployerOutput;
