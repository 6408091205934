import React from "react";
import ListFilterBar, { TFilterBarProps } from "components/ListFilterBar";
import { BuildValidationSchema, Checkbox, FormItem, Stack, yup } from "@budgeinc/budge-ui-core";
import FilterCollapse from "components/ListFilterBar/FilterCollapse";
import {
  FinancialAccountDataRatingSearchCriteriaInputDataPointSourceEnum,
  FinancialAccountDataRatingSearchCriteriaInputDataPointTypeEnum,
  FinancialAccountDataRatingSearchCriteriaInputDataPointRatingEnum,
} from "@budgeinc/budge-api";
import { formatEnumValue } from "utils/format";
import { useDataRatingFilterContext } from "./context";

export enum FilterFields {
  SOURCE = "source",
  RATING = "rating",
  TYPE = "type",
}

export type TFormValues = {
  [FilterFields.SOURCE]: FinancialAccountDataRatingSearchCriteriaInputDataPointSourceEnum[];
  [FilterFields.RATING]: FinancialAccountDataRatingSearchCriteriaInputDataPointRatingEnum[];
  [FilterFields.TYPE]: FinancialAccountDataRatingSearchCriteriaInputDataPointTypeEnum[];
};

const validationSchema = BuildValidationSchema({
  [FilterFields.SOURCE]: yup
    .array()
    .of(yup.string().oneOf(Object.values(FinancialAccountDataRatingSearchCriteriaInputDataPointSourceEnum))),
  [FilterFields.RATING]: yup
    .array()
    .of(yup.string().oneOf(Object.values(FinancialAccountDataRatingSearchCriteriaInputDataPointRatingEnum))),
  [FilterFields.TYPE]: yup
    .array()
    .of(yup.string().oneOf(Object.values(FinancialAccountDataRatingSearchCriteriaInputDataPointTypeEnum))),
});

const FiltersBar = (props: TFilterBarProps) => {
  const { dispatch, initialFilters } = useDataRatingFilterContext();

  const handleOnSearch = (value: string | undefined) =>
    dispatch({
      type: "updateFilters",
      data: {
        genericSearchLike: value,
      },
    });

  return (
    <ListFilterBar<TFormValues>
      {...props}
      showSearchBar
      searchPlaceHolder="Search by first name, last name or email"
      onSearchChange={handleOnSearch}
      formProps={{
        onSubmit: async values => {
          const sources = values[FilterFields.SOURCE];
          const ratings = values[FilterFields.RATING];
          const types = values[FilterFields.TYPE];

          dispatch({
            type: "updateFilters",
            data: {
              dataPointSource: sources.length ? sources : undefined,
              dataPointRating: ratings.length ? ratings : undefined,
              dataPointType: types.length ? types : undefined,
            },
          });
        },
        validationSchema,
        getInitialValues: reset => {
          if (initialFilters && !reset) {
            return {
              [FilterFields.SOURCE]: initialFilters.dataPointSource || [],
              [FilterFields.RATING]: initialFilters.dataPointRating || [],
              [FilterFields.TYPE]: initialFilters.dataPointType || [],
            };
          }

          return {
            [FilterFields.SOURCE]: [],
            [FilterFields.RATING]: [],
            [FilterFields.TYPE]: [],
          };
        },
      }}
    >
      <Stack spacing="sm">
        <FilterCollapse defaultOpen trigger="Source">
          <FormItem name={FilterFields.SOURCE}>
            <Checkbox.Group
              options={Object.values(FinancialAccountDataRatingSearchCriteriaInputDataPointSourceEnum).map(source => ({
                label: formatEnumValue(source),
                value: source,
              }))}
            />
          </FormItem>
        </FilterCollapse>
        <FilterCollapse defaultOpen trigger="Rating">
          <FormItem name={FilterFields.RATING}>
            <Checkbox.Group
              options={Object.values(FinancialAccountDataRatingSearchCriteriaInputDataPointRatingEnum).map(rating => ({
                label: formatEnumValue(rating),
                value: rating,
              }))}
            />
          </FormItem>
        </FilterCollapse>
        <FilterCollapse defaultOpen trigger="Type">
          <FormItem name={FilterFields.TYPE}>
            <Checkbox.Group
              options={Object.values(FinancialAccountDataRatingSearchCriteriaInputDataPointTypeEnum).map(type => ({
                label: formatEnumValue(type),
                value: type,
              }))}
            />
          </FormItem>
        </FilterCollapse>
      </Stack>
    </ListFilterBar>
  );
};

export default FiltersBar;
