import React from "react";
import { ActionIcon, Text, Tooltip, TrashIcon, errorManager, modalManager, toast } from "@budgeinc/budge-ui-core";
import { authzApi } from "api/BudgeApi";
import { AuthProfileUserOutput, ErrorResponse } from "@budgeinc/budge-api";
import { formatEnumValue } from "utils/format";
import { useAuthProfileUserDataTableContext } from "components/Table/AuthProfileUserTable/AuthProfileUserDataTableContext";
import { AxiosError } from "axios";
import { useMutation } from "@tanstack/react-query";

interface OwnProps {
  authProfileUser: AuthProfileUserOutput;
}

const RemoveProfileButton = ({ authProfileUser }: OwnProps) => {
  const { removeRecord } = useAuthProfileUserDataTableContext();

  const { isPending, mutateAsync } = useMutation({
    mutationFn: async () => authzApi.deleteAuthProfileUser(authProfileUser.authProfile.id, authProfileUser.user.id),
    onSuccess: () => {
      removeRecord?.(authProfileUser);
      toast.success({
        message: `Profile removed successfully`,
      });
    },
    onError: (error: AxiosError<ErrorResponse>) =>
      errorManager.showError({
        error: error.response?.data,
      }),
  });

  const confirmDelete = () =>
    modalManager.openConfirm({
      confirmProps: {
        title: "Yes, remove",
        color: "red",
      },
      onConfirm: mutateAsync,
      children: (
        <Text variant="bodyLarge">
          Are you sure you want to remove the profile{" "}
          <Text variant="bodyLarge" fw="500">
            {formatEnumValue(authProfileUser.authProfile.name)}
          </Text>{" "}
          from{" "}
          <Text variant="bodyLarge" fw="500">
            {authProfileUser.user.firstName} {authProfileUser.user.lastName}
          </Text>
          ?
        </Text>
      ),
    });

  return (
    <Tooltip content="Remove">
      <ActionIcon
        icon={TrashIcon}
        color="red"
        variant="light"
        size="xs"
        alignSelf="flex-end"
        onPress={confirmDelete}
        loading={isPending}
      />
    </Tooltip>
  );
};

export default RemoveProfileButton;
