import { useSelector } from "react-redux";

import { useMemo } from "react";
import { buildDefaultCategoriesTree, getKpiFilters } from "features/reporting/kpis/utils";
import { globalSelector } from "./selector";

export { default, GlobalInitialState } from "./slice";
export type { initialState as globalInitialState } from "./types";

export const useD2CEmployer = () => useSelector(globalSelector).d2cEmployer;
export const useGlobal = () => useSelector(globalSelector);
export const useAppMessages = () => useSelector(globalSelector).messages;
export const useKpis = () => {
  const state = useSelector(globalSelector);
  const categoriesTree = useMemo(() => buildDefaultCategoriesTree(state.kpis.categories), [state.kpis.categories]);
  const savedKpiFilters = getKpiFilters();

  return {
    ...state.kpis,
    initialTreeData: savedKpiFilters.length ? savedKpiFilters : categoriesTree,
    defaultTreeData: categoriesTree,
    requestStatus: state.requestStatus,
  };
};

export const useInitialLoading = () =>
  useSelector(globalSelector, (a, b) => a.requestStatus === b.requestStatus).requestStatus;
