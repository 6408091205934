import { Box, Stack, Text, TStackProps } from "@budgeinc/budge-ui-core";

const MissingDataTag = (props: TStackProps) => (
  <Stack.Horizontal alignItems="center" {...props}>
    <Text color="secondary">Missing Data</Text>
    <Box h={8} w={8} bg="red" sx={{ borderRadius: 50 }} />
  </Stack.Horizontal>
);

export default MissingDataTag;
