import { Modal, Box, toast, LoadingOverlay, FormMessage, Stack, getApiErrorMessage } from "@budgeinc/budge-ui-core";
import { useCallback, useEffect, useState } from "react";
import {
  ErrorResponse,
  FinancialAccountOutput,
  ProgramTransactionAdminOutput,
  ProgramTransactionOutputStatusEnum,
  UserOutput,
} from "@budgeinc/budge-api";
import { accountsApi, accountsCrossTenantApi, backofficeApi, employersApi, usersApi } from "api/BudgeApi";
import { AxiosError } from "axios";
import { useAppMessages } from "store/global";
import { useEmployerContext } from "features/employers/contexts/EmployerContext";
import { delayed } from "utils/helpers";
import ReviewManualPayment from "../ReviewManualPayment";

interface OwnProps {
  open: boolean;
  onClose: () => void;
  onClosed?: () => void;
  onSuccess?: (transaction: ProgramTransactionAdminOutput) => void;
  transaction: ProgramTransactionAdminOutput;
}

const ApproveManualPaymentModal = ({ open, transaction, onClose, onClosed, onSuccess }: OwnProps) => {
  const messages = useAppMessages();
  const [loadingApproval, setLoadingApproval] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(true);
  const [formError, setFormError] = useState<string>();
  const [creatorUser, setCreatorUser] = useState<UserOutput>();
  const [employerAccount, setEmployerAccount] = useState<FinancialAccountOutput>();
  const [sourceAccount, setSourceAccount] = useState<FinancialAccountOutput>();
  const [destinationAccount, setDestinationAccount] = useState<FinancialAccountOutput>();

  const {
    state: { employer },
  } = useEmployerContext();

  const handleOnClosed = () => {
    setFormError(undefined);
    onClosed?.();
  };

  const handleApprove = () => {
    setLoadingApproval(true);
    backofficeApi
      .approvePayment(transaction.programTransaction.id)
      .then(({ data }) => {
        onClose();
        delayed(() => onSuccess?.(data), 500);
        if (data.programTransaction.status === ProgramTransactionOutputStatusEnum.Rejected) {
          toast.error({
            title: "Error",
            message: "Payment rejected",
          });
        } else {
          toast.success({
            title: "Success",
            message: "Payment approved successfully",
          });
        }
      })
      .catch((e: AxiosError<ErrorResponse>) => setFormError(getApiErrorMessage(messages, e.response?.data)))
      .finally(() => setLoadingApproval(false));
  };

  const fetchSourceAccount = useCallback(async (): Promise<void> => {
    if (
      employer &&
      transaction.methodfiFundingAccountId &&
      transaction.methodfiFundingAccountId !== employerAccount?.methodAccountId
    ) {
      return accountsCrossTenantApi
        .searchAccountsForEmployeesOfEmployer(employer.id, {
          methodAccountId: transaction.methodfiFundingAccountId,
        })
        .then(({ data }) => {
          if (data.length) setSourceAccount(data[0]);
        });
    }
  }, [employer?.id, transaction.methodfiFundingAccountId, employerAccount?.id]);

  const fetchDestinationAccount = useCallback(async (): Promise<void> => {
    if (
      employer &&
      transaction.methodfiDestinationAccountId &&
      transaction.methodfiDestinationAccountId !== employerAccount?.methodAccountId
    ) {
      return accountsCrossTenantApi
        .searchAccountsForEmployeesOfEmployer(employer.id, {
          methodAccountId: transaction.methodfiDestinationAccountId,
        })
        .then(({ data }) => {
          if (data.length) setDestinationAccount(data[0]);
        });
    }
  }, [employer?.id, transaction.methodfiFundingAccountId, employerAccount?.id]);

  const fetchCreatorUser = useCallback(async (): Promise<void> => {
    if (employer && transaction.programTransaction.creatorUserId) {
      return usersApi.getUser(transaction.programTransaction.creatorUserId).then(({ data }) => setCreatorUser(data));
    }
  }, [employer?.id, transaction.methodfiFundingAccountId]);

  useEffect(() => {
    if (open && employer) {
      accountsCrossTenantApi
        .getEmployerFundingAccount(employer?.id!)
        .then(({ data }) => {
          setEmployerAccount(data);
        })
        .then(() =>
          Promise.all([fetchSourceAccount(), fetchDestinationAccount(), fetchCreatorUser()]).finally(() =>
            setLoadingDetails(false)
          )
        );
    }
  }, [open, employer]);

  const fromAccount =
    transaction.methodfiFundingAccountId === employerAccount?.methodAccountId ? employerAccount : sourceAccount;
  const toAccount =
    transaction.methodfiDestinationAccountId === employerAccount?.methodAccountId
      ? employerAccount
      : destinationAccount;

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      onClosed={handleOnClosed}
      header={{
        title: "Payment Approval",
      }}
      footer={{
        okButtonProps: {
          title: "Approve",
          loading: loadingApproval,
          onPress: handleApprove,
        },
        cancelButtonProps: {
          display: "none",
        },
      }}
    >
      <Stack px="xl" spacing="md">
        {formError && <FormMessage message={formError} color="red" />}
        {loadingDetails ? (
          <Box h={250}>
            <LoadingOverlay />
          </Box>
        ) : toAccount && fromAccount ? (
          <ReviewManualPayment
            creatorName={`${creatorUser?.firstName} ${creatorUser?.lastName}`}
            fromAccount={fromAccount}
            toAccount={toAccount}
            amountCents={transaction.programTransaction.transactionAmountCents}
          />
        ) : null}
      </Stack>
    </Modal>
  );
};

export default ApproveManualPaymentModal;
