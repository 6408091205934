import { Breadcrumb as BudgeBreadcrumb, TBoxProps } from "@budgeinc/budge-ui-core";
import { Match } from "common/routerConfig.types";
import React from "react";
import { useMatches, useNavigate } from "react-router-dom";

const Breadcrumb = (props: TBoxProps) => {
  const navigate = useNavigate();
  const matches = useMatches();

  const items = matches
    .filter(match => Boolean((match.handle as Match["handle"])?.crumb))
    .map(match => (match.handle as Match["handle"]).crumb(match.data, match.pathname));

  if (items.length <= 1) return null;

  return <BudgeBreadcrumb {...props} stack={items} onLinkClick={navigate} />;
};

export default Breadcrumb;
