import { LoginInputTargetEnum, UserWhoamiOutput } from "@budgeinc/budge-api";
import { authzApi, budgeApiConfig, usersApi } from "api/BudgeApi";
import { createBudgeApiAsyncThunk, isJWTExpired, isJWTValid } from "@budgeinc/budge-ui-core";
import { RootState } from "store/types";
import { AdminAppStorage } from "utils/storage";
import { TLoginSignupPayload, TTokensPayload } from "./types";

export const EXPIRED_TIME_KEY = "adminExpiredTime";

const whoAmiPromise = async () => {
  const response = await usersApi.whoami();
  return response.data;
};

const whoAmi = createBudgeApiAsyncThunk<UserWhoamiOutput, void, RootState>("user/whoAmi", async () => {
  const whoAmiData = await whoAmiPromise();
  return whoAmiData;
});

const refreshAccessToken = createBudgeApiAsyncThunk<
  TTokensPayload & {
    sessionExpired?: boolean;
  },
  void
>("user/refreshToken", async (args, thunkApi) => {
  const refreshToken = AdminAppStorage.getRefreshToken();
  const idToken = AdminAppStorage.getIdToken();

  if (!refreshToken || isJWTExpired(refreshToken, 30 * 1000) || !idToken || isJWTExpired(idToken, 30 * 1000)) {
    return {
      idToken: "",
      accessToken: "",
      refreshToken: null,
      sessionExpired: true,
    };
  }

  try {
    const response = await authzApi.refreshAccessToken({
      refreshToken,
    });

    budgeApiConfig.accessToken = response.data.access_token;

    return {
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token,
      idToken: response.data.id_token,
      sessionExpired: false,
    };
  } catch {
    return {
      idToken: "",
      accessToken: "",
      refreshToken: null,
      sessionExpired: true,
    };
  }
});

const logoutUser = createBudgeApiAsyncThunk<
  void,
  {
    onDone?: () => void;
  }
>("user/logout", async (args, thunkApi) => {
  const accessToken = AdminAppStorage.getAccessToken();

  if (accessToken && isJWTValid(accessToken)) {
    await usersApi.logout();
  }
});

const loginUser = createBudgeApiAsyncThunk<
  TLoginSignupPayload,
  {
    email: string;
    password: string;
  }
>("user/login", async (args, thunkApi) => {
  const response = await usersApi.login({
    email: args.email,
    password: args.password,
    target: LoginInputTargetEnum.AdminApp,
  });

  budgeApiConfig.accessToken = response.data.access_token;

  const whoAmiData = await whoAmiPromise();

  // clear any idle timer expired time
  AdminAppStorage.delete(EXPIRED_TIME_KEY);

  return {
    accessToken: response.data.access_token,
    refreshToken: response.data.refresh_token,
    idToken: response.data.id_token,
    user: whoAmiData,
  };
});

export { loginUser, logoutUser, whoAmi, refreshAccessToken };
