import React from "react";
import ListFilterBar, { TFilterBarProps } from "components/ListFilterBar";
import {
  BuildValidationSchema,
  Checkbox,
  DatePickerInput,
  DateSchema,
  FormItem,
  Stack,
  yup,
} from "@budgeinc/budge-ui-core";
import { FormikConsumer } from "formik";
import { FinancialAccountBalanceSearchCriteriaInputBalanceSourceEnum } from "@budgeinc/budge-api";
import FilterCollapse from "components/ListFilterBar/FilterCollapse";
import { formatEnumValue } from "utils/format";
import { getFilterDateFromMaxDate, getFilterDateToMinDate } from "components/ListFilterBar/utils";
import { useAccountHistoryFilterContext } from "./context";

export enum FilterFields {
  BALANCE_SOURCE = "balanceSource",
  AS_OF_DATE_FROM = "asOfDateFrom",
  AS_OF_DATE_TO = "asOfDateTo",
}

export type TFormValues = {
  [FilterFields.BALANCE_SOURCE]: FinancialAccountBalanceSearchCriteriaInputBalanceSourceEnum[];
  [FilterFields.AS_OF_DATE_FROM]: Date | undefined;
  [FilterFields.AS_OF_DATE_TO]: Date | undefined;
};

const validationSchema = BuildValidationSchema({
  [FilterFields.BALANCE_SOURCE]: yup
    .array()
    .of(yup.string().oneOf(Object.values(FinancialAccountBalanceSearchCriteriaInputBalanceSourceEnum))),
  [FilterFields.AS_OF_DATE_FROM]: DateSchema().nullable(),
  [FilterFields.AS_OF_DATE_TO]: DateSchema().nullable(),
});

const FiltersBar = ({ ...props }: TFilterBarProps) => {
  const { dispatch } = useAccountHistoryFilterContext();

  return (
    <ListFilterBar<TFormValues>
      {...props}
      showSearchBar={false}
      searchPlaceHolder="Search by referral email, first name or last name"
      formProps={{
        onSubmit: async values => {
          const balanceSource = values[FilterFields.BALANCE_SOURCE];

          dispatch({
            type: "updateFilters",
            data: {
              balanceSource: balanceSource.length ? balanceSource : undefined,
              asOfDateFrom: values[FilterFields.AS_OF_DATE_FROM]?.toISOString() || undefined,
              asOfDateTo: values[FilterFields.AS_OF_DATE_TO]?.toISOString() || undefined,
            },
          });
        },
        validationSchema,
        getFormValues: values => {
          const dateFrom = values?.[FilterFields.AS_OF_DATE_FROM];
          const dateTo = values?.[FilterFields.AS_OF_DATE_TO];

          return {
            [FilterFields.BALANCE_SOURCE]: values?.[FilterFields.BALANCE_SOURCE] || [],
            [FilterFields.AS_OF_DATE_FROM]: dateFrom ? new Date(dateFrom) : undefined,
            [FilterFields.AS_OF_DATE_TO]: dateTo ? new Date(dateTo) : undefined,
          };
        },
      }}
    >
      <FormikConsumer>
        {form => (
          <Stack>
            <FilterCollapse defaultOpen trigger="As Of Date">
              <Stack>
                <FormItem name={FilterFields.AS_OF_DATE_FROM}>
                  <DatePickerInput
                    label="From"
                    maxDate={getFilterDateFromMaxDate(form.getFieldMeta<Date>(FilterFields.AS_OF_DATE_TO).value)}
                  />
                </FormItem>
                <FormItem name={FilterFields.AS_OF_DATE_TO}>
                  <DatePickerInput
                    label="To"
                    minDate={getFilterDateToMinDate(form.getFieldMeta<Date>(FilterFields.AS_OF_DATE_FROM).value)}
                  />
                </FormItem>
              </Stack>
            </FilterCollapse>
            <FilterCollapse defaultOpen trigger="Balance Source">
              <FormItem name={FilterFields.BALANCE_SOURCE}>
                <Checkbox.Group
                  options={Object.values(FinancialAccountBalanceSearchCriteriaInputBalanceSourceEnum).map(source => ({
                    label: formatEnumValue(source),
                    value: source,
                  }))}
                />
              </FormItem>
            </FilterCollapse>
          </Stack>
        )}
      </FormikConsumer>
    </ListFilterBar>
  );
};

export default FiltersBar;
