import { useContext } from "react";

import { AuthTokenOutput } from "@budgeinc/budge-api";
import { createInfiniteScrollDataTableContext } from "components/Table/tableContext";

export const AuthTokenDataTableContext = createInfiniteScrollDataTableContext<AuthTokenOutput>();

export const useAuthTokenDataTableContext = () => {
  const context = useContext(AuthTokenDataTableContext);

  if (!context) {
    throw new Error("AuthTokenDataTableContext must be used inside an AuthTokenDataTableContext.Provider");
  }

  return context;
};
