import { getPersistedFilters } from "components/ListFilterBar/utils";
import { ReferralPaymentSearchInput } from "@budgeinc/budge-api";
import { FilterFields, TFormValues } from "./FiltersBar";

export const getInitialFilters = (persistId: string): ReferralPaymentSearchInput => {
  const filters = getPersistedFilters<TFormValues>(persistId);

  return {
    status: filters?.[FilterFields.STATUS] || undefined,
    paymentDateFrom: (filters?.[FilterFields.DATE_FROM] as any) || undefined,
    paymentDateTo: (filters?.[FilterFields.DATE_TO] as any) || undefined,
    genericSearchLike: undefined,
  };
};
