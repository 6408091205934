import { useContext } from "react";
import { createInfiniteScrollDataTableContext } from "../tableContext";
import { ProgramTransactionAdminOutputWithId } from "./types";

export const TransactionInfiniteScrollTableContext =
  createInfiniteScrollDataTableContext<ProgramTransactionAdminOutputWithId>();

export const useTransactionDataTableContext = () => {
  const context = useContext(TransactionInfiniteScrollTableContext);

  if (!context) {
    throw new Error(
      "TransactionInfiniteScrollTableContext must be used inside an TransactionInfiniteScrollTableContext.Provider"
    );
  }

  return context;
};
