import ProgramEnrollmentsTable from "components/Table/ProgramEnrollmentsTable";
import { employersApi, programsCrossTenantApi } from "api/BudgeApi";
import { useInfiniteScrollList } from "@budgeinc/budge-ui-core";
import { useEmployerContext } from "features/employers/contexts/EmployerContext";
import { useCallback, useEffect, useMemo, useReducer } from "react";
import FiltersBar from "components/Table/ProgramEnrollmentsTable/filters/FiltersBar";
import {
  ProgramEnrollmentFilterContext,
  ProgramEnrollmentFilterReducer,
} from "components/Table/ProgramEnrollmentsTable/filters/context";
import { getInitialFilters } from "components/Table/ProgramEnrollmentsTable/filters/utils";
import { TListFilterContextType } from "components/ListFilterBar/context";
import { ProgramEnrollmentSearchCriteriaInput, UserWhoamiOutputScopesEnum } from "@budgeinc/budge-api";
import { useSearchParams } from "react-router-dom";
import ExportAccessScopeValidator from "components/AccessScopeValidator/validators/ExportAccessScopeValidator";
import ExportProgramEnrollmentsButton from "./components/ExportProgramEnrollmentsButton";

const EmployerProgramEnrollmentsListPersistFilterId = "employer-program-enrollments-list-filters";

const ProgramEnrollmentListTab = () => {
  const {
    state: { employer },
  } = useEmployerContext();
  const [params] = useSearchParams();
  const initialFilters = getInitialFilters(EmployerProgramEnrollmentsListPersistFilterId, params);
  const [filters, dispatch] = useReducer(ProgramEnrollmentFilterReducer, initialFilters);

  const handleOnFetch = useCallback(
    ({ page, pageSize }: { page: number; pageSize: number }) =>
      programsCrossTenantApi
        .searchEnrollmentsForEmployer(employer?.id!, filters, `${pageSize * (page - 1)}`, pageSize.toString())
        .then(({ data }) => ({ results: data })),
    [JSON.stringify(filters)]
  );

  const { requestState, onEndReached, forceRefresh, updateData } = useInfiniteScrollList({
    onFetch: handleOnFetch,
    skipFirstForceRefresh: true,
  });

  useEffect(() => {
    forceRefresh();
  }, [JSON.stringify(filters)]);

  const memoedFilterContextValues = useMemo<TListFilterContextType<ProgramEnrollmentSearchCriteriaInput>>(
    () => ({
      state: filters,
      dispatch,
      initialFilters,
    }),
    [filters, dispatch]
  );

  return (
    <ProgramEnrollmentFilterContext.Provider value={memoedFilterContextValues}>
      <FiltersBar
        persistId={EmployerProgramEnrollmentsListPersistFilterId}
        extra={
          <ExportAccessScopeValidator rule={[UserWhoamiOutputScopesEnum.ClientsFinancials]}>
            <ExportProgramEnrollmentsButton />
          </ExportAccessScopeValidator>
        }
      />
      <ProgramEnrollmentsTable
        keyExtractor={item => item.id}
        requestState={requestState}
        onEndReached={onEndReached}
        showEmployee
        updateData={updateData}
      />
    </ProgramEnrollmentFilterContext.Provider>
  );
};

export default ProgramEnrollmentListTab;
