import React from "react";
import ListFilterBar, { TFilterBarProps } from "components/ListFilterBar";
import { useOnePayPlanFilterContext } from "./context";

const FiltersBar = (props: TFilterBarProps) => {
  const { dispatch, initialFilters } = useOnePayPlanFilterContext();

  const handleOnSearch = (value: string | undefined) =>
    dispatch({
      type: "updateFilters",
      data: {
        genericSearchLike: value,
      },
    });

  return (
    <ListFilterBar
      {...props}
      showSearchBar
      searchPlaceHolder="Search by first name, last name or email"
      onSearchChange={handleOnSearch}
    />
  );
};

export default FiltersBar;
