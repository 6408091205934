import React, { useCallback } from "react";
import { emailApi } from "api/BudgeApi";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";
import { Description, InfiniteScrollTable, useInfiniteScrollList } from "@budgeinc/budge-ui-core";
import { getEmailsColumns } from "./columns";

const EmailsTab = () => {
  const {
    state: { employee },
  } = useEmployeeEntityContext();

  const handleOnFetch = useCallback(
    ({ page, pageSize }: { page: number; pageSize: number }) =>
      emailApi
        .searchEmails(
          {
            emailAddress: employee?.email!,
          },
          `${pageSize * (page - 1)}`,
          pageSize.toString()
        )
        .then(({ data }) => ({ results: data })),
    []
  );

  const { requestState, onEndReached } = useInfiniteScrollList({
    onFetch: handleOnFetch,
  });

  return (
    <InfiniteScrollTable
      requestState={requestState}
      onEndReached={onEndReached}
      keyExtractor={item => item.id}
      columns={getEmailsColumns()}
      local={{
        empty: {
          m: 24,
          title: "No emails found",
        },
        noMoreItems: "No more emails to load",
      }}
      contentContainerStyle={{ marginBottom: 16 }}
      expandable={record => (
        <Description>
          <Description.Item label="ID">{record.id}</Description.Item>
          <Description.Item label="Response Message ID">{record.responseMessageID}</Description.Item>
        </Description>
      )}
    />
  );
};

export default EmailsTab;
