import {
  SubscriptionWithEmployeeOutput,
  EmployeeWithUserAndSubscriptionsOutputSubscriptionTypeEnum,
  SubscriptionWithEmployeeOutputStatusEnum,
  SubscriptionOutput,
} from "@budgeinc/budge-api";
import {
  EMPTY_VALUE_PLACEHOLDER,
  TColumnProps,
  Text,
  Tag,
  formatEmployeeName,
  DeleteIcon,
  SuccessIcon,
  MoreDotsCircledIcon,
  UrgentIcon,
} from "@budgeinc/budge-ui-core";
import { Link } from "components/Link";
import { employeeEntityRoute } from "common/routes";
import DateDisplay from "components/DateDisplay";
import ViewStripeSubscriptionButton from "features/employees/tabs/EmployeeDetails/components/ViewStripeSubscriptionButton";

export const getSubscriptionsColumns = (): TColumnProps<SubscriptionWithEmployeeOutput>[] => [
  {
    id: "name",
    title: "Name",
    render: ({ employee }) => (
      <Text numberOfLines={1}>
        {employee ? (
          <Link to={employeeEntityRoute(employee.employerId, employee.id)} onClick={e => e.stopPropagation()}>
            {formatEmployeeName({ employee })}
          </Link>
        ) : (
          EMPTY_VALUE_PLACEHOLDER
        )}
      </Text>
    ),
  },
  {
    id: "status",
    title: "Status",
    render: record => getSubscriptionStatusTag(record.status),
  },
  {
    id: "type",
    title: "Type",
    render: record => getSubscriptionTag(record),
  },
  {
    id: "trialdays",
    title: "Trial Days",
    render: record => <Text>{record.trialDays}</Text>,
  },
  {
    id: "createdat",
    title: "Created at",
    render: ({ createdAt }) => <DateDisplay numberOfLines={1} value={createdAt} showTime />,
  },
  {
    id: "action",
    title: "",
    align: "flex-end",
    render: record =>
      record.stripeSubscriptionId ? (
        <ViewStripeSubscriptionButton size="xs" stripeSubscriptionId={record.stripeSubscriptionId} />
      ) : null,
  },
];

export const getSubscriptionStatusTag = (status: SubscriptionWithEmployeeOutputStatusEnum) => {
  switch (status) {
    case SubscriptionWithEmployeeOutputStatusEnum.Active:
      return <Tag value="Active" color="green" icon={SuccessIcon} />;
    case SubscriptionWithEmployeeOutputStatusEnum.Checkout:
      return <Tag value="Checkout" icon={MoreDotsCircledIcon} />;
    case SubscriptionWithEmployeeOutputStatusEnum.PaymentDefault:
      return <Tag value="Payment Default" color="yellow" icon={UrgentIcon} />;
    case SubscriptionWithEmployeeOutputStatusEnum.PendingCancellation:
      return <Tag value="Pending Cancellation" color="blue" icon={MoreDotsCircledIcon} />;
    case SubscriptionWithEmployeeOutputStatusEnum.Ended:
      return <Tag value="Ended" />;
    default:
      return <Tag value="Unknown" />;
  }
};

export const getSubscriptionTag = (subscription: SubscriptionOutput) => {
  if (subscription.isFreeTrial) {
    return <Tag value="Free Trial" color="green" icon={SuccessIcon} />;
  }

  switch (subscription.subscriptionType) {
    case EmployeeWithUserAndSubscriptionsOutputSubscriptionTypeEnum.None:
      return <Tag value="None" color="red" icon={DeleteIcon} />;
    case EmployeeWithUserAndSubscriptionsOutputSubscriptionTypeEnum.Premium:
      return <Tag value="Premium" color="green" icon={SuccessIcon} />;
    case EmployeeWithUserAndSubscriptionsOutputSubscriptionTypeEnum.Discounted:
      return <Tag value="Discounted" color="green" icon={SuccessIcon} />;
    case EmployeeWithUserAndSubscriptionsOutputSubscriptionTypeEnum.Standard:
      return <Tag value="Free" />;
    case EmployeeWithUserAndSubscriptionsOutputSubscriptionTypeEnum.Unknown:
    default:
      return <Tag value="Unknown" />;
  }
};
