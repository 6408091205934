import ProgramEnrollmentsTable from "components/Table/ProgramEnrollmentsTable";
import { useCallback, useEffect, useMemo, useReducer } from "react";
import { employersApi, programsCrossTenantApi } from "api/BudgeApi";
import { useInfiniteScrollList } from "@budgeinc/budge-ui-core";
import FiltersBar from "components/Table/ProgramEnrollmentsTable/filters/FiltersBar";
import {
  ProgramEnrollmentFilterContext,
  ProgramEnrollmentFilterReducer,
} from "components/Table/ProgramEnrollmentsTable/filters/context";
import { getInitialFilters } from "components/Table/ProgramEnrollmentsTable/filters/utils";
import { useSearchParams } from "react-router-dom";
import { useProgramEntityContext } from "../contexts/ProgramEntityContext";

const ProgramEnrollmentsListPersistFilterId = "program-program-enrollments-list-filters";

const ProgramEnrollmentsListTab = () => {
  const { state: pageState } = useProgramEntityContext();
  const [params] = useSearchParams();
  const initialFilters = getInitialFilters(ProgramEnrollmentsListPersistFilterId, params);
  const [filters, dispatch] = useReducer(ProgramEnrollmentFilterReducer, initialFilters);

  const handleOnFetch = useCallback(
    ({ page, pageSize }: { page: number; pageSize: number }) =>
      programsCrossTenantApi
        .searchEnrollmentsForProgram(
          pageState.employer?.id!,
          pageState.program?.program.id!,
          filters,
          `${pageSize * (page - 1)}`,
          pageSize.toString()
        )
        .then(({ data }) => ({ results: data })),
    [JSON.stringify(filters)]
  );

  const { requestState, onEndReached, forceRefresh, updateData } = useInfiniteScrollList({
    onFetch: handleOnFetch,
    skipFirstForceRefresh: true,
  });

  useEffect(() => {
    forceRefresh();
  }, [JSON.stringify(filters)]);

  const memoedFilterContextValues = useMemo(
    () => ({
      state: filters,
      dispatch,
      initialFilters,
    }),
    [filters, dispatch]
  );

  return (
    <ProgramEnrollmentFilterContext.Provider value={memoedFilterContextValues}>
      <FiltersBar persistId={ProgramEnrollmentsListPersistFilterId} />
      <ProgramEnrollmentsTable
        keyExtractor={item => item.id}
        requestState={requestState}
        onEndReached={onEndReached}
        showEmployee
        updateData={updateData}
      />
    </ProgramEnrollmentFilterContext.Provider>
  );
};

export default ProgramEnrollmentsListTab;
