import React from "react";
import { Box, DebtPayoffChart, DebtPlanProgressSummary, Stack, Tag, Text, Collapse } from "@budgeinc/budge-ui-core";
import { useFetchDebtPlanStats } from "api/hooks";
import { DebtPaymentPlanOutput } from "@budgeinc/budge-api";
import { useEmployeeRouteLoaderData } from "features/employees/routeConfig";
import DeleteDebtPlanButton from "./DeleteDebtPlanButton";

interface OwnProps {
  plan: DebtPaymentPlanOutput;
  onDeleted: () => void;
}

const GraphDetailsCard = ({ plan, onDeleted }: OwnProps) => {
  const employee = useEmployeeRouteLoaderData();
  const { data } = useFetchDebtPlanStats(employee);

  return (
    <Collapse
      trigger={
        <Stack.Horizontal f={1} justifyContent="space-between" alignItems="center">
          <Text fw="500" variant="bodyMedium">
            Summary
          </Text>
          <DeleteDebtPlanButton employeeId={employee.id} onDeleted={onDeleted} />
        </Stack.Horizontal>
      }
      defaultOpen
    >
      <Stack.Horizontal alignItems="center" spacing="xxl">
        <DebtPayoffChart
          height={250}
          showMockData={false}
          actualDataPoints={data?.dataSeriesActual || []}
          forecastDataPoints={data?.dataSeriesForecast || []}
          f={1}
        />
        <Box w={350}>
          <DebtPlanProgressSummary
            stats={data}
            plan={plan}
            renderLinkDebtsButton={() => <Tag color="primary" value="Link debts" />}
            renderAutomatePlanButton={disabled => <Tag color={disabled ? "gray" : "primary"} value="Make Payment" />}
            renderCreatePlanButton={disabled => <Tag color={disabled ? "gray" : "primary"} value="Create Plan" />}
            renderCheckingAccountButton={() => <></>}
          />
        </Box>
      </Stack.Horizontal>
    </Collapse>
  );
};

export default GraphDetailsCard;
