import { IntakeCashFlowDataPointInputFrequencyEnum } from "@budgeinc/budge-api";

const frequencyMultipliers: Record<IntakeCashFlowDataPointInputFrequencyEnum, number> = {
  [IntakeCashFlowDataPointInputFrequencyEnum.Daily]: 365 / 12,
  [IntakeCashFlowDataPointInputFrequencyEnum.Weekly]: 52 / 12,
  [IntakeCashFlowDataPointInputFrequencyEnum.BiWeekly]: 26 / 12,
  [IntakeCashFlowDataPointInputFrequencyEnum.Monthly]: 1,
  [IntakeCashFlowDataPointInputFrequencyEnum.TwiceMonthly]: 2,
  [IntakeCashFlowDataPointInputFrequencyEnum.Quarterly]: 1 / 3,
  [IntakeCashFlowDataPointInputFrequencyEnum.Yearly]: 1 / 12,
};

export const calculateMonthlyAmount = (
  amount: number,
  frequency: IntakeCashFlowDataPointInputFrequencyEnum
): number => {
  const frequencyMultiplier = frequencyMultipliers[frequency];

  if (frequencyMultiplier === undefined) {
    throw new Error("Invalid frequency");
  }

  return amount * frequencyMultiplier;
};
