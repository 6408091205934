import { createListFilterReducer, TListFilterState } from "components/ListFilterBar/reducer";
import { createListFilterContext } from "components/ListFilterBar/context";
import { AuditLogSearchCriteriaInput } from "@budgeinc/budge-api";
import { useContext } from "react";

export const initialState: TListFilterState<AuditLogSearchCriteriaInput> = {
  dateFrom: undefined,
  dateTo: undefined,
};

export const AuditFilterReducer = createListFilterReducer<AuditLogSearchCriteriaInput>(initialState);
export const AuditFilterContext = createListFilterContext<AuditLogSearchCriteriaInput>(initialState);

export const useAuditFilterContext = () => {
  const context = useContext(AuditFilterContext);

  if (!context) {
    throw new Error("AuditFilterContext must be used inside an AuditFilterContext.Provider");
  }

  return context;
};
