import { IntakePainInput, IntakePainItemInput, IntakePainOutput } from "@budgeinc/budge-api";
import { IntakePainFormItemKey, IntakePainFormItemType, IntakePainFormValue } from "./types";

export const getIntakeWhyFormItemName = (id: string, type: IntakePainFormItemType): IntakePainFormItemKey =>
  `${id}-${type}`;

export const getFormInitialValue = (intakeWhys: IntakePainOutput[]): IntakePainFormValue => {
  const initialValues: IntakePainFormValue = {};

  intakeWhys.forEach(ip => {
    initialValues[getIntakeWhyFormItemName(ip.intakePainElementId, IntakePainFormItemType.COMMENT)] = ip.comment || "";
    initialValues[getIntakeWhyFormItemName(ip.intakePainElementId, IntakePainFormItemType.VALUE)] = !!ip.value;
  });

  return initialValues;
};

export const getApiPayload = (value: IntakePainFormValue, initialIntakeWhy: IntakePainOutput[]): IntakePainInput => {
  const items: IntakePainItemInput[] = [];

  initialIntakeWhy.forEach(ip => {
    const comment = value[`${ip.intakePainElementId}-${IntakePainFormItemType.COMMENT}`];
    const response = value[`${ip.intakePainElementId}-${IntakePainFormItemType.VALUE}`];

    items.push({
      intakePainElementId: ip.intakePainElementId,
      comment: comment.toString(),
      value: response ? 10 : 0,
    });
  });

  return { items };
};
