import { ErrorResponse } from "@budgeinc/budge-api";
import { errorManager, isInvalidTokenError } from "@budgeinc/budge-ui-core";
import { AxiosError } from "axios";

interface Options {
  showErrorModal?: boolean;
  ignore404?: boolean;
  onCatch?: (e: AxiosError<ErrorResponse>) => void;
}

const useHandleApiError = () => {
  const handleError = (promise: Promise<any>, options?: Options) =>
    promise.catch((e: AxiosError<ErrorResponse>) => {
      const is404 = e.response?.status === 404;
      const skipError = is404 && options?.ignore404;

      if (options?.onCatch) {
        options.onCatch(e);
      }

      if (
        !(e.response?.data && isInvalidTokenError(e.response?.data)) &&
        !skipError &&
        (options?.showErrorModal || options?.showErrorModal === undefined)
      ) {
        errorManager.showError({
          error: e.response?.data,
        });
      }
    });

  return {
    handleError,
  };
};

export default useHandleApiError;
