import { EmployeeOutputPerTransactionFeeModelEnum } from "@budgeinc/budge-api";
import { EMPTY_VALUE_PLACEHOLDER, formatPercent } from "@budgeinc/budge-ui-core";

export const getPerTransactionAmountDisplay = (
  perTransactionFeeModel: EmployeeOutputPerTransactionFeeModelEnum | null | undefined,
  perTransactionFeeAmount: number | null | undefined
): string => {
  switch (perTransactionFeeModel) {
    case EmployeeOutputPerTransactionFeeModelEnum.Percent:
      return formatPercent({
        value: perTransactionFeeAmount,
        minFractionDigits: 0,
      });
    default:
      return EMPTY_VALUE_PLACEHOLDER;
  }
};
