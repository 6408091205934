import { accountsCrossTenantApi, employersApi } from "api/BudgeApi";
import { useInfiniteScrollList } from "@budgeinc/budge-ui-core";
import AccountsTable from "components/Table/AccountsTable";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";
import { AccountFilterContext, AccountFilterReducer } from "components/Table/AccountsTable/filter/context";
import { getInitialFilters } from "components/Table/AccountsTable/filter/utils";
import FiltersBar from "components/Table/AccountsTable/filter/FiltersBar";
import { useEffect, useMemo, useReducer } from "react";
import { useSearchParams } from "react-router-dom";
import ExportAccessScopeValidator from "components/AccessScopeValidator/validators/ExportAccessScopeValidator";
import { UserWhoamiOutputScopesEnum } from "@budgeinc/budge-api";
import ExportAccountsButton from "features/employers/entity/tabs/Financials/Accounts/components/ExportAccountsButton";

const EmployeeAccountsListPersistFilterId = "employee-accounts-list-filters";

const AccountListTab = () => {
  const { state: pageState, employerId } = useEmployeeEntityContext();
  const [params] = useSearchParams();
  const initialFilters = getInitialFilters(EmployeeAccountsListPersistFilterId, params);
  const [filters, dispatch] = useReducer(AccountFilterReducer, initialFilters);

  const { requestState, onEndReached, forceRefresh, updateData } = useInfiniteScrollList({
    onFetch: ({ page, pageSize }) =>
      accountsCrossTenantApi
        .searchAccountsForEmployeesOfEmployer(
          employerId!,
          {
            ...filters,
            employeeId: pageState.employee?.id,
          },
          `${pageSize * (page - 1)}`,
          pageSize.toString()
        )
        .then(({ data }) => ({ results: data })),
    skipFirstForceRefresh: true,
  });

  useEffect(() => {
    forceRefresh();
  }, [JSON.stringify(filters)]);

  const memoedContextValues = useMemo(
    () => ({
      state: filters,
      dispatch,
      initialFilters,
    }),
    [filters, dispatch]
  );

  return (
    <AccountFilterContext.Provider value={memoedContextValues}>
      <FiltersBar
        persistId={EmployeeAccountsListPersistFilterId}
        extra={
          <ExportAccessScopeValidator rule={[UserWhoamiOutputScopesEnum.ClientsFinancials]}>
            <ExportAccountsButton
              extraFilters={{
                employeeId: pageState.employee?.id,
              }}
            />
          </ExportAccessScopeValidator>
        }
      />
      <AccountsTable
        keyExtractor={item => item.id}
        requestState={requestState}
        onEndReached={onEndReached}
        updateData={updateData}
      />
    </AccountFilterContext.Provider>
  );
};

export default AccountListTab;
