import React from "react";
import { errorManager } from "@budgeinc/budge-ui-core";
import { onePayPlansCrossTenantApi } from "api/BudgeApi";
import ExportAsCsvButton from "components/ExportAsCsvButton";
import { AxiosError } from "axios";
import { ErrorResponse } from "@budgeinc/budge-api";
import { useEmployerContext } from "features/employers/contexts/EmployerContext";
import { useMutation } from "@tanstack/react-query";
import { showExportRequestToast } from "features/exports/utils";
import { useOnePayPlanFilterContext } from "components/Table/OnePayPlansTable/filters/context";

const ExportOnePayPlansButton = () => {
  const {
    state: { employer },
  } = useEmployerContext();
  const { state: filters } = useOnePayPlanFilterContext();

  const searchMutation = useMutation({
    mutationFn: async (includePii: boolean) =>
      onePayPlansCrossTenantApi.searchEmployerOnePaymentPlansAsCsv(employer?.id!, filters, includePii),
    onSuccess: response => showExportRequestToast(response.data),
    onError: (error: AxiosError<ErrorResponse>) => {
      errorManager.showError({
        error: error.response?.data,
      });
    },
  });

  return <ExportAsCsvButton loading={searchMutation.isPending} onPress={searchMutation.mutateAsync} />;
};

export default ExportOnePayPlansButton;
