import { FinancialAccountBalanceOutput } from "@budgeinc/budge-api";
import { formatMoney, TColumnProps, Text } from "@budgeinc/budge-ui-core";
import DateDisplay from "components/DateDisplay";

export const getAccountBalanceHistoryColumns = (): TColumnProps<FinancialAccountBalanceOutput>[] => [
  {
    id: "balance",
    title: "Balance",
    render: record => (
      <Text>
        {formatMoney({
          amountCents: record.balanceCents,
        })}
      </Text>
    ),
  },
  {
    id: "asOfDate",
    title: "As Of",
    render: record => <DateDisplay value={record.asOfDate} />,
  },
  {
    id: "source",
    title: "Source",
    render: record => <Text>{record.source}</Text>,
  },
];
