/* eslint-disable react/no-unstable-nested-components */
import {
  Box,
  InfiniteScrollTable,
  useInfiniteScrollList,
  AccessScopeValidator,
  TAccessOperation,
} from "@budgeinc/budge-ui-core";
import { useEmployerContext, useIsEmployerD2C } from "features/employers/contexts/EmployerContext";
import { useCallback, useEffect, useMemo, useReducer } from "react";
import { employeesApi, employeesCrossTenantApi, employersApi } from "api/BudgeApi";
import {
  EmployeeSearchCriteriaInput,
  EmployeeWithUserAndSubscriptionsOutput,
  UserWhoamiOutputScopesEnum,
} from "@budgeinc/budge-api";
import { getPersistedFilters, hasFilters } from "components/ListFilterBar/utils";
import { TListFilterContextType } from "components/ListFilterBar/context";
import EmployeeStatusInfoContent from "features/employees/tabs/EmployeeDetails/components/EmployeeStatusInfoContent";
import useTableSorting from "hooks/useTableSorting";
import ExportAccessScopeValidator from "components/AccessScopeValidator/validators/ExportAccessScopeValidator";

import { getEmployeeColumns } from "./columns";
import { EmployeeFilterContext, EmployeeFilterReducer, EmployeeSearchContext } from "./context";
import { employeesReducer, initialState } from "./reducer";
import FiltersBar, { FilterFields, TFormValues, getBooleanDataFilterValue } from "./components/FiltersBar";
import BatchUpdateButton from "./components/BatchUpdateButton";
import ExportEmployeesButton from "./components/ExportEmployeesButton";

const EmployeeListPersistFilterId = "employee-list-filters";
const EmployeeListPersistSortId = "employee-list-sort";

const getInitialFilters = (): EmployeeSearchCriteriaInput => {
  const filters = getPersistedFilters<TFormValues>(EmployeeListPersistFilterId);

  const missingDataFilter = filters?.[FilterFields.MISSING_DATA];
  const excludedFilter = filters?.[FilterFields.EXCLUDED];
  const kpiExcludedFilter = filters?.[FilterFields.KPI_EXCLUDED];
  const activeCampaignExcludedFilter = filters?.[FilterFields.ACTIVE_CAMPAIGN_EXCLUDED];

  return {
    isMissingEmployeeOnboardingData: missingDataFilter ? getBooleanDataFilterValue(missingDataFilter) : undefined,
    isExcluded: excludedFilter ? getBooleanDataFilterValue(excludedFilter) : undefined,
    isKpiExcluded: kpiExcludedFilter ? getBooleanDataFilterValue(kpiExcludedFilter) : undefined,
    isActiveCampaignExcluded: activeCampaignExcludedFilter
      ? getBooleanDataFilterValue(activeCampaignExcludedFilter)
      : undefined,
    genericSearchLike: undefined,
  };
};

const EmployeesListTab = () => {
  const {
    state: { employer, managers },
  } = useEmployerContext();
  const isD2C = useIsEmployerD2C();
  const { sort, updateSort, sortRefreshIndex } = useTableSorting(EmployeeListPersistSortId);
  const [state, dispatch] = useReducer(employeesReducer, initialState);
  const [filters, filterDispatch] = useReducer(EmployeeFilterReducer, getInitialFilters());

  const handleOnFetch = useCallback(
    ({ page, pageSize }: { page: number; pageSize: number }) =>
      employeesCrossTenantApi
        .searchEmployees(
          employer?.id!,
          filters,
          `${pageSize * (page - 1)}`,
          pageSize.toString(),
          sort?.columnId,
          sort?.direction
        )
        .then(({ data }) => ({ results: data })),
    [JSON.stringify(filters), sortRefreshIndex]
  );

  const { requestState, onEndReached, forceRefresh } = useInfiniteScrollList({
    onFetch: handleOnFetch,
    skipFirstForceRefresh: true,
  });

  useEffect(() => {
    forceRefresh();
  }, [JSON.stringify(filters), sortRefreshIndex]);

  const filterMemoedContextValues = useMemo<TListFilterContextType<EmployeeSearchCriteriaInput>>(
    () => ({
      state: filters,
      dispatch: filterDispatch,
    }),
    [filters, filterDispatch]
  );

  const memoedContextValues = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state, dispatch]
  );

  return (
    <EmployeeFilterContext.Provider value={filterMemoedContextValues}>
      <EmployeeSearchContext.Provider value={memoedContextValues}>
        <Box f={1}>
          <FiltersBar
            persistId={EmployeeListPersistFilterId}
            extra={
              <>
                <AccessScopeValidator op={TAccessOperation.WRITE}>
                  <BatchUpdateButton onComplete={forceRefresh} />
                </AccessScopeValidator>
                <ExportAccessScopeValidator rule={[UserWhoamiOutputScopesEnum.UserAccess]}>
                  <ExportEmployeesButton />
                </ExportAccessScopeValidator>
              </>
            }
          />
          <InfiniteScrollTable<EmployeeWithUserAndSubscriptionsOutput>
            f={1}
            sort={sort}
            onSort={updateSort}
            columns={getEmployeeColumns(employer!, managers)}
            keyExtractor={item => item.id}
            requestState={requestState}
            onEndReached={onEndReached}
            local={{
              empty: {
                m: 24,
                title: "No customer found",
                description: hasFilters(filterMemoedContextValues)
                  ? "No customer match these filters. Edit or clear all filters."
                  : undefined,
              },
              noMoreItems: "No more customer to load",
            }}
            contentContainerStyle={{ marginBottom: 16 }}
            expandable={record => (
              <EmployeeStatusInfoContent
                record={{
                  ...record,
                  subscription: record.subscriptions.length ? record.subscriptions[0] : undefined,
                }}
              />
            )}
          />
        </Box>
      </EmployeeSearchContext.Provider>
    </EmployeeFilterContext.Provider>
  );
};

export default EmployeesListTab;
