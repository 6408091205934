import { formatEmployeeName, Tabs } from "@budgeinc/budge-ui-core";
import ContentHeader from "components/Layout/Main/Header";
import { useLocation } from "react-router-dom";
import DirectPaymentTransactionsTab from "features/direct-payments/tabs/Transactions";

import { UserWhoamiOutputScopesEnum } from "@budgeinc/budge-api";
import { useEmployeeRouteLoaderData } from "features/employees/routeConfig";
import ProgramEnrollmentsTab from "features/direct-payments/tabs/ProgramEnrollments";
import DetailsTab from "features/direct-payments/tabs/Details";
import withAccessScopeValidator from "components/AccessScopeValidator/withAccessScopeValidator";

export enum DIRECT_PAYMENT_TAB_KEYS {
  DETAILS = "#details",
  PROGRAM_ENROLLMENTS = "#program-enrollments",
  TRANSACTIONS = "#transactions",
}

export const Component = withAccessScopeValidator(() => {
  const { hash } = useLocation();
  const employee = useEmployeeRouteLoaderData();

  return (
    <>
      <ContentHeader
        title={formatEmployeeName({
          employee,
        })}
        subTitle="Direct Payment"
      />
      <Tabs
        onChange={activeKey => {
          window.location.hash = activeKey;
        }}
        defaultActiveKey={hash || DIRECT_PAYMENT_TAB_KEYS.DETAILS}
        activeKey={hash}
      >
        <Tabs.Pane title="Details" tabKey={DIRECT_PAYMENT_TAB_KEYS.DETAILS} flex={false}>
          <DetailsTab />
        </Tabs.Pane>
        <Tabs.Pane title="Program Enrollments" tabKey={DIRECT_PAYMENT_TAB_KEYS.PROGRAM_ENROLLMENTS}>
          <ProgramEnrollmentsTab />
        </Tabs.Pane>
        <Tabs.Pane title="Transactions" tabKey={DIRECT_PAYMENT_TAB_KEYS.TRANSACTIONS}>
          <DirectPaymentTransactionsTab />
        </Tabs.Pane>
      </Tabs>
    </>
  );
}, [
  {
    OR: [
      UserWhoamiOutputScopesEnum.Admin,
      UserWhoamiOutputScopesEnum.AdminRead,
      UserWhoamiOutputScopesEnum.ClientsFinancials,
    ],
  },
]);
