import { useInfiniteScrollList } from "@budgeinc/budge-ui-core";
import InvitesTable from "components/Table/InvitesTable";
import { useEmployerContext } from "features/employers/contexts/EmployerContext";
import { useCallback, useEffect, useMemo, useReducer } from "react";
import { employersApi, invitesCrossTenantApi } from "api/BudgeApi";
import FiltersBar from "components/Table/InvitesTable/filters/FiltersBar";
import { InviteFilterReducer, InviteFilterContext } from "components/Table/InvitesTable/filters/context";
import { getInitialFilters } from "components/Table/InvitesTable/filters/utils";

const EmployerInvitesListPersistFilterId = "employer-invites-list-filters";

const InvitesListTab = () => {
  const {
    state: { employer },
  } = useEmployerContext();
  const [filters, dispatch] = useReducer(InviteFilterReducer, getInitialFilters(EmployerInvitesListPersistFilterId));

  const handleOnFetch = useCallback(
    ({ page, pageSize }: { page: number; pageSize: number }) =>
      invitesCrossTenantApi
        .searchEmployerInvites(employer?.id!, filters, `${pageSize * (page - 1)}`, pageSize.toString())
        .then(({ data }) => ({ results: data })),
    [JSON.stringify(filters)]
  );

  const { requestState, onEndReached, forceRefresh } = useInfiniteScrollList({
    onFetch: handleOnFetch,
    skipFirstForceRefresh: true,
  });

  useEffect(() => {
    forceRefresh();
  }, [JSON.stringify(filters)]);

  const memoedContextValues = useMemo(
    () => ({
      state: filters,
      dispatch,
    }),
    [filters, dispatch]
  );

  return (
    <InviteFilterContext.Provider value={memoedContextValues}>
      <FiltersBar persistId={EmployerInvitesListPersistFilterId} />
      <InvitesTable keyExtractor={item => item.id} requestState={requestState} onEndReached={onEndReached} />
    </InviteFilterContext.Provider>
  );
};

export default InvitesListTab;
