import React from "react";
import ListFilterBar, { TFilterBarProps } from "components/ListFilterBar";
import {
  BuildValidationSchema,
  Checkbox,
  FormItem,
  Stack,
  yup,
  DateSchema,
  DatePickerInput,
} from "@budgeinc/budge-ui-core";
import FilterCollapse from "components/ListFilterBar/FilterCollapse";
import { ReferralPaymentSearchInputStatusEnum } from "@budgeinc/budge-api";
import { getFilterDateFromMaxDate, getFilterDateToMinDate } from "components/ListFilterBar/utils";
import { FormikConsumer } from "formik";
import { formatEnumValue } from "utils/format";
import { useReferralPaymentsFilterContext } from "./context";

export enum FilterFields {
  REFERRER = "referrer",
  REFERRAL = "referral",
  DATE_FROM = "from",
  DATE_TO = "to",
  STATUS = "status",
}

export type TFormValues = {
  [FilterFields.STATUS]: ReferralPaymentSearchInputStatusEnum[];
  [FilterFields.DATE_FROM]: Date | undefined;
  [FilterFields.DATE_TO]: Date | undefined;
  [FilterFields.REFERRAL]: string | undefined;
  [FilterFields.REFERRER]: string | undefined;
};

const validationSchema = BuildValidationSchema({
  [FilterFields.STATUS]: yup.array().of(yup.string().oneOf(Object.values(ReferralPaymentSearchInputStatusEnum))),
  [FilterFields.DATE_FROM]: DateSchema().nullable(),
  [FilterFields.DATE_TO]: DateSchema().nullable(),
  [FilterFields.REFERRAL]: yup.string().nullable(),
  [FilterFields.REFERRER]: yup.string().nullable(),
});

const FiltersBar = ({
  ignoreReferrerFilter = false,
  ...props
}: TFilterBarProps & {
  ignoreReferrerFilter?: boolean;
}) => {
  const { dispatch } = useReferralPaymentsFilterContext();

  const handleOnSearch = (value: string | undefined) =>
    dispatch({
      type: "updateFilters",
      data: {
        genericSearchLike: value,
      },
    });

  return (
    <ListFilterBar<TFormValues>
      {...props}
      showSearchBar
      onSearchChange={handleOnSearch}
      searchPlaceHolder={`Search by${
        ignoreReferrerFilter ? "" : " referrer or"
      } referral email, first name or last name`}
      formProps={{
        onSubmit: async values => {
          const status = values[FilterFields.STATUS];

          dispatch({
            type: "updateFilters",
            data: {
              status: status.length ? status : undefined,
              paymentDateFrom: values[FilterFields.DATE_FROM]?.toISOString() || undefined,
              paymentDateTo: values[FilterFields.DATE_TO]?.toISOString() || undefined,
            },
          });
        },
        validationSchema,
        getFormValues: values => {
          const dateFrom = values?.[FilterFields.DATE_FROM];
          const dateTo = values?.[FilterFields.DATE_TO];

          return {
            [FilterFields.STATUS]: values?.[FilterFields.STATUS] || [],
            [FilterFields.DATE_FROM]: dateFrom ? new Date(dateFrom) : undefined,
            [FilterFields.DATE_TO]: dateTo ? new Date(dateTo) : undefined,
            [FilterFields.REFERRAL]: values?.[FilterFields.REFERRAL] || "",
            [FilterFields.REFERRER]: values?.[FilterFields.REFERRER] || "",
          };
        },
      }}
    >
      <FormikConsumer>
        {form => (
          <Stack>
            <FilterCollapse defaultOpen trigger="Payment Date">
              <Stack>
                <FormItem name={FilterFields.DATE_FROM}>
                  <DatePickerInput
                    label="From"
                    maxDate={getFilterDateFromMaxDate(form.getFieldMeta<Date>(FilterFields.DATE_TO).value)}
                  />
                </FormItem>
                <FormItem name={FilterFields.DATE_TO}>
                  <DatePickerInput
                    label="To"
                    minDate={getFilterDateToMinDate(form.getFieldMeta<Date>(FilterFields.DATE_FROM).value)}
                  />
                </FormItem>
              </Stack>
            </FilterCollapse>
            <FilterCollapse defaultOpen trigger="Status">
              <FormItem name={FilterFields.STATUS}>
                <Checkbox.Group
                  options={Object.values(ReferralPaymentSearchInputStatusEnum).map(status => ({
                    label: formatEnumValue(status),
                    value: status,
                  }))}
                />
              </FormItem>
            </FilterCollapse>
          </Stack>
        )}
      </FormikConsumer>
    </ListFilterBar>
  );
};

export default FiltersBar;
