import React, { useCallback } from "react";
import { DebtPaymentPlanOutput } from "@budgeinc/budge-api";
import { Description, Table, Text, Collapse } from "@budgeinc/budge-ui-core";
import { getAccountColumns } from "./columns";

interface OwnProps {
  plan: DebtPaymentPlanOutput;
  employerId: string;
}

const AccountsCard = ({ plan, employerId }: OwnProps) => {
  const getPlanAccounts = useCallback(
    () =>
      plan.planResult?.items.map(item => ({
        ...item,
        status: plan.debtPaymentPlanAccounts.find(account => account.financialAccount.id === item.financialAccount.id)
          ?.debtPaymentPlanAccountStatus!,
      })) || [],
    [plan.updatedAt]
  );

  return (
    <Collapse
      trigger={
        <Text fw="500" variant="bodyMedium">
          Plan Accounts ({plan.planResult?.items.length})
        </Text>
      }
      expandable={false}
      defaultOpen
    >
      <Table
        data={getPlanAccounts()}
        variant="dark"
        columns={getAccountColumns(employerId!)}
        local={{
          empty: {
            m: 24,
            title: "No associated accounts",
          },
        }}
        expandable={record => (
          <Description>
            <Description.Item label="ID">{record.financialAccount.id}</Description.Item>
            <Description.Item label="Skipped">{record.isSkipped ? "True" : "False"}</Description.Item>
          </Description>
        )}
      />
    </Collapse>
  );
};

export default AccountsCard;
