/* eslint-disable react/no-unstable-nested-components */
import { ReferralPaymentOutput } from "@budgeinc/budge-api";
import { Description, InfiniteScrollTable, TEmptyProps } from "@budgeinc/budge-ui-core";

import { hasFilters } from "components/ListFilterBar/utils";
import { getReferralPaymentsColumns } from "./columns";
import { TInfiniteScrollDataTableProps } from "../../types";
import { useReferralPaymentsFilterContext } from "./filters/context";

type OwnProps = TInfiniteScrollDataTableProps<ReferralPaymentOutput> & {
  emptyLocal?: Pick<TEmptyProps, "description" | "action">;
  includeReferrer?: boolean;
};

const ReferralPaymentsTable = ({ emptyLocal, includeReferrer = false, ...props }: OwnProps) => {
  const filters = useReferralPaymentsFilterContext();

  return (
    <InfiniteScrollTable<ReferralPaymentOutput>
      columns={getReferralPaymentsColumns(includeReferrer)}
      local={{
        empty: {
          title: "No referral payments",
          description: hasFilters(filters)
            ? `No referral payments match these filters. Edit or clear all filters.`
            : undefined,
          ...emptyLocal,
        },
        noMoreItems: "No more referral payments to load",
      }}
      contentContainerStyle={{ marginBottom: 16 }}
      expandable={record => (
        <Description>
          <Description.Item label="ID">{record.id}</Description.Item>
        </Description>
      )}
      {...props}
    />
  );
};

export default ReferralPaymentsTable;
