import {
  QuizInputGrossIncomeYearlyAmountRangeEnum,
  EmployeeInputQuizTotalDebtAmountRangeEnum,
  EmployeeInputQuizAvailableMonthlyCashFlowRangeEnum,
  EmployeeInputQuizEmergencySavingsRangeEnum,
  EmployeeInputQuizAvailableMonthlyCashFlowRangeV2Enum,
} from "@budgeinc/budge-api";

export const TotalDebtCopyMap: Record<EmployeeInputQuizTotalDebtAmountRangeEnum, string> = {
  [EmployeeInputQuizTotalDebtAmountRangeEnum.Zero]: "$0",
  [EmployeeInputQuizTotalDebtAmountRangeEnum.Range14999]: "Between $1 and $4,999",
  [EmployeeInputQuizTotalDebtAmountRangeEnum.Range50009999]: "Between $5,000 and $9,999",
  [EmployeeInputQuizTotalDebtAmountRangeEnum.Range1000029999]: "Between $10,000 and $29,999",
  [EmployeeInputQuizTotalDebtAmountRangeEnum.Range30000OrAbove]: "$30,000 and above",
};

export const GrossSalaryIncomeCopyMap: Record<QuizInputGrossIncomeYearlyAmountRangeEnum, string> = {
  [QuizInputGrossIncomeYearlyAmountRangeEnum._49999OrLess]: "$49,999 or less",
  [QuizInputGrossIncomeYearlyAmountRangeEnum._5000099999]: "Between $50,000 and $99,999",
  [QuizInputGrossIncomeYearlyAmountRangeEnum._100000199999]: "Between $100,000 and $199,999",
  [QuizInputGrossIncomeYearlyAmountRangeEnum._200000OrAbove]: "$200,000 and above",
};

export const LeftOverIncomeV2CopyMap: Record<EmployeeInputQuizAvailableMonthlyCashFlowRangeV2Enum, string> = {
  [EmployeeInputQuizAvailableMonthlyCashFlowRangeV2Enum.Zero]: "$0",
  [EmployeeInputQuizAvailableMonthlyCashFlowRangeV2Enum.Range150]: "$1 - $50",
  [EmployeeInputQuizAvailableMonthlyCashFlowRangeV2Enum.Range51100]: "$51 - $100",
  [EmployeeInputQuizAvailableMonthlyCashFlowRangeV2Enum.Range101250]: "$101 - $250",
  [EmployeeInputQuizAvailableMonthlyCashFlowRangeV2Enum.Range251OrMore]: "$251+",
};

export const LeftOverIncomeCopyMap: Record<EmployeeInputQuizAvailableMonthlyCashFlowRangeEnum, string> = {
  [EmployeeInputQuizAvailableMonthlyCashFlowRangeEnum.LessThan0]: "Less than $0",
  [EmployeeInputQuizAvailableMonthlyCashFlowRangeEnum.Range0499]: "$0 - $499",
  [EmployeeInputQuizAvailableMonthlyCashFlowRangeEnum.Range500999]: "$500 - $999",
  [EmployeeInputQuizAvailableMonthlyCashFlowRangeEnum.Range10002499]: "$1000 - $2499",
  [EmployeeInputQuizAvailableMonthlyCashFlowRangeEnum.Range2500OrMore]: "$2500 and above",
};

export const EmergencySavingsCopyMap: Record<EmployeeInputQuizEmergencySavingsRangeEnum, string> = {
  [EmployeeInputQuizEmergencySavingsRangeEnum._14999]: "Between $1 and $4,999",
  [EmployeeInputQuizEmergencySavingsRangeEnum._50009999]: "Between $5,000 and $9,999",
  [EmployeeInputQuizEmergencySavingsRangeEnum._1000019999]: "Between $10,000 and $19,999",
  [EmployeeInputQuizEmergencySavingsRangeEnum._20000OrAbove]: "$20,000 and above",
};
