import { ErrorResponse, FinancialAccountOutput } from "@budgeinc/budge-api";
import { BuildValidationSchema, yup } from "@budgeinc/budge-ui-utils";
import {
  Alert,
  FormFieldsWrapper,
  FormItem,
  FormProvider,
  getApiErrorMessage,
  InfoIcon,
  Input,
  Modal,
  Stack,
  Text,
} from "@budgeinc/budge-ui-core";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import { useState } from "react";
import { accountsCrossTenantApi, employersApi } from "api/BudgeApi";
import { useAppMessages } from "store/global";
import { useEmployerContext } from "features/employers/contexts/EmployerContext";

interface OwnProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (financialAccount: FinancialAccountOutput) => void;
}

enum FIELDS {
  VALUE_1 = "value1",
  VALUE_2 = "value2",
}

const VerifyFundingAccountModal = ({ isOpen, onClose, onSuccess }: OwnProps) => {
  const messages = useAppMessages();
  const { state } = useEmployerContext();
  const [formError, setFormError] = useState<string>();

  const form = useFormik({
    initialValues: {
      [FIELDS.VALUE_1]: "",
      [FIELDS.VALUE_2]: "",
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async value => {
      if (!state.employer) return;

      return accountsCrossTenantApi
        .verifyEmployerFundingAccount(state.employer.id, {
          value1: parseInt(value[FIELDS.VALUE_1], 10),
          value2: parseInt(value[FIELDS.VALUE_2], 10),
        })
        .then(({ data }) => {
          onSuccess(data);
          onClose();
        })
        .catch((e: AxiosError<ErrorResponse>) => setFormError(getApiErrorMessage(messages, e.response?.data)));
    },
  });

  const handleOnClosed = () => {
    form.resetForm();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onClosed={handleOnClosed}
      header={{
        title: (
          <Text variant="bodyLarge" fw="600">
            Verify Funding Source
          </Text>
        ),
      }}
      footer={{
        okButtonProps: {
          title: "Confirm",
          disabled: !form.dirty,
          loading: form.isSubmitting,
          onPress: form.submitForm,
        },
      }}
    >
      <Stack spacing="md" px="xl">
        <Alert icon={InfoIcon} color="blue">
          <Text>
            3 micro-transactions will be made to your bank account. To confirm ownership of the bank account, Budge will
            require that you input the value for the two debit transactions below.
          </Text>
        </Alert>
        <FormProvider value={form} formErrorMsg={formError}>
          <FormFieldsWrapper>
            <Stack spacing="md">
              <FormItem name={FIELDS.VALUE_1}>
                <Input label="Micro-debit 1" />
              </FormItem>
              <FormItem name={FIELDS.VALUE_2}>
                <Input label="Micro-debit 2" />
              </FormItem>
            </Stack>
          </FormFieldsWrapper>
        </FormProvider>
      </Stack>
    </Modal>
  );
};

const validationSchema = BuildValidationSchema({
  [FIELDS.VALUE_1]: yup.string().required(),
  [FIELDS.VALUE_2]: yup.string().required(),
});

export default VerifyFundingAccountModal;
