import React, { useState } from "react";
import VerifyFundingAccountModal from "components/FundingAccount/VerifyFundingAccountModal";
import { Button } from "@budgeinc/budge-ui-core";
import { FinancialAccountOutput } from "@budgeinc/budge-api";
import { useEmployerEntityContext } from "features/employers/entity/contexts/EmployerEntityContext";

const VerifyFundingButton = () => {
  const [visible, setVisible] = useState(false);
  const { dispatch } = useEmployerEntityContext();

  const handleClose = () => setVisible(false);
  const handleOpen = () => setVisible(true);
  const handleSuccess = (account: FinancialAccountOutput) => dispatch({ type: "setFundingAccount", data: account });

  return (
    <>
      <Button
        title="Verify Funding Account"
        size="sm"
        color="primary"
        variant="gradient"
        alignSelf="flex-start"
        onPress={handleOpen}
      />
      <VerifyFundingAccountModal onSuccess={handleSuccess} isOpen={visible} onClose={handleClose} />
    </>
  );
};

export default VerifyFundingButton;
