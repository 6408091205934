import { useNavigate } from "react-router";
import { BuildValidationSchema, EmailSchema } from "@budgeinc/budge-ui-utils";
import {
  Button,
  Form,
  FormFieldsWrapper,
  FormItem,
  getApiErrorMessage,
  Input,
  Stack,
  Text,
} from "@budgeinc/budge-ui-core";
import { ROUTES } from "common/routes";
import styles from "features/authentication/password/styles";
import { useState } from "react";
import { useAppMessages } from "store/global";
import { usersApi } from "api/BudgeApi";
import { AxiosError } from "axios";
import { ErrorResponse, UserResetPasswordRequestInputOriginEnum } from "@budgeinc/budge-api";
import AuthLayout from "features/authentication/Layout";

enum FIELDS {
  EMAIL = "email",
}

interface OwnProps {
  setEmailCb: (email: string) => void;
}

const ResetPwSchema = BuildValidationSchema({
  email: EmailSchema().required(),
});

const EmailPage = ({ setEmailCb }: OwnProps) => {
  const navigate = useNavigate();
  const messages = useAppMessages();
  const [formError, setFormError] = useState<string | undefined>();

  const resetPasswordEmail = (email: string) =>
    usersApi
      .sendResetPasswordEmail({
        email,
        origin: UserResetPasswordRequestInputOriginEnum.AdminApp,
      })
      .then(() => navigate(ROUTES.resetPasswordEmailSent))
      .catch((error: AxiosError<ErrorResponse>) => setFormError(getApiErrorMessage(messages, error.response?.data)));

  return (
    <AuthLayout title="Reset Password">
      <Text variant="bodyLarge" style={styles.notice}>
        Enter your Budge email address and we’ll send you a link to reset your password.
      </Text>
      <Form
        formikProps={{
          initialValues: { [FIELDS.EMAIL]: "" },
          validationSchema: ResetPwSchema,
          onSubmit: values => {
            setEmailCb(values.email);
            return resetPasswordEmail(values.email);
          },
        }}
        formErrorMsg={formError}
      >
        {({ isSubmitting, submitForm }) => (
          <FormFieldsWrapper>
            <Stack spacing="xl">
              <FormItem name={FIELDS.EMAIL}>
                <Input label="Email Address" />
              </FormItem>
              <Button
                onPress={submitForm}
                title="Send Reset Link"
                loading={isSubmitting}
                color="primary"
                variant="gradient"
                size="lg"
                fullWidth
              />
            </Stack>
          </FormFieldsWrapper>
        )}
      </Form>
    </AuthLayout>
  );
};

export default EmailPage;
