import { ReferralPaymentOutput, ReferralPaymentOutputStatusEnum } from "@budgeinc/budge-api";
import { intlFormatDate } from "@budgeinc/budge-ui-utils";
import {
  TColumnProps,
  Text,
  Tag,
  EMPTY_VALUE_PLACEHOLDER,
  SuccessIcon,
  formatMoney,
  MoreDotsCircledIcon,
} from "@budgeinc/budge-ui-core";
import DateDisplay from "components/DateDisplay";
import PaymentDetailsButton from "./components/PaymentDetailsButton";

export const getReferralPaymentsColumns = (includeReferrer: boolean = false): TColumnProps<ReferralPaymentOutput>[] => [
  {
    id: "amount",
    title: "Amount",
    render: ({ totalAmountCents }) => <Text>{formatMoney({ amountCents: totalAmountCents })}</Text>,
  },
  {
    id: "status",
    title: "Status",
    render: ({ status }) => (
      <Tag
        value={status}
        color={status === ReferralPaymentOutputStatusEnum.Paid ? "green" : "blue"}
        icon={status === ReferralPaymentOutputStatusEnum.Paid ? SuccessIcon : MoreDotsCircledIcon}
      />
    ),
  },
  {
    id: "paymentdate",
    title: "Payment Date",
    render: ({ paymentDate }) => (
      <Text>
        {paymentDate
          ? intlFormatDate({
              value: paymentDate,
            })
          : EMPTY_VALUE_PLACEHOLDER}
      </Text>
    ),
  },
  {
    id: "updatedat",
    title: "Updated at",
    render: ({ updatedAt }) => <DateDisplay numberOfLines={1} value={updatedAt} />,
  },
  {
    id: "createdat",
    title: "Created at",
    render: ({ createdAt }) => <DateDisplay numberOfLines={1} value={createdAt} />,
  },
  {
    align: "flex-end",
    id: "action",
    title: "",
    render: record => <PaymentDetailsButton payment={record} includeReferrer={includeReferrer} />,
  },
];
