import { AuthTokenOutput } from "@budgeinc/budge-api";
import { DeleteIcon, SuccessIcon, TColumnProps, Tag } from "@budgeinc/budge-ui-core";
import DateDisplay from "components/DateDisplay";
import ActionCell from "./ActionCell";

export const getAuthTokenColumns = (): TColumnProps<AuthTokenOutput>[] => [
  {
    id: "type",
    title: "Type",
    render: ({ type }) => <Tag value={type} />,
  },
  {
    id: "status",
    title: "Status",
    render: ({ isExpired, isRevoked }) => {
      if (!isExpired && !isRevoked) {
        return <Tag value="Active" color="green" icon={SuccessIcon} />;
      }

      return <Tag value={isExpired ? "Expired" : "Revoked"} color="red" icon={DeleteIcon} />;
    },
  },
  {
    id: "expiration",
    title: "Expiration",
    render: ({ expiration }) => <DateDisplay value={expiration} showTime />,
  },
  {
    id: "createdAt",
    title: "Created At",
    render: ({ createdAt }) => <DateDisplay value={createdAt} showTime />,
  },
  {
    id: "action",
    title: "",
    align: "flex-end",
    render: record => (!record.isRevoked && !record.isExpired ? <ActionCell token={record} /> : null),
  },
];
