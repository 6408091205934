import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import {
  ReferralPaymentsFilterReducer,
  ReferralPaymentsFilterContext,
} from "components/Table/Referral/PaymentsList/filters/context";
import { getInitialFilters } from "components/Table/Referral/PaymentsList/filters/utils";
import { backofficeApi } from "api/BudgeApi";
import { useInfiniteScrollList } from "@budgeinc/budge-ui-core";
import FiltersBar from "components/Table/Referral/PaymentsList/filters/FiltersBar";
import ReferralPaymentsTable from "components/Table/Referral/PaymentsList";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";

const EmployeeReferralPaymentsPersistFilterId = "employee-referral-payments-list-filters";

const EarningsListTab = () => {
  const { state } = useEmployeeEntityContext();
  const [filters, dispatch] = useReducer(
    ReferralPaymentsFilterReducer,
    getInitialFilters(EmployeeReferralPaymentsPersistFilterId)
  );

  const handleOnFetch = useCallback(
    ({ page, pageSize }: { page: number; pageSize: number }) =>
      backofficeApi
        .searchReferralPayments(
          {
            ...filters,
            referrerEmployeeId: state.employee?.id,
          },
          `${pageSize * (page - 1)}`,
          pageSize.toString()
        )
        .then(({ data }) => ({ results: data })),
    [JSON.stringify(filters)]
  );

  const { requestState, onEndReached, forceRefresh } = useInfiniteScrollList({
    onFetch: handleOnFetch,
    skipFirstForceRefresh: true,
  });

  useEffect(() => {
    forceRefresh();
  }, [JSON.stringify(filters)]);

  const memoedContextValues = useMemo(
    () => ({
      state: filters,
      dispatch,
    }),
    [filters, dispatch]
  );

  return (
    <ReferralPaymentsFilterContext.Provider value={memoedContextValues}>
      <FiltersBar persistId={EmployeeReferralPaymentsPersistFilterId} ignoreReferrerFilter />
      <ReferralPaymentsTable keyExtractor={item => item.id} requestState={requestState} onEndReached={onEndReached} />
    </ReferralPaymentsFilterContext.Provider>
  );
};

export default EarningsListTab;
