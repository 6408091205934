import React from "react";
import ListFilterBar, { TFilterBarProps } from "components/ListFilterBar";
import { BuildValidationSchema, Checkbox, DatePickerInput, FormItem, Stack, yup } from "@budgeinc/budge-ui-core";
import FilterCollapse from "components/ListFilterBar/FilterCollapse";
import { DirectPaymentSearchCriteriaInputStatusEnum } from "@budgeinc/budge-api";
import { DateSchema } from "@budgeinc/budge-ui-utils";
import { getFilterDateFromMaxDate, getFilterDateToMinDate } from "components/ListFilterBar/utils";
import { FormikConsumer } from "formik";
import { formatEnumValue } from "utils/format";
import { useDirectPaymentFilterContext } from "./context";

export enum FilterFields {
  STATUS = "status",
  DATE_FROM = "from",
  DATE_TO = "to",
}

export type TFormValues = {
  [FilterFields.STATUS]: DirectPaymentSearchCriteriaInputStatusEnum[];
  [FilterFields.DATE_FROM]: Date | undefined;
  [FilterFields.DATE_TO]: Date | undefined;
};

const validationSchema = BuildValidationSchema({
  [FilterFields.STATUS]: yup.array().of(yup.string().oneOf(Object.values(DirectPaymentSearchCriteriaInputStatusEnum))),
  [FilterFields.DATE_FROM]: DateSchema().nullable(),
  [FilterFields.DATE_TO]: DateSchema().nullable(),
});

const FiltersBar = ({ showSearchBar = false, ...props }: TFilterBarProps & { showSearchBar?: boolean }) => {
  const { dispatch, initialFilters } = useDirectPaymentFilterContext();

  const handleOnSearch = (value: string | undefined) =>
    dispatch({
      type: "updateFilters",
      data: {
        genericSearchLike: value,
      },
    });

  return (
    <ListFilterBar<TFormValues>
      {...props}
      showSearchBar={showSearchBar}
      searchPlaceHolder="Search by first name, last name and email"
      onSearchChange={handleOnSearch}
      formProps={{
        onSubmit: async values => {
          const status = values[FilterFields.STATUS];

          dispatch({
            type: "updateFilters",
            data: {
              status: status.length ? status : undefined,
              processedDateFrom: values[FilterFields.DATE_FROM]?.toISOString() || undefined,
              processedDateTo: values[FilterFields.DATE_TO]?.toISOString() || undefined,
            },
          });
        },
        validationSchema,
        getInitialValues: reset => {
          if (initialFilters && !reset) {
            return {
              [FilterFields.STATUS]: initialFilters.status || [],
              [FilterFields.DATE_FROM]: initialFilters.processedDateFrom
                ? new Date(initialFilters.processedDateFrom)
                : undefined,
              [FilterFields.DATE_TO]: initialFilters.processedDateTo
                ? new Date(initialFilters?.processedDateTo)
                : undefined,
            };
          }

          return {
            [FilterFields.STATUS]: [],
            [FilterFields.DATE_FROM]: undefined,
            [FilterFields.DATE_TO]: undefined,
          };
        },
      }}
    >
      <FormikConsumer>
        {form => (
          <Stack>
            <FilterCollapse defaultOpen trigger="Processed Date">
              <Stack>
                <FormItem name={FilterFields.DATE_FROM}>
                  <DatePickerInput
                    label="From"
                    maxDate={getFilterDateFromMaxDate(form.getFieldMeta<Date>(FilterFields.DATE_TO).value)}
                  />
                </FormItem>
                <FormItem name={FilterFields.DATE_TO}>
                  <DatePickerInput
                    label="To"
                    minDate={getFilterDateToMinDate(form.getFieldMeta<Date>(FilterFields.DATE_FROM).value)}
                  />
                </FormItem>
              </Stack>
            </FilterCollapse>
            <FilterCollapse defaultOpen trigger="Status">
              <FormItem name={FilterFields.STATUS}>
                <Checkbox.Group
                  options={Object.values(DirectPaymentSearchCriteriaInputStatusEnum).map(status => ({
                    label: formatEnumValue(status),
                    value: status,
                  }))}
                />
              </FormItem>
            </FilterCollapse>
          </Stack>
        )}
      </FormikConsumer>
    </ListFilterBar>
  );
};

export default FiltersBar;
