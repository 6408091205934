import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import { CreditScoreSearchCriteriaInput, UserWhoamiOutputScopesEnum } from "@budgeinc/budge-api";
import { useInfiniteScrollList } from "@budgeinc/budge-ui-core";
import { creditScoresCrossTenantApi, employersApi } from "api/BudgeApi";
import CreditScoresTable from "components/Table/CreditScoresTable";
import ExportAccessScopeValidator from "components/AccessScopeValidator/validators/ExportAccessScopeValidator";
import ExportCreditScoresButton from "features/credit-scores/ExportCreditScoresButton";
import useTableSorting from "hooks/useTableSorting";
import FiltersBar from "components/Table/CreditScoresTable/filters/FiltersBar";
import { CreditScoreFilterContext, CreditScoreFilterReducer } from "components/Table/CreditScoresTable/filters/context";
import { TListFilterContextType } from "components/ListFilterBar/context";
import { getInitialFilters } from "components/Table/CreditScoresTable/filters/utils";
import { useEmployerContext } from "features/employers/contexts/EmployerContext";

const EmployerCreditScoreListPersistSortId = "employer-credit-score-list-sort";
const EmployerCreditScoreListPersistfilterId = "employer-credit-score-list-filters";

const CreditScoresListTab = () => {
  const {
    state: { employer },
  } = useEmployerContext();
  const { sort, sortRefreshIndex, updateSort } = useTableSorting(EmployerCreditScoreListPersistSortId);
  const initialFilters = getInitialFilters(EmployerCreditScoreListPersistfilterId);
  const [filters, dispatch] = useReducer(CreditScoreFilterReducer, initialFilters);

  const handleOnFetch = useCallback(
    ({ page, pageSize }: { page: number; pageSize: number }) =>
      creditScoresCrossTenantApi
        .searchEmployerCreditScores(
          employer?.id!,
          filters,
          `${pageSize * (page - 1)}`,
          pageSize.toString(),
          sort?.columnId,
          sort?.direction
        )
        .then(({ data }) => ({ results: data })),
    [sortRefreshIndex, JSON.stringify(filters)]
  );

  const { requestState, onEndReached, forceRefresh } = useInfiniteScrollList({
    onFetch: handleOnFetch,
    skipFirstForceRefresh: true,
  });

  useEffect(() => {
    forceRefresh();
  }, [JSON.stringify(filters), sortRefreshIndex]);

  const memoedFilterContextValues = useMemo<TListFilterContextType<CreditScoreSearchCriteriaInput>>(
    () => ({
      state: filters,
      dispatch,
      initialFilters,
    }),
    [JSON.stringify(filters), dispatch]
  );

  return (
    <>
      <CreditScoreFilterContext.Provider value={memoedFilterContextValues}>
        <FiltersBar
          persistId={EmployerCreditScoreListPersistfilterId}
          extra={
            <ExportAccessScopeValidator rule={[UserWhoamiOutputScopesEnum.ClientsFinancials]}>
              <ExportCreditScoresButton employerId={employer?.id!} />
            </ExportAccessScopeValidator>
          }
        />
        <CreditScoresTable
          sort={sort}
          onSort={updateSort}
          requestState={requestState}
          onEndReached={onEndReached}
          keyExtractor={item => item.id}
          employerId={employer?.id}
        />
      </CreditScoreFilterContext.Provider>
    </>
  );
};

export default CreditScoresListTab;
