import { useCallback, useState } from "react";
import { AppSecureStorage, TSortState } from "@budgeinc/budge-ui-core";

const useTableSorting = (persistId: string) => {
  const [sort, setSort] = useState<TSortState | null>(getPersistedSort(persistId));

  const updateSort = useCallback(
    (value: TSortState | null) => {
      persistFilters(persistId, value);
      setSort(value);
    },
    [persistId]
  );

  return {
    sort,
    updateSort,
    sortRefreshIndex: sort ? `${sort.columnId}${sort.direction}` : null,
  };
};

const persistFilters = (persistId: string, filters: any) => {
  AppSecureStorage.save(persistId, JSON.stringify(filters));
};

const getPersistedSort = (persistId: string | undefined): TSortState | null => {
  const sort = persistId ? AppSecureStorage.get(persistId) : null;

  return sort ? (JSON.parse(sort) as TSortState) : null;
};

export default useTableSorting;
