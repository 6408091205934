import React from "react";
import { Stack } from "@budgeinc/budge-ui-core";
import GeneralInfoCard from "./GeneralInfoCard";

const DetailsTab = () => (
  <Stack mb={40}>
    <GeneralInfoCard />
  </Stack>
);

export default DetailsTab;
