import React from "react";
import { Text, Grid, Box, FormItem, Input } from "@budgeinc/budge-ui-core";
import { ActionTakingOutput } from "@budgeinc/budge-api";
import { ActionTakingFormItemType } from "../types";
import ActionTakingGridItem from "./ActionTakingGridItem";
import { getActionTakingFormItemName } from "../utils";

interface OwnProps {
  title: string;
  actionTakingItems: ActionTakingOutput[];
}

const ActionTakingGrid = ({ title, actionTakingItems }: OwnProps) => (
  <>
    <Text ml="md" fw="600">
      {title}
    </Text>
    <Grid column={4} gutter={0} bordered>
      {actionTakingItems.map(action => [
        <Box f={1} p="md">
          <Text fw="400" maw="75%">
            {action.description}
          </Text>
        </Box>,
        <Box f={1} p="md" justifyContent="center">
          <ActionTakingGridItem
            actionElementId={action.actionTakingElementId}
            value={action.coachRecommended}
            actionTakingFormItemType={ActionTakingFormItemType.COACH_RECOMMENCED}
          />
        </Box>,
        <Box f={1} p="md" justifyContent="center">
          <ActionTakingGridItem
            actionElementId={action.actionTakingElementId}
            value={action.userExecuted}
            actionTakingFormItemType={ActionTakingFormItemType.USER_EXECUTED}
          />
        </Box>,
        <Box f={1} p="md" justifyContent="center">
          <FormItem name={getActionTakingFormItemName(action.actionTakingElementId, ActionTakingFormItemType.COMMENT)}>
            <Input multiline numberOfLines={4} />
          </FormItem>
        </Box>,
      ])}
    </Grid>
  </>
);

export default ActionTakingGrid;
