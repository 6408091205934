import { Text } from "react-native";
import React from "react";
import { Box, Button, Divider, Dropdown, List, Pressable, useDisclosure } from "@budgeinc/budge-ui-core";
import { v4 as uuidv4 } from "uuid";
import { useCashFlowsContext } from "../context";

const AddCashFlowButton = () => {
  const disclosure = useDisclosure();
  const { state, dispatch } = useCashFlowsContext();

  return (
    <Box alignSelf="flex-start">
      <Dropdown
        isOpen={disclosure.isOpen}
        onOpenChange={disclosure.toggle}
        anchor={<Button size="sm" title="Add Income/Expense" onPress={disclosure.toggle} />}
        overlay={
          <List
            p="md"
            data={state.elements}
            renderItem={({ item }) => (
              <Pressable
                onPress={() => {
                  dispatch({
                    type: "addLocalDataPoint",
                    data: {
                      localId: uuidv4(),
                      intakeCashFlowElementId: item.id,
                    },
                  });
                  disclosure.toggle();
                }}
              >
                <Text>{item.description}</Text>
              </Pressable>
            )}
            ItemSeparatorComponent={() => <Divider spacing="sm" />}
          />
        }
      />
    </Box>
  );
};

export default AddCashFlowButton;
