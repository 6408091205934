import { EmployerOutput, EmployerOutputPayrollFrequencyEnum, UserWhoamiOutputScopesEnum } from "@budgeinc/budge-api";
import { BuildValidationSchema, yup } from "@budgeinc/budge-ui-utils";
import {
  EditSaveToggleButtons,
  FormItem,
  Grid,
  Stack,
  Text,
  Input,
  Select,
  AccessScopeValidator,
  TAccessOperation,
} from "@budgeinc/budge-ui-core";
import { FormikConsumer } from "formik";
import { useState } from "react";
import { useEmployerContext, useIsEmployerD2C } from "features/employers/contexts/EmployerContext";
import MethodExternalButton from "components/Method/MethodExternalButton";
import { formatEnumValue } from "utils/format";

import EmployerCollapseEditForm from "../components/EmployerCollapseEditForm";

enum FIELDS {
  NAME = "name",
  IDENTIFICATION_NUMBER = "employerIdentificationNumber",
  SUBSCRIPTION_PRICE_KEY = "subscriptionPriceLookupKey",
  METHOD_ENTITY_ID = "methodEntityId",
  PAYROLL_FREQUENCY = "payrollFrequency",
}

const GeneralInfoCard = () => {
  const isD2C = useIsEmployerD2C();
  const {
    state: { employer },
  } = useEmployerContext();
  const [editModeOn, setEditMode] = useState(false);

  return (
    <EmployerCollapseEditForm
      header={
        <Text fw="500" variant="bodyMedium">
          General Information
        </Text>
      }
      getFormValues={getFormValues}
      setEditMode={setEditMode}
      initialOpen
      validationSchema={getValidationSchema(isD2C)}
      transformForSubmit={(values: Record<FIELDS, any>) => values}
    >
      <FormikConsumer>
        {form => (
          <Stack spacing="md">
            <Grid
              column={2}
              gutter="md"
              responsive={{
                xs: 1,
                sm: 1,
                md: 1,
                xl: 3,
                xxl: 3,
              }}
            >
              <FormItem name={FIELDS.NAME}>
                <Input label="Name" disabled={!editModeOn} />
              </FormItem>
              {!isD2C && (
                <FormItem name={FIELDS.PAYROLL_FREQUENCY}>
                  <Select
                    label="Payments Frequency"
                    disabled={!editModeOn}
                    options={Object.values(EmployerOutputPayrollFrequencyEnum).map(frequency => ({
                      value: frequency,
                      label: formatEnumValue(frequency),
                    }))}
                  />
                </FormItem>
              )}
              {!isD2C && (
                <FormItem name={FIELDS.IDENTIFICATION_NUMBER}>
                  <Input label="Identification number" disabled={!editModeOn} />
                </FormItem>
              )}
              {!isD2C && (
                <FormItem name={FIELDS.METHOD_ENTITY_ID}>
                  <Input
                    label="Method Entity ID"
                    disabled={!editModeOn}
                    rightSection={
                      employer?.methodEntityId && (
                        <Stack.Horizontal>
                          <MethodExternalButton
                            buttonType="actionIcon"
                            type="entities"
                            recordId={employer.methodEntityId}
                          />
                        </Stack.Horizontal>
                      )
                    }
                  />
                </FormItem>
              )}
              <FormItem name={FIELDS.SUBSCRIPTION_PRICE_KEY}>
                <Input label="Subscription Price Key" disabled={!editModeOn} />
              </FormItem>
            </Grid>
            <AccessScopeValidator op={TAccessOperation.WRITE} rule={[UserWhoamiOutputScopesEnum.Clients]}>
              <EditSaveToggleButtons
                isEditModeEnabled={editModeOn}
                setEditMode={setEditMode}
                onSubmit={form.handleSubmit}
                saveBtnDisabled={!form.dirty || !!Object.values(form.errors).length}
                onCancel={() => form.resetForm()}
                loading={form.isSubmitting}
              />
            </AccessScopeValidator>
          </Stack>
        )}
      </FormikConsumer>
    </EmployerCollapseEditForm>
  );
};

const getValidationSchema = (isD2C: boolean) =>
  BuildValidationSchema({
    [FIELDS.NAME]: yup.string().required(),
    [FIELDS.IDENTIFICATION_NUMBER]: yupOptionalString(isD2C),
    [FIELDS.SUBSCRIPTION_PRICE_KEY]: yupOptionalString(isD2C),
    [FIELDS.METHOD_ENTITY_ID]: yup.string(),
    [FIELDS.PAYROLL_FREQUENCY]: yupOptionalString(isD2C),
  });

const yupOptionalString = (required: boolean) => (required ? yup.string().required() : yup.string());

const getFormValues = (employer: EmployerOutput): Partial<EmployerOutput> => ({
  [FIELDS.NAME]: employer.name || "",
  [FIELDS.IDENTIFICATION_NUMBER]: employer.employerIdentificationNumber || "",
  [FIELDS.SUBSCRIPTION_PRICE_KEY]: employer.subscriptionPriceLookupKey || "",
  [FIELDS.METHOD_ENTITY_ID]: employer.methodEntityId || "",
  [FIELDS.PAYROLL_FREQUENCY]: employer.payrollFrequency || "",
});

export default GeneralInfoCard;
