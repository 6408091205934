import { Stack } from "@budgeinc/budge-ui-core";
import GeneralInfoCard from "./cards/GeneralInfoCard";
import MethodInfoCard from "./cards/MethodInfoCard";
import PlaidInfoCard from "./cards/PlaidInfoCard";

const AccountDetailTab = () => (
  <Stack spacing="md" mb={40}>
    <GeneralInfoCard />
    <MethodInfoCard />
    <PlaidInfoCard />
  </Stack>
);

export default AccountDetailTab;
