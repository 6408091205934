import { OnePaymentPlanOutput } from "@budgeinc/budge-api";
import { RouteHandle } from "common/routerConfig.types";
import EntityNotFound from "components/EntityNotFound";
import { LoaderFunctionArgs, useParams, useRouteLoaderData } from "react-router-dom";
import { onePayPlansApi } from "api/BudgeApi";

export type OnePayPlanRouteParams = {
  employerId: string;
  employeeId: string;
  OnePayPlanId: string;
};

export const EmployeeOnePayPlanRouteId = "EmployeeOnePayPlanRouteId";

export const loadEmployeeOnePayPlan = async ({ params }: LoaderFunctionArgs): Promise<OnePaymentPlanOutput> => {
  const routeParams = params as OnePayPlanRouteParams;
  return onePayPlansApi.getOnePaymentPlan(routeParams.employeeId).then(({ data }) => data);
};

export const EmployeeOnePayPlanErrorElement = () => {
  const params = useParams<OnePayPlanRouteParams>();

  return (
    <EntityNotFound
      title="OnePay Plan not found"
      description={`OnePay Plan ID ${params.OnePayPlanId} was not found in the database`}
    />
  );
};

export const employeeOnePayPlanHandles: RouteHandle = {
  crumb: (data: OnePaymentPlanOutput, pathname: string) => ({
    pathname,
    hash: "",
    search: "",
    meta: {
      title: data ? "OnePay Plan" : "Not Found",
    },
  }),
};

export const useOnePayPlanRouteLoaderData = () => useRouteLoaderData(EmployeeOnePayPlanRouteId) as OnePaymentPlanOutput;
