import { addDays, subDays } from "date-fns";
import { AppSecureStorage } from "@budgeinc/budge-ui-core";
import { TListFilterContextType } from "./context";

export const hasFilters = (filters: TListFilterContextType<any>) => Object.values(filters.state).some(value => !!value);

export const getFilterDateToMinDate = (startDate: Date): Date | undefined =>
  startDate ? addDays(startDate, 1) : undefined;

export const getFilterDateFromMaxDate = (endDate: Date): Date | undefined =>
  endDate ? subDays(endDate, 1) : undefined;

export const persistFilters = (persistId: string, filters: any) => {
  AppSecureStorage.save(persistId, JSON.stringify(filters));
};

export const getPersistedFilters = <T>(persistId: string | undefined): T | null => {
  const filters = persistId ? AppSecureStorage.get(persistId) : null;

  return filters ? (JSON.parse(filters) as T) : null;
};
