import { getPersistedFilters } from "components/ListFilterBar/utils";
import { LoginHistoryOutputTargetEnum, LoginSearchCriteriaInput } from "@budgeinc/budge-api";
import { splitAndRemoveWhiteSpace } from "utils/array";
import { paramAsList } from "utils/queryParams";
import { FilterFields, TFormValues } from "./FiltersBar";

export const getInitialFilters = (persistId: string, params: URLSearchParams): LoginSearchCriteriaInput => {
  const persistedFilters = getPersistedFilters<TFormValues>(persistId);
  const success = persistedFilters?.[FilterFields.SUCCESS];
  const ips = persistedFilters?.[FilterFields.IP] ? splitAndRemoveWhiteSpace(persistedFilters[FilterFields.IP]) : [];

  const targetsFilterParams = paramAsList(params?.get(FilterFields.TARGET)) as LoginHistoryOutputTargetEnum[];
  const dateFromFilterParams = params?.get(FilterFields.DATE_FROM);
  const dateToFilterParams = params?.get(FilterFields.DATE_TO);
  const successFilterParams = params?.get(FilterFields.SUCCESS);

  return {
    target: targetsFilterParams.length ? targetsFilterParams : persistedFilters?.[FilterFields.TARGET] || undefined,
    loginDateFrom: dateFromFilterParams || (persistedFilters?.[FilterFields.DATE_FROM] as any) || undefined,
    loginDateTo: dateToFilterParams || (persistedFilters?.[FilterFields.DATE_TO] as any) || undefined,
    genericSearchLike: undefined,
    success: successFilterParams !== null ? Boolean(successFilterParams) : success?.length ? success[0] : undefined,
    ipAddress: ips?.length ? ips : undefined,
  };
};
