import React from "react";
import { Box, Stack, Text } from "@budgeinc/budge-ui-core";

interface OwnProps {
  value: number | undefined;
}

const answers = [
  {
    title: "Very Disappointed",
    value: 1,
  },
  {
    title: "Somewhat Disappointed",
    value: 5,
  },
  {
    title: "Not disappointed",
    value: 10,
  },
];

const ProductMarketFitSurveyScale = ({ value }: OwnProps) => (
  <Stack.Horizontal>
    {answers.map(answer => {
      const isSelected = value === answer.value;

      return (
        <Box
          bw={1}
          bc={isSelected ? "primary" : "gray.3"}
          bg={isSelected ? "primary" : undefined}
          r={8}
          h={32}
          alignItems="center"
          justifyContent="center"
          px="sm"
        >
          <Text color={isSelected ? "white" : undefined}>{answer.title}</Text>
        </Box>
      );
    })}
  </Stack.Horizontal>
);

export default ProductMarketFitSurveyScale;
