import { Text, Stack, Box, Logo } from "@budgeinc/budge-ui-core";
import { PropsWithChildren } from "react";

interface OwnProps {
  title?: string;
}

const AuthLayout = ({ children, title }: PropsWithChildren<OwnProps>) => (
  <Box mih="100%" p={32} justifyContent="center" bg="primary.9" alignItems="center" sx={{ overflow: "hidden" }}>
    <Box
      maw={400}
      w="100%"
      px={32}
      py={40}
      sx={theme => ({
        backgroundColor: theme.white,
        borderRadius: theme.radius.md,
        shadowColor: theme.black,
        shadowOpacity: 0.4,
        shadowRadius: 40,
      })}
    >
      <Stack spacing={40}>
        <Stack alignItems="center" spacing="xl">
          <Logo width={125} />
          {title && (
            <Text variant="titleH4" fw="500">
              {title}
            </Text>
          )}
        </Stack>
        <Box>{children}</Box>
      </Stack>
    </Box>
  </Box>
);

export default AuthLayout;
