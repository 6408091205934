import React from "react";
import { TTabsProps, Tabs } from "@budgeinc/budge-ui-core";
import WhysTab from "./Whys";
import PainsTab from "./Pains";
import AssetsTab from "./Assets";
import DebtsTab from "./Debts";
import CashFlowTab from "./Cashflow";

export enum FINANCIAL_PROFILE_TAB_KEYS {
  WHYS = "#whys",
  PAINS = "#pains",
  ASSETS = "#assets",
  DEBTS = "#debts",
  CASHFLOW = "#cashflow",
}

const FinancialProfileTabs = (props: TTabsProps) => (
  <Tabs {...props}>
    <Tabs.Pane title="Whys" tabKey={FINANCIAL_PROFILE_TAB_KEYS.WHYS}>
      <WhysTab />
    </Tabs.Pane>
    <Tabs.Pane title="Pains" tabKey={FINANCIAL_PROFILE_TAB_KEYS.PAINS}>
      <PainsTab />
    </Tabs.Pane>
    <Tabs.Pane title="Assets" tabKey={FINANCIAL_PROFILE_TAB_KEYS.ASSETS}>
      <AssetsTab />
    </Tabs.Pane>
    <Tabs.Pane title="Debts" tabKey={FINANCIAL_PROFILE_TAB_KEYS.DEBTS}>
      <DebtsTab />
    </Tabs.Pane>
    <Tabs.Pane title="Cash Flow" tabKey={FINANCIAL_PROFILE_TAB_KEYS.CASHFLOW}>
      <CashFlowTab />
    </Tabs.Pane>
  </Tabs>
);

export default FinancialProfileTabs;
