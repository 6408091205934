import { OnePaymentPlanAccountOutput } from "@budgeinc/budge-api";
import { formatMoney, formatPercent } from "@budgeinc/budge-ui-utils";
import { TColumnProps, Text } from "@budgeinc/budge-ui-core";
import { Link } from "components/Link";
import { accountEntityRoute } from "common/routes";

export const getAccountColumns = (employerId: string): TColumnProps<OnePaymentPlanAccountOutput>[] => [
  {
    id: "financialAccount.name",
    title: "Name",
    render: record => (
      <Text numberOfLines={2}>
        <Link to={accountEntityRoute(employerId, record.financialAccount.employeeId, record.financialAccount.id)}>
          {record.financialAccount.name}
        </Link>
      </Text>
    ),
  },
  {
    id: "debtAmountCents",
    title: "Debt Amount",
    render: record => <Text>{formatMoney({ amountCents: record.financialAccount.balanceCurrentCents })}</Text>,
  },
  {
    id: "interestRate",
    title: "Interest Rate",
    render: record => <Text>{formatPercent({ value: record.financialAccount.interestRate })}</Text>,
  },
];
