import { EmployeeOutput } from "@budgeinc/budge-api";

export type TEmployeesState = {
  employees: EmployeeOutput[];
  isLoading: boolean;
};

export type TEmployeesAction =
  | { type: "loading" }
  | { type: "set"; data: EmployeeOutput[] }
  | { type: "updateEmployee"; data: EmployeeOutput };

export const initialState: TEmployeesState = {
  employees: [],
  isLoading: true,
};

export const employeesReducer = (state: TEmployeesState, action: TEmployeesAction): TEmployeesState => {
  switch (action.type) {
    case "set":
      return { ...state, isLoading: false, employees: action.data };
    case "loading":
      return { ...state, isLoading: true };
    case "updateEmployee":
      return {
        ...state,
        employees: state.employees.map(emp => (emp.id === action.data.id ? action.data : emp)),
      };
  }
};
