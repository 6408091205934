import { formatEmployeeName, Tabs } from "@budgeinc/budge-ui-core";
import ContentHeader from "components/Layout/Main/Header";
import { useLocation } from "react-router-dom";
import { AccountEntityContext } from "features/accounts/contexts/AccountEntityContext";
import AccountDetailTab from "features/accounts/tabs/AccountDetails";
import AccountHistoryTab from "features/accounts/tabs/AccountHistory";
import useAccountEntity from "features/accounts/hooks/useAccountEntity";

import { UserWhoamiOutputScopesEnum } from "@budgeinc/budge-api";
import withAccessScopeValidator from "components/AccessScopeValidator/withAccessScopeValidator";

export enum ACCOUNT_TAB_KEYS {
  DETAILS = "#details",
  HISTORY = "#history",
}

export const Component = withAccessScopeValidator(() => {
  const { hash } = useLocation();
  const { state, memoedContextValues } = useAccountEntity();

  return (
    <AccountEntityContext.Provider value={memoedContextValues}>
      <ContentHeader
        title={formatEmployeeName({
          employee: state.employee!,
        })}
        subTitle={state.account?.name}
      />
      <Tabs
        onChange={activeKey => {
          window.location.hash = activeKey;
        }}
        defaultActiveKey={hash || ACCOUNT_TAB_KEYS.DETAILS}
        activeKey={hash}
      >
        <Tabs.Pane title="Details" tabKey={ACCOUNT_TAB_KEYS.DETAILS} flex={false}>
          <AccountDetailTab />
        </Tabs.Pane>
        <Tabs.Pane title="Balance History" tabKey={ACCOUNT_TAB_KEYS.HISTORY}>
          <AccountHistoryTab />
        </Tabs.Pane>
      </Tabs>
    </AccountEntityContext.Provider>
  );
}, [
  {
    OR: [
      UserWhoamiOutputScopesEnum.Admin,
      UserWhoamiOutputScopesEnum.AdminRead,
      UserWhoamiOutputScopesEnum.ClientsFinancials,
    ],
  },
]);
