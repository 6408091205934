import { SentimentPollWithEmployeeOutput } from "@budgeinc/budge-api";
import { EMPTY_VALUE_PLACEHOLDER, TColumnProps, Text, Tag, formatEmployeeName } from "@budgeinc/budge-ui-core";
import { employeeEntityRoute } from "common/routes";
import DateDisplay from "components/DateDisplay";
import { Link } from "components/Link";

export const getSentimentPollColumns = (): TColumnProps<SentimentPollWithEmployeeOutput>[] => [
  {
    id: "name",
    title: "Name",
    maxWidth: 250,
    render: ({ employee }) => (
      <Text numberOfLines={1}>
        {employee ? (
          <Link to={employeeEntityRoute(employee.employerId, employee.id)} onClick={e => e.stopPropagation()}>
            {formatEmployeeName({ employee })}
          </Link>
        ) : (
          EMPTY_VALUE_PLACEHOLDER
        )}
      </Text>
    ),
  },
  {
    id: "type",
    title: "Type",
    maxWidth: 225,
    render: ({ type }) => <Tag color="dark" value={type} />,
  },
  {
    id: "question",
    title: "Question",
    render: ({ question }) => <Text numberOfLines={2}>{question}</Text>,
  },
  {
    id: "createdat",
    title: "Created at",
    render: ({ createdAt }) => <DateDisplay numberOfLines={1} value={createdAt} showTime />,
  },
];
