import {
  AppMessageOutput,
  EmployerOutput,
  KpiCategoryOutput,
  KpiSeriesOutput,
  KpiTypeCategoryOutput,
} from "@budgeinc/budge-api";
import { createBudgeApiAsyncThunk } from "@budgeinc/budge-ui-core";

import { backofficeApi, employersApi } from "api/BudgeApi";
import { RootState } from "store/types";

const fetchAppMessage = createBudgeApiAsyncThunk<AppMessageOutput, void, RootState>(
  "global/fetchAppMessage",
  async () => {
    const response = await backofficeApi.getAppMessages();
    return {
      messages: response.data.messages,
    };
  }
);

const fetchD2CEmployer = createBudgeApiAsyncThunk<EmployerOutput, void, RootState>(
  "global/fetchD2CEmployer",
  async () => {
    const response = await employersApi.getD2CEmployer();
    return response.data;
  }
);

const fetchKpis = createBudgeApiAsyncThunk<
  {
    kpis: KpiSeriesOutput[];
    categories: KpiCategoryOutput[];
    categoriesTypes: KpiTypeCategoryOutput[];
  },
  void,
  RootState
>(
  "global/fetchAllKpis",
  async () => {
    const response = await backofficeApi.getAllKPIs();
    const kpiCategoriesResponse = await backofficeApi.getAllKpiCategories();
    const kpiTypeCategoriesResponse = await backofficeApi.getAllKpiTypeCategories();
    return {
      kpis: response.data,
      categories: kpiCategoriesResponse.data,
      categoriesTypes: kpiTypeCategoriesResponse.data,
    };
  },
  {
    condition: (args, api) => {
      const currentKpis = api.getState().global.kpis.data;
      return currentKpis.length === 0;
    },
  }
);

export { fetchAppMessage, fetchKpis, fetchD2CEmployer };
