import { directPaymentsCrossTenantApi, employersApi } from "api/BudgeApi";
import { useCallback, useEffect, useMemo, useReducer } from "react";
import { useInfiniteScrollList } from "@budgeinc/budge-ui-core";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";
import {
  DirectPaymentFilterContext,
  DirectPaymentFilterReducer,
} from "components/Table/DirectPaymentsTable/filters/context";
import FiltersBar from "components/Table/DirectPaymentsTable/filters/FiltersBar";
import DirectPaymentsTable from "components/Table/DirectPaymentsTable";
import { getInitialFilters } from "components/Table/DirectPaymentsTable/filters/utils";
import { TListFilterContextType } from "components/ListFilterBar/context";
import { useSearchParams } from "react-router-dom";

const EmployeeDirectPaymentsFilterId = "employee-direct-payments-filters";

const EmployeeDirectPaymentsListTab = () => {
  const {
    state: { employee },
    employerId,
  } = useEmployeeEntityContext();
  const [params] = useSearchParams();
  const initialFilters = getInitialFilters(EmployeeDirectPaymentsFilterId, params);
  const [filters, dispatch] = useReducer(DirectPaymentFilterReducer, initialFilters);

  const handleOnFetch = useCallback(
    ({ page, pageSize }: { page: number; pageSize: number }) =>
      directPaymentsCrossTenantApi
        .searchEmployeeDirectPayments(
          employerId!,
          employee?.id!,
          filters,
          `${pageSize * (page - 1)}`,
          pageSize.toString()
        )
        .then(({ data }) => ({ results: data })),
    [JSON.stringify(filters)]
  );

  const { requestState, onEndReached, forceRefresh } = useInfiniteScrollList({
    onFetch: handleOnFetch,
    skipFirstForceRefresh: true,
  });

  useEffect(() => {
    forceRefresh();
  }, [JSON.stringify(filters)]);

  const memoedFilterContextValues = useMemo<TListFilterContextType>(
    () => ({
      state: filters,
      dispatch,
      initialFilters,
    }),
    [JSON.stringify(filters), dispatch]
  );

  return (
    <DirectPaymentFilterContext.Provider value={memoedFilterContextValues}>
      <FiltersBar persistId={EmployeeDirectPaymentsFilterId} />
      <DirectPaymentsTable keyExtractor={item => item.id} onEndReached={onEndReached} requestState={requestState} />
    </DirectPaymentFilterContext.Provider>
  );
};

export default EmployeeDirectPaymentsListTab;
