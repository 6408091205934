import { EmployeeInput, EmployeeOutput, ErrorResponse, UserWhoamiOutputScopesEnum } from "@budgeinc/budge-api";
import { BuildValidationSchema, yup } from "@budgeinc/budge-ui-utils";
import {
  EditSaveToggleButtons,
  FormItem,
  FormProvider,
  getApiErrorMessage,
  Grid,
  Input,
  Stack,
  Text,
  toast,
  AccessScopeValidator,
  TAccessOperation,
  Collapse,
} from "@budgeinc/budge-ui-core";
import { useState } from "react";
import { useFormik } from "formik";
import { employeesCrossTenantApi, employersApi } from "api/BudgeApi";
import { AxiosError } from "axios";
import { useAppMessages } from "store/global";
import EmployeeSearchSelect from "components/Select/EmployeeSearchSelect";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";

enum FIELDS {
  REFERRAL_CODE = "referralCode",
  REFERRER = "referrer",
  REFERRAL_CODE_USED = "referralCodeUsedOnSignup",
}

type TFormValues = Record<FIELDS, any>;

const validationSchema = BuildValidationSchema({
  [FIELDS.REFERRER]: yup.string(),
  [FIELDS.REFERRAL_CODE_USED]: yup.string(),
});

const ReferralProgramCard = () => {
  const messages = useAppMessages();
  const [editModeOn, setEditMode] = useState(false);
  const { state, dispatch, employerId } = useEmployeeEntityContext();
  const [formError, setFormError] = useState<string | undefined>();
  const [validSchema, setValidationSchema] = useState<any>(validationSchema);

  const activeEmployee = state.employee!;

  const form = useFormik({
    initialValues: getFormInitialValues(state.employee!),
    enableReinitialize: true,
    validationSchema: validSchema,
    onSubmit: async values =>
      employeesCrossTenantApi
        .updateEmployeeForEmployer(employerId!, activeEmployee.id, getEmployeePayload(values))
        .then(resp => {
          dispatch({
            type: "set",
            data: resp.data,
          });
          setEditMode(false);
          setFormError(undefined);
          toast.success({
            message: "Customer saved successfully",
          });
        })
        .catch((error: AxiosError<ErrorResponse>) => {
          setValidationSchema(validationSchema);
          setFormError(getApiErrorMessage(messages, error.response?.data));
        }),
  });

  return (
    <Collapse
      trigger={
        <Text fw="500" variant="bodyMedium">
          Referral Program
        </Text>
      }
    >
      <FormProvider value={form} formErrorMsg={formError}>
        <Stack spacing="md">
          <Grid column={3} gutter="md">
            <FormItem name={FIELDS.REFERRAL_CODE}>
              <Input label="Referral Code" disabled />
            </FormItem>
            <FormItem name={FIELDS.REFERRAL_CODE_USED}>
              <Input
                label="Referral Code Used On Signup"
                disabled={activeEmployee.referrerEmployeeId ? true : !editModeOn}
              />
            </FormItem>
            <FormItem name={FIELDS.REFERRER}>
              <EmployeeSearchSelect
                label="Referrer"
                employerId={employerId!}
                employeeId={state.employee?.id}
                disabled={!editModeOn}
              />
            </FormItem>
          </Grid>
          <AccessScopeValidator op={TAccessOperation.WRITE} rule={[UserWhoamiOutputScopesEnum.Clients]}>
            <EditSaveToggleButtons
              isEditModeEnabled={editModeOn}
              setEditMode={setEditMode}
              onSubmit={form.handleSubmit}
              saveBtnDisabled={!form.dirty}
              onCancel={() => {
                form.setValues(form.initialValues, true);
                setFormError(undefined);
              }}
              loading={form.isSubmitting}
            />
          </AccessScopeValidator>
        </Stack>
      </FormProvider>
    </Collapse>
  );
};

const getFormInitialValues = (employee: EmployeeOutput): TFormValues => ({
  [FIELDS.REFERRER]: employee.referrerEmployeeId || undefined,
  [FIELDS.REFERRAL_CODE_USED]: employee.referralCodeUsedOnSignup || undefined,
  [FIELDS.REFERRAL_CODE]: employee.referralCode,
});

const getEmployeePayload = (values: TFormValues): EmployeeInput => ({
  referrerEmployeeId: values.referrer || null,
  referralCodeUsedOnSignup: values.referralCodeUsedOnSignup || undefined,
});

export default ReferralProgramCard;
