import React, { useEffect, useState } from "react";
import { IntakeProfileOutput } from "@budgeinc/budge-api";
import { intakeApi } from "api/BudgeApi";
import { Select, TSelectProps } from "@budgeinc/budge-ui-core";

const FinancialProfileSelect = (props: Omit<TSelectProps, "options" | "label">) => {
  const [unassignedProfile, setUnassignedProfiles] = useState<IntakeProfileOutput[]>([]);

  useEffect(() => {
    intakeApi.getAllUnasignedIntakeProfiles().then(({ data }) => setUnassignedProfiles(data));
  }, []);

  return (
    <Select
      label="Select Financial Profile"
      options={unassignedProfile.map(profile => ({
        label: profile.description || profile.id,
        value: profile.id,
      }))}
      enableSearch
      {...props}
    />
  );
};

export default FinancialProfileSelect;
