import React from "react";
import { ActionIcon, Button, ExternalLinkIcon, TSize, Tooltip } from "@budgeinc/budge-ui-core";
import { plaidPrimaryColor } from "./constants";

type OwnProps = {
  plaidItemId: string;
  size?: TSize;
} & (
  | {
      buttonType: "actionIcon";
      title?: never;
    }
  | {
      buttonType: "actionIcon" | "normal";
      title?: string;
    }
);

const PlaidExternalButton = ({ title = "View in Plaid", buttonType, plaidItemId, size = "xs" }: OwnProps) => (
  <a
    href={`${process.env.REACT_APP_PLAID_DEBUGGER_URL}/${plaidItemId}`}
    target="_blank"
    onClick={e => e.stopPropagation()}
    rel="noreferrer"
  >
    {buttonType === "actionIcon" ? (
      <Tooltip content="View in Plaid">
        <ActionIcon
          icon={ExternalLinkIcon}
          r="xl"
          variant="gradient"
          iconColor="white"
          color={plaidPrimaryColor}
          size={size}
        />
      </Tooltip>
    ) : (
      <Button
        title={title}
        size={size}
        variant="gradient"
        color={plaidPrimaryColor}
        titleTextProps={{
          color: "white",
        }}
        rightIcon={ExternalLinkIcon}
      />
    )}
  </a>
);

export default PlaidExternalButton;
