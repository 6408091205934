import { createListFilterReducer, TListFilterState } from "components/ListFilterBar/reducer";
import { createListFilterContext } from "components/ListFilterBar/context";
import { useContext } from "react";
import { FinancialAccountBalanceSearchCriteriaInput } from "@budgeinc/budge-api";

export const initialState: TListFilterState<FinancialAccountBalanceSearchCriteriaInput> = {
  balanceSource: undefined,
  asOfDateFrom: undefined,
  asOfDateTo: undefined,
};

export const AccountHistoryFilterReducer =
  createListFilterReducer<FinancialAccountBalanceSearchCriteriaInput>(initialState);
export const AccountHistoryFilterContext =
  createListFilterContext<FinancialAccountBalanceSearchCriteriaInput>(initialState);

export const useAccountHistoryFilterContext = () => {
  const context = useContext(AccountHistoryFilterContext);

  if (!context) {
    throw new Error("AccountHistoryFilterContext must be used inside an AccountHistoryFilterContext.Provider");
  }

  return context;
};
