import {
  BuildValidationSchema,
  FormItem,
  FormProvider,
  getApiErrorMessage,
  Modal,
  MultiSelect,
  Stack,
  Text,
  yup,
} from "@budgeinc/budge-ui-core";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { AuthProfileOutput, ErrorResponse } from "@budgeinc/budge-api";
import { authzApi } from "api/BudgeApi";
import { AxiosError } from "axios";
import { useAppMessages } from "store/global";
import { formatEnumValue } from "utils/format";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";
import { useAuthProfileUserDataTableContext } from "components/Table/AuthProfileUserTable/AuthProfileUserDataTableContext";

interface OwnProps {
  isOpen: boolean;
  onClose: () => void;
  onClosed?: () => void;
  currentAuthProfileIds: string[];
}

enum FIELDS {
  PROFILE_ID = "profileId",
}

const validationSchema = BuildValidationSchema({
  [FIELDS.PROFILE_ID]: yup.array().of(yup.string()).required(),
});

const AddProfileModal = ({ isOpen, onClose, onClosed, currentAuthProfileIds }: OwnProps) => {
  const messages = useAppMessages();
  const employee = useEmployeeEntityContext();
  const [formError, setFormError] = useState<string>();
  const { addManyRecords } = useAuthProfileUserDataTableContext();
  const [authProfiles, setAuthProfiles] = useState<AuthProfileOutput[]>([]);

  useEffect(() => {
    if (isOpen) {
      authzApi.getAllAuthProfiles().then(({ data }) => setAuthProfiles(data));
    }
  }, [isOpen]);

  const form = useFormik({
    initialValues: {
      [FIELDS.PROFILE_ID]: [],
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async values =>
      Promise.all(
        values[FIELDS.PROFILE_ID].map(async authProfileId =>
          authzApi.createAuthProfileUser(authProfileId, employee.state.user?.id!)
        )
      )
        .then(res => {
          onClose();
          addManyRecords?.(res.map(({ data }) => data));
        })
        .catch((e: AxiosError<ErrorResponse>) => setFormError(getApiErrorMessage(messages, e.response?.data))),
  });

  const handleOnClosed = () => {
    form.resetForm();
    setFormError(undefined);
    if (onClosed) {
      onClosed();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onClosed={handleOnClosed}
      header={{
        title: "Add Profiles",
      }}
      footer={{
        okButtonProps: {
          title: "Add",
          loading: form.isSubmitting,
          disabled: !form.dirty,
          onPress: form.submitForm,
        },
      }}
    >
      <FormProvider value={form} formErrorMsg={formError} px="xl">
        <FormItem name={FIELDS.PROFILE_ID}>
          <MultiSelect
            label="Select profiles"
            options={authProfiles
              .filter(scope => !currentAuthProfileIds.includes(scope.id))
              .map(scope => ({
                label: formatEnumValue(scope.name),
                value: scope.id,
                customOptionLabel: (
                  <Stack spacing={0} f={1}>
                    <Text>{formatEnumValue(scope.name)}</Text>
                    <Text variant="bodySmall" color="secondary">
                      {scope.description}
                    </Text>
                  </Stack>
                ),
              }))}
          />
        </FormItem>
      </FormProvider>
    </Modal>
  );
};

export default AddProfileModal;
