import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  stateIcon: {
    marginBottom: 32,
  },
  forgotPasswordBtn: {
    textAlign: "center",
    marginTop: 30,
  },
  forgotPasswordBtnText: {
    fontSize: 18,
    fontWeight: "bold",
  },
  notice: {
    marginBottom: 16,
    textAlign: "center",
  },
  email: {
    whiteSpace: "nowrap",
  } as any,
});

export default styles;
