import React, { useState } from "react";
import { Button, modalManager } from "@budgeinc/budge-ui-core";
import { intakeApi } from "api/BudgeApi";
import useHandleApiError from "hooks/useHandleApiError";
import { useFinancialProfileEntityContext } from "features/financial-profiles/entity/contexts/FinancialProfileEntityContext";
import { useCashFlowsContext } from "../context";

const AutoFeedButton = () => {
  const { handleError } = useHandleApiError();
  const [loading, setLoading] = useState(false);
  const { state, dispatch } = useCashFlowsContext();
  const { state: fpState, dispatch: fpDispatch } = useFinancialProfileEntityContext();

  const handleOnPress = async () => {
    if (fpState.financialProfile?.hasFeededCashFlow && state.localDataPoint.length > 0) {
      modalManager.openConfirm({
        confirmProps: { title: "Proceed" },
        onConfirm: handleFeed,
        children: "Any changes made will be overwritten!",
      });
    } else {
      setLoading(true);
      handleFeed();
      setLoading(false);
    }
  };

  const handleFeed = async () => {
    if (fpState.financialProfile) {
      return handleError(
        intakeApi
          .createEmployeeIntakeCashFlowDataPointsFromLinkedAccounts(fpState.financialProfile.id)
          .then(({ data }) => {
            dispatch({
              type: "setLocalDataPoints",
              data,
            });

            fpDispatch({
              type: "setFinancialProfile",
              data: {
                ...fpState.financialProfile!,
                hasFeededCashFlow: true,
              },
            });
          })
      );
    }
  };

  return <Button size="sm" title="Feed From Linked Accounts" onPress={handleOnPress} loading={loading} />;
};

export default AutoFeedButton;
