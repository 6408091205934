import { TMethodEntityDetailType, TMethodEntitySyncType } from "./utils";

export const methodPrimaryColor = "#00ecac";

export const methodEntityDetailSyncTypeMap: Record<TMethodEntityDetailType, TMethodEntitySyncType | null> = {
  entityRaw: "entitySync",
  accountRaw: "accountSync",
  paymentRaw: "paymentSync",
  accountVerificationRaw: null,
  merchantRaw: null,
  webhooksRaw: null,
};
