import { createListFilterReducer, TListFilterState } from "components/ListFilterBar/reducer";
import { createListFilterContext } from "components/ListFilterBar/context";
import { DirectPaymentSearchCriteriaInput } from "@budgeinc/budge-api";
import { useContext } from "react";

export const initialState: TListFilterState<DirectPaymentSearchCriteriaInput> = {
  status: undefined,
  processedDateFrom: undefined,
  processedDateTo: undefined,
};

export const DirectPaymentFilterReducer = createListFilterReducer<DirectPaymentSearchCriteriaInput>(initialState);
export const DirectPaymentFilterContext = createListFilterContext<DirectPaymentSearchCriteriaInput>(initialState);

export const useDirectPaymentFilterContext = () => {
  const context = useContext(DirectPaymentFilterContext);

  if (!context) {
    throw new Error("DirectPaymentFilterContext must be used inside an DirectPaymentFilterContext.Provider");
  }

  return context;
};
