import {
  Description,
  Divider,
  GoalIconMapping,
  Pressable,
  Stack,
  Tooltip,
  InfiniteScrollTable,
  useInfiniteScrollList,
} from "@budgeinc/budge-ui-core";
import { ProgramWithGoalsOutput } from "@budgeinc/budge-api";
import { programsCrossTenantApi } from "api/BudgeApi";
import { useEmployerContext } from "features/employers/contexts/EmployerContext";
import DateDisplay from "components/DateDisplay";
import { getProgramsColumns } from "./columns";

const ProgramsListTab = () => {
  const {
    state: { employer },
  } = useEmployerContext();

  const { requestState, onEndReached } = useInfiniteScrollList({
    onFetch: ({ page, pageSize }) =>
      programsCrossTenantApi
        .getProgramsForEmployer(employer?.id!, `${pageSize * (page - 1)}`, pageSize.toString())
        .then(({ data }) => ({ results: data })),
  });

  return (
    <InfiniteScrollTable<ProgramWithGoalsOutput>
      keyExtractor={item => item.program.id}
      requestState={requestState}
      onEndReached={onEndReached}
      columns={getProgramsColumns()}
      local={{
        empty: {
          m: 24,
          title: "No programs found",
        },
        noMoreItems: "No more programs to load",
      }}
      contentContainerStyle={{ marginBottom: 16 }}
      expandable={record => (
        <>
          <Description>
            <Description.Item label="ID">{record.program.id}</Description.Item>
            <Description.Item label="Type">{record.program.type}</Description.Item>
            <Description.Item label="Frequency">{record.program.frequency}</Description.Item>
          </Description>
          <Divider />
          <Description>
            <Description.Item alignItems="center" label="Associated Goals">
              <Stack.Horizontal spacing="xs">
                {record.goals.map(goal => {
                  const Icon = GoalIconMapping[goal.type];
                  return (
                    <Tooltip key={goal.id} content={goal.name}>
                      <Pressable noCursor>
                        <Icon size="xs" />
                      </Pressable>
                    </Tooltip>
                  );
                })}
              </Stack.Horizontal>
            </Description.Item>
          </Description>
          <Divider />
          <Description>
            <Description.Item label="Update At">
              <DateDisplay numberOfLines={1} value={record.program.createdAt} showTime />
            </Description.Item>
            <Description.Item label="Created At">
              <DateDisplay numberOfLines={1} value={record.program.createdAt} showTime />
            </Description.Item>
          </Description>
        </>
      )}
    />
  );
};

export default ProgramsListTab;
