import { ProgramWithGoalsOutput } from "@budgeinc/budge-api";
import { formatMoney, intlFormatDate } from "@budgeinc/budge-ui-utils";
import { EMPTY_VALUE_PLACEHOLDER, TColumnProps, Text, SuccessIcon, Tag } from "@budgeinc/budge-ui-core";

import ActionCell from "./components/ActionCell";

export const getProgramsColumns = (): TColumnProps<ProgramWithGoalsOutput>[] => [
  {
    id: "name",
    title: "Name",
    render: ({ program }) => <Text numberOfLines={2}>{program.name}</Text>,
  },
  {
    id: "status",
    title: "Status",
    maxWidth: 250,
    render: record => {
      const isLive = record.program.status === "ENABLED";

      return (
        <Tag
          value={isLive ? "Live" : "Terminated"}
          color={isLive ? "green" : "red"}
          withIcon={isLive}
          icon={SuccessIcon}
        />
      );
    },
  },
  {
    id: "amount",
    title: "Amount",
    maxWidth: 250,
    render: ({ program }) => (
      <Text>
        {program.type === "EMPLOYEE_PER_PAYCHECK_CONTRIBUTION"
          ? "N/A"
          : formatMoney({
              amountCents: program.amountCents,
              minFractionDigits: 0,
            })}
      </Text>
    ),
  },
  {
    id: "startat",
    title: "Start at",
    maxWidth: 250,
    render: ({ program }) => (
      <Text numberOfLines={1}>
        {intlFormatDate({
          value: program.startDate,
          month: "short",
          day: "numeric",
          year: "numeric",
        })}
      </Text>
    ),
  },
  {
    id: "endat",
    title: "End at",
    maxWidth: 250,
    render: ({ program }) => (
      <Text numberOfLines={1}>
        {program.endDate
          ? intlFormatDate({
              value: program.endDate,
              month: "short",
              day: "numeric",
              year: "numeric",
            })
          : EMPTY_VALUE_PLACEHOLDER}
      </Text>
    ),
  },
  {
    title: "",
    id: "action",
    align: "flex-end",
    maxWidth: 250,
    render: record => <ActionCell program={record.program} />,
  },
];
