import { capitalize } from "@budgeinc/budge-ui-utils";
import { Grid, Input, Text, Collapse } from "@budgeinc/budge-ui-core";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";

const PayrollInfoCard = () => {
  const { state } = useEmployeeEntityContext();

  return (
    <Collapse
      trigger={
        <Text fw="500" variant="bodyMedium">
          Payroll
        </Text>
      }
    >
      <Grid column={3} gutter="md">
        <Input label="Payroll Employee ID" disabled value={state.employee?.payrollEmployeeId} />
        <Input
          label="Net Pay Before Deductions Cents"
          disabled
          value={state.employee?.netPayBeforeDeductionsCents?.toString()}
        />
        <Input label="Skip Payroll Sync" disabled value={capitalize(JSON.stringify(state.employee?.skipPayrollSync))} />
      </Grid>
    </Collapse>
  );
};

export default PayrollInfoCard;
