import { ProgramEnrollmentOutput, ProgramEnrollmentOutputStatusEnum } from "@budgeinc/budge-api";
import { capitalize, formatMoney, intlFormatDate } from "@budgeinc/budge-ui-utils";
import {
  formatEmployeeName,
  TColumnProps,
  Text,
  Tag,
  getDayOfMonthDisplay,
  isForPayDay,
  Stack,
  EMPTY_VALUE_PLACEHOLDER,
  forceLocalDate,
  i18n,
  getNextEffectivePayDay,
  getNextEffectivePayDayForDayOfMonth,
  getPaydayFrequencyDisplay,
} from "@budgeinc/budge-ui-core";
import { accountEntityRoute, employeeEntityRoute } from "common/routes";
import { Link } from "components/Link";
import { formatEnumValue } from "utils/format";
import ActionCell from "./components/ActionCell";

export const getProgramEnrollmentsColumns = ({
  showEmployee = false,
  employerId,
}: {
  showEmployee: boolean;
  employerId: string;
}): TColumnProps<ProgramEnrollmentOutput>[] => {
  const columns: TColumnProps<ProgramEnrollmentOutput>[] = [
    {
      id: "account",
      title: "Account",
      render: record => (
        <Text numberOfLines={2}>
          <Link to={accountEntityRoute(employerId, record.financialAccount.employeeId, record.financialAccount.id)}>
            {record.financialAccount.name}
          </Link>
        </Text>
      ),
    },
    {
      id: "source",
      title: "Source",
      maxWidth: 150,
      render: ({ source }) => (
        <Tag
          value={i18n.t(`enums.enrollmentSource.${source}`, {
            defaultValue: formatEnumValue(source),
          })}
        />
      ),
    },
    {
      id: "type",
      title: "Type",
      maxWidth: 150,
      render: ({ type }) => (
        <Tag
          value={i18n.t(`enums.enrollmentType.${type}`, {
            defaultValue: formatEnumValue(type),
          })}
        />
      ),
    },
    {
      id: "status",
      title: "Status",
      render: ({ status }) => <Tag value={status} />,
    },
    {
      id: "amount",
      title: "Amount",
      render: record => (
        <Text>
          {formatMoney({
            amountCents: record.amountCents > 0 ? record.amountCents : record.amountCentsPreDisable,
            minFractionDigits: 0,
          })}
        </Text>
      ),
    },
    {
      id: "paymentdayofmonth",
      title: "Payment Date",
      width: 250,
      render: ({ paymentDayOfMonth, employee, noTransactionBeforeDate, status, paymentDate }) =>
        status === ProgramEnrollmentOutputStatusEnum.Enabled ? (
          <Stack spacing={0} w="100%">
            {paymentDate ? (
              <Text>
                {intlFormatDate({
                  value: forceLocalDate(paymentDate),
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                  weekday: "short",
                  useUserTimezone: true,
                })}
              </Text>
            ) : isForPayDay(paymentDayOfMonth) && employee.payrollSettings ? (
              <Stack spacing={0}>
                <Text numberOfLines={1}>
                  {capitalize(
                    getPaydayFrequencyDisplay(employee.payrollSettings, {
                      showStartingOn: false,
                    })
                  )}
                </Text>
                <Text variant="bodySmall" color="secondary" numberOfLines={1}>
                  Next:{" "}
                  {intlFormatDate({
                    value: noTransactionBeforeDate
                      ? forceLocalDate(noTransactionBeforeDate)
                      : getNextEffectivePayDay(employee.payrollSettings),
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                    weekday: "short",
                    useUserTimezone: true,
                  })}
                </Text>
              </Stack>
            ) : (
              <Stack spacing={0}>
                <Text numberOfLines={1}>
                  {paymentDayOfMonth
                    ? `${getDayOfMonthDisplay(paymentDayOfMonth)} of the month`
                    : EMPTY_VALUE_PLACEHOLDER}
                </Text>
                {paymentDayOfMonth ? (
                  <Text variant="bodySmall" color="secondary" numberOfLines={1}>
                    Next:{" "}
                    {intlFormatDate({
                      value: noTransactionBeforeDate
                        ? forceLocalDate(noTransactionBeforeDate)
                        : getNextEffectivePayDayForDayOfMonth(paymentDayOfMonth),
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                      weekday: "short",
                      useUserTimezone: true,
                    })}
                  </Text>
                ) : (
                  EMPTY_VALUE_PLACEHOLDER
                )}
              </Stack>
            )}
          </Stack>
        ) : (
          <Text>{EMPTY_VALUE_PLACEHOLDER}</Text>
        ),
    },
  ];

  if (showEmployee) {
    columns.splice(1, 0, {
      id: "clientemployee",
      title: "Customer",
      render: ({ employee }) => (
        <Text numberOfLines={1}>
          <Link to={employeeEntityRoute(employerId, employee.id)} onClick={e => e.stopPropagation()}>
            {formatEmployeeName({ employee })}
          </Link>
        </Text>
      ),
    });
  }

  columns.push({
    id: "action",
    title: "",
    render: record =>
      record.status === ProgramEnrollmentOutputStatusEnum.Enabled ? <ActionCell record={record} /> : null,
  });

  return columns;
};
