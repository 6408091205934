import React, { useContext } from "react";
import { createListFilterReducer, TListFilterState } from "components/ListFilterBar/reducer";
import { createListFilterContext } from "components/ListFilterBar/context";
import { EmployeeSearchCriteriaInput } from "@budgeinc/budge-api";
import { initialState, TEmployeesAction, TEmployeesState } from "./reducer";

interface EmployeeSearchContextType {
  dispatch: React.Dispatch<TEmployeesAction>;
  state: TEmployeesState;
}

export const EmployeeSearchContext = React.createContext<EmployeeSearchContextType>({
  dispatch: () => {},
  state: initialState,
});

export const initialFilterState: TListFilterState<EmployeeSearchCriteriaInput> = {
  isMissingEmployerOnboardingData: undefined,
  genericSearchLike: undefined,
  isExcluded: undefined,
};

export const EmployeeFilterReducer = createListFilterReducer<EmployeeSearchCriteriaInput>(initialFilterState);
export const EmployeeFilterContext = createListFilterContext<EmployeeSearchCriteriaInput>(initialFilterState);

export const useEmployeeFilterContext = () => {
  const context = useContext(EmployeeFilterContext);

  if (!context) {
    throw new Error("EmployeeFilterContext must be used inside an EmployeeFilterContext.Provider");
  }

  return context;
};
