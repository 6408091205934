import { DebtPaymentPlanOutput } from "@budgeinc/budge-api";
import { Button, Pressable } from "@budgeinc/budge-ui-core";
import { Link } from "components/Link";

import { debtPlanEntityRoute } from "common/routes";
import { useEmployerContext } from "features/employers/contexts/EmployerContext";

interface OwnProps {
  debtPlan: DebtPaymentPlanOutput;
}

const ActionCell = ({ debtPlan }: OwnProps) => {
  const {
    state: { employer },
  } = useEmployerContext();

  return (
    <Pressable withPressEffect>
      <Link asChild to={debtPlanEntityRoute(employer?.id!, debtPlan.employeeId, debtPlan.id)}>
        <Button title="View" size="xs" color="primary" variant="gradient" />
      </Link>
    </Pressable>
  );
};

export default ActionCell;
