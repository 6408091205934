import { getPersistedFilters } from "components/ListFilterBar/utils";
import { CreditScoreSearchCriteriaInput } from "@budgeinc/budge-api";
import { FilterFields, getBooleanDataFilterValue, TFormValues } from "./FiltersBar";

export const getInitialFilters = (persistId: string): CreditScoreSearchCriteriaInput => {
  const filters = getPersistedFilters<TFormValues>(persistId);
  const isLatest = filters?.[FilterFields.IS_LATEST];
  const isPrevious = filters?.[FilterFields.IS_PREVIOUS];

  return {
    isLatest: isLatest ? getBooleanDataFilterValue(isLatest) : undefined,
    isPrevious: isPrevious ? getBooleanDataFilterValue(isPrevious) : undefined,
    creditScoreHighValue: filters?.[FilterFields.SCORE_HIGH] || undefined,
    creditScoreLowValue: filters?.[FilterFields.SCORE_LOW] || undefined,
    status: filters?.[FilterFields.STATUS] || undefined,
    rating: filters?.[FilterFields.RATING] || undefined,
    source: filters?.[FilterFields.SOURCE] || undefined,
    model: filters?.[FilterFields.MODEL] || undefined,
    genericSearchLike: undefined,
  };
};
