import { IntakeWhyInput, IntakeWhyItemInput, IntakeWhyOutput } from "@budgeinc/budge-api";
import { IntakeWhyFormItemKey, IntakeWhyFormItemType, IntakeWhyFormValue } from "./types";

export const getIntakeWhyFormItemName = (id: string, type: IntakeWhyFormItemType): IntakeWhyFormItemKey =>
  `${id}-${type}`;

export const getFormInitialValue = (intakeWhys: IntakeWhyOutput[]): IntakeWhyFormValue => {
  const initialValues: IntakeWhyFormValue = {};

  intakeWhys.forEach(iw => {
    initialValues[getIntakeWhyFormItemName(iw.intakeWhyElementId, IntakeWhyFormItemType.COMMENT)] = iw.comment || "";
    initialValues[getIntakeWhyFormItemName(iw.intakeWhyElementId, IntakeWhyFormItemType.VALUE)] = iw.value || "";
  });

  return initialValues;
};

export const getApiPayload = (value: IntakeWhyFormValue, initialIntakeWhy: IntakeWhyOutput[]): IntakeWhyInput => {
  const items: IntakeWhyItemInput[] = [];

  initialIntakeWhy.forEach(iw => {
    const comment = value[`${iw.intakeWhyElementId}-${IntakeWhyFormItemType.COMMENT}`];
    const response = value[`${iw.intakeWhyElementId}-${IntakeWhyFormItemType.VALUE}`];

    items.push({
      intakeWhyElementId: iw.intakeWhyElementId,
      comment,
      value: response,
    });
  });

  return { items };
};
