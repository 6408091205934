import { Navigate, createBrowserRouter } from "react-router-dom";
import PrivateRoute from "PrivateRoute";
import { ROUTES } from "common/routes";
import MainLayout from "components/Layout/Main";
import ResetPasswordPage from "pages/Auth/Password";
import SigninPage from "pages/Auth/Signin";
import NotFound from "pages/NotFound";
import {
  loadEmployer,
  EmployerErrorElement,
  employerHandles,
  EmployerIndexRouteId,
} from "features/employers/routeConfig";
import {
  EmployeeErrorElement,
  EmployeeIndexRouteId,
  employeeHandles,
  loadEmployee,
} from "features/employees/routeConfig";
import {
  EmployeeAccountErrorElement,
  EmployeeAccountRouteId,
  employeeAccountHandles,
  loadEmployeeAccount,
} from "features/accounts/routeConfig";
import {
  FinancialProfileEntityRouteId,
  FinancialProfileErrorElement,
  financialProfileHandles,
  loadFinancialProfile,
} from "features/financial-profiles/entity/routeConfig";
import { ProgramErrorElement, programHandles, loadProgram, ProgramEntityRouteId } from "features/programs/routeConfig";
import {
  EmployeeDirectPaymentErrorElement,
  EmployeeDirectPaymentRouteId,
  employeeDirectPaymentHandles,
  loadEmployeeDirectPayment,
} from "features/direct-payments/routeConfig";
import {
  AuthProfileEntityRouteId,
  AuthProfileErrorElement,
  authProfileHandles,
  loadAuthProfile,
} from "features/auth-profiles/entity/routeConfig";
import {
  EmployeeDebtPlanErrorElement,
  EmployeeDebtPlanRouteId,
  employeeDebtPlanHandles,
  loadEmployeeDebtPlan,
} from "features/debt-plans/routeConfig";
import RouterRoot from "RouterRoot";
import {
  EmployeeOnePayPlanErrorElement,
  employeeOnePayPlanHandles,
  EmployeeOnePayPlanRouteId,
  loadEmployeeOnePayPlan,
} from "features/onepay-plans/routeConfig";

const routerConfig = createBrowserRouter([
  {
    Component: RouterRoot,
    children: [
      {
        path: ROUTES.root,
        element: <Navigate to={ROUTES.signin} />,
      },
      {
        path: ROUTES.signin,
        element: <SigninPage />,
      },
      {
        path: `${ROUTES.resetPassword}/*`,
        element: <ResetPasswordPage />,
      },
      {
        path: ROUTES.diag,
        lazy: () => import("pages/Diag"),
      },
      {
        path: "*",
        element: <Navigate to={ROUTES.pageNotFound} />,
      },
      {
        path: ROUTES.pageNotFound,
        element: <NotFound />,
      },
      {
        element: <PrivateRoute />,
        children: [
          {
            element: <MainLayout />,
            children: [
              {
                path: ROUTES.metricsKpis,
                lazy: () => import("pages/Reporting/MetricAndKpis"),
              },
              {
                path: ROUTES.debtPlanEnrollments,
                lazy: () => import("pages/Reporting/DebtPlanEnrollments"),
              },
              {
                path: ROUTES.reporting,
                lazy: () => import("pages/Reporting"),
              },
              {
                handle: {
                  crumb: () => ({
                    pathname: ROUTES.security,
                    hash: "",
                    search: "",
                    meta: {
                      title: "Security",
                    },
                  }),
                },
                children: [
                  {
                    path: ROUTES.security,
                    lazy: () => import("pages/Security"),
                  },
                  {
                    id: AuthProfileEntityRouteId,
                    path: ROUTES.authProfilesEntity,
                    handle: authProfileHandles,
                    lazy: () => import("pages/Security/AuthProfileEntity"),
                    errorElement: <AuthProfileErrorElement />,
                    loader: loadAuthProfile,
                  },
                ],
              },
              {
                path: ROUTES.exports,
                lazy: () => import("pages/Exports"),
              },
              {
                path: ROUTES.advisoryNotices,
                lazy: () => import("pages/AdvisoryNotices"),
              },
              {
                path: ROUTES.marketing,
                lazy: () => import("pages/Marketing"),
              },
              {
                handle: {
                  crumb: () => ({
                    pathname: ROUTES.financialProfileList,
                    hash: "",
                    search: "",
                    meta: {
                      title: "Financial Profiles",
                    },
                  }),
                },
                children: [
                  {
                    path: ROUTES.financialProfileList,
                    lazy: () => import("pages/FinancialProfiles"),
                  },
                  {
                    id: FinancialProfileEntityRouteId,
                    path: ROUTES.financialProfileEntity,
                    lazy: () => import("pages/FinancialProfiles/FinancialProfileEntity"),
                    handle: financialProfileHandles,
                    errorElement: <FinancialProfileErrorElement />,
                    loader: loadFinancialProfile,
                  },
                ],
              },
              {
                path: ROUTES.advanced,
                lazy: () => import("pages/Advanced"),
              },
              {
                handle: {
                  crumb: () => ({
                    pathname: ROUTES.employersList,
                    hash: "",
                    search: "",
                    meta: {
                      title: "Tenants",
                    },
                  }),
                },
                children: [
                  {
                    path: ROUTES.employersList,
                    lazy: () => import("pages/Employers/EmployerList"),
                  },
                  {
                    id: EmployerIndexRouteId,
                    path: ROUTES.employerEntity,
                    lazy: () => import("pages/Employers/index"),
                    errorElement: <EmployerErrorElement />,
                    handle: employerHandles,
                    loader: loadEmployer,
                    children: [
                      {
                        path: ROUTES.employerEntity,
                        lazy: () => import("pages/Employers/EmployerEntity"),
                      },
                      {
                        id: ProgramEntityRouteId,
                        path: ROUTES.employerProgramEntity,
                        lazy: () => import("pages/ProgramEntity"),
                        handle: programHandles,
                        errorElement: <ProgramErrorElement />,
                        loader: loadProgram,
                      },
                      {
                        id: EmployeeIndexRouteId,
                        path: ROUTES.employerEmployeeEntity,
                        handle: employeeHandles,
                        errorElement: <EmployeeErrorElement />,
                        loader: loadEmployee,
                        children: [
                          {
                            path: ROUTES.employerEmployeeEntity,
                            lazy: () => import("pages/Employees/EmployeeEntity"),
                          },
                          {
                            id: EmployeeAccountRouteId,
                            path: ROUTES.employerEmployeeAccountEntity,
                            lazy: () => import("pages/AccountEntity"),
                            handle: employeeAccountHandles,
                            errorElement: <EmployeeAccountErrorElement />,
                            loader: loadEmployeeAccount,
                          },
                          {
                            id: EmployeeDirectPaymentRouteId,
                            path: ROUTES.employerEmployeeDirectPaymentEntity,
                            lazy: () => import("pages/DirectPaymentEntity"),
                            handle: employeeDirectPaymentHandles,
                            errorElement: <EmployeeDirectPaymentErrorElement />,
                            loader: loadEmployeeDirectPayment,
                          },
                          {
                            id: EmployeeDebtPlanRouteId,
                            path: ROUTES.employerEmployeeDebtPlanEntity,
                            lazy: () => import("pages/DebtPlanEntity"),
                            handle: employeeDebtPlanHandles,
                            errorElement: <EmployeeDebtPlanErrorElement />,
                            loader: loadEmployeeDebtPlan,
                          },
                          {
                            id: EmployeeOnePayPlanRouteId,
                            path: ROUTES.employerEmployeeOnePayPlanEntity,
                            lazy: () => import("pages/OnePayPlanEntity"),
                            handle: employeeOnePayPlanHandles,
                            errorElement: <EmployeeOnePayPlanErrorElement />,
                            loader: loadEmployeeOnePayPlan,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                path: ROUTES.settings,
                lazy: () => import("pages/Settings"),
              },
            ],
          },
        ],
      },
    ],
  },
]);

export default routerConfig;
