import { useEffect, useMemo, useReducer, useState } from "react";
import useHandleApiError from "hooks/useHandleApiError";
import { intakeApi } from "api/BudgeApi";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";
import { financialProfileEntityReducer, initialState } from "features/financial-profiles/entity/contexts/reducer";

const useFinancialProfileForEmployee = () => {
  const [isLoading, setLoading] = useState(true);
  const { state: eState } = useEmployeeEntityContext();
  const [state, dispatch] = useReducer(financialProfileEntityReducer, initialState);
  const { handleError } = useHandleApiError();

  const memoedContextValues = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  useEffect(() => {
    handleError(
      intakeApi.getIntakeProfileForEmployee(eState.employee?.id!).then(({ data }) => {
        dispatch({ type: "setFinancialProfile", data });
      }),
      {
        showErrorModal: true,
        ignore404: true,
      }
    ).finally(() => setLoading(false));
  }, [eState.employee?.id]);

  return {
    isLoading,
    state,
    dispatch,
    memoedContextValues,
  };
};

export default useFinancialProfileForEmployee;
