import React from "react";
import ListFilterBar, { TFilterBarProps } from "components/ListFilterBar";
import { BuildValidationSchema, Checkbox, FormItem, Stack, yup } from "@budgeinc/budge-ui-core";
import FilterCollapse from "components/ListFilterBar/FilterCollapse";
import { ProgramEnrollmentSearchCriteriaInputStatusEnum } from "@budgeinc/budge-api";
import { formatEnumValue } from "utils/format";
import { useProgramEnrollmentFilterContext } from "./context";

export enum FilterFields {
  STATUS = "status",
}

export type TFormValues = {
  [FilterFields.STATUS]: ProgramEnrollmentSearchCriteriaInputStatusEnum[];
};

const validationSchema = BuildValidationSchema({
  [FilterFields.STATUS]: yup
    .array()
    .of(yup.string().oneOf(Object.values(ProgramEnrollmentSearchCriteriaInputStatusEnum))),
});

const FiltersBar = (props: TFilterBarProps) => {
  const { dispatch, initialFilters } = useProgramEnrollmentFilterContext();

  const handleOnSearch = (value: string | undefined) =>
    dispatch({
      type: "updateFilters",
      data: {
        genericSearchLike: value,
      },
    });

  return (
    <ListFilterBar<TFormValues>
      {...props}
      showSearchBar
      searchPlaceHolder="Search by first name, last name or email"
      onSearchChange={handleOnSearch}
      formProps={{
        onSubmit: async values => {
          const status = values[FilterFields.STATUS];

          dispatch({
            type: "updateFilters",
            data: {
              status: status.length ? status : undefined,
            },
          });
        },
        validationSchema,
        getInitialValues: reset => {
          if (initialFilters && !reset) {
            return {
              [FilterFields.STATUS]: initialFilters.status || [],
            };
          }

          return {
            [FilterFields.STATUS]: [],
          };
        },
      }}
    >
      <Stack spacing="sm">
        <FilterCollapse defaultOpen trigger="Status">
          <FormItem name={FilterFields.STATUS}>
            <Checkbox.Group
              options={Object.values(ProgramEnrollmentSearchCriteriaInputStatusEnum).map(status => ({
                label: formatEnumValue(status),
                value: status,
              }))}
            />
          </FormItem>
        </FilterCollapse>
      </Stack>
    </ListFilterBar>
  );
};

export default FiltersBar;
