import { AuditLogOutput } from "@budgeinc/budge-api";
import {
  Box,
  Button,
  Description,
  Modal,
  Pressable,
  ScrollView,
  Stack,
  Text,
  useDisclosure,
} from "@budgeinc/budge-ui-core";

interface OwnProps {
  audit: AuditLogOutput;
}

const ViewRawDataButton = ({ audit }: OwnProps) => {
  const disclosure = useDisclosure();

  return (
    <>
      <Pressable>
        <Button
          title="View Technical Data"
          size="xs"
          color="primary"
          variant="gradient"
          alignSelf="flex-start"
          onPress={disclosure.toggle}
        />
      </Pressable>
      <Modal
        isOpen={disclosure.isOpen}
        onClose={disclosure.toggle}
        size={500}
        header={{
          title: (
            <Text fw="600" variant="bodyLarge">
              Audit Technical Data
            </Text>
          ),
        }}
      >
        <Stack spacing="lg">
          <Description px="xl">
            <Description.Item label="Method">{audit.method}</Description.Item>
            <Description.Item label="URL">{audit.url}</Description.Item>
          </Description>
          <Box px="xl" pb="xl">
            <Box bg="dark.8" sx={theme => ({ borderRadius: theme.fn.radius(theme.defaultRadius) })}>
              <ScrollView mah={350}>
                <Text color="white" fw="400" p="sm">
                  {JSON.stringify(JSON.parse(audit.rawData), null, 2)}
                </Text>
              </ScrollView>
            </Box>
          </Box>
        </Stack>
      </Modal>
    </>
  );
};

export default ViewRawDataButton;
