import { EmployeeInput, EmployeeOutput } from "@budgeinc/budge-api";
import CollapseEditForm, { TEntityCollapseEditForm } from "components/CollapseEditForm";
import { employeesCrossTenantApi, employersApi } from "api/BudgeApi";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";
import { toast } from "@budgeinc/budge-ui-core";

const EmployeeCollapseEditForm = ({
  setEditMode,
  transformForSubmit,
  ...props
}: TEntityCollapseEditForm<EmployeeOutput, EmployeeInput>) => {
  const { state, dispatch, employerId } = useEmployeeEntityContext();

  return (
    <CollapseEditForm
      {...props}
      entity={state.employee!}
      onSubmit={values =>
        employeesCrossTenantApi
          .updateEmployeeForEmployer(employerId!, state.employee?.id!, transformForSubmit(values))
          .then(({ data }) => {
            dispatch({ type: "set", data });
            toast.success({
              message: "Customer saved successfully",
            });
          })
          .finally(() => {
            setEditMode(false);
          })
      }
    />
  );
};

export default EmployeeCollapseEditForm;
