import React from "react";
import { ErrorPage } from "@budgeinc/budge-ui-core";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  return <ErrorPage onDismiss={() => navigate("/")} errorCode={404} />;
};

export default NotFound;
