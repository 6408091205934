import { createListFilterReducer, TListFilterState } from "components/ListFilterBar/reducer";
import { createListFilterContext } from "components/ListFilterBar/context";
import { SubscriptionSearchCriteriaInput } from "@budgeinc/budge-api";
import { useContext } from "react";

export const SubFilterDefaultState: TListFilterState<SubscriptionSearchCriteriaInput> = {
  status: undefined,
  stripeSubscriptionId: undefined,
  genericSearchLike: undefined,
};

export const SubscriptionFilterReducer =
  createListFilterReducer<SubscriptionSearchCriteriaInput>(SubFilterDefaultState);
export const SubscriptionFilterContext =
  createListFilterContext<SubscriptionSearchCriteriaInput>(SubFilterDefaultState);

export const useSubscriptionFilterContext = () => {
  const context = useContext(SubscriptionFilterContext);

  if (!context) {
    throw new Error("SubscriptionFilterContext must be used inside an SubscriptionFilterContext.Provider");
  }

  return context;
};
