import React from "react";
import { Stack } from "@budgeinc/budge-ui-core";
import { DebtPaymentPlanOutput, EmployeeOutput } from "@budgeinc/budge-api";
import GeneralInfoCard from "./GeneralInfoCard";
import AccountsCard from "./AccountsCard";
import GraphDetailsCard from "./GraphDetailsCard";
import ProgramEnrollmentsCard from "./ProgramEnrollmentsCard";

interface OwnProps {
  debtPlan: DebtPaymentPlanOutput;
  employerId: string;
  employee: EmployeeOutput;
  onDeleted: () => void;
}

const DebtPlanDetails = ({ debtPlan, employee, employerId, onDeleted }: OwnProps) => (
  <Stack spacing="md" mb={40}>
    <GraphDetailsCard plan={debtPlan} onDeleted={onDeleted} />
    <GeneralInfoCard plan={debtPlan} />
    <AccountsCard plan={debtPlan} employerId={employerId} />
    <ProgramEnrollmentsCard employee={employee} />
  </Stack>
);

export default DebtPlanDetails;
