import React, { useState } from "react";
import { FinancialProfileEntityContext } from "features/financial-profiles/entity/contexts/FinancialProfileEntityContext";
import { Box } from "@budgeinc/budge-ui-core";
import FinancialProfileTabs, { FINANCIAL_PROFILE_TAB_KEYS } from "features/financial-profiles/entity/tabs";
import CenteredSpinner from "components/CenteredSpinner";
import AssignedFinancialProfileCard from "./components/AssignedFinancialProfileCard";
import useFinancialProfileForEmployee from "./useFinancialProfileForEmployee";

const FinancialProfileTab = () => {
  const { memoedContextValues, isLoading, state, dispatch } = useFinancialProfileForEmployee();
  const [activeKey, setActiveKey] = useState<FINANCIAL_PROFILE_TAB_KEYS>(FINANCIAL_PROFILE_TAB_KEYS.WHYS);

  if (!isLoading && !state.financialProfile) {
    return (
      <Box alignItems="center">
        <AssignedFinancialProfileCard
          miw={450}
          onSuccess={data =>
            dispatch({
              type: "setFinancialProfile",
              data,
            })
          }
        />
      </Box>
    );
  }

  return (
    <Box mb={40}>
      <FinancialProfileEntityContext.Provider value={memoedContextValues}>
        {isLoading ? (
          <CenteredSpinner />
        ) : (
          <FinancialProfileTabs
            defaultActiveKey={FINANCIAL_PROFILE_TAB_KEYS.WHYS}
            activeKey={activeKey}
            onChange={e => setActiveKey(e as FINANCIAL_PROFILE_TAB_KEYS)}
          />
        )}
      </FinancialProfileEntityContext.Provider>
    </Box>
  );
};

export default FinancialProfileTab;
