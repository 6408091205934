import React, { useContext } from "react";

import { initialState, TProgramEntityAction, TProgramEntityState } from "./reducer";

interface ProgramEntityContextType {
  dispatch: React.Dispatch<TProgramEntityAction>;
  state: TProgramEntityState;
}

export const ProgramEntityContext = React.createContext<ProgramEntityContextType>({
  dispatch: () => {},
  state: initialState,
});

export const useProgramEntityContext = () => {
  const context = useContext(ProgramEntityContext);

  if (!context) {
    throw new Error("ProgramEntityContext must be used inside an ProgramEntityContext.Provider");
  }

  return context;
};
