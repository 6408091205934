import React, { useContext } from "react";

import { initialState, TFinancialProfileEntityState, TFinancialProfileEntityAction } from "./reducer";

interface FinancialProfileEntityContextType {
  dispatch: React.Dispatch<TFinancialProfileEntityAction>;
  state: TFinancialProfileEntityState;
}

export const FinancialProfileEntityContext = React.createContext<FinancialProfileEntityContextType | null>({
  dispatch: () => {},
  state: initialState,
});

export const useFinancialProfileEntityContext = () => {
  const context = useContext(FinancialProfileEntityContext);

  if (!context) {
    throw new Error("FinancialProfileEntityContext must be used inside an FinancialProfileEntityContext.Provider");
  }

  return context;
};
