import { getPersistedFilters } from "components/ListFilterBar/utils";
import {
  ProgramTransactionSearchCriteriaInput,
  ProgramTransactionSearchCriteriaInputStatusEnum,
} from "@budgeinc/budge-api";
import { paramAsList } from "utils/queryParams";
import { FilterFields, TFormValues } from "./FiltersBar";

export const getInitialFilters = (
  persistId: string,
  params?: URLSearchParams
): ProgramTransactionSearchCriteriaInput => {
  const filters = getPersistedFilters<TFormValues>(persistId);

  const statusParams = paramAsList(
    params?.get(FilterFields.STATUS)
  ) as ProgramTransactionSearchCriteriaInputStatusEnum[];
  const dateFromParam = params?.get(FilterFields.DATE_FROM);
  const dateToParam = params?.get(FilterFields.DATE_TO);

  return {
    status: statusParams.length ? statusParams : filters?.[FilterFields.STATUS] || undefined,
    transactionDateFrom: dateFromParam || (filters?.[FilterFields.DATE_FROM] as any) || undefined,
    transactionDateTo: dateToParam || (filters?.[FilterFields.DATE_TO] as any) || undefined,
    genericSearchLike: undefined,
  };
};
