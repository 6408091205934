import React, { useState } from "react";
import { Button, TButtonProps, useDisclosure } from "@budgeinc/budge-ui-core";
import { MethodSyncHistoryOutput } from "@budgeinc/budge-api";
import MethodSyncHistoryModal from "components/Method/MethodSyncHistoryModal";
import { AxiosResponse } from "axios";

type OwnProps = TButtonProps & {
  modalTitle: string;
  api(): Promise<AxiosResponse<MethodSyncHistoryOutput[]>>;
};

const ShowSyncAuditButton = ({ modalTitle, api, ...props }: OwnProps) => {
  const [data, setData] = useState<MethodSyncHistoryOutput[]>([]);
  const [loading, setLoading] = useState(false);
  const disclosure = useDisclosure();

  const handleFetchSyncAudit = () => {
    setLoading(true);

    api()
      .then(resp => {
        setData(resp.data);
        disclosure.open();
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Button
        title="Show Sync Audit"
        size="sm"
        color="primary"
        variant="gradient"
        loading={loading}
        onPress={handleFetchSyncAudit}
        {...props}
      />
      <MethodSyncHistoryModal
        data={data}
        isOpen={disclosure.isOpen}
        onClose={() => disclosure.close()}
        header={{
          title: modalTitle,
          onClose: () => disclosure.close(),
        }}
      />
    </>
  );
};

export default ShowSyncAuditButton;
