import React, { useContext } from "react";
import ListFilterBar, { TFilterBarProps } from "components/ListFilterBar";
import { BuildValidationSchema, Checkbox, DatePickerInput, FormItem, Stack, yup } from "@budgeinc/budge-ui-core";
import FilterCollapse from "components/ListFilterBar/FilterCollapse";
import { FinancialAccountTransferOutputStatusEnum } from "@budgeinc/budge-api";
import { DateSchema } from "@budgeinc/budge-ui-utils";
import { FormikConsumer } from "formik";
import { getFilterDateFromMaxDate, getFilterDateToMinDate } from "components/ListFilterBar/utils";
import { formatEnumValue } from "utils/format";
import { TransferFilterContext } from "../context";

export enum FilterFields {
  STATUS = "status",
  DATE_FROM = "from",
  DATE_TO = "to",
}

export type TFormValues = {
  [FilterFields.STATUS]: FinancialAccountTransferOutputStatusEnum[];
  [FilterFields.DATE_FROM]: Date | undefined;
  [FilterFields.DATE_TO]: Date | undefined;
};

const validationSchema = BuildValidationSchema({
  [FilterFields.STATUS]: yup.array().of(yup.string().oneOf(Object.values(FinancialAccountTransferOutputStatusEnum))),
  [FilterFields.DATE_FROM]: DateSchema().nullable(),
  [FilterFields.DATE_TO]: DateSchema().nullable(),
});

const FiltersBar = (props: TFilterBarProps) => {
  const { dispatch } = useContext(TransferFilterContext);

  return (
    <ListFilterBar<TFormValues>
      {...props}
      showSearchBar={false}
      formProps={{
        onSubmit: async values => {
          const status = values[FilterFields.STATUS];

          dispatch({
            type: "updateFilters",
            data: {
              status: status.length ? status : undefined,
              processedDateFrom: values[FilterFields.DATE_FROM]?.toISOString() || undefined,
              processedDateTo: values[FilterFields.DATE_TO]?.toISOString() || undefined,
            },
          });
        },
        validationSchema,
        getFormValues: values => {
          const dateFrom = values?.[FilterFields.DATE_FROM];
          const dateTo = values?.[FilterFields.DATE_TO];

          return {
            [FilterFields.STATUS]: values?.[FilterFields.STATUS] || [],
            [FilterFields.DATE_FROM]: dateFrom ? new Date(dateFrom) : undefined,
            [FilterFields.DATE_TO]: dateTo ? new Date(dateTo) : undefined,
          };
        },
      }}
    >
      <FormikConsumer>
        {form => (
          <Stack>
            <FilterCollapse defaultOpen trigger="Processed Date">
              <Stack>
                <FormItem name={FilterFields.DATE_FROM}>
                  <DatePickerInput
                    label="From"
                    maxDate={getFilterDateFromMaxDate(form.getFieldMeta<Date>(FilterFields.DATE_TO).value)}
                  />
                </FormItem>
                <FormItem name={FilterFields.DATE_TO}>
                  <DatePickerInput
                    label="To"
                    minDate={getFilterDateToMinDate(form.getFieldMeta<Date>(FilterFields.DATE_FROM).value)}
                  />
                </FormItem>
              </Stack>
            </FilterCollapse>
            <FilterCollapse defaultOpen trigger="Status">
              <FormItem name={FilterFields.STATUS}>
                <Checkbox.Group
                  options={Object.values(FinancialAccountTransferOutputStatusEnum).map(status => ({
                    label: formatEnumValue(status),
                    value: status,
                  }))}
                />
              </FormItem>
            </FilterCollapse>
          </Stack>
        )}
      </FormikConsumer>
    </ListFilterBar>
  );
};

export default FiltersBar;
