import { CSSProperties } from "react";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import { BuildValidationSchema, EmailSchema, isLoading, yup } from "@budgeinc/budge-ui-utils";
import {
  Button,
  Form,
  FormFieldsWrapper,
  FormItem,
  Input,
  PasswordInput,
  Stack,
  Text,
  TAccessOperation,
  useAccessScopeValidator,
} from "@budgeinc/budge-ui-core";

import { employerEntityRoute, REDIRECT_PARAM_KEY, ROUTES } from "common/routes";
import useActionOnUnMount from "hooks/useActionOnUnMount";
import useSession from "SessionContext";
import { userActions } from "store/user/slice";

import AuthLayout from "features/authentication/Layout";

import { UserWhoamiOutputScopesEnum } from "@budgeinc/budge-api";
import { useD2CEmployer } from "store/global";
import { EMPLOYER_TAB_KEYS } from "pages/Employers/EmployerEntity";

enum FIELDS {
  EMAIL = "email",
  PASSWORD = "password",
}

const LoginValidationSchema = BuildValidationSchema({
  password: yup.string().required(),
  email: EmailSchema().required(),
});

const SigninPage = () => {
  const [searchParams] = useSearchParams();
  const defaultRoute = useUserDefaultRoute();
  const { login, loginState, isAuthenticated } = useSession();
  const redirectPath = searchParams.get(REDIRECT_PARAM_KEY);

  useActionOnUnMount(userActions.clearErrors);

  if (isAuthenticated) {
    return <Navigate to={redirectPath || defaultRoute} />;
  }

  return (
    <AuthLayout title="Admin Login">
      <Form
        formikProps={{
          initialValues: {
            [FIELDS.EMAIL]: "",
            [FIELDS.PASSWORD]: "",
          },
          validationSchema: LoginValidationSchema,
          onSubmit: values => login(values),
        }}
        formErrorMsg={loginState.error?.message}
      >
        {({ submitForm }) => (
          <FormFieldsWrapper>
            <Stack spacing="xl">
              <Stack spacing="md">
                <FormItem name={FIELDS.EMAIL}>
                  <Input label="Email Address" />
                </FormItem>
                <FormItem name={FIELDS.PASSWORD}>
                  <PasswordInput
                    label="Password"
                    id="current-password"
                    autoComplete="off"
                    // @ts-ignore @web only
                    name="current-password"
                  />
                </FormItem>
              </Stack>
              <Button
                title="Login"
                onPress={submitForm}
                loading={isLoading(loginState.requestStatus)}
                color="primary"
                variant="gradient"
                size="lg"
                fullWidth
              />
            </Stack>
          </FormFieldsWrapper>
        )}
      </Form>
      <Link to={ROUTES.resetPassword} style={domLinkStyle}>
        <Text variant="titleH4" fw="600">
          Forgot your password?
        </Text>
      </Link>
    </AuthLayout>
  );
};

const useUserDefaultRoute = () => {
  const d2cEmployer = useD2CEmployer();
  const { validateAccessScopes } = useAccessScopeValidator();

  if (validateAccessScopes(TAccessOperation.READ, [UserWhoamiOutputScopesEnum.MetricsKpis])) {
    return employerEntityRoute(d2cEmployer?.id!, EMPLOYER_TAB_KEYS.EMPLOYEES);
  }

  if (
    validateAccessScopes(TAccessOperation.READ, [
      {
        OR: [UserWhoamiOutputScopesEnum.Marketing, UserWhoamiOutputScopesEnum.MarketingRead],
      },
    ])
  ) {
    return ROUTES.marketing;
  }

  if (validateAccessScopes(TAccessOperation.READ, [UserWhoamiOutputScopesEnum.Tenants])) {
    return ROUTES.employersList;
  }

  return ROUTES.metricsKpis;
};

const domLinkStyle: CSSProperties = {
  textAlign: "center",
  marginTop: 50,
  textDecorationLine: "none",
};

export default SigninPage;
