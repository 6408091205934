import React, { PropsWithChildren, ReactNode, useEffect, useState } from "react";
import {
  UnstyledTextInput,
  Box,
  Button,
  FormProvider,
  ScrollView,
  Stack,
  Text,
  WebDrawer,
  useDebounce,
  useDisclosure,
  useTheme,
  isEmpty,
  ActionIcon,
  SyncIcon,
  Tooltip,
} from "@budgeinc/budge-ui-core";
import { useFormik, FormikValues } from "formik";
import { getPersistedFilters, persistFilters } from "./utils";

export type TFilterFormProps<TFormValues = any> = {
  onSubmit: (values: TFormValues) => Promise<any>;
  getFormValues?: (initialValues?: TFormValues | null) => TFormValues;
  getInitialValues?: (reset?: boolean) => TFormValues;
  validationSchema: any; // ObjectSchema<any, any, any>;
};

export type TFilterBarProps = {
  // ID used to persist locally the filters
  persistId: string;
  extra?: ReactNode;
  searchValue?: string;
  onRefresh?: () => void;
};

type OwnProps<TFormValues = any> = TFilterBarProps & {
  onClear?(): void;
  formProps?: TFilterFormProps<TFormValues>;
  searchPlaceHolder?: string;
  showSearchBar?: boolean;
  onSearchChange?(value: string | undefined): void;
};

const ListFilterBar = <TFormValues extends FormikValues>({
  searchValue = undefined,
  persistId,
  showSearchBar,
  searchPlaceHolder = "Search",
  children,
  onClear,
  onSearchChange,
  formProps,
  extra,
  onRefresh,
}: PropsWithChildren<OwnProps<TFormValues>>) => {
  const budgeTheme = useTheme();
  const disclosure = useDisclosure();
  const [localSearchValue, setLocalSearchValue] = useState<string | undefined>(searchValue);

  const debounceSearchValue = useDebounce({
    value: localSearchValue,
    delay: 350,
  });

  useEffect(() => {
    onSearchChange?.(debounceSearchValue);
  }, [debounceSearchValue]);

  useEffect(() => {
    if (searchValue) {
      setLocalSearchValue(searchValue);
    }
  }, [searchValue]);

  const handleOnSearchChange = (text: string) => setLocalSearchValue(text || "");

  const handleOnClear = () => {
    onClear?.();
    form.resetForm({
      values: formProps?.getInitialValues ? formProps?.getInitialValues(true) : formProps?.getFormValues?.(),
    });
  };

  const form = useFormik<TFormValues>({
    initialValues: formProps
      ? formProps.getInitialValues
        ? formProps.getInitialValues()
        : formProps.getFormValues?.(getPersistedFilters(persistId))
      : ({} as any),
    validationSchema: formProps?.validationSchema,
    onSubmit: values => {
      persistFilters(persistId, values);
      formProps?.onSubmit?.(values);
      disclosure.close();
    },
  });

  const handleSubmitForm = () => {
    form.submitForm();
  };

  const activeFilterCount = Object.values(form.values).reduce((a, b) => {
    if (Array.isArray(b)) {
      return a + b.length;
    }

    return a + (isEmpty(b) ? 0 : 1);
  }, 0);

  return (
    <>
      <Stack.Horizontal mb="lg" spacing="sm" alignItems="center" w="100%">
        <Box
          f={1}
          pl={16}
          pr={8}
          py={8}
          h={36}
          opacity={showSearchBar ? 1 : 0}
          r="md"
          sx={theme => ({
            backgroundColor: theme.white,
            borderColor: theme.palette.border.default,
            borderWidth: 1,
          })}
        >
          <UnstyledTextInput
            f={1}
            value={localSearchValue}
            onChangeText={handleOnSearchChange}
            placeholderTextColor={budgeTheme.palette.textColor.secondary}
            placeholder={searchPlaceHolder}
          />
        </Box>
        {onRefresh && (
          <Tooltip content="Refresh">
            <ActionIcon icon={SyncIcon} size="sm" variant="defaultBordered" onPress={onRefresh} />
          </Tooltip>
        )}
        {!!formProps && (
          <Button
            title="Advanced Filters"
            size="sm"
            variant="defaultBordered"
            sx={theme => ({
              borderColor: theme.palette.border.default,
              borderWidth: 1,
            })}
            onPress={disclosure.open}
            extra={
              activeFilterCount > 0 && (
                <Box alignItems="center" justifyContent="center" r={30} h={20} w={20} bg="primary">
                  <Text variant="bodySmall" color="white" fw="600">
                    {activeFilterCount}
                  </Text>
                </Box>
              )
            }
          />
        )}
        {extra}
      </Stack.Horizontal>
      {!!formProps && (
        <WebDrawer
          title={
            <Stack.Horizontal w="100%" alignItems="center" justifyContent="space-between">
              <Text fw="600" variant="bodyLarge">
                Advanced Filters
              </Text>
              <Button
                title="Clear"
                onPress={handleOnClear}
                size="xs"
                variant="transparent"
                titleTextProps={{
                  fw: "600",
                  color: "primary",
                }}
                disabled={activeFilterCount === 0}
              />
            </Stack.Horizontal>
          }
          isOpen={disclosure.isOpen}
          onClose={disclosure.close}
        >
          <FormProvider value={form} f={1} fgrow={1}>
            <Stack f={1} justifyContent="space-between">
              <ScrollView>{children}</ScrollView>
              <Button title="Apply" fullWidth onPress={handleSubmitForm} size="md" variant="gradient" color="primary" />
            </Stack>
          </FormProvider>
        </WebDrawer>
      )}
    </>
  );
};

export default ListFilterBar;
