import React from "react";
import {
  Grid,
  Input,
  Text,
  capitalize,
  getNextEffectivePayDay,
  getPaydayFrequencyDisplay,
  intlFormatDate,
  Collapse,
} from "@budgeinc/budge-ui-core";
import { EmployeePayrollSettingsPayFrequencyEnum } from "@budgeinc/budge-api";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";

const PaydaySettingCard = () => {
  const { state } = useEmployeeEntityContext();
  const activeEmployee = state.employee!;

  return (
    <Collapse
      trigger={
        <Text fw="500" variant="bodyMedium">
          Payday Settings
        </Text>
      }
    >
      <Grid column={3} gutter="md">
        <Input
          label="Payday Settings"
          disabled
          value={
            activeEmployee.payrollSettings ? capitalize(getPaydayFrequencyDisplay(activeEmployee.payrollSettings)) : "-"
          }
        />
        {activeEmployee.payrollSettings?.payFrequency === EmployeePayrollSettingsPayFrequencyEnum.BiWeekly && (
          <Input
            label="Payday Start Date"
            disabled
            value={
              activeEmployee.payrollSettings?.payDayStartDate
                ? intlFormatDate({
                    value: activeEmployee.payrollSettings.payDayStartDate,
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                    weekday: "short",
                  })
                : "-"
            }
          />
        )}
        <Input
          label="Next Effective Payment Date"
          disabled
          value={
            activeEmployee.payrollSettings?.payDayStartDate
              ? intlFormatDate({
                  value: getNextEffectivePayDay(activeEmployee.payrollSettings),
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                  weekday: "short",
                })
              : "-"
          }
        />
      </Grid>
    </Collapse>
  );
};

export default PaydaySettingCard;
