import { DirectPaymentOutput } from "@budgeinc/budge-api";
import { RouteHandle } from "common/routerConfig.types";
import EntityNotFound from "components/EntityNotFound";
import { LoaderFunctionArgs, useParams, useRouteLoaderData } from "react-router-dom";
import { directPaymentsApi, directPaymentsCrossTenantApi, employeesApi } from "api/BudgeApi";

export type EmployeeDirectPaymentRouteParams = {
  employerId: string;
  employeeId: string;
  directPaymentId: string;
};

export const EmployeeDirectPaymentRouteId = "EmployeeDirectPaymentRouteId";

export const loadEmployeeDirectPayment = async ({ params }: LoaderFunctionArgs): Promise<DirectPaymentOutput> => {
  const routeParams = params as EmployeeDirectPaymentRouteParams;
  return directPaymentsApi
    .getEmployeeDirectPayment(routeParams.employeeId!, routeParams.directPaymentId!)
    .then(({ data }) => data);
};

export const EmployeeDirectPaymentErrorElement = () => {
  const params = useParams<EmployeeDirectPaymentRouteParams>();

  return (
    <EntityNotFound
      title="Direct Payment not found"
      description={`Direct Payment ID ${params.directPaymentId} was not found in the database`}
    />
  );
};

export const employeeDirectPaymentHandles: RouteHandle = {
  crumb: (data: DirectPaymentOutput, pathname: string) => ({
    pathname,
    hash: "",
    search: "",
    meta: {
      title: data ? `Direct Payment` : "Not Found",
    },
  }),
};

export const useDirectPaymentRouteLoaderData = () =>
  useRouteLoaderData(EmployeeDirectPaymentRouteId) as DirectPaymentOutput;
