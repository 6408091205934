import React, { useState } from "react";
import { ActionIcon, DocumentIcon, TSize, Tooltip, errorManager } from "@budgeinc/budge-ui-core";
import { AxiosError } from "axios";
import { ErrorResponse } from "@budgeinc/budge-api";
import { plaidApi } from "api/BudgeApi";
import { delayed } from "utils/helpers";
import { openPlaidDetailsModal } from "./utils";
import { plaidPrimaryColor } from "./constants";

interface OwnProps {
  accountId: string;
  size?: TSize;
}

const PlaidRawDetailsButton = ({ accountId, size = "xs" }: OwnProps) => {
  const [loading, setLoading] = useState(false);

  const handleFetchDetails = async () => {
    setLoading(true);
    return (
      plaidApi
        .getAccountRawPlaid(accountId)
        // small hack so that the tooltip close when the
        // button is enabled after loading
        // TODO: Ajust tooltip component to prevent this
        .then(resp => delayed(() => openPlaidDetailsModal(resp.data), 50))
        .catch((e: AxiosError<ErrorResponse>) =>
          delayed(
            () =>
              errorManager.showError({
                error: e.response?.data,
                customMessage: (e.response?.data as any)?.data?.exceptionData?.exceptionMessage,
              }),
            50
          )
        )
        .finally(() => setLoading(false))
    );
  };

  return (
    <Tooltip content="Plaid Account Details">
      <ActionIcon
        icon={DocumentIcon}
        size={size}
        r="xl"
        color={plaidPrimaryColor}
        variant="gradient"
        loading={loading}
        onPress={handleFetchDetails}
      />
    </Tooltip>
  );
};

export default PlaidRawDetailsButton;
