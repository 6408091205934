import { DebtPaymentPlanOutput, DebtPaymentPlanOutputDebtPaymentPlanStatusEnum } from "@budgeinc/budge-api";
import { TColumnProps, Text, Tag, MoreDotsCircledIcon, SuccessIcon } from "@budgeinc/budge-ui-core";
import DateDisplay from "components/DateDisplay";
import { Link } from "components/Link";
import { employeeEntityRoute } from "common/routes";
import ActionCell from "./components/ActionCell";

export const getDebtPlanColumns = (employerId: string): TColumnProps<DebtPaymentPlanOutput>[] => [
  {
    id: "name",
    title: "Name",
    render: ({ employeeFirstName, employeeLastName, employeeId }) => (
      <Text numberOfLines={1}>
        <Link to={employeeEntityRoute(employerId, employeeId)} onClick={e => e.stopPropagation()}>
          {employeeFirstName} {employeeLastName}
        </Link>
      </Text>
    ),
  },
  {
    id: "type",
    title: "Type",
    render: ({ debtPaymentPlanType }) => <Tag value={debtPaymentPlanType} />,
  },
  {
    id: "status",
    title: "Status",
    render: ({ debtPaymentPlanStatus }) => {
      switch (debtPaymentPlanStatus) {
        case DebtPaymentPlanOutputDebtPaymentPlanStatusEnum.Active:
          return <Tag value={debtPaymentPlanStatus} color="blue" icon={MoreDotsCircledIcon} />;
        case DebtPaymentPlanOutputDebtPaymentPlanStatusEnum.PaidOff:
          return <Tag value={debtPaymentPlanStatus} color="green" icon={SuccessIcon} />;
        default:
          return <Tag value={debtPaymentPlanStatus} />;
      }
    },
  },
  {
    id: "updatedat",
    title: "Updated at",
    render: ({ updatedAt }) => <DateDisplay numberOfLines={1} value={updatedAt} showTime />,
  },
  {
    id: "createdat",
    title: "Created at",
    render: ({ createdAt }) => <DateDisplay numberOfLines={1} value={createdAt} />,
  },
  {
    id: "action",
    title: "",
    align: "flex-end",
    render: record => <ActionCell debtPlan={record} />,
  },
];
