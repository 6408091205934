import React from "react";
import { useEmployerContext } from "features/employers/contexts/EmployerContext";
import { directPaymentsCrossTenantApi, employersApi } from "api/BudgeApi";
import { useInfiniteScrollList } from "@budgeinc/budge-ui-core";
import ProgramEnrollmentsTable from "components/Table/ProgramEnrollmentsTable";
import { useDirectPaymentRouteLoaderData } from "features/direct-payments/routeConfig";

const ProgramEnrollmentsTab = () => {
  const {
    state: { employer },
  } = useEmployerContext();
  const directPayment = useDirectPaymentRouteLoaderData();

  const { requestState, onEndReached, updateData } = useInfiniteScrollList({
    onFetch: ({ page, pageSize }) =>
      directPaymentsCrossTenantApi
        .getEmployerDirectPaymentProgramEnrollments(
          employer?.id!,
          directPayment.id,
          `${pageSize * (page - 1)}`,
          pageSize.toString()
        )
        .then(({ data }) => ({
          results: data.map(({ programEnrollment }) => programEnrollment),
        })),
  });

  return (
    <ProgramEnrollmentsTable
      requestState={requestState}
      onEndReached={onEndReached}
      keyExtractor={({ id }) => id}
      updateData={updateData}
    />
  );
};

export default ProgramEnrollmentsTab;
