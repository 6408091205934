import { FinancialAccountOutput } from "@budgeinc/budge-api";
import { RouteHandle } from "common/routerConfig.types";
import EntityNotFound from "components/EntityNotFound";
import { LoaderFunctionArgs, useParams, useRouteLoaderData } from "react-router-dom";
import { accountsApi, employeesApi } from "api/BudgeApi";

export type EmployeeAccountRouteParams = {
  employerId: string;
  employeeId: string;
  accountId: string;
};

export const EmployeeAccountRouteId = "employeeAccountEntity";

export const loadEmployeeAccount = async ({ params }: LoaderFunctionArgs): Promise<FinancialAccountOutput> => {
  const routeParams = params as EmployeeAccountRouteParams;
  return accountsApi
    .getEmployeeFinancialAccount(routeParams.employeeId!, routeParams.accountId!)
    .then(({ data }) => data);
};

export const EmployeeAccountErrorElement = () => {
  const params = useParams<EmployeeAccountRouteParams>();

  return (
    <EntityNotFound
      title="Account not found"
      description={`Account ID ${params.accountId} was not found in the database`}
    />
  );
};

export const employeeAccountHandles: RouteHandle = {
  crumb: (data: FinancialAccountOutput, pathname: string) => ({
    pathname,
    hash: "",
    search: "",
    meta: {
      title: data ? data.name : "Not Found",
    },
  }),
};

export const useAccountRouteLoaderData = () => useRouteLoaderData(EmployeeAccountRouteId) as FinancialAccountOutput;
