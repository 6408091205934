import { Navigate } from "react-router-dom";
import { Button, Stack, Text } from "@budgeinc/budge-ui-core";
import { ROUTES } from "common/routes";
import styles from "features/authentication/password/styles";
import { useState } from "react";
import { usersApi } from "api/BudgeApi";
import { UserResetPasswordRequestInputOriginEnum } from "@budgeinc/budge-api";
import AuthLayout from "features/authentication/Layout";

interface OwnProps {
  email: string;
}

const EmailSentPage = ({ email }: OwnProps) => {
  const [isLoading, setLoading] = useState(false);

  if (!email) {
    return <Navigate to={ROUTES.resetPassword} />;
  }

  const resetPasswordEmail = () => {
    setLoading(true);
    usersApi
      .sendResetPasswordEmail({
        email,
        origin: UserResetPasswordRequestInputOriginEnum.AdminApp,
      })
      .finally(() => setLoading(false));
  };

  return (
    <AuthLayout title="Password Reset Sent">
      <Stack spacing="xl">
        <Text variant="bodyLarge" style={styles.notice}>
          We’ve sent an email to{" "}
          <Text variant="bodyLarge" fw="600">
            {email}
          </Text>{" "}
          containing a link to reset your password.
        </Text>
        <Button
          onPress={resetPasswordEmail}
          title="Resend email"
          loading={isLoading}
          color="primary"
          variant="gradient"
          size="lg"
          fullWidth
        />
      </Stack>
    </AuthLayout>
  );
};

export default EmailSentPage;
