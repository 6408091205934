import React from "react";
import { FormItem, Checkbox, Tooltip, ConditionalWrapper, intlFormatDate } from "@budgeinc/budge-ui-core";
import { getActionTakingFormItemName } from "../utils";
import { ActionTakingFormItemType } from "../types";

interface IActionTakingGridItem {
  actionElementId: string;
  value: string | null | undefined;
  actionTakingFormItemType: ActionTakingFormItemType;
}

const ActionTakingGridItem = ({ actionElementId, value, actionTakingFormItemType }: IActionTakingGridItem) => (
  <FormItem name={getActionTakingFormItemName(actionElementId, actionTakingFormItemType)}>
    <ConditionalWrapper
      condition={!!value}
      wrapper={children => (
        <Tooltip content={intlFormatDate({ value: value!, showTime: true, useUserTimezone: true })}>{children}</Tooltip>
      )}
    >
      <Checkbox alignSelf="center" />
    </ConditionalWrapper>
  </FormItem>
);

export default ActionTakingGridItem;
