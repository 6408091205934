import React, { useState } from "react";
import { Tabs } from "@budgeinc/budge-ui-core";
import AccountListTab from "./Accounts";
import DebtPlansTab from "./DebtPlans";
import ProgramEnrollmentListTab from "./ProgramEnrollments";
import TransactionListTab from "./Transactions";
import DirectPaymentsListTab from "./DirectPayments";
import CreditScoresListTab from "./CreditScores";
import DataRatingListTab from "./DataRating";
import OnePayPlansListTab from "./OnePayPlans";

export enum FINANCIALS_TAB_KEYS {
  CREDIT_SCORES = "#credit-scores",
  ACCOUNTS = "#accounts",
  RATINGS = "#ratings",
  DEBT_PLANS = "#debt-plans",
  ONEPAY_PLANS = "#onepay-plans",
  PROGRAM_ENROLLMENTS = "#program-enrollments",
  DIRECT_PAYMENTS = "#direct-payments",
  TRANSACTIONS = "#transactions",
}

const FinancialsTab = () => {
  const [activeKey, setActiveKey] = useState<FINANCIALS_TAB_KEYS>(FINANCIALS_TAB_KEYS.CREDIT_SCORES);

  return (
    <Tabs
      defaultActiveKey={FINANCIALS_TAB_KEYS.CREDIT_SCORES}
      activeKey={activeKey}
      onChange={e => setActiveKey(e as FINANCIALS_TAB_KEYS)}
      mt={-16}
    >
      <Tabs.Pane title="Credit Scores" tabKey={FINANCIALS_TAB_KEYS.CREDIT_SCORES}>
        <CreditScoresListTab />
      </Tabs.Pane>
      <Tabs.Pane title="Accounts" tabKey={FINANCIALS_TAB_KEYS.ACCOUNTS}>
        <AccountListTab />
      </Tabs.Pane>
      <Tabs.Pane title="Account Ratings" tabKey={FINANCIALS_TAB_KEYS.RATINGS}>
        <DataRatingListTab />
      </Tabs.Pane>
      <Tabs.Pane title="OnePay Plans" tabKey={FINANCIALS_TAB_KEYS.ONEPAY_PLANS}>
        <OnePayPlansListTab />
      </Tabs.Pane>
      <Tabs.Pane title="Debt Plans" tabKey={FINANCIALS_TAB_KEYS.DEBT_PLANS}>
        <DebtPlansTab />
      </Tabs.Pane>
      <Tabs.Pane title="Program Enrollments" tabKey={FINANCIALS_TAB_KEYS.PROGRAM_ENROLLMENTS}>
        <ProgramEnrollmentListTab />
      </Tabs.Pane>
      <Tabs.Pane title="Direct Payments" tabKey={FINANCIALS_TAB_KEYS.DIRECT_PAYMENTS}>
        <DirectPaymentsListTab />
      </Tabs.Pane>
      <Tabs.Pane title="Transactions" tabKey={FINANCIALS_TAB_KEYS.TRANSACTIONS}>
        <TransactionListTab />
      </Tabs.Pane>
    </Tabs>
  );
};

export default FinancialsTab;
