import { createListFilterReducer, TListFilterState } from "components/ListFilterBar/reducer";
import { createListFilterContext } from "components/ListFilterBar/context";
import { ProgramTransactionSearchCriteriaInput } from "@budgeinc/budge-api";
import { useContext } from "react";

export const initialState: TListFilterState<ProgramTransactionSearchCriteriaInput> = {
  genericSearchLike: undefined,
  status: undefined,
  transactionDateFrom: undefined,
  transactionDateTo: undefined,
};

export const ProgramTransactionFilterReducer =
  createListFilterReducer<ProgramTransactionSearchCriteriaInput>(initialState);
export const ProgramTransactionFilterContext =
  createListFilterContext<ProgramTransactionSearchCriteriaInput>(initialState);

export const useProgramTransactionFilterContext = () => {
  const context = useContext(ProgramTransactionFilterContext);

  if (!context) {
    throw new Error("ProgramTransactionFilterContext must be used inside an ProgramTransactionFilterContext.Provider");
  }

  return context;
};
