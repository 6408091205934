import { OnePaymentPlanOutput } from "@budgeinc/budge-api";
import { onePayPlansApi } from "api/BudgeApi";
import { useEffect, useState } from "react";
import useHandleApiError from "hooks/useHandleApiError";
import EntityNotFound from "components/EntityNotFound";
import { useParams } from "react-router-dom";
import { useEmployerContext } from "features/employers/contexts/EmployerContext";
import CenteredSpinner from "components/CenteredSpinner";
import OnePayPlanDetails from "features/onepay-plans/components/OnePayPlanDetails";
import { useEmployeeRouteLoaderData } from "features/employees/routeConfig";

const OnePayPlanTab = () => {
  const { handleError } = useHandleApiError();
  const [isLoading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const params = useParams<{ employeeId: string }>();
  const employee = useEmployeeRouteLoaderData();
  const {
    state: { employer },
  } = useEmployerContext();
  const [onePayPlan, setOnePayPlan] = useState<OnePaymentPlanOutput>();

  useEffect(() => {
    handleError(
      onePayPlansApi.getOnePaymentPlan(employee.id).then(resp => setOnePayPlan(resp.data)),
      {
        ignore404: true,
        onCatch: e => {
          if (e.response?.status === 404) {
            setNotFound(true);
          }
        },
      }
    ).finally(() => setLoading(false));
  }, []);

  return isLoading ? (
    <CenteredSpinner />
  ) : notFound || !onePayPlan ? (
    <EntityNotFound
      title="OnePay Plan not found"
      description={`Customer ID ${params?.employeeId} has not created any onepay plan yet`}
    />
  ) : (
    <OnePayPlanDetails
      onePayPlan={onePayPlan}
      employee={employee}
      employerId={employer?.id!}
      onDeleted={() => setOnePayPlan(undefined)}
    />
  );
};

export default OnePayPlanTab;
