import React from "react";
import ListFilterBar, { TFilterBarProps } from "components/ListFilterBar";
import { BuildValidationSchema } from "@budgeinc/budge-ui-core";
import { useSentimentPollFilterContext } from "../context";

export enum FilterFields {}

export type TFormValues = {};

const validationSchema = BuildValidationSchema({});

const FiltersBar = (props: TFilterBarProps) => {
  const { dispatch } = useSentimentPollFilterContext();

  const handleOnSearch = (value: string | undefined) =>
    dispatch({
      type: "updateFilters",
      data: {
        genericSearchLike: value,
      },
    });

  return (
    <ListFilterBar<TFormValues>
      {...props}
      showSearchBar
      onSearchChange={handleOnSearch}
      searchPlaceHolder="Search by first name, last name or email"
    />
  );
};

export default FiltersBar;
