import React from "react";
import ListFilterBar, { TFilterBarProps } from "components/ListFilterBar";
import { BuildValidationSchema, DatePickerInput, FormItem, Stack } from "@budgeinc/budge-ui-core";
import FilterCollapse from "components/ListFilterBar/FilterCollapse";
import { DateSchema } from "@budgeinc/budge-ui-utils";
import { FormikConsumer } from "formik";
import { getFilterDateFromMaxDate, getFilterDateToMinDate } from "components/ListFilterBar/utils";
import { useAuditFilterContext } from "../context";

export enum FilterFields {
  DATE_FROM = "from",
  DATE_TO = "to",
}

export type TFormValues = {
  [FilterFields.DATE_FROM]: string | undefined;
  [FilterFields.DATE_TO]: string | undefined;
};

const validationSchema = BuildValidationSchema({
  [FilterFields.DATE_FROM]: DateSchema().nullable(),
  [FilterFields.DATE_TO]: DateSchema().nullable(),
});

const FiltersBar = (props: TFilterBarProps) => {
  const { dispatch } = useAuditFilterContext();

  return (
    <ListFilterBar<TFormValues>
      {...props}
      showSearchBar={false}
      formProps={{
        onSubmit: async values =>
          dispatch({
            type: "updateFilters",
            data: {
              dateFrom: values[FilterFields.DATE_FROM] || undefined,
              dateTo: values[FilterFields.DATE_TO] || undefined,
            },
          }),
        validationSchema,
        getFormValues: values => {
          const dateFrom = values?.[FilterFields.DATE_FROM];
          const dateTo = values?.[FilterFields.DATE_TO];

          return {
            [FilterFields.DATE_FROM]: dateFrom ? (new Date(dateFrom) as any) : "",
            [FilterFields.DATE_TO]: dateTo ? (new Date(dateTo) as any) : "",
          };
        },
      }}
    >
      <FormikConsumer>
        {form => (
          <Stack>
            <FilterCollapse defaultOpen trigger="Audit Date">
              <Stack>
                <FormItem name={FilterFields.DATE_FROM}>
                  <DatePickerInput
                    label="From"
                    maxDate={getFilterDateFromMaxDate(form.getFieldMeta<Date>(FilterFields.DATE_TO).value)}
                  />
                </FormItem>
                <FormItem name={FilterFields.DATE_TO}>
                  <DatePickerInput
                    label="To"
                    minDate={getFilterDateToMinDate(form.getFieldMeta<Date>(FilterFields.DATE_FROM).value)}
                  />
                </FormItem>
              </Stack>
            </FilterCollapse>
          </Stack>
        )}
      </FormikConsumer>
    </ListFilterBar>
  );
};

export default FiltersBar;
