import {
  KpiCategoryOutput,
  KpiOutputDataTypeEnum,
  KpiSeriesItemOutput,
  KpiTypeCategoryOutput,
  KpiCategoryOutputCategoryEnum,
  KpiOutputTypeEnum,
} from "@budgeinc/budge-api";
import { ChartCoordinates, TColorToken, formatMoney, formatNumber } from "@budgeinc/budge-ui-core";
import { AdminAppStorage } from "utils/storage";

export const KPI_FILTERS_KEY = "kpi-filters";

export const KpiDataTypeTitleMap: Record<KpiOutputDataTypeEnum, TColorToken> = {
  [KpiOutputDataTypeEnum.AmountCents]: "Amount",
  [KpiOutputDataTypeEnum.Count]: "Count",
  [KpiOutputDataTypeEnum.Average]: "Average",
};

export const KpiCategoryColorMap: Record<KpiCategoryOutputCategoryEnum, TColorToken> = {
  // principal category
  [KpiCategoryOutputCategoryEnum.Usage]: "blue",
  [KpiCategoryOutputCategoryEnum.Revenue]: "green",
  [KpiCategoryOutputCategoryEnum.Engagement]: "yellow",
  [KpiCategoryOutputCategoryEnum.Accounts]: "water",
  [KpiCategoryOutputCategoryEnum.Action]: "primary",
  [KpiCategoryOutputCategoryEnum.ClientSentiment]: "dark",
  [KpiCategoryOutputCategoryEnum.Security]: "red",
  // sub category
  [KpiCategoryOutputCategoryEnum.Recomm]: "gray",
  [KpiCategoryOutputCategoryEnum.Debt]: "gray",
  [KpiCategoryOutputCategoryEnum.Savings]: "gray",
  [KpiCategoryOutputCategoryEnum.ActionTaking]: "gray",
  [KpiCategoryOutputCategoryEnum.ActionVsRecomm]: "gray",
  [KpiCategoryOutputCategoryEnum.ActionVsUsers]: "gray",
  [KpiCategoryOutputCategoryEnum.Separator]: "gray",
  [KpiCategoryOutputCategoryEnum.Growth]: "gray",
  [KpiCategoryOutputCategoryEnum.Others]: "gray",
};

export const getKpiChartCoordinates = (seriesData: KpiSeriesItemOutput[]) =>
  seriesData.map<ChartCoordinates>(serieData => ({
    x: new Date(serieData.date).getTime(),
    y: serieData.value,
  }));

export const getKpiFormattedValue = (dataType: KpiOutputDataTypeEnum | undefined, value: number) => {
  switch (dataType) {
    case KpiOutputDataTypeEnum.AmountCents:
      return formatMoney({
        amountCents: value,
        minFractionDigits: 0,
      });
    default:
      return formatNumber({
        value,
      });
  }
};

export const getCategoryColorByKpiType = (
  type: KpiOutputTypeEnum,
  kpiCategories: KpiCategoryOutput[],
  categoriesTypes: KpiTypeCategoryOutput[]
): TColorToken => {
  const categoryType = categoriesTypes.find(cType => cType.type === type);
  const kpiCategory = kpiCategories.find(({ kpiCategoryId }) => kpiCategoryId === categoryType?.kpiCategoryId);

  return kpiCategory?.category ? KpiCategoryColorMap[kpiCategory.category] : "gray";
};

export type KpiCategoryTreeNode = KpiCategoryOutput & {
  checked: boolean;
};

export type KpiCategoryNode = KpiCategoryTreeNode & {
  children: KpiCategoryTreeNode[];
};

export const buildDefaultCategoriesTree = (categories: KpiCategoryOutput[]) => {
  const idToNodeMap = new Map();
  const nodes: KpiCategoryNode[] = [];

  categories.forEach((obj, index) => {
    const node: KpiCategoryNode = { ...obj, checked: index === 1 || index === 0, children: [] };
    idToNodeMap.set(node.kpiCategoryId, node);
    nodes.push(node);
  });

  nodes.forEach(node => {
    if (node.parentKpiCategoryId !== null) {
      const parentNode = idToNodeMap.get(node.parentKpiCategoryId);
      if (parentNode) {
        parentNode.children.push(node);
      }
    }
  });

  return nodes.filter(node => node.parentKpiCategoryId === null);
};

const arrayIntersect = (array1: KpiTypeCategoryOutput[], array2: KpiTypeCategoryOutput[]) =>
  array1.filter(n => !!array2.find(({ type }) => n.type === type));

export const getTypeCategoryByNode = (
  node: KpiCategoryNode,
  categoriesTypes: KpiTypeCategoryOutput[]
): KpiTypeCategoryOutput[] => {
  if (node.checked) {
    return categoriesTypes
      .filter(({ kpiCategoryId }) => kpiCategoryId === node.kpiCategoryId)
      .sort((a, b) => a.sortOrder - b.sortOrder);
  }

  const categoryTypeByOpType: { and: KpiTypeCategoryOutput[]; or: KpiTypeCategoryOutput[] } = {
    and: [],
    or: [],
  };

  node.children.forEach(child => {
    if (child.checked) {
      const cts = categoriesTypes.filter(({ kpiCategoryId }) => kpiCategoryId === child.kpiCategoryId);

      if (child.opType === "AND") {
        categoryTypeByOpType.and = categoryTypeByOpType.and.concat(cts);
      } else {
        categoryTypeByOpType.or = categoryTypeByOpType.or.concat(cts);
      }
    }
  });

  const result = categoryTypeByOpType.and.length
    ? arrayIntersect(categoryTypeByOpType.and, categoryTypeByOpType.or)
    : categoryTypeByOpType.or;

  return result.sort((a, b) => a.sortOrder - b.sortOrder);
};

export const checkParentIfNeeded = (nodes: KpiCategoryNode[]): KpiCategoryNode[] =>
  nodes.map(node => ({
    ...node,
    checked: node.children.length ? node.children.every(child => child.checked) : node.checked,
  }));

export const saveKpiFilters = (nodes: KpiCategoryNode[]) =>
  AdminAppStorage.save(KPI_FILTERS_KEY, JSON.stringify(nodes));

export const getKpiFilters = (): KpiCategoryNode[] => {
  const filters = AdminAppStorage.get(KPI_FILTERS_KEY);

  return filters ? JSON.parse(filters) : [];
};
