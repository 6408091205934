import React, { useState } from "react";
import { Button, TButtonProps } from "@budgeinc/budge-ui-core";
import { IntakeProfileOutput } from "@budgeinc/budge-api";
import { intakeApi } from "api/BudgeApi";
import useHandleApiError from "hooks/useHandleApiError";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";

interface OwnProps extends Omit<TButtonProps, "onPress" | "loading"> {
  onSuccess(data: IntakeProfileOutput): void;
}

const CreateFinancialProfileButton = ({ onSuccess, ...props }: OwnProps) => {
  const [loading, setLoading] = useState(false);
  const { state } = useEmployeeEntityContext();
  const { handleError } = useHandleApiError();

  const handleCreateFinancialProfile = () => {
    setLoading(true);
    handleError(
      intakeApi
        .createIntakeProfile({
          employeeId: state.employee?.id!,
          description: state.employee?.email!,
        })
        .then(({ data }) => {
          onSuccess(data);
        })
        .finally(() => setLoading(false))
    );
  };

  return (
    <Button
      title="Create Financial Profile"
      color="primary"
      size="sm"
      variant="gradient"
      fullWidth
      onPress={handleCreateFinancialProfile}
      loading={loading}
      {...props}
    />
  );
};

export default CreateFinancialProfileButton;
