import React, { useCallback, useEffect, useState } from "react";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";
import { employeesApi, sentimentsApi } from "api/BudgeApi";
import { EmployeeOutput, SentimentPollOutput, SentimentPollOutputTypeEnum } from "@budgeinc/budge-api";
import useHandleApiError from "hooks/useHandleApiError";
import {
  Divider,
  Text,
  Stack,
  i18n,
  Box,
  Tag,
  Description,
  EMPTY_VALUE_PLACEHOLDER,
  Collapse,
} from "@budgeinc/budge-ui-core";
import DateDisplay from "components/DateDisplay";
import { formatEnumValue } from "utils/format";
import CenteredSpinner from "components/CenteredSpinner";
import SentimentDetails from "./SentimentDetails";

const SentimentListTab = () => {
  const {
    state: { employee },
  } = useEmployeeEntityContext();
  const [loading, setLoading] = useState(true);
  const [sentimentPolls, setSentimentPolls] = useState<SentimentPollOutput[]>([]);
  const { handleError } = useHandleApiError();

  const hasCancellationData = (empl: EmployeeOutput): boolean =>
    !!(
      empl &&
      (empl?.canceledAt || empl?.cancellationReason || empl?.customCancellationReason || empl?.improvementSuggestion)
    );

  useEffect(() => {
    if (employee?.id) {
      handleError(
        sentimentsApi.getEmployeeSentimentPolls(employee.id).then(({ data }) => setSentimentPolls(data))
      ).finally(() => setLoading(false));
    }
  }, [employee?.id]);

  const getSentimentPollsByType = useCallback(() => {
    const sentimentPollsByType: Record<SentimentPollOutputTypeEnum, SentimentPollOutput[]> = {
      [SentimentPollOutputTypeEnum.GeneralFeedback]: [],
      [SentimentPollOutputTypeEnum.FinancialConfidence]: [],
      [SentimentPollOutputTypeEnum.ProductMarketFit]: [],
    };
    sentimentPolls.forEach(sentimentPoll => sentimentPollsByType[sentimentPoll.type].push(sentimentPoll));

    return sentimentPollsByType;
  }, [sentimentPolls.length]);

  if (loading) {
    return <CenteredSpinner />;
  }

  return (
    <Stack spacing="md" mb={40}>
      {hasCancellationData(employee!) && <CancellationReasonSection employee={employee!} />}
      {Object.entries(getSentimentPollsByType()).map(([type, sentiments]) => (
        <SentimentPollSection type={type as SentimentPollOutputTypeEnum} sentiments={sentiments} />
      ))}
    </Stack>
  );
};

const CancellationReasonSection = ({ employee }: { employee: EmployeeOutput }) => (
  <Collapse
    trigger={
      <Text fw="500" variant="bodyMedium">
        Cancellation Details
      </Text>
    }
    defaultOpen
  >
    <Description.Item label="Reason" alignItems="center">
      <Text>
        {employee.cancellationReason
          ? i18n.t(`enums.accountCancellationReason.${employee.cancellationReason}`, {
              defaultValue: formatEnumValue(employee.cancellationReason),
            })
          : EMPTY_VALUE_PLACEHOLDER}
      </Text>
    </Description.Item>
    {employee.customCancellationReason && (
      <Description.Item label="Custom Reason" alignItems="center">
        <Text>{employee.customCancellationReason}</Text>
      </Description.Item>
    )}
    <Description.Item label="Suggestion" alignItems="center">
      <Text>{employee.improvementSuggestion ? employee.improvementSuggestion : EMPTY_VALUE_PLACEHOLDER}</Text>
    </Description.Item>
    <Description.Item label="Canceled At">
      {employee.canceledAt ? <DateDisplay value={employee.canceledAt} showTime /> : EMPTY_VALUE_PLACEHOLDER}
    </Description.Item>
  </Collapse>
);

const SentimentPollSection = ({
  type,
  sentiments,
}: {
  type: SentimentPollOutputTypeEnum;
  sentiments: SentimentPollOutput[];
}) => (
  <Collapse
    trigger={
      <Stack.Horizontal w="100%" alignItems="center" justifyContent="space-between">
        <Stack spacing={0}>
          <Text fw="500" variant="bodyMedium">
            {i18n.t(`enums.sentimentPollType.${type}`, {
              defaultValue: formatEnumValue(type),
            })}
          </Text>
          <Text color="secondary">{sentiments.length > 0 ? sentiments[0].question : ""}</Text>
        </Stack>
        {type === SentimentPollOutputTypeEnum.GeneralFeedback ? (
          <Stack.Horizontal>
            <Tag
              color="primary"
              value={i18n.t("sentimentAnalysis.feedbackSent", {
                count: sentiments.length,
              })}
            />
          </Stack.Horizontal>
        ) : (
          <Stack.Horizontal>
            <Tag
              color="primary"
              value={i18n.t("sentimentAnalysis.pollAsked", {
                count: sentiments.length,
              })}
            />
            <Tag
              color="green"
              value={i18n.t("sentimentAnalysis.pollAnswered", {
                count: sentiments.filter(({ answeredAt }) => !!answeredAt).length,
              })}
            />
          </Stack.Horizontal>
        )}
      </Stack.Horizontal>
    }
    expandable={sentiments.length > 0}
  >
    {sentiments.map((sentiment, index) => (
      <Box>
        <SentimentDetails sentiment={sentiment} />
        {index < sentiments.length - 1 && <Divider spacing="xl" />}
      </Box>
    ))}
  </Collapse>
);

export default SentimentListTab;
