/* eslint-disable react/no-unstable-nested-components */
import { LoginHistoryOutput } from "@budgeinc/budge-api";
import {
  Description,
  Divider,
  EMPTY_VALUE_PLACEHOLDER,
  InfiniteScrollTable,
  TEmptyProps,
} from "@budgeinc/budge-ui-core";

import { hasFilters } from "components/ListFilterBar/utils";
import { getLoginsColumns } from "./columns";
import { TInfiniteScrollDataTableProps } from "../types";
import { useLoginFilterContext } from "./filters/context";

type OwnProps = TInfiniteScrollDataTableProps<LoginHistoryOutput> & {
  emptyLocal?: Pick<TEmptyProps, "description" | "action">;
  includeEmployeeColumns?: boolean;
};

const LoginsTable = ({ emptyLocal, includeEmployeeColumns = false, ...props }: OwnProps) => {
  const filters = useLoginFilterContext();

  return (
    <InfiniteScrollTable<LoginHistoryOutput>
      columns={getLoginsColumns(includeEmployeeColumns)}
      local={{
        empty: {
          title: "No login history",
          description: hasFilters(filters) ? `No login match these filters. Edit or clear all filters.` : undefined,
          ...emptyLocal,
        },
        noMoreItems: "No more login history to load",
      }}
      contentContainerStyle={{ marginBottom: 40 }}
      expandable={record => (
        <>
          <Description>
            <Description.Item label="ID">{record.id}</Description.Item>
          </Description>
          <Divider />
          <Description>
            <Description.Item label="User Agent">{record.userAgent || EMPTY_VALUE_PLACEHOLDER}</Description.Item>
          </Description>
        </>
      )}
      {...props}
    />
  );
};

export default LoginsTable;
