import { useNavigate } from "react-router-dom";
import { Button, Container, Stack, Text } from "@budgeinc/budge-ui-core";
import { ROUTES } from "common/routes";
import styles from "features/authentication/password/styles";
import AuthLayout from "features/authentication/Layout";

const LinkExpiredPage = () => {
  const navigate = useNavigate();

  return (
    <AuthLayout title="Oops! We’re having issues">
      <Container contentMaxWidth={400}>
        <Stack spacing="xl">
          <Text variant="bodyLarge" style={styles.notice}>
            Sorry, the verification link expired or something went wrong on our end. Please try again.
          </Text>
          <Button
            onPress={() => navigate(ROUTES.resetPassword)}
            title="Resend Email"
            color="primary"
            variant="gradient"
            size="lg"
            fullWidth
          />
        </Stack>
      </Container>
    </AuthLayout>
  );
};

export default LinkExpiredPage;
