import { ErrorManager as BudgeUIErrorManager } from "@budgeinc/budge-ui-core";
import { useAppMessages } from "store/global";

const ErrorManager = () => {
  const messages = useAppMessages();

  return <BudgeUIErrorManager messages={messages} />;
};

export default ErrorManager;
