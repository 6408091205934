import React from "react";
import GridTableHeader, { TGridTableHeader } from "features/financial-profiles/components/GridTable/GridTableHeader";
import { MONEY_COL_MAW } from "../types";

const headers: TGridTableHeader[] = [
  {
    title: "",
  },
  {
    title: "Company Name",
    maw: 200,
  },
  {
    title: "Balance",
    maw: MONEY_COL_MAW,
  },
  {
    title: "Annual Contribution",
    maw: MONEY_COL_MAW,
  },
  {
    title: "Annual Match",
    maw: MONEY_COL_MAW,
  },
  {
    title: "APR",
    maw: 100,
  },
  {
    title: "Target",
    maw: 100,
  },
  {
    title: "Comment",
  },
  {
    title: "",
    maw: 45,
  },
];

const AssetHeader = () => <GridTableHeader headers={headers} />;

export default AssetHeader;
