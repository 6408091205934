import { CreditScoreOutput, CreditScoreOutputStatusEnum } from "@budgeinc/budge-api";
import {
  DeleteIcon,
  EMPTY_VALUE_PLACEHOLDER,
  MoreDotsCircledIcon,
  Stack,
  SuccessIcon,
  TColumnProps,
  Tag,
  Text,
  i18n,
} from "@budgeinc/budge-ui-core";
import { employeeEntityRoute } from "common/routes";
import DateDisplay from "components/DateDisplay";
import { Link } from "components/Link";

export const getCreditScoreColumns = (employerId?: string): TColumnProps<CreditScoreOutput>[] => {
  const columns: TColumnProps<CreditScoreOutput>[] = [];
  if (employerId) {
    columns.push({
      id: "name",
      title: "Name",
      render: ({ employeeFirstName, employeeLastName, employeeId }) => (
        <Text numberOfLines={1}>
          <Link to={employeeEntityRoute(employerId, employeeId)} onClick={e => e.stopPropagation()}>
            {employeeFirstName} {employeeLastName}
          </Link>
        </Text>
      ),
    });
  }

  columns.push(
    {
      id: "creditScore",
      title: "Score",
      sortable: true,
      render: ({ creditScore, isLatest, isPrevious }) => {
        let tag = null;

        if (!creditScore) return <Text>{EMPTY_VALUE_PLACEHOLDER}</Text>;

        if (isLatest) {
          tag = <Tag value="Latest" color="green" size="xs" />;
        } else if (isPrevious) {
          tag = <Tag value="Previous" color="gray" size="xs" />;
        }

        return (
          <Stack.Horizontal fwrap="wrap">
            <Text>{creditScore}</Text>
            {tag}
          </Stack.Horizontal>
        );
      },
    },
    {
      id: "status",
      title: "Status",
      render: ({ status }) => {
        switch (status) {
          case CreditScoreOutputStatusEnum.Completed:
            return <Tag value="Completed" color="green" icon={SuccessIcon} />;
          case CreditScoreOutputStatusEnum.Failed:
            return <Tag value="Failed" color="red" icon={DeleteIcon} />;
          case CreditScoreOutputStatusEnum.InProgress:
          case CreditScoreOutputStatusEnum.Pending:
            return <Tag value={status} color="blue" icon={MoreDotsCircledIcon} />;
        }
      },
    },
    {
      id: "variation",
      title: "Variation",
      sortable: true,
      render: ({ variation }) => {
        if (!variation) return <Text>{EMPTY_VALUE_PLACEHOLDER}</Text>;

        return variation > 0 ? (
          <Text color="green">{`+${variation}`}</Text>
        ) : (
          <Text color="red">{`-${Math.abs(variation)}`}</Text>
        );
      },
    },
    {
      id: "rating",
      title: "Rating",
      render: ({ rating }) => (
        <Text>
          {i18n.t(`enums.creditScore.rating.${rating}`, {
            defaultValue: EMPTY_VALUE_PLACEHOLDER,
          })}
        </Text>
      ),
    },
    {
      id: "creditScoreLastUpdated",
      title: "Last Updated At",
      sortable: true,
      render: ({ creditScoreLastUpdated }) =>
        creditScoreLastUpdated ? (
          <DateDisplay numberOfLines={1} value={creditScoreLastUpdated} showTime />
        ) : (
          <Text>{EMPTY_VALUE_PLACEHOLDER}</Text>
        ),
    }
  );

  return columns;
};
