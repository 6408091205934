import { FinancialAccountOutput } from "@budgeinc/budge-api";
import {
  EMPTY_VALUE_PLACEHOLDER,
  Tag,
  TColumnProps,
  Text,
  AccountTypeIconMapping,
  Stack,
  formatEmployeeName,
  GiftIcon,
  formatMoney,
} from "@budgeinc/budge-ui-core";
import { employeeEntityRoute } from "common/routes";
import DateDisplay from "components/DateDisplay";
import { Link } from "components/Link";
import ActionCell from "./components/ActionCell";

export const getAccountColumns = (includeEmployeeColumns: boolean = false): TColumnProps<FinancialAccountOutput>[] => {
  const columns: TColumnProps<FinancialAccountOutput>[] = [];

  if (includeEmployeeColumns) {
    columns.push({
      id: "name",
      title: "Name",
      maxWidth: 250,
      render: ({ employee }) => (
        <Text numberOfLines={1}>
          {employee ? (
            <Link to={employeeEntityRoute(employee.employerId, employee.id)} onClick={e => e.stopPropagation()}>
              {formatEmployeeName({ employee })}
            </Link>
          ) : (
            EMPTY_VALUE_PLACEHOLDER
          )}
        </Text>
      ),
    });
  }

  columns.push(
    {
      id: "accountname",
      title: "Account Name",
      render: record => <Text numberOfLines={2}>{record.name}</Text>,
    },
    {
      id: "balance",
      title: "Balance",
      maxWidth: 115,
      render: record => (
        <Text numberOfLines={1}>
          {formatMoney({
            amountCents: record.balanceCurrentCents || 0,
          })}
        </Text>
      ),
    },
    {
      id: "type",
      title: "Type",
      render: record => {
        const Icon = AccountTypeIconMapping[record.accountType];

        return record.accountType ? (
          <Stack.Horizontal f={1} maw="100%" alignItems="center">
            <Icon size="xs" color={record.isFundingSource ? "green" : "primary"} />
            <Tag
              f={1}
              color={record.isFundingSource ? "green" : "primary"}
              value={record.isFundingSource ? "Funding Account" : record.accountType}
              icon={record.referralPaymentsTarget ? GiftIcon : undefined}
            />
          </Stack.Horizontal>
        ) : (
          EMPTY_VALUE_PLACEHOLDER
        );
      },
    },
    {
      id: "updatedat",
      title: "Updated at",
      render: ({ updatedAt }) => <DateDisplay numberOfLines={1} value={updatedAt} showTime />,
    },
    {
      id: "createdat",
      title: "Created at",
      render: ({ createdAt }) => <DateDisplay numberOfLines={1} value={createdAt} />,
    },
    {
      title: "",
      id: "action",
      align: "flex-end",
      render: record => <ActionCell account={record} />,
    }
  );

  return columns;
};
