import { createListFilterReducer, TListFilterState } from "components/ListFilterBar/reducer";
import { createListFilterContext } from "components/ListFilterBar/context";
import { FinancialAccountDataRatingSearchCriteriaInput } from "@budgeinc/budge-api";
import { useContext } from "react";

export const initialState: TListFilterState<FinancialAccountDataRatingSearchCriteriaInput> = {
  genericSearchLike: undefined,
  dataPointSource: undefined,
  dataPointRating: undefined,
  dataPointType: undefined,
  employeeId: undefined,
  methodAccountId: undefined,
  methodEntityId: undefined,
  financialAccountId: undefined,
};

export const DataRatingFilterReducer =
  createListFilterReducer<FinancialAccountDataRatingSearchCriteriaInput>(initialState);
export const DataRatingFilterContext =
  createListFilterContext<FinancialAccountDataRatingSearchCriteriaInput>(initialState);

export const useDataRatingFilterContext = () => {
  const context = useContext(DataRatingFilterContext);

  if (!context) {
    throw new Error("useDataRatingFilterContext must be used inside an DataRatingFilterContext.Provider");
  }

  return context;
};
