import { createListFilterReducer, TListFilterState } from "components/ListFilterBar/reducer";
import { createListFilterContext } from "components/ListFilterBar/context";
import { SentimentPollSearchCriteriaInput } from "@budgeinc/budge-api";
import { useContext } from "react";

export const SubFilterDefaultState: TListFilterState<SentimentPollSearchCriteriaInput> = {
  genericSearchLike: undefined,
};

export const SentimentPollFilterReducer =
  createListFilterReducer<SentimentPollSearchCriteriaInput>(SubFilterDefaultState);
export const SentimentPollFilterContext =
  createListFilterContext<SentimentPollSearchCriteriaInput>(SubFilterDefaultState);

export const useSentimentPollFilterContext = () => {
  const context = useContext(SentimentPollFilterContext);

  if (!context) {
    throw new Error("SentimentPollFilterContext must be used inside an SentimentPollFilterContext.Provider");
  }

  return context;
};
