import React from "react";
import ListFilterBar, { TFilterBarProps } from "components/ListFilterBar";
import { BuildValidationSchema, Checkbox, FormItem, RadioGroup, Stack, yup } from "@budgeinc/budge-ui-core";
import { useIsEmployerD2C } from "features/employers/contexts/EmployerContext";
import FilterCollapse from "components/ListFilterBar/FilterCollapse";
import { useEmployeeFilterContext } from "../context";

export enum FilterFields {
  EXCLUDED = "excluded",
  MISSING_DATA = "missing_data",
  ADMIN = "admin",
  KPI_EXCLUDED = "kpi_excluded",
  ACTIVE_CAMPAIGN_EXCLUDED = "active_campaign_excluded",
}

enum BooleanFilterGroupValues {
  YES = "yes",
  NO = "no",
}

enum AdminFilterGroupValues {
  READ = "read",
  WRITE = "write",
  NOT_READ = "not_read",
  NOT_WRITE = "not_write",
}

export type TFormValues = {
  [FilterFields.EXCLUDED]: BooleanFilterGroupValues[];
  [FilterFields.MISSING_DATA]: BooleanFilterGroupValues[];
  [FilterFields.KPI_EXCLUDED]: BooleanFilterGroupValues[];
  [FilterFields.ACTIVE_CAMPAIGN_EXCLUDED]: BooleanFilterGroupValues[];
  [FilterFields.ADMIN]: AdminFilterGroupValues | undefined;
};

const yupArraySchema = yup.array().of(yup.string().oneOf(Object.values(BooleanFilterGroupValues)));

const validationSchema = BuildValidationSchema({
  [FilterFields.EXCLUDED]: yupArraySchema,
  [FilterFields.MISSING_DATA]: yupArraySchema,
  [FilterFields.KPI_EXCLUDED]: yupArraySchema,
  [FilterFields.ACTIVE_CAMPAIGN_EXCLUDED]: yupArraySchema,
  [FilterFields.ADMIN]: yup.string().oneOf(Object.values(AdminFilterGroupValues)).optional(),
});

const FiltersBar = (props: TFilterBarProps) => {
  const isD2C = useIsEmployerD2C();
  const { dispatch: filterDispatch } = useEmployeeFilterContext();

  const handleOnSearch = (value: string | undefined) =>
    filterDispatch({
      type: "updateFilters",
      data: {
        genericSearchLike: value,
      },
    });

  return (
    <ListFilterBar<TFormValues>
      {...props}
      showSearchBar
      onSearchChange={handleOnSearch}
      searchPlaceHolder="Search by first name, last name or email"
      formProps={{
        onSubmit: async values => {
          filterDispatch({
            type: "updateFilters",
            data: {
              isMissingEmployeeOnboardingData: getBooleanDataFilterValue(values[FilterFields.MISSING_DATA]),
              isExcluded: getBooleanDataFilterValue(values[FilterFields.EXCLUDED]),
              isKpiExcluded: getBooleanDataFilterValue(values[FilterFields.KPI_EXCLUDED]),
              isActiveCampaignExcluded: getBooleanDataFilterValue(values[FilterFields.ACTIVE_CAMPAIGN_EXCLUDED]),
            },
          });
        },
        validationSchema,
        getFormValues: values => ({
          [FilterFields.EXCLUDED]: values?.[FilterFields.EXCLUDED] || [],
          [FilterFields.MISSING_DATA]: values?.[FilterFields.MISSING_DATA] || [],
          [FilterFields.KPI_EXCLUDED]: values?.[FilterFields.KPI_EXCLUDED] || [],
          [FilterFields.ACTIVE_CAMPAIGN_EXCLUDED]: values?.[FilterFields.ACTIVE_CAMPAIGN_EXCLUDED] || [],
          [FilterFields.ADMIN]: values?.[FilterFields.ADMIN] || undefined,
        }),
      }}
    >
      <Stack spacing="sm">
        <FilterCollapse defaultOpen trigger="Clients KYC/onboarding">
          <FormItem name={FilterFields.MISSING_DATA}>
            <Checkbox.Group
              options={[
                { label: "With missing data", value: BooleanFilterGroupValues.YES },
                { label: "Without missing data", value: BooleanFilterGroupValues.NO },
              ]}
            />
          </FormItem>
        </FilterCollapse>
        <FilterCollapse defaultOpen trigger="Excluded clients">
          <FormItem name={FilterFields.EXCLUDED}>
            <Checkbox.Group
              options={[
                { label: "Excluded", value: BooleanFilterGroupValues.YES },
                { label: "Not Excluded", value: BooleanFilterGroupValues.NO },
              ]}
            />
          </FormItem>
        </FilterCollapse>
        <FilterCollapse defaultOpen trigger="Admin">
          <FormItem name={FilterFields.ADMIN}>
            <RadioGroup
              options={[
                { label: "Admin (write)", value: AdminFilterGroupValues.WRITE },
                { label: "Not Admin (write)", value: AdminFilterGroupValues.NOT_WRITE },
                { label: "Admin (read)", value: AdminFilterGroupValues.READ },
                { label: "Not Admin (read)", value: AdminFilterGroupValues.NOT_READ },
              ]}
            />
          </FormItem>
        </FilterCollapse>
        <FilterCollapse defaultOpen trigger="KPI Excluded">
          <FormItem name={FilterFields.KPI_EXCLUDED}>
            <Checkbox.Group
              options={[
                { label: "Excluded", value: BooleanFilterGroupValues.YES },
                { label: "Not Excluded", value: BooleanFilterGroupValues.NO },
              ]}
            />
          </FormItem>
        </FilterCollapse>
        <FilterCollapse defaultOpen trigger="Active Campaign Excluded">
          <FormItem name={FilterFields.ACTIVE_CAMPAIGN_EXCLUDED}>
            <Checkbox.Group
              options={[
                { label: "Excluded", value: BooleanFilterGroupValues.YES },
                { label: "Not Excluded", value: BooleanFilterGroupValues.NO },
              ]}
            />
          </FormItem>
        </FilterCollapse>
      </Stack>
    </ListFilterBar>
  );
};

export const getBooleanDataFilterValue = (values: BooleanFilterGroupValues[]): boolean | undefined => {
  if (!values.length || values.length === 2) return undefined;

  return values.includes(BooleanFilterGroupValues.YES);
};

export default FiltersBar;
