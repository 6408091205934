import { Text } from "react-native";
import React from "react";
import { Description, Divider, EMPTY_VALUE_PLACEHOLDER, formatMoney } from "@budgeinc/budge-ui-core";
import { SubscriptionOutput } from "@budgeinc/budge-api";
import DateDisplay from "components/DateDisplay";
import { formatEnumValue } from "utils/format";

interface OwnProps {
  subscription: SubscriptionOutput;
}

const SubscriptionDetails = ({ subscription }: OwnProps) => (
  <>
    <Description>
      <Description.Item label="ID">{subscription.id}</Description.Item>
      <Description.Item label="User ID">{subscription.userId}</Description.Item>
    </Description>
    <Divider />
    <Description>
      <Description.Item label="Start Date">
        {subscription.startDate ? (
          <DateDisplay numberOfLines={1} value={subscription.startDate} showTime />
        ) : (
          EMPTY_VALUE_PLACEHOLDER
        )}
      </Description.Item>
      <Description.Item label="Subscription Price">
        {formatMoney({
          amountCents: subscription.subscriptionAmountCents,
        })}
      </Description.Item>
      <Description.Item label="Last Payment Date">
        {subscription.lastPaymentDate ? (
          <DateDisplay numberOfLines={1} value={subscription.lastPaymentDate} showTime />
        ) : (
          EMPTY_VALUE_PLACEHOLDER
        )}
      </Description.Item>
      <Description.Item label="Last Payment Amount">
        {formatMoney({
          amountCents: subscription.lastPaymentAmountCents,
        })}
      </Description.Item>
      <Description.Item label="Last Discount Amount">
        {formatMoney({
          amountCents: subscription.lastDiscountAmountCents,
        })}
      </Description.Item>
      <Description.Item label="Last Payment Defaut Start Date">
        <Text>
          {subscription.lastPaymentDefaultStartDate ? (
            <DateDisplay value={subscription.lastPaymentDefaultStartDate} showTime />
          ) : (
            EMPTY_VALUE_PLACEHOLDER
          )}
        </Text>
      </Description.Item>
      <Description.Item label="Last Payment Default Attempts Count">
        <Text>{subscription.lastPaymentDefaultAttemptsCount}</Text>
      </Description.Item>
      <Description.Item label="Cancellation Date">
        {subscription.cancellationDate ? (
          <DateDisplay numberOfLines={1} value={subscription.cancellationDate} showTime />
        ) : (
          EMPTY_VALUE_PLACEHOLDER
        )}
      </Description.Item>
      <Description.Item label="Cancellation Because Payment Default">
        <Text>{subscription.canceledBecauseOfPaymentDefault ? "True" : "False"}</Text>
      </Description.Item>
    </Description>
    <Divider />
    <Description>
      <Description.Item label="Free Trial">{subscription.isFreeTrial ? "True" : "False"}</Description.Item>
      <Description.Item label="Free Trial Reminder Email Sent">
        {subscription.freeTrialReminderEmailSent ? "True" : "False"}
      </Description.Item>
      <Description.Item label="Trial Days">{subscription.trialDays.toString()}</Description.Item>
      <Description.Item label="Trial End Date">
        {subscription.trialEndDate ? (
          <DateDisplay numberOfLines={1} value={subscription.trialEndDate} showTime />
        ) : (
          EMPTY_VALUE_PLACEHOLDER
        )}
      </Description.Item>
    </Description>
    <Divider />
    <Description>
      <Description.Item label="Stripe Subscription ID">
        {subscription.stripeSubscriptionId || EMPTY_VALUE_PLACEHOLDER}
      </Description.Item>
      <Description.Item label="Stripe Price Lookup Key">{subscription.stripePriceLookupKey}</Description.Item>
      <Description.Item label="Tier">{formatEnumValue(subscription.tier)}</Description.Item>
    </Description>
    <Divider />
    <Description>
      <Description.Item label="Updated At">
        <DateDisplay numberOfLines={1} value={subscription.updatedAt} showTime />
      </Description.Item>
      <Description.Item label="Created At">
        <DateDisplay numberOfLines={1} value={subscription.createdAt} showTime />
      </Description.Item>
    </Description>
  </>
);

export default SubscriptionDetails;
