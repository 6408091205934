import { ErrorResponse } from "@budgeinc/budge-api";
import { getApiErrorMessage } from "@budgeinc/budge-ui-core";
import { QueryKey, useInfiniteQuery, InfiniteData, UndefinedInitialDataInfiniteOptions } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { globalSelector } from "store/global/selector";

export const useBudgeInfiniteQuery = <
  TQueryFnData = unknown,
  TError = AxiosError<ErrorResponse>,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>({
  pageSize,
  initialPageParam = 1,
  ...options
}: Omit<
  UndefinedInitialDataInfiniteOptions<TQueryFnData, TError, InfiniteData<TData, number>, TQueryKey, number>,
  "initialPageParam" | "getNextPageParam" | "getPreviousPageParam"
> & {
  pageSize: number;
  initialPageParam?: number;
}) => {
  const { messages } = useSelector(globalSelector);
  const useInfiniteQueryResult = useInfiniteQuery<TQueryFnData, TError, InfiniteData<TData, number>, TQueryKey, number>(
    {
      initialPageParam,
      ...options,
      getNextPageParam: (lastPage, _allPages, lastPageParam) => {
        if ((lastPage as any[]).length < pageSize) {
          return undefined;
        }
        return lastPageParam + 1;
      },
      getPreviousPageParam: (_firstPage, _allPages, firstPageParam) => {
        if (firstPageParam <= 1) {
          return undefined;
        }

        return firstPageParam - 1;
      },
    }
  );

  const errorMessage = useMemo(() => {
    if (useInfiniteQueryResult.error == null) return "";

    const error = useInfiniteQueryResult.error as any as AxiosError;

    return error.response?.data ? getApiErrorMessage(messages, useInfiniteQueryResult.error as any) : error.message;
  }, [useInfiniteQueryResult.error]);

  return {
    ...useInfiniteQueryResult,
    errorMessage,
  };
};
