export const MONEY_COL_MAW = 145;

export enum DEBT_FIELDS {
  TYPE = "type",
  COMPANY_NAME = "companyName",
  BALANCE_CURRENT = "balanceCurrent",
  BALANCE_ORIGINAL = "balanceOriginal",
  INTEREST_RATE = "interestRate",
  MONTHLY_PAYMENT = "monthlyPayment",
  PROGRAM_TYPE = "programType",
  TERM = "term",
  TARGET = "target",
  COMMENT = "comment",
}
