import React, { useState } from "react";
import { Button, TButtonProps, Text, modalManager, toast } from "@budgeinc/budge-ui-core";
import { usersApi } from "api/BudgeApi";
import { UserResetPasswordRequestInputOriginEnum } from "@budgeinc/budge-api";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";

const ResetPasswordButton = (props: TButtonProps) => {
  const [loading, setLoading] = useState(false);
  const { state, dispatch } = useEmployeeEntityContext();

  const handleRequestResetPassword = () => {
    if (state.user) {
      setLoading(true);
      usersApi
        .forceResetPassword({
          email: state.user.email,
          origin: UserResetPasswordRequestInputOriginEnum.EmployeeApp,
        })
        .then(() => {
          dispatch({
            type: "setUser",
            data: {
              ...state.user!,
              forcePasswordReset: true,
            },
          });
          toast.success({
            message: "Reset password requested successfully",
          });
        })
        .finally(() => setLoading(false));
    }
  };

  const handleOpenConfirm = () =>
    modalManager.openConfirm({
      confirmProps: {
        title: "Yes",
      },
      onConfirm: handleRequestResetPassword,
      children: (
        <Text variant="bodyLarge">
          Are you sure you want to request a password reset for user:{" "}
          <Text variant="bodyLarge" fw="500">
            {state.user?.email}
          </Text>
          ?
        </Text>
      ),
    });

  return (
    <Button
      title="Request Password Reset"
      loading={loading}
      color="primary"
      variant="gradient"
      {...props}
      onPress={handleOpenConfirm}
    />
  );
};

export default ResetPasswordButton;
