import React from "react";
import { Grid, Input, MoneyInput, Text, Collapse } from "@budgeinc/budge-ui-core";
import { useDirectPaymentRouteLoaderData } from "features/direct-payments/routeConfig";

const GeneralInfoCard = () => {
  const directPaymentOutput = useDirectPaymentRouteLoaderData();

  return (
    <Collapse
      trigger={
        <Text fw="500" variant="bodyMedium">
          General Information
        </Text>
      }
      defaultOpen
    >
      <Grid column={3} gutter="md">
        <Input label="Status" value={directPaymentOutput.status} disabled />
        <Input label="Date" value={directPaymentOutput.date} disabled />
        <Input label="Processed Date" value={directPaymentOutput.processedDate} disabled />
        <MoneyInput label="Total Payment" value={directPaymentOutput.totalPaymentCents} disabled />
      </Grid>
    </Collapse>
  );
};

export default GeneralInfoCard;
