import React, { useEffect, useMemo, useReducer, useState } from "react";
import { Box, Button, Card, Stack, Text } from "@budgeinc/budge-ui-core";
import { intakeApi } from "api/BudgeApi";
import { IntakeDebtDataPointOutput } from "@budgeinc/budge-api";
import { SectionList } from "react-native";
import TabFormStateChecker from "features/financial-profiles/components/TabFormStateChecker";
import CenteredSpinner from "components/CenteredSpinner";
import DebtRow from "./components/DebtRow";
import { initialState, intakeDebtsReducer } from "./reducer";
import AddDebtButton from "./components/AddDebtButton";
import { DebtsContext } from "./context";
import DebtHeader from "./components/DebtHeader";
import { splitDataPointByMainElementType } from "../../utils";
import DebtFooter from "./components/DebtFooter";
import AutoFeedButton from "./components/AutoFeedButton";
import { useFinancialProfileEntityContext } from "../../contexts/FinancialProfileEntityContext";

const DebtsTab = () => {
  const [loading, setLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(true);
  const { state: fpState } = useFinancialProfileEntityContext();
  const [state, dispatch] = useReducer(intakeDebtsReducer, initialState);

  useEffect(() => {
    if (fpState.financialProfile?.id) {
      const promises: Promise<any>[] = [];

      promises.push(
        intakeApi.getIntakeDebtElements().then(({ data }) =>
          dispatch({
            type: "setElements",
            data,
          })
        )
      );

      if (!state.localDataPoint.length) {
        promises.push(
          intakeApi.getEmployeeIntakeDebtDataPoints(fpState.financialProfile.id).then(({ data }) =>
            dispatch({
              type: "setLocalDataPoints",
              data,
            })
          )
        );
      }

      Promise.all(promises).finally(() => setInitLoading(false));
    }
  }, [fpState.financialProfile?.id, state.localDataPoint.length]);

  const handleOnSaveChanges = async () => {
    setLoading(true);

    await Promise.all(state.formRefs.filter(({ dirty }) => !!dirty).map(formRef => formRef.formSubmitRef()));

    const errorResults = await Promise.all(
      state.formRefs.filter(({ dirty }) => !!dirty).map(formRef => formRef.validateFormRef())
    );

    setLoading(false);

    if (errorResults.some(errors => Object.keys(errors).length > 0)) {
      throw Error("Some rows have errors");
    }
  };

  const handleOnSave = () => {
    handleOnSaveChanges().catch(() => {});
  };

  const memoedContextValue = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state, dispatch]
  );

  if (initLoading) {
    return <CenteredSpinner />;
  }

  const hasNoDirtyRows = state.formRefs.every(({ dirty }) => !dirty);

  return (
    <>
      <DebtsContext.Provider value={memoedContextValue}>
        <Card>
          <Stack spacing="xl">
            {state.localDataPoint.length ? (
              <Stack spacing="md">
                <Box>
                  <DebtHeader />
                  <SectionList
                    sections={splitDataPointByMainElementType(
                      state.localDataPoint,
                      state.elements,
                      "intakeDebtElementId"
                    )}
                    renderItem={({ item: { localId, ...rest } }) => (
                      <DebtRow initialData={rest as IntakeDebtDataPointOutput} localId={localId} />
                    )}
                    renderSectionHeader={({ section: { title } }) => (
                      <Text variant="bodyMedium" fw="600" py="md" px="lg">
                        {title}
                      </Text>
                    )}
                    initialNumToRender={99}
                  />
                </Box>
                <DebtFooter />
              </Stack>
            ) : null}
            <Stack.Horizontal justifyContent="space-between">
              <AddDebtButton />
              <Stack.Horizontal>
                {fpState.financialProfile?.employeeId && <AutoFeedButton />}
                {state.localDataPoint.length > 0 && (
                  <Button
                    title="Save"
                    size="sm"
                    color="primary"
                    variant="gradient"
                    alignSelf="flex-end"
                    loading={loading}
                    onPress={handleOnSave}
                    disabled={state.formRefs.every(({ dirty }) => !dirty)}
                  />
                )}
              </Stack.Horizontal>
            </Stack.Horizontal>
          </Stack>
        </Card>
      </DebtsContext.Provider>
      <TabFormStateChecker canNavigate={hasNoDirtyRows} onSaveChanges={handleOnSaveChanges} />
    </>
  );
};

export default DebtsTab;
