import React, { useState } from "react";
import { Tabs } from "@budgeinc/budge-ui-core";
import EmailsTab from "./Emails";
import NotificationsTab from "./Notifications";

export enum COMMS_TAB_KEYS {
  EMAILS = "#emails",
  NOTIFICATIONS = "#notifications",
}

const CommsTab = () => {
  const [activeKey, setActiveKey] = useState<COMMS_TAB_KEYS>(COMMS_TAB_KEYS.EMAILS);

  return (
    <Tabs
      defaultActiveKey={COMMS_TAB_KEYS.EMAILS}
      activeKey={activeKey}
      onChange={e => setActiveKey(e as COMMS_TAB_KEYS)}
      mt={-16}
    >
      <Tabs.Pane title="Emails" tabKey={COMMS_TAB_KEYS.EMAILS}>
        <EmailsTab />
      </Tabs.Pane>
      <Tabs.Pane title="Notifications" tabKey={COMMS_TAB_KEYS.NOTIFICATIONS}>
        <NotificationsTab />
      </Tabs.Pane>
    </Tabs>
  );
};

export default CommsTab;
