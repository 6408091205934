import ProgramEnrollmentsTable from "components/Table/ProgramEnrollmentsTable";
import { employersApi, programsCrossTenantApi } from "api/BudgeApi";
import { useInfiniteScrollList } from "@budgeinc/budge-ui-core";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";

const ProgramEnrollmentListTab = () => {
  const { state: pageState, employerId } = useEmployeeEntityContext();

  const { requestState, onEndReached, updateData } = useInfiniteScrollList({
    onFetch: ({ page, pageSize }) =>
      programsCrossTenantApi
        .getEmployeeEnrollmentsForAllPrograms(
          employerId!,
          pageState.employee?.id!,
          `${pageSize * (page - 1)}`,
          pageSize.toString()
        )
        .then(({ data }) => ({ results: data })),
  });

  return (
    <ProgramEnrollmentsTable
      keyExtractor={item => item.id}
      requestState={requestState}
      onEndReached={onEndReached}
      updateData={updateData}
    />
  );
};

export default ProgramEnrollmentListTab;
