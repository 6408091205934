import {
  EmployeeInput,
  EmployeeOutput,
  EntitySyncOutput,
  ErrorResponse,
  UserWhoamiOutputScopesEnum,
} from "@budgeinc/budge-api";
import { BuildValidationSchema, yup, isEmpty } from "@budgeinc/budge-ui-utils";
import {
  Description,
  EditSaveToggleButtons,
  EMPTY_VALUE_PLACEHOLDER,
  FormItem,
  FormProvider,
  getApiErrorMessage,
  Grid,
  Input,
  Stack,
  Text,
  toast,
  Tag,
  Box,
  AccessScopeValidator,
  TAccessOperation,
  Collapse,
} from "@budgeinc/budge-ui-core";
import { useState } from "react";
import { useFormik } from "formik";
import { employeesCrossTenantApi, employersApi, methodApi } from "api/BudgeApi";
import { AxiosError } from "axios";
import { useAppMessages } from "store/global";
import MethodExternalButton from "components/Method/MethodExternalButton";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";
import MethodRawDetailsButton from "components/Method/MethodRawDetailsButton";
import ShowSyncAuditButton from "components/Method/ShowSyncAuditButton";
import MethodSyncButton from "components/Method/MethodSyncButton";
import {
  MethodAccessScopeValidator,
  MethodReadAccessScopeValidator,
} from "components/AccessScopeValidator/validators/MethodAccessScopeValidator";

enum FIELDS {
  METHOD_ENTITY_ID = "methodEntityId",
  METHOD_LAST_SYNC = "methodLastSync",
  STATUS = "methodStatus",
}

type TFormValues = Record<FIELDS, any>;

const validationSchema = BuildValidationSchema({
  [FIELDS.METHOD_ENTITY_ID]: yup.string(),
});

const MethodInfoCard = () => {
  const messages = useAppMessages();
  const [editModeOn, setEditMode] = useState(false);
  const { state, dispatch, employerId } = useEmployeeEntityContext();
  const [formError, setFormError] = useState<string | undefined>();
  const activeEmployee = state.employee!;

  const form = useFormik({
    initialValues: getFormInitialValues(state.employee!),
    enableReinitialize: true,
    validationSchema,
    onSubmit: async values =>
      employeesCrossTenantApi
        .updateEmployeeForEmployer(employerId!, activeEmployee.id, getEmployeePayload(values))
        .then(resp => {
          dispatch({
            type: "set",
            data: resp.data,
          });
          setEditMode(false);
          setFormError(undefined);
          toast.success({
            message: "Customer saved successfully",
          });
        })
        .catch((error: AxiosError<ErrorResponse>) => {
          setFormError(getApiErrorMessage(messages, error.response?.data));
        }),
  });

  return (
    <Collapse
      trigger={
        <Text fw="500" variant="bodyMedium">
          Method
        </Text>
      }
    >
      <FormProvider value={form} formErrorMsg={formError}>
        <Stack spacing="xl">
          <Grid column={3} gutter="md">
            <FormItem name={FIELDS.METHOD_ENTITY_ID}>
              <Input
                label="Method Entity ID"
                disabled={!editModeOn}
                rightSection={
                  activeEmployee.methodEntityId && (
                    <Stack.Horizontal>
                      <MethodExternalButton
                        buttonType="actionIcon"
                        type="entities"
                        recordId={activeEmployee.methodEntityId}
                      />
                      <MethodReadAccessScopeValidator>
                        <MethodRawDetailsButton type="entityRaw" recordId={activeEmployee.methodEntityId} />
                      </MethodReadAccessScopeValidator>
                      <MethodAccessScopeValidator>
                        <MethodSyncButton<EntitySyncOutput>
                          type="entitySync"
                          recordId={activeEmployee.methodEntityId}
                          onSynced={payload => {
                            dispatch({
                              type: "set",
                              data: payload.employee,
                            });
                          }}
                        />
                      </MethodAccessScopeValidator>
                    </Stack.Horizontal>
                  )
                }
              />
            </FormItem>
            <FormItem name={FIELDS.METHOD_LAST_SYNC}>
              <Input label="Method Last Sync" disabled={!editModeOn} />
            </FormItem>
          </Grid>
          <Description>
            <Description.Item label="Method Subscriptions" alignItems="center">
              {isEmpty(activeEmployee.methodSubscriptionsRaw)
                ? EMPTY_VALUE_PLACEHOLDER
                : JSON.parse(activeEmployee.methodSubscriptionsRaw!).map((sub: any) => <Tag key={sub} value={sub} />)}
            </Description.Item>
            <Description.Item label="Method Products" alignItems="center">
              {isEmpty(activeEmployee.methodProductsRaw)
                ? EMPTY_VALUE_PLACEHOLDER
                : JSON.parse(activeEmployee.methodProductsRaw!).map((product: any) => (
                    <Tag key={product} value={product} />
                  ))}
            </Description.Item>
            <Description.Item label="Method Error Code" alignItems="center">
              {activeEmployee.methodErrorCode?.toString() || EMPTY_VALUE_PLACEHOLDER}
            </Description.Item>
            <Description.Item label="Method Error Message" alignItems="center">
              {activeEmployee.methodErrorMessage || EMPTY_VALUE_PLACEHOLDER}
            </Description.Item>
            <Description.Item label="Method Previous Entity IDs" alignItems="center">
              {activeEmployee.methodEntityIdPrevious || EMPTY_VALUE_PLACEHOLDER}
            </Description.Item>
          </Description>
          <Stack.Horizontal justifyContent="space-between">
            <Box>
              {activeEmployee.methodEntityId && (
                <ShowSyncAuditButton
                  modalTitle="Method Entity Sync History"
                  api={() => methodApi.getEntitySyncHistory(activeEmployee.methodEntityId!)}
                  size="sm"
                />
              )}
            </Box>
            <AccessScopeValidator op={TAccessOperation.WRITE} rule={[UserWhoamiOutputScopesEnum.Clients]}>
              <EditSaveToggleButtons
                isEditModeEnabled={editModeOn}
                setEditMode={setEditMode}
                onSubmit={form.submitForm}
                saveBtnDisabled={!form.dirty}
                onCancel={() => {
                  form.setValues(form.initialValues, true);
                  setFormError(undefined);
                }}
                loading={form.isSubmitting}
              />
            </AccessScopeValidator>
          </Stack.Horizontal>
        </Stack>
      </FormProvider>
    </Collapse>
  );
};

const getFormInitialValues = (employee: EmployeeOutput): TFormValues => ({
  [FIELDS.METHOD_ENTITY_ID]: employee.methodEntityId || "",
  [FIELDS.METHOD_LAST_SYNC]: employee.methodLastSync || "",
  [FIELDS.STATUS]: employee.status,
});

const getEmployeePayload = (values: TFormValues): EmployeeInput => ({
  methodEntityId: values.methodEntityId || undefined,
});

export default MethodInfoCard;
