import {
  FinancialAccountTransferOutput,
  FinancialAccountTransferSearchCriteriaInput,
  FinancialAccountTransferSearchCriteriaInputStatusEnum,
} from "@budgeinc/budge-api";
import { Description, InfiniteScrollTable, Text, useInfiniteScrollList } from "@budgeinc/budge-ui-core";
import { accountsCrossTenantApi, employersApi } from "api/BudgeApi";
import { useCallback, useEffect, useMemo, useReducer } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { accountEntityRoute } from "common/routes";
import { getPersistedFilters, hasFilters } from "components/ListFilterBar/utils";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";
import { TListFilterContextType } from "components/ListFilterBar/context";
import { paramAsList } from "utils/queryParams";
import { getTransfersColumns } from "./columns";
import { TransferFilterContext, TransferFilterReducer } from "./context";
import FiltersBar, { FilterFields, TFormValues } from "./components/FiltersBar";

const EmployeeTransfersFilterId = "employee-transfers-filters";

export const getInitialFilters = (params?: URLSearchParams): FinancialAccountTransferSearchCriteriaInput => {
  const filters = getPersistedFilters<TFormValues>(EmployeeTransfersFilterId);

  const statusParams = paramAsList(
    params?.get(FilterFields.STATUS)
  ) as FinancialAccountTransferSearchCriteriaInputStatusEnum[];
  const dateFromParam = params?.get(FilterFields.DATE_FROM);
  const dateToParam = params?.get(FilterFields.DATE_TO);

  return {
    status: statusParams.length ? statusParams : filters?.[FilterFields.STATUS] || undefined,
    processedDateFrom: dateFromParam || (filters?.[FilterFields.DATE_FROM] as any) || undefined,
    processedDateTo: dateToParam || (filters?.[FilterFields.DATE_TO] as any) || undefined,
  };
};

const TransfersListTab = () => {
  const { state: pageState, employerId } = useEmployeeEntityContext();
  const [params] = useSearchParams();
  const initialFilters = getInitialFilters(params);
  const [filters, dispatch] = useReducer(TransferFilterReducer, initialFilters);

  const handleOnFetch = useCallback(
    ({ page, pageSize }: { page: number; pageSize: number }) =>
      accountsCrossTenantApi
        .searchEmployeeAccountTransfers(
          employerId!,
          pageState.employee?.id!,
          filters,
          `${pageSize * (page - 1)}`,
          pageSize.toString()
        )
        .then(({ data }) => ({
          results: data,
        })),
    [JSON.stringify(filters)]
  );

  const { requestState, onEndReached, forceRefresh } = useInfiniteScrollList({
    onFetch: handleOnFetch,
    skipFirstForceRefresh: true,
  });

  useEffect(() => {
    forceRefresh();
  }, [JSON.stringify(filters)]);

  const memoedContextValues = useMemo<TListFilterContextType>(
    () => ({
      state: filters,
      dispatch,
      initialFilters,
    }),
    [filters, dispatch]
  );

  return (
    <TransferFilterContext.Provider value={memoedContextValues}>
      <FiltersBar persistId={EmployeeTransfersFilterId} />
      <InfiniteScrollTable<FinancialAccountTransferOutput>
        columns={getTransfersColumns()}
        keyExtractor={item => item.id}
        requestState={requestState}
        onEndReached={onEndReached}
        local={{
          empty: {
            m: 24,
            title: "No account transfers found",
            description: hasFilters(memoedContextValues)
              ? `No transfer match these filters. Edit or clear all filters.`
              : undefined,
          },
          noMoreItems: "No more account transfers to load",
        }}
        contentContainerStyle={{ marginBottom: 16 }}
        expandable={record => (
          <Description>
            <Description.Item label="ID">{record.id}</Description.Item>
            <Description.Item label="Account ID (From)">
              <Text>
                <Link to={accountEntityRoute(employerId!, pageState.employee?.id!, record.financialAccountFromId)}>
                  {record.financialAccountFromId}
                </Link>
              </Text>
            </Description.Item>
            <Description.Item label="Account ID (To)">
              <Text>
                <Link to={accountEntityRoute(employerId!, pageState.employee?.id!, record.financialAccountToId)}>
                  {record.financialAccountToId}
                </Link>
              </Text>
            </Description.Item>
          </Description>
        )}
      />
    </TransferFilterContext.Provider>
  );
};

export default TransfersListTab;
