import React from "react";
import { Description, Divider, Collapse, Stack, Text, formatDate, formatMoney } from "@budgeinc/budge-ui-core";
import { DebtPaymentPlanOutput } from "@budgeinc/budge-api";
import DateDisplay from "components/DateDisplay";
import ShowRawDataButton from "components/ShowRawDataButton";
import DebtPlanStatusTag from "./DebtPlanStatusTag";

interface OwnProps {
  plan: DebtPaymentPlanOutput;
}

const GeneralInfoCard = ({ plan }: OwnProps) => (
  <Collapse
    trigger={
      <Stack.Horizontal alignItems="center">
        <Text fw="500" variant="bodyMedium">
          General Information
        </Text>
        <DebtPlanStatusTag status={plan.debtPaymentPlanStatus} />
      </Stack.Horizontal>
    }
    defaultOpen
  >
    <Description>
      <Description.Item label="ID">{plan.id}</Description.Item>
      <Description.Item label="Strategy">{plan.debtPaymentPlanType}</Description.Item>
      <Description.Item label="Skip Accounts Without Connectivity">
        {plan.skipAccountsWithoutConnectivity ? "True" : "False"}
      </Description.Item>
      <Description.Item label="Payoff Action Required">
        {plan.accountPayoffActionIsRequired ? "True" : "False"}
      </Description.Item>
      <Description.Item label="Payoff Reminder Sent At">
        {formatDate(plan.accountPayoffReminderSentAt)}
      </Description.Item>
      <>
        {plan.planResult && (
          <>
            <Divider />
            <Description.Item label="Months Without Plan">
              <Text>{plan.planResult.monthsToPayoffWithoutPlan}</Text>
            </Description.Item>
            <Description.Item label="Months With Plan">
              <Text>{plan.planResult.monthsToPayoffWithPlan}</Text>
            </Description.Item>
            <Description.Item label="Months Saved">
              <Text> {plan.planResult.monthsToPayoffWithoutPlan - plan.planResult.monthsToPayoffWithPlan}</Text>
            </Description.Item>
            <Divider />
            <Description.Item label="Interest Paid Without Plan">
              {formatMoney({
                amountCents: plan.planResult.interestPaidCentsWithoutPlan,
              })}
            </Description.Item>
            <Description.Item label="Interest Paid With Plan">
              {formatMoney({
                amountCents: plan.planResult.interestPaidCentsWithPlan,
              })}
            </Description.Item>
            <Description.Item label="Interest Saved">
              {formatMoney({
                amountCents: plan.planResult.savedInterestsAmountCents,
              })}
            </Description.Item>
            <Divider />
          </>
        )}
      </>
      <Divider />
      <Description.Item label="Created At">
        <DateDisplay value={plan.createdAt} showTime />
      </Description.Item>
      <Description.Item label="Updated At">
        <DateDisplay value={plan.updatedAt} showTime />
      </Description.Item>
    </Description>
    <ShowRawDataButton data={plan} alignSelf="flex-start" mt="xl" size="xs" />
  </Collapse>
);

export default GeneralInfoCard;
