import React, { useEffect, useState } from "react";
import { Select, Stack, TSelectOption, TSelectProps, Text, formatMoney, isUUID } from "@budgeinc/budge-ui-core";
import { accountsApi, accountsCrossTenantApi, employeesApi } from "api/BudgeApi";
import { FinancialAccountOutput } from "@budgeinc/budge-api";

type OwnProps = Omit<TSelectProps, "enableSearch" | "onSearch" | "options"> & {
  employerId: string;
  employeeId: string;
  idsToExclude?: string[];
  onAccountChange?: (account: FinancialAccountOutput | null | undefined) => void;
};

const EmployeeAccountSearchSelect = ({
  employerId,
  employeeId,
  idsToExclude = [],
  onAccountChange,
  ...props
}: OwnProps) => {
  const [accounts, setAccounts] = useState<FinancialAccountOutput[]>([]);

  useEffect(() => {
    if (employeeId && isUUID(employeeId)) {
      accountsApi
        .getEmployeeFinancialAccountsAll(employeeId)
        .then(({ data }) => data.filter(({ id }) => !idsToExclude.includes(id)))
        .then(data => setAccounts(data))
        .catch(() => {});
    }
  }, [employeeId]);

  return (
    <Select
      {...props}
      enableSearch
      includeEmptyOption
      onChange={accountId => {
        onAccountChange?.(accounts.find(({ id }) => id === accountId));
      }}
      options={accounts.map(account => getSelectOption(account))}
    />
  );
};

const getSelectOption = (account: FinancialAccountOutput): TSelectOption => ({
  label: account.name,
  customContent: (
    <Stack spacing={0}>
      <Text>{account.name}</Text>
      <Text color="secondary" variant="bodySmall">
        {formatMoney({ amountCents: account.balanceCurrentCents })}
      </Text>
    </Stack>
  ),
  value: account.id,
});

export default EmployeeAccountSearchSelect;
