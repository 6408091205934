import React from "react";
import ListFilterBar, { TFilterBarProps } from "components/ListFilterBar";
import { BuildValidationSchema, Checkbox, FormItem, Input, Stack, yup } from "@budgeinc/budge-ui-core";
import FilterCollapse from "components/ListFilterBar/FilterCollapse";
import { SubscriptionSearchCriteriaInputStatusEnum } from "@budgeinc/budge-api";
import { formatEnumValue } from "utils/format";
import { useSubscriptionFilterContext } from "../context";

export enum FilterFields {
  STATUS = "status",
  STRIPE_SUBSCRIPTION_ID = "stripeSubscriptionId",
}

export type TFormValues = {
  [FilterFields.STATUS]: SubscriptionSearchCriteriaInputStatusEnum[];
  [FilterFields.STRIPE_SUBSCRIPTION_ID]: string;
};

const validationSchema = BuildValidationSchema({
  [FilterFields.STATUS]: yup.array().of(yup.string().oneOf(Object.values(SubscriptionSearchCriteriaInputStatusEnum))),
  [FilterFields.STRIPE_SUBSCRIPTION_ID]: yup.string(),
});

const FiltersBar = (props: TFilterBarProps) => {
  const { dispatch, initialFilters } = useSubscriptionFilterContext();

  const handleOnSearch = (value: string | undefined) =>
    dispatch({
      type: "updateFilters",
      data: {
        genericSearchLike: value,
      },
    });

  return (
    <ListFilterBar<TFormValues>
      {...props}
      showSearchBar
      onSearchChange={handleOnSearch}
      searchPlaceHolder="Search by first name, last name or email"
      formProps={{
        onSubmit: async values => {
          const status = values[FilterFields.STATUS];

          dispatch({
            type: "updateFilters",
            data: {
              status: status.length ? status : undefined,
              stripeSubscriptionId: values[FilterFields.STRIPE_SUBSCRIPTION_ID] || undefined,
            },
          });
        },
        validationSchema,
        getInitialValues: reset => {
          if (initialFilters && !reset) {
            return {
              [FilterFields.STATUS]: initialFilters.status || [],
              [FilterFields.STRIPE_SUBSCRIPTION_ID]: initialFilters.stripeSubscriptionId || "",
            };
          }

          return {
            [FilterFields.STATUS]: [],
            [FilterFields.STRIPE_SUBSCRIPTION_ID]: "",
          };
        },
      }}
    >
      <Stack>
        <FilterCollapse defaultOpen trigger="Stripe">
          <FormItem name={FilterFields.STRIPE_SUBSCRIPTION_ID}>
            <Input label="Subscription ID" />
          </FormItem>
        </FilterCollapse>
        <FilterCollapse defaultOpen trigger="Status">
          <FormItem name={FilterFields.STATUS}>
            <Checkbox.Group
              options={Object.values(SubscriptionSearchCriteriaInputStatusEnum).map(status => ({
                label: formatEnumValue(status),
                value: status,
              }))}
            />
          </FormItem>
        </FilterCollapse>
      </Stack>
    </ListFilterBar>
  );
};

export default FiltersBar;
