import { EmployeeOutput, FinancialAccountOutput } from "@budgeinc/budge-api";

export type TAccountEntityState = {
  account?: FinancialAccountOutput;
  notFound: boolean;
  employee?: EmployeeOutput;
};

export type TAccountEntityAction =
  | {
      type: "set";
      data: FinancialAccountOutput;
    }
  | {
      type: "setEmployee";
      data: EmployeeOutput;
    }
  | { type: "notFound" };

export const initialState: TAccountEntityState = {
  account: undefined,
  notFound: false,
};

export const accountDetailPageReducer = (
  state: TAccountEntityState,
  action: TAccountEntityAction
): TAccountEntityState => {
  switch (action.type) {
    case "set":
      return {
        ...state,
        notFound: false,
        account: action.data,
      };
    case "setEmployee":
      return {
        ...state,
        notFound: false,
        employee: action.data,
      };
    case "notFound":
      return {
        ...state,
        notFound: true,
      };
  }
};
