import React from "react";
import { OnePaymentPlanOutput } from "@budgeinc/budge-api";
import { Description, Table, Text, Collapse } from "@budgeinc/budge-ui-core";
import { getAccountColumns } from "./columns";

interface OwnProps {
  onePayPlan: OnePaymentPlanOutput;
  employerId: string;
}

const AccountsCard = ({ onePayPlan, employerId }: OwnProps) => (
  <Collapse
    trigger={
      <Text fw="500" variant="bodyMedium">
        Plan Accounts ({onePayPlan.onePaymentPlanAccounts.length})
      </Text>
    }
    expandable={false}
    defaultOpen
  >
    <Table
      data={onePayPlan.onePaymentPlanAccounts}
      variant="dark"
      columns={getAccountColumns(employerId!)}
      local={{
        empty: {
          m: 24,
          title: "No associated accounts",
        },
      }}
      expandable={record => (
        <Description>
          <Description.Item label="ID">{record.financialAccount.id}</Description.Item>
        </Description>
      )}
    />
  </Collapse>
);

export default AccountsCard;
