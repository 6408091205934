import React, { useCallback, useMemo } from "react";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";
import {
  Description,
  InfiniteScrollTable,
  useInfiniteScrollList,
  AccessScopeValidator,
  TAccessOperation,
} from "@budgeinc/budge-ui-core";
import { authzApi } from "api/BudgeApi";
import { AuthProfileUserOutput, UserWhoamiOutputScopesEnum } from "@budgeinc/budge-api";
import { InfiniteScrollDataTableContextType } from "components/Table/tableContext";
import { AuthProfileUserDataTableContext } from "components/Table/AuthProfileUserTable/AuthProfileUserDataTableContext";

import { getAuthProfileUserColumns } from "./columns";
import AddProfileButton from "./components/AddProfile/AddProfileButton";

const AuthorizationsListTab = () => {
  const employee = useEmployeeEntityContext();
  const { requestState, onEndReached, updateData } = useInfiniteScrollList({
    onFetch: ({ page, pageSize }) =>
      authzApi
        .getAuthProfileUsersForUser(employee.state.user?.id!, `${pageSize * (page - 1)}`, pageSize.toString())
        .then(({ data }) => ({ results: data })),
  });

  const updateRecord = useCallback(
    (newRecord: AuthProfileUserOutput) => {
      updateData(requestState.data.map(record => (record.id === newRecord.id ? newRecord : record)));
    },
    [requestState]
  );

  const removeRecord = useCallback(
    (recordToRemove: AuthProfileUserOutput) => {
      updateData(requestState.data.filter(record => record.id !== recordToRemove.id));
    },
    [requestState]
  );

  const addManyRecords = useCallback(
    (newRecords: AuthProfileUserOutput[]) => {
      updateData([...requestState.data, ...newRecords]);
    },
    [requestState]
  );
  const memoedContext = useMemo<InfiniteScrollDataTableContextType<AuthProfileUserOutput>>(
    () => ({
      updateRecord,
      removeRecord,
      addManyRecords,
    }),
    [addManyRecords, updateData, removeRecord]
  );

  return (
    <AuthProfileUserDataTableContext.Provider value={memoedContext}>
      <AccessScopeValidator op={TAccessOperation.READ} rule={[UserWhoamiOutputScopesEnum.Security]}>
        <AddProfileButton currentAuthProfileIds={requestState.data.map(({ authProfile }) => authProfile.id)} />
      </AccessScopeValidator>
      <InfiniteScrollTable<AuthProfileUserOutput>
        columns={getAuthProfileUserColumns()}
        keyExtractor={item => item.id}
        requestState={requestState}
        onEndReached={onEndReached}
        local={{
          empty: {
            m: 24,
            title: "No profiles found",
          },
        }}
        contentContainerStyle={{ marginBottom: 16 }}
        expandable={record => (
          <Description>
            <Description.Item label="ID">{record.id}</Description.Item>
          </Description>
        )}
      />
    </AuthProfileUserDataTableContext.Provider>
  );
};

export default AuthorizationsListTab;
