import {
  SubscriptionSearchCriteriaInput,
  SubscriptionWithEmployeeOutput,
  SubscriptionSearchCriteriaInputStatusEnum,
  UserWhoamiOutputScopesEnum,
} from "@budgeinc/budge-api";
import { InfiniteScrollTable, useInfiniteScrollList } from "@budgeinc/budge-ui-core";
import { useEmployerContext, useIsEmployerD2C } from "features/employers/contexts/EmployerContext";
import { employersApi, subscriptionsCrossTenantApi } from "api/BudgeApi";
import { useCallback, useEffect, useMemo, useReducer } from "react";
import { getPersistedFilters, hasFilters } from "components/ListFilterBar/utils";
import { TListFilterContextType } from "components/ListFilterBar/context";
import { useSearchParams } from "react-router-dom";
import { paramAsList } from "utils/queryParams";
import ExportAccessScopeValidator from "components/AccessScopeValidator/validators/ExportAccessScopeValidator";
import SubscriptionDetails from "features/employees/tabs/Subscription/SubscriptionDetails";
import { getSubscriptionsColumns } from "./columns";
import FiltersBar, { FilterFields, TFormValues } from "./components/FiltersBar";
import { SubscriptionFilterContext, SubscriptionFilterReducer } from "./context";
import ExportSubscriptionsButton from "./components/ExportSubscriptionsButton";

const EmployerSubscriptionsListPersistFilterId = "employer-subscriptions-list-filters";

const getInitialFilters = (params?: URLSearchParams): SubscriptionSearchCriteriaInput => {
  const filters = getPersistedFilters<TFormValues>(EmployerSubscriptionsListPersistFilterId);

  const statusParam = paramAsList(params?.get(FilterFields.STATUS)) as SubscriptionSearchCriteriaInputStatusEnum[];
  const stripeSubscriptionIdParam = params?.get(FilterFields.STRIPE_SUBSCRIPTION_ID);

  return {
    status: statusParam.length ? statusParam : filters?.[FilterFields.STATUS] || undefined,
    stripeSubscriptionId: stripeSubscriptionIdParam || filters?.[FilterFields.STRIPE_SUBSCRIPTION_ID] || undefined,
    genericSearchLike: undefined,
  };
};

const SubscriptionsListTab = () => {
  const isD2c = useIsEmployerD2C();
  const {
    state: { employer },
  } = useEmployerContext();
  const isD2C = useIsEmployerD2C();
  const [params] = useSearchParams();
  const initialFilters = getInitialFilters(params);
  const [filters, dispatch] = useReducer(SubscriptionFilterReducer, initialFilters);

  const handleOnFetch = useCallback(
    ({ page, pageSize }: { page: number; pageSize: number }) =>
      subscriptionsCrossTenantApi
        .searchEmployerSubscriptions(employer?.id!, filters, `${pageSize * (page - 1)}`, pageSize.toString())
        .then(({ data }) => ({
          results: data,
        })),
    [JSON.stringify(filters)]
  );

  const { requestState, onEndReached, forceRefresh } = useInfiniteScrollList({
    onFetch: handleOnFetch,
    skipFirstForceRefresh: true,
  });

  useEffect(() => {
    forceRefresh();
  }, [JSON.stringify(filters)]);

  const memoedFilterContextValues = useMemo<TListFilterContextType<SubscriptionSearchCriteriaInput>>(
    () => ({
      state: filters,
      dispatch,
      initialFilters,
    }),
    [filters, dispatch]
  );

  return (
    <SubscriptionFilterContext.Provider value={memoedFilterContextValues}>
      <FiltersBar
        persistId={EmployerSubscriptionsListPersistFilterId}
        extra={
          <ExportAccessScopeValidator rule={[UserWhoamiOutputScopesEnum.Subscriptions]}>
            <ExportSubscriptionsButton />
          </ExportAccessScopeValidator>
        }
      />
      <InfiniteScrollTable<SubscriptionWithEmployeeOutput>
        columns={getSubscriptionsColumns()}
        keyExtractor={item => item.id}
        requestState={requestState}
        onEndReached={onEndReached}
        local={{
          empty: {
            m: 24,
            title: "No subscriptions found",
            description: hasFilters(memoedFilterContextValues)
              ? `No subscription match these filters. Edit or clear all filters.`
              : undefined,
          },
          noMoreItems: "No more subscriptions to load",
        }}
        contentContainerStyle={{ marginBottom: 16 }}
        expandable={record => <SubscriptionDetails subscription={record} />}
      />
    </SubscriptionFilterContext.Provider>
  );
};

export default SubscriptionsListTab;
