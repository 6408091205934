import { EmployerOnboardingStatusOutput, FinancialAccountOutput } from "@budgeinc/budge-api";

export type TEmployerEntityState = {
  fundingAccount?: FinancialAccountOutput;
  onboardingInfo: EmployerOnboardingStatusOutput;
};

export type TEmployerEntityAction =
  | { type: "setFundingAccount"; data: FinancialAccountOutput }
  | { type: "setOnboardingStatus"; data: EmployerOnboardingStatusOutput };

export const initialState: TEmployerEntityState = {
  onboardingInfo: {
    percentCompleted: 0,
    statusItems: [],
  },
};

export const employerEntityReducer = (
  state: TEmployerEntityState,
  action: TEmployerEntityAction
): TEmployerEntityState => {
  switch (action.type) {
    case "setFundingAccount":
      return {
        ...state,
        fundingAccount: action.data,
      };
    case "setOnboardingStatus":
      return {
        ...state,
        onboardingInfo: action.data,
      };
  }
};
