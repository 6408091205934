import TransactionsTable from "components/Table/TransactionsTable";
import { TransactionInfiniteScrollTableContext } from "components/Table/TransactionsTable/context";
import { useCallback, useEffect, useMemo, useReducer } from "react";
import { employersApi, programsCrossTenantApi } from "api/BudgeApi";
import { useInfiniteScrollList } from "@budgeinc/budge-ui-core";
import { ProgramTransactionAdminOutputWithId } from "components/Table/TransactionsTable/types";
import FiltersBar from "components/Table/TransactionsTable/filters/FiltersBar";
import {
  ProgramTransactionFilterContext,
  ProgramTransactionFilterReducer,
} from "components/Table/TransactionsTable/filters/context";
import { getInitialFilters } from "components/Table/TransactionsTable/filters/utils";
import { useProgramEntityContext } from "features/programs/contexts/ProgramEntityContext";
import { InfiniteScrollDataTableContextType } from "components/Table/tableContext";

const ProgramTransactionsListPersistFilterId = "program-transactions-list-filters";

const TransactionsListTab = () => {
  const { state: pageState } = useProgramEntityContext();
  const [filters, dispatch] = useReducer(
    ProgramTransactionFilterReducer,
    getInitialFilters(ProgramTransactionsListPersistFilterId)
  );

  const handleOnFetch = useCallback(
    ({ page, pageSize }: { page: number; pageSize: number }) =>
      programsCrossTenantApi
        .searchTransactionsForProgram(
          pageState.employer?.id!,
          pageState.program?.program.id!,
          filters,
          `${pageSize * (page - 1)}`,
          pageSize.toString()
        )
        .then(({ data }) => ({
          results: data.map(record => ({
            id: record.programTransaction.id,
            ...record,
          })),
        })),
    [JSON.stringify(filters)]
  );

  const { requestState, updateData, onEndReached, forceRefresh } = useInfiniteScrollList({
    onFetch: handleOnFetch,
    skipFirstForceRefresh: true,
  });

  const updateRecord = (newRecord: ProgramTransactionAdminOutputWithId) => {
    updateData(requestState.data.map(record => (record.id === newRecord.id ? newRecord : record)));
  };

  useEffect(() => {
    forceRefresh();
  }, [JSON.stringify(filters)]);

  const memoedFilterContextValues = useMemo(
    () => ({
      state: filters,
      dispatch,
    }),
    [filters, dispatch]
  );

  const memoedContextValues = useMemo<InfiniteScrollDataTableContextType<ProgramTransactionAdminOutputWithId>>(
    () => ({
      updateRecord,
    }),
    [updateRecord, updateData]
  );

  return (
    <ProgramTransactionFilterContext.Provider value={memoedFilterContextValues}>
      <TransactionInfiniteScrollTableContext.Provider value={memoedContextValues}>
        <FiltersBar persistId={ProgramTransactionsListPersistFilterId} />
        <TransactionsTable
          requestState={requestState}
          onEndReached={onEndReached}
          keyExtractor={item => item.programTransaction.id}
          onTransactionUpdated={t => {
            updateRecord({
              ...t,
              id: t.programTransaction.id,
            });
          }}
          showEmployee
        />
      </TransactionInfiniteScrollTableContext.Provider>
    </ProgramTransactionFilterContext.Provider>
  );
};

export default TransactionsListTab;
