import React from "react";
import { Text, Collapse } from "@budgeinc/budge-ui-core";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";
import EmployeeStatusInfoContent from "./components/EmployeeStatusInfoContent";

const StatusInfoCard = () => {
  const { state } = useEmployeeEntityContext();

  return (
    <Collapse
      trigger={
        <Text fw="500" variant="bodyMedium">
          Status
        </Text>
      }
      defaultOpen
    >
      <EmployeeStatusInfoContent
        record={{
          ...state.employee!,
          user: state.user || undefined,
        }}
      />
    </Collapse>
  );
};

export default StatusInfoCard;
