import { getPersistedFilters } from "components/ListFilterBar/utils";
import { InviteSearchCriteriaInput } from "@budgeinc/budge-api";
import { FilterFields, TFormValues } from "./FiltersBar";

export const getInitialFilters = (persistId: string): InviteSearchCriteriaInput => {
  const filters = getPersistedFilters<TFormValues>(persistId);

  return {
    status: filters?.[FilterFields.STATUS] || undefined,
    genericSearchLike: undefined,
  };
};
