import { AuthProfileUserOutput, UserWhoamiOutputScopesEnum } from "@budgeinc/budge-api";
import { TColumnProps, Text, AccessScopeValidator, TAccessOperation } from "@budgeinc/budge-ui-core";
import DateDisplay from "components/DateDisplay";

import RemoveProfileButton from "./components/RemoveProfileButton";

export const getAuthProfileUserColumns = (): TColumnProps<AuthProfileUserOutput>[] => [
  {
    id: "profilename",
    title: "Profile Name",
    maxWidth: 250,
    render: ({ authProfile }) => <Text numberOfLines={1}>{authProfile.name}</Text>,
  },
  {
    id: "description",
    title: "Description",
    render: ({ authProfile }) => <Text numberOfLines={1}>{authProfile.description}</Text>,
  },
  {
    id: "createdat",
    title: "Created at",
    render: ({ createdAt }) => <DateDisplay numberOfLines={1} value={createdAt} showTime />,
  },
  {
    title: "",
    id: "action",
    align: "flex-end",
    maxWidth: 250,
    render: record => (
      <AccessScopeValidator op={TAccessOperation.WRITE} rule={[UserWhoamiOutputScopesEnum.Security]}>
        <RemoveProfileButton authProfileUser={record} />
      </AccessScopeValidator>
    ),
  },
];
