import {
  IntakeCashFlowDataPointInputFrequencyEnum,
  IntakeCashFlowDataPointInputIntakeTargetEnum,
  IntakeCashFlowDataPointOutput,
  IntakeCashFlowElementOutput,
} from "@budgeinc/budge-api";
import {
  TGridTableState,
  createGridTableReducer,
  TLocalDataPointBase,
  TGridTableAction,
} from "features/financial-profiles/components/GridTable/reducer";

export type TCashFlowFormRefComputable = {
  incomeAmountCents: number;
  expenseAmountCents: number;
  frequency: IntakeCashFlowDataPointInputFrequencyEnum;
  target: IntakeCashFlowDataPointInputIntakeTargetEnum;
};

export type TLocalCashFlowDataPoint = TLocalDataPointBase<IntakeCashFlowDataPointOutput>;

export type TIntakeCashFlowState = TGridTableState<
  IntakeCashFlowDataPointOutput,
  IntakeCashFlowElementOutput,
  TCashFlowFormRefComputable
>;

export type TIntakeCashFlowAction = TGridTableAction<
  IntakeCashFlowDataPointOutput,
  IntakeCashFlowElementOutput,
  TCashFlowFormRefComputable
>;

export const intakeCashFlowReducer = createGridTableReducer<
  IntakeCashFlowDataPointOutput,
  IntakeCashFlowElementOutput,
  TCashFlowFormRefComputable
>();

export const initialState: TIntakeCashFlowState = {
  localDataPoint: [],
  elements: [],
  formRefs: [],
  refreshIndex: 0,
};
