import React, { useContext } from "react";

import { initialState, TAccountEntityAction, TAccountEntityState } from "./reducer";

interface AccountEntityContextType {
  dispatch: React.Dispatch<TAccountEntityAction>;
  state: TAccountEntityState;
}

export const AccountEntityContext = React.createContext<AccountEntityContextType>({
  dispatch: () => {},
  state: initialState,
});

export const useAccountEntityContext = () => useContext(AccountEntityContext);
