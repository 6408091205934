import { createListFilterReducer, TListFilterState } from "components/ListFilterBar/reducer";
import { createListFilterContext } from "components/ListFilterBar/context";
import { CreditScoreSearchCriteriaInput } from "@budgeinc/budge-api";
import { useContext } from "react";

export const initialState: TListFilterState<CreditScoreSearchCriteriaInput> = {
  genericSearchLike: undefined,
};

export const CreditScoreFilterReducer = createListFilterReducer<CreditScoreSearchCriteriaInput>(initialState);
export const CreditScoreFilterContext = createListFilterContext<CreditScoreSearchCriteriaInput>(initialState);

export const useCreditScoreFilterContext = () => {
  const context = useContext(CreditScoreFilterContext);

  if (!context) {
    throw new Error("CreditScoreFilterContext must be used inside an CreditScoreFilterContext.Provider");
  }

  return context;
};
