import React from "react";
import ListFilterBar, { TFilterBarProps } from "components/ListFilterBar";
import { BuildValidationSchema, Checkbox, FormItem, Stack, yup } from "@budgeinc/budge-ui-core";
import FilterCollapse from "components/ListFilterBar/FilterCollapse";
import { InviteSearchCriteriaInputStatusEnum } from "@budgeinc/budge-api";
import { capitalize } from "@budgeinc/budge-ui-utils";
import { useInviteFilterContext } from "./context";

export enum FilterFields {
  STATUS = "status",
}

export type TFormValues = {
  [FilterFields.STATUS]: InviteSearchCriteriaInputStatusEnum[];
};

const validationSchema = BuildValidationSchema({
  [FilterFields.STATUS]: yup.array().of(yup.string().oneOf(Object.values(InviteSearchCriteriaInputStatusEnum))),
});

const FiltersBar = (props: TFilterBarProps) => {
  const { dispatch } = useInviteFilterContext();

  const handleOnSearch = (value: string | undefined) =>
    dispatch({
      type: "updateFilters",
      data: {
        genericSearchLike: value,
      },
    });

  return (
    <ListFilterBar<TFormValues>
      {...props}
      showSearchBar
      onSearchChange={handleOnSearch}
      searchPlaceHolder="Search by first name, last name or email"
      formProps={{
        onSubmit: async values => {
          const status = values[FilterFields.STATUS];

          dispatch({
            type: "updateFilters",
            data: {
              status: status.length ? status : undefined,
            },
          });
        },
        validationSchema,
        getFormValues: values => ({
          [FilterFields.STATUS]: values?.[FilterFields.STATUS] || [],
        }),
      }}
    >
      <Stack spacing="sm">
        <FilterCollapse defaultOpen trigger="Status">
          <FormItem name={FilterFields.STATUS}>
            <Checkbox.Group
              options={Object.values(InviteSearchCriteriaInputStatusEnum).map(status => ({
                label: capitalize(status),
                value: status,
              }))}
            />
          </FormItem>
        </FilterCollapse>
      </Stack>
    </ListFilterBar>
  );
};

export default FiltersBar;
