import { useContext } from "react";

import { FinancialAccountOutput } from "@budgeinc/budge-api";
import { createInfiniteScrollDataTableContext } from "components/Table/tableContext";

export const AccountDataTableContext = createInfiniteScrollDataTableContext<FinancialAccountOutput>();

export const useAccountDataTableContext = () => {
  const context = useContext(AccountDataTableContext);

  if (!context) {
    throw new Error("AccountDataTableContext must be used inside an AccountDataTableContext.Provider");
  }

  return context;
};
