/* eslint-disable react/no-array-index-key */
import React, { ReactElement } from "react";
import { Col, Row, TBoxProps, Text } from "@budgeinc/budge-ui-core";

export type TGridTableFooter = TBoxProps & {
  title?: string | ReactElement | null | undefined;
};

interface OwnProps {
  footers: TGridTableFooter[];
}
const GridTableFooter = ({ footers }: OwnProps) => (
  <Row>
    {footers.map(({ title, ...props }, index) => (
      <Col key={index} {...props}>
        {typeof title === "string" ? (
          <Text fw="600" px={14}>
            {title}
          </Text>
        ) : (
          title
        )}
      </Col>
    ))}
  </Row>
);

export default GridTableFooter;
