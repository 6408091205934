import React, { useCallback, useMemo } from "react";
import { Description, InfiniteScrollTable } from "@budgeinc/budge-ui-core";
import { FinancialAccountOutput } from "@budgeinc/budge-api";
import { hasFilters } from "components/ListFilterBar/utils";
import { getAccountColumns } from "./columns";
import { TInfiniteScrollDataTableProps } from "../types";
import { useAccountFilterContext } from "./filter/context";
import { InfiniteScrollDataTableContextType } from "../tableContext";
import { AccountDataTableContext } from "./AccountDataTableContext";

type OwnProps = TInfiniteScrollDataTableProps<FinancialAccountOutput> & {
  includeEmployeeColumns?: boolean;
  updateData: (accounts: FinancialAccountOutput[]) => void;
};

const AccountsTable = ({
  emptyLocal,
  includeEmployeeColumns = false,
  updateData,
  requestState,
  ...props
}: OwnProps) => {
  const filters = useAccountFilterContext();

  const removeRecord = useCallback(
    (recordToRemove: FinancialAccountOutput) => {
      updateData(requestState.data.filter(record => record.id !== recordToRemove.id));
    },
    [requestState]
  );

  const memoedDataTableContext = useMemo<InfiniteScrollDataTableContextType<FinancialAccountOutput>>(
    () => ({
      removeRecord,
    }),
    [updateData, removeRecord]
  );

  return (
    <AccountDataTableContext.Provider value={memoedDataTableContext}>
      <InfiniteScrollTable<FinancialAccountOutput>
        requestState={requestState}
        columns={getAccountColumns(includeEmployeeColumns)}
        local={{
          empty: {
            m: 24,
            title: "No accounts found",
            description: hasFilters(filters) ? `No account match these filters. Edit or clear all filters.` : undefined,
            ...emptyLocal,
          },
          noMoreItems: "No more accounts to load",
        }}
        contentContainerStyle={{ marginBottom: 16 }}
        expandable={record => (
          <Description>
            <Description.Item label="ID">{record.id}</Description.Item>
          </Description>
        )}
        {...props}
      />
    </AccountDataTableContext.Provider>
  );
};

export default AccountsTable;
