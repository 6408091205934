import { EmailOutput } from "@budgeinc/budge-api";
import { DeleteIcon, SuccessIcon, TColumnProps, Text } from "@budgeinc/budge-ui-core";
import DateDisplay from "components/DateDisplay";
import PostmarkExternalButton from "components/Postmark/PostmarkExternalButton";

export const getEmailsColumns = (): TColumnProps<EmailOutput>[] => [
  {
    id: "templatealias",
    title: "Template Alias",
    render: ({ templateAlias }) => <Text>{templateAlias}</Text>,
  },
  {
    id: "responsesubmittedat",
    title: "Response Submitted At",
    render: ({ responseSubmittedAt }) => <DateDisplay numberOfLines={1} value={responseSubmittedAt} showTime />,
  },
  {
    id: "createdat",
    title: "Created At",
    render: ({ createdAt }) => <DateDisplay numberOfLines={1} value={createdAt} showTime />,
  },
  {
    id: "failed",
    title: "Send Successful",
    render: ({ failed }) => (failed ? <DeleteIcon color="red" /> : <SuccessIcon color="green" />),
  },
  {
    title: "",
    id: "action",
    align: "flex-end",
    render: record => <PostmarkExternalButton url={record.messageUrl} />,
  },
];
