import React from "react";
import { Button, useDisclosure } from "@budgeinc/budge-ui-core";
import AddProfileModal from "./AddProfileModal";

interface OwnProps {
  currentAuthProfileIds: string[];
}

const AddProfileButton = ({ currentAuthProfileIds }: OwnProps) => {
  const disclosure = useDisclosure();

  return (
    <>
      <Button
        title="Add Profiles"
        size="xs"
        color="primary"
        variant="gradient"
        alignSelf="flex-end"
        onPress={disclosure.open}
      />
      <AddProfileModal
        isOpen={disclosure.isOpen}
        onClose={disclosure.close}
        currentAuthProfileIds={currentAuthProfileIds}
      />
    </>
  );
};

export default AddProfileButton;
