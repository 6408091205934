import { createListFilterReducer, TListFilterState } from "components/ListFilterBar/reducer";
import { createListFilterContext } from "components/ListFilterBar/context";
import { InviteSearchCriteriaInput } from "@budgeinc/budge-api";
import { useContext } from "react";

export const initialState: TListFilterState<InviteSearchCriteriaInput> = {
  genericSearchLike: undefined,
  status: undefined,
};

export const InviteFilterReducer = createListFilterReducer<InviteSearchCriteriaInput>(initialState);
export const InviteFilterContext = createListFilterContext<InviteSearchCriteriaInput>(initialState);

export const useInviteFilterContext = () => {
  const context = useContext(InviteFilterContext);

  if (!context) {
    throw new Error("InviteFilterContext must be used inside an InviteFilterContext.Provider");
  }

  return context;
};
