import { ProgramTransactionOutputStatusEnum } from "@budgeinc/budge-api";
import { Button, TButtonProps, toast } from "@budgeinc/budge-ui-core";
import useHandleApiError from "hooks/useHandleApiError";
import { useEmployerContext } from "features/employers/contexts/EmployerContext";
import { employersApi, programsApi, programsCrossTenantApi } from "api/BudgeApi";
import { useState } from "react";
import { useTransactionDataTableContext } from "../context";
import { ProgramTransactionAdminOutputWithId } from "../types";

type OwnProps = Omit<TButtonProps, "loading" | "onPress"> & {
  record: ProgramTransactionAdminOutputWithId;
};

const UpdateTransactionStatusButton = ({ record, ...buttonProps }: OwnProps) => {
  const {
    state: { employer },
  } = useEmployerContext();
  const { handleError } = useHandleApiError();
  const { updateRecord } = useTransactionDataTableContext();
  const [loading, setLoading] = useState(false);
  const isOnHold = record.programTransaction.status === ProgramTransactionOutputStatusEnum.Hold;

  const handleUpdate = () => {
    setLoading(true);
    handleError(
      programsCrossTenantApi
        .updateProgramTransaction(employer?.id!, record.id, {
          status: isOnHold ? ProgramTransactionOutputStatusEnum.Submitted : ProgramTransactionOutputStatusEnum.Hold,
        })
        .then(({ data }) => {
          updateRecord?.({
            id: data.programTransaction.id,
            ...data,
          });
          toast.success({
            message: isOnHold ? "Transaction submitted successfully" : "Transaction put on hold successfully",
          });
        })
    ).finally(() => setLoading(false));
  };

  return (
    <Button
      title={isOnHold ? "Remove Hold" : "Put on Hold"}
      color={isOnHold ? "blue" : "yellow"}
      titleTextProps={{
        color: isOnHold ? "white" : "textPrimary",
      }}
      variant="gradient"
      {...buttonProps}
      loading={loading}
      onPress={handleUpdate}
    />
  );
};

export default UpdateTransactionStatusButton;
