import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import { AccountFilterContext, AccountFilterReducer } from "components/Table/AccountsTable/filter/context";
import { getInitialFilters } from "components/Table/AccountsTable/filter/utils";
import FiltersBar from "components/Table/AccountsTable/filter/FiltersBar";
import AccountsTable from "components/Table/AccountsTable";
import { accountsCrossTenantApi, employersApi } from "api/BudgeApi";
import { useInfiniteScrollList } from "@budgeinc/budge-ui-core";
import { useEmployerContext } from "features/employers/contexts/EmployerContext";
import { TListFilterContextType } from "components/ListFilterBar/context";
import { useSearchParams } from "react-router-dom";
import ExportAccountsButton from "./components/ExportAccountsButton";

const EmployerAccountsListPersistFilterId = "employer-accounts-list-filters";

const AccountListTab = () => {
  const {
    state: { employer },
  } = useEmployerContext();
  const [params] = useSearchParams();
  const initialFilters = getInitialFilters(EmployerAccountsListPersistFilterId, params);
  const [filters, dispatch] = useReducer(AccountFilterReducer, initialFilters);

  const handleOnFetch = useCallback(
    ({ page, pageSize }: { page: number; pageSize: number }) =>
      accountsCrossTenantApi
        .searchAccountsForEmployeesOfEmployer(employer?.id!, filters, `${pageSize * (page - 1)}`, pageSize.toString())
        .then(({ data }) => ({ results: data })),
    [JSON.stringify(filters)]
  );

  const { requestState, onEndReached, forceRefresh, updateData } = useInfiniteScrollList({
    onFetch: handleOnFetch,
    skipFirstForceRefresh: true,
  });

  useEffect(() => {
    forceRefresh();
  }, [JSON.stringify(filters)]);

  const memoedContextValues = useMemo<TListFilterContextType>(
    () => ({
      state: filters,
      dispatch,
      initialFilters,
    }),
    [filters, dispatch]
  );

  return (
    <AccountFilterContext.Provider value={memoedContextValues}>
      <FiltersBar persistId={EmployerAccountsListPersistFilterId} extra={<ExportAccountsButton />} />
      <AccountsTable
        keyExtractor={item => item.id}
        includeEmployeeColumns
        requestState={requestState}
        onEndReached={onEndReached}
        updateData={updateData}
      />
    </AccountFilterContext.Provider>
  );
};

export default AccountListTab;
